import ReportingActionType from 'actions/reporting/reportingActionType';

const initialState = {
  closedAccounts: {},
  largeTransactions: {},
  largeTransactionRecipients: [],
  largeTransactionsByPlayer: {},
  largeTransactionsByBrand: {},
  negativeBalance: {},
  balanceAdjustments: {},
  biBetByPlayer: {},
  biBetByCurrency: {},
  biFinancialByPlayer: {},
  biFinancialByCurrency: {},
  playersWallets: {},
  playersWalletsV2: {},
  financialByProvider: [],
  providersTopPositiveGgrs: {},
  providersTopNegativeGgrs: {},
  playersFirstDeposits: {},
  bonusTotalsByPlayer: {},
  bonusTotalsByType: {},
  bonusTotalsByStatus: {},
  bonusTotalsByProvider: {},
  inactivePlayers: {},
  topPlayersByGgr: [],
  topPlayersByGgrDetails: {},
  topPlayersByTransactionType: [],
  topPlayersByTransactionTypeDetails: {},
};

const reportingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ReportingActionType.SAVE_CLOSED_ACCOUNTS:
    return {
      ...state,
      closedAccounts: payload.data,
    };
  case ReportingActionType.RESET_CLOSED_ACCOUNTS:
    return {
      ...state,
      closedAccounts: {},
    };
  case ReportingActionType.SAVE_LARGE_TRANSACTIONS:
    return {
      ...state,
      largeTransactions: payload.data,
    };
  case ReportingActionType.RESET_LARGE_TRANSACTIONS:
    return {
      ...state,
      largeTransactions: {},
    };
  case ReportingActionType.SAVE_LARGE_TRANSACTION_RECIPIENTS:
    return {
      ...state,
      largeTransactionRecipients: payload.data,
    };
  case ReportingActionType.RESET_LARGE_TRANSACTION_RECIPIENTS:
    return {
      ...state,
      largeTransactionRecipients: [],
    };
  case ReportingActionType.SAVE_LARGE_TRANSACTIONS_BY_BRAND:
    return {
      ...state,
      largeTransactionsByBrand: payload.data,
    };
  case ReportingActionType.RESET_LARGE_TRANSACTIONS_BY_BRAND:
    return {
      ...state,
      largeTransactionsByBrand: {},
    };
  case ReportingActionType.SAVE_LARGE_TRANSACTIONS_BY_PLAYER:
    return {
      ...state,
      largeTransactionsByPlayer: payload.data,
    };
  case ReportingActionType.RESET_LARGE_TRANSACTIONS_BY_PLAYER:
    return {
      ...state,
      largeTransactionsByPlayer: {},
    };
  case ReportingActionType.SAVE_NEGATIVE_BALANCE:
    return {
      ...state,
      negativeBalance: payload.data,
    };
  case ReportingActionType.RESET_NEGATIVE_BALANCE:
    return {
      ...state,
      negativeBalance: {},
    };
  case ReportingActionType.SAVE_BALANCE_ADJUSTMENTS_REPORT:
    return {
      ...state,
      balanceAdjustments: payload.data,
    };
  case ReportingActionType.RESET_BALANCE_ADJUSTMENTS_REPORT:
    return {
      ...state,
      balanceAdjustments: {},
    };
  case ReportingActionType.SAVE_BI_BET_BY_PLAYER:
    return {
      ...state,
      biBetByPlayer: payload.data,
    };
  case ReportingActionType.RESET_BI_BET_BY_PLAYER:
    return {
      ...state,
      biBetByPlayer: {},
    };
  case ReportingActionType.SAVE_BI_BET_BY_CURRENCY:
    return {
      ...state,
      biBetByCurrency: payload.data,
    };
  case ReportingActionType.RESET_BI_BET_BY_CURRENCY:
    return {
      ...state,
      biBetByCurrency: {},
    };
  case ReportingActionType.SAVE_BI_FINANCIAL_BY_PLAYER:
    return {
      ...state,
      biFinancialByPlayer: payload.data,
    };
  case ReportingActionType.RESET_BI_FINANCIAL_BY_PLAYER:
    return {
      ...state,
      biFinancialByPlayer: {},
    };
  case ReportingActionType.SAVE_BI_FINANCIAL_BY_CURRENCY:
    return {
      ...state,
      biFinancialByCurrency: payload.data,
    };
  case ReportingActionType.RESET_BI_FINANCIAL_BY_CURRENCY:
    return {
      ...state,
      biFinancialByCurrency: {},
    };
  case ReportingActionType.SAVE_PLAYERS_WALLETS_INFO:
    return {
      ...state,
      playersWallets: payload.data,
    };
  case ReportingActionType.RESET_PLAYERS_WALLETS_INFO:
    return {
      ...state,
      playersWallets: {},
    };
  case ReportingActionType.SAVE_PLAYERS_WALLETS_INFO_V2:
    return {
      ...state,
      playersWalletsV2: payload.data,
    };
  case ReportingActionType.RESET_PLAYERS_WALLETS_INFO_V2:
    return {
      ...state,
      playersWalletsV2: {},
    };
  case ReportingActionType.SAVE_FINANCIAL_BY_PROVIDER:
    return {
      ...state,
      financialByProvider: payload.data,
    };
  case ReportingActionType.RESET_FINANCIAL_BY_PROVIDER:
    return {
      ...state,
      financialByProvider: [],
    };
  case ReportingActionType.SAVE_PROVIDER_TOP_POSITIVE_GGRS:
    return {
      ...state,
      providersTopPositiveGgrs: payload.data,
    };
  case ReportingActionType.RESET_PROVIDER_TOP_POSITIVE_GGRS:
    return {
      ...state,
      providersTopPositiveGgrs: {},
    };
  case ReportingActionType.SAVE_PROVIDER_TOP_NEGATIVE_GGRS:
    return {
      ...state,
      providersTopNegativeGgrs: payload.data,
    };
  case ReportingActionType.RESET_PROVIDER_TOP_NEGATIVE_GGRS:
    return {
      ...state,
      providersTopNegativeGgrs: {},
    };
  case ReportingActionType.SAVE_PLAYERS_FIRST_DEPOSITS:
    return {
      ...state,
      playersFirstDeposits: payload.data,
    };
  case ReportingActionType.RESET_PLAYERS_FIRST_DEPOSITS:
    return {
      ...state,
      playersFirstDeposits: {},
    };
  case ReportingActionType.SAVE_BONUS_TOTALS_BY_PLAYER:
    return {
      ...state,
      bonusTotalsByPlayer: payload.data,
    };
  case ReportingActionType.RESET_BONUS_TOTALS_BY_PLAYER:
    return {
      ...state,
      bonusTotalsByPlayer: {},
    };
  case ReportingActionType.SAVE_BONUS_TOTALS_BY_TYPE:
    return {
      ...state,
      bonusTotalsByType: payload.data,
    };
  case ReportingActionType.RESET_BONUS_TOTALS_BY_TYPE:
    return {
      ...state,
      bonusTotalsByType: {},
    };
  case ReportingActionType.SAVE_BONUS_TOTALS_BY_STATUS:
    return {
      ...state,
      bonusTotalsByStatus: payload.data,
    };
  case ReportingActionType.RESET_BONUS_TOTALS_BY_STATUS:
    return {
      ...state,
      bonusTotalsByStatus: {},
    };
  case ReportingActionType.SAVE_BONUS_TOTALS_BY_PROVIDER:
    return {
      ...state,
      bonusTotalsByProvider: payload.data,
    };
  case ReportingActionType.RESET_BONUS_TOTALS_BY_PROVIDER:
    return {
      ...state,
      bonusTotalsByProvider: {},
    };
  case ReportingActionType.SAVE_INACTIVE_PLAYERS:
    return {
      ...state,
      inactivePlayers: payload.data,
    };
  case ReportingActionType.RESET_INACTIVE_PLAYERS:
    return {
      ...state,
      inactivePlayers: {},
    };
  case ReportingActionType.SAVE_TOP_PLAYERS_BY_GGR:
    return {
      ...state,
      topPlayersByGgr: payload.data,
    };
  case ReportingActionType.RESET_TOP_PLAYERS_BY_GGR:
    return {
      ...state,
      topPlayersByGgr: [],
    };
  case ReportingActionType.SAVE_TOP_PLAYER_BY_GGR_DETAILS:
    return {
      ...state,
      topPlayersByGgrDetails: {
        ...state.topPlayersByGgrDetails,
        [payload.playerId]: payload.data,
      },
    };
  case ReportingActionType.RESET_TOP_PLAYER_BY_GGR_DETAILS:
    return {
      ...state,
      topPlayersByGgrDetails: {},
    };
  case ReportingActionType.SAVE_TOP_PLAYERS_BY_TRANSACTION_TYPE:
    return {
      ...state,
      topPlayersByTransactionType: payload.data,
    };
  case ReportingActionType.RESET_TOP_PLAYERS_BY_TRANSACTION_TYPE:
    return {
      ...state,
      topPlayersByTransactionType: [],
    };
  case ReportingActionType.SAVE_TOP_PLAYER_BY_TRANSACTION_TYPE_DETAILS:
    return {
      ...state,
      topPlayersByTransactionTypeDetails: {
        ...state.topPlayersByTransactionTypeDetails,
        [payload.playerId]: payload.data,
      },
    };
  case ReportingActionType.RESET_TOP_PLAYER_BY_TRANSACTION_TYPE_DETAILS:
    return {
      ...state,
      topPlayersByTransactionTypeDetails: {},
    };
  default:
    return state;
  }
};

export default reportingReducer;
