const RegulatoryActionType = {
  GET_SOFTWARE_CHANGES: 'GET_SOFTWARE_CHANGES',
  EXPORT_SOFTWARE_CHANGES: 'EXPORT_SOFTWARE_CHANGES',

  GET_GAME_RATIO: 'GET_GAME_RATIO',
  EXPORT_GAME_RATIO: 'EXPORT_GAME_RATIO',

  GET_PLAYER_EXCLUSION: 'GET_PLAYER_EXCLUSION',
  EXPORT_PLAYER_EXCLUSION: 'EXPORT_PLAYER_EXCLUSION',

  GET_GAME_TYPES_REPORT: 'GET_GAME_TYPES_REPORT',
  EXPORT_GAME_TYPES_REPORT: 'EXPORT_GAME_TYPES_REPORT',

  GET_BALANCE_ADJUSTMENT: 'GET_BALANCE_ADJUSTMENT',
  EXPORT_BALANCE_ADJUSTMENT: 'EXPORT_BALANCE_ADJUSTMENT',

  GET_SESSIONS: 'GET_SESSIONS',
  EXPORT_SESSIONS: 'EXPORT_SESSIONS',

  GET_MIRRORING_SERVER: 'GET_MIRRORING_SERVER',
  EXPORT_MIRRORING_SERVER: 'EXPORT_MIRRORING_SERVER',

  GET_SAFE_SERVER: 'GET_SAFE_SERVER',
  EXPORT_SAFE_SERVER: 'EXPORT_SAFE_SERVER',

  GET_CHANGE_NOTIFICATION: 'GET_CHANGE_NOTIFICATION',
  EXPORT_CHANGE_NOTIFICATION: 'EXPORT_CHANGE_NOTIFICATION',
  ADD_CHANGE_NOTIFICATION: 'ADD_CHANGE_NOTIFICATION',

  ADD_INACCESSIBILITY_SERVER: 'ADD_INACCESSIBILITY_SERVER',
  DELETE_INACCESSIBILITY_SERVER: 'DELETE_INACCESSIBILITY_SERVER',

  GET_INACCESSIBILITY_SERVER: 'GET_INACCESSIBILITY_SERVER',
  EXPORT_INACCESSIBILITY_SERVER: 'EXPORT_INACCESSIBILITY_SERVER',

  GET_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS: 'GET_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS',
  EXPORT_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS: 'EXPORT_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS',

  GET_SIGNIFICANT_EVENTS_RESTRICTIONS: 'GET_SIGNIFICANT_EVENTS_RESTRICTIONS',
  EXPORT_SIGNIFICANT_EVENTS_RESTRICTIONS: 'EXPORT_SIGNIFICANT_EVENTS_RESTRICTIONS',

  GET_JACKPOT_WIN_RATIO: 'GET_JACKPOT_WIN_RATIO',
  EXPORT_JACKPOT_WIN_RATIO: 'EXPORT_JACKPOT_WIN_RATIO',

  GET_JACKPOT_CONFIGURATION: 'GET_JACKPOT_CONFIGURATION',
  EXPORT_JACKPOT_CONFIGURATION: 'EXPORT_JACKPOT_CONFIGURATION',

  GET_PROMOTIONAL_ITEMS: 'GET_PROMOTIONAL_ITEMS',
  EXPORT_PROMOTIONAL_ITEMS: 'EXPORT_PROMOTIONAL_ITEMS',

  GET_TAXATION_DAILY: 'GET_TAXATION_DAILY',
  EXPORT_TAXATION_DAILY: 'EXPORT_TAXATION_DAILY',

  GET_TAXATION_MONTHLY: 'GET_TAXATION_MONTHLY',
  EXPORT_TAXATION_MONTHLY: 'EXPORT_TAXATION_MONTHLY',

  EXPORT_BETTING_SELF_PROTECTION_REPORT: 'EXPORT_BETTING_SELF_PROTECTION_REPORT',

  GET_EXCLUSIONS: 'GET_EXCLUSIONS',
  EXPORT_EXCLUSIONS: 'EXPORT_EXCLUSIONS',
  SAVE_REGULATORY_REPORT: 'SAVE_REGULATORY_REPORT',
  RESET_REGULATORY_REPORT: 'RESET_REGULATORY_REPORT',

  GET_MONTHLY_ECONOMIC_REPORT: 'GET_MONTHLY_ECONOMIC_REPORT',
  EXPORT_MONTHLY_ECONOMIC_REPORT: 'EXPORT_MONTHLY_ECONOMIC_REPORT',

  GET_JACKPOT_WINNINGS: 'GET_JACKPOT_WINNINGS',
  EXPORT_JACKPOT_WINNINGS: 'EXPORT_JACKPOT_WINNINGS',

  GET_MONTHLY_ECONOMIC_BETTING_2_REPORT: 'GET_MONTHLY_ECONOMIC_BETTING_2_REPORT',
  EXPORT_MONTHLY_ECONOMIC_BETTING_2_REPORT: 'EXPORT_MONTHLY_ECONOMIC_BETTING_2_REPORT',

  GET_PLAY_SESSIONS: 'GET_PLAY_SESSIONS',
  EXPORT_PLAY_SESSIONS: 'EXPORT_PLAY_SESSIONS',

  GET_MONTHLY_ECONOMIC_PLAYER_TAXES: 'GET_MONTHLY_ECONOMIC_PLAYER_TAXES',
  EXPORT_MONTHLY_ECONOMIC_PLAYER_TAXES: 'EXPORT_MONTHLY_ECONOMIC_PLAYER_TAXES',

  GET_MONTHLY_ECONOMIC_BETTING_1_REPORT: 'GET_MONTHLY_ECONOMIC_BETTING_1_REPORT',
  EXPORT_MONTHLY_ECONOMIC_BETTING_1_REPORT: 'EXPORT_MONTHLY_ECONOMIC_BETTING_1_REPORT',

  GET_PLAYERS_LIMITS: 'GET_PLAYERS_LIMITS',
  EXPORT_PLAYERS_LIMITS: 'EXPORT_PLAYERS_LIMITS',

  GET_GAME_SESSIONS: 'GET_GAME_SESSIONS',
  EXPORT_GAME_SESSIONS: 'EXPORT_GAME_SESSIONS',

  GET_GGR_MONTHLY_REPORT: 'GET_GGR_MONTHLY_REPORT',
  EXPORT_GGR_MONTHLY_REPORT: 'EXPORT_GGR_MONTHLY_REPORT',

  GET_BET_EVENTS: 'GET_BET_EVENTS',
  EXPORT_BET_EVENTS: 'EXPORT_BET_EVENTS',

  GET_BONUS_SCHEMAS: 'GET_BONUS_SCHEMAS',
  EXPORT_BONUS_SCHEMAS: 'EXPORT_BONUS_SCHEMAS',

  GET_ONLINE_ACCOUNTS: 'GET_ONLINE_ACCOUNTS',
  EXPORT_ONLINE_ACCOUNTS: 'EXPORT_ONLINE_ACCOUNTS',

  GET_PLAYERS_BETTING: 'GET_PLAYERS_BETTING',
  EXPORT_PLAYERS_BETTING: 'EXPORT_PLAYERS_BETTING',

  GET_BETTING_PERFORMANCE: 'GET_BETTING_PERFORMANCE',
  EXPORT_BETTING_PERFORMANCE: 'EXPORT_BETTING_PERFORMANCE',

  GET_GAME_PERFORMANCE_DAILY: 'GET_GAME_PERFORMANCE_DAILY',
  EXPORT_GAME_PERFORMANCE_DAILY: 'EXPORT_GAME_PERFORMANCE_DAILY',

  GET_JACKPOT_SETTINGS: 'GET_JACKPOT_SETTINGS',
  EXPORT_JACKPOT_SETTINGS: 'EXPORT_JACKPOT_SETTINGS',

  GET_TOTAL_BALANCES: 'GET_TOTAL_BALANCES',
  EXPORT_TOTAL_BALANCES: 'EXPORT_TOTAL_BALANCES',
};

export default RegulatoryActionType;
