import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectAuthUser, selectLocaleLanguage, selectMode } from 'selectors';
import { changeLoadingState } from 'actions/setting/settingActionCreator';
import Routers from './Routers';

const mapStateToProps = ({ settings, auth, locale }) => ({
  isNightMode: selectMode(settings),
  user: selectAuthUser(auth),
  localeLanguage: selectLocaleLanguage(locale),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    changeLoadingState,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Routers);
