import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Brands } from 'components/layout/header';
import { setCurrentBrand } from 'actions/brand/brandActionCreator';
import { selectCurrentBrand, selectCurrentBrandId, selectUserBrands } from 'selectors';

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setCurrentBrand,
  },
  dispatch,
);

const mapStateToProps = ({ auth, brands }) => ({
  brands: selectUserBrands(auth),
  brand: selectCurrentBrand({ auth, brands }),
  brandId: selectCurrentBrandId(brands),
});

export default connect(mapStateToProps, mapDispatchToProps)(Brands);
