import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Layout } from 'antd';
import { withTheme } from 'styled-components';
import { StyledHeader, StyledListFirstItemHeader, StyledList } from 'components/layout/Layout.styled';
import { getSidebarSizes } from 'utils/styledHelpers';
import {
  Logo, Languages, Menu, Partners, TimeZones, Brands, ThemeColorsMode, SystemConversions,
} from 'containers/layout/header/index';
import { useLayout, useConnection, useLogout } from 'hooks';
import { useTranslation } from 'react-i18next';
import { MessageService } from 'services';
import Config from 'config';

import QuickSearch from './QuickSearch';

const sidebarSizes = getSidebarSizes();
const { isBrRegulation } = Config;

const Header = (props) => {
  const {
    isSearch, collapsedWidth, width, changeTokenState, isTokenChanged,
  } = props;

  const {
    partnerSettings, brandSettings, showConversion, timeZoneSettings,
  } = useLayout();

  const { t } = useTranslation();
  const { logout } = useLogout();
  const { connection, setConnection, connect } = useConnection();

  useEffect(() => {
    if (isTokenChanged) {
      changeTokenState(false);
      if (connection) {
        connection.stop();
      }
      connect();
    }
  }, [changeTokenState, connection, isTokenChanged, connect]);

  useEffect(() => {
    connect();
  }, [connect]);

  useEffect(() => {
    // todo temporary not allowed for pm
    if (!isBrRegulation && connection) {
      connection
        .start()
        .then(() => {
          connection.on('ReceiveMessage', (userId, mess) => {
            switch (mess) {
            case 'LogoutOnDisable': {
              return MessageService.error({
                content: t('notification:accountDisabled'),
                onClose: logout,
              });
            }
            case 'LogoutOnDelete': {
              return MessageService.error({
                content: t('notification:accountDeleted'),
                onClose: logout,
              });
            }
            default:
              return null;
            }
          });
        })
        .catch((error) => {
          connection.stop();
          setConnection(null);
          if (error.statusCode !== 401) {
            setTimeout(connect, 5000);
          }
        });
    }
  }, [t, connection, logout, connect, setConnection]);

  return (
    <StyledHeader>
      <Layout.Header id="layoutHeader">
        <Logo collapsedWidth={collapsedWidth} width={width} />
        <StyledList>
          <StyledListFirstItemHeader>
            {(timeZoneSettings?.editMode || timeZoneSettings?.viewMode) && <TimeZones timeZoneSettings={timeZoneSettings} />}
            {(partnerSettings?.editMode || partnerSettings?.viewMode) && <Partners partnerSettings={partnerSettings} />}
            {(brandSettings?.editMode || brandSettings?.viewMode) && <Brands brandSettings={brandSettings} />}
            {showConversion && <SystemConversions showConversion={showConversion} />}
          </StyledListFirstItemHeader>
          {isSearch && <QuickSearch />}
          <Languages />
          <ThemeColorsMode />
          <Menu />
        </StyledList>
      </Layout.Header>
    </StyledHeader>
  );
};

Header.propTypes = {
  isSearch: PropTypes.bool,
  collapsedWidth: PropTypes.string,
  width: PropTypes.string,
  changeTokenState: PropTypes.func.isRequired,
  isTokenChanged: PropTypes.bool,
};

Header.defaultProps = {
  isSearch: true,
  collapsedWidth: sidebarSizes.collapsedWidth,
  width: sidebarSizes.width,
  isTokenChanged: false,
};

export default withTheme(Header);
