const types = {
  view: 'view',
  edit: 'edit',
  add: 'add',
  delete: 'delete',
  statusChange: 'statusChange',
  import: 'import',
  export: 'export',
  manage: 'manage',
  archive: 'archive',
  block: 'block',
  blacklist: 'blacklist',
  startProcess: 'startProcess',
  approve: 'approve',
  decline: 'decline',
  cancel: 'cancel',
  freeze: 'freeze',
  verify: 'verify',
  finish: 'finish',
  markAttention: 'markAttention',
  blockControl: 'blockControl',
  activate: 'activate',
  unlock: 'unlock',
  blockMessages: 'blockMessages',
  close: 'close',
  assign: 'assign',
  endSession: 'endSession',
  privateView1: 'privateView1',
  privateView2: 'privateView2',
  privateView3: 'privateView3',
  privateView4: 'privateView4',
};

const resource = {
  brands: 'brands',
  tools: 'tools',
  userManagement: 'userManagement',
  riskManagement: 'riskManagement',
  bulkStatusUpdate: 'bulkStatusUpdate',
  bulkBlockProvider: 'bulkBlockProvider',
  bulkBlockAccess: 'bulkBlockAccess',
  similarAccounts: 'similarAccounts',
  playersReports: 'playersReports',
  playersTotals: 'playersTotals',
  permissionGroups: 'permissionGroups',
  permissions: 'permissions',
  users: 'users',
  ipWhitelist: 'ipWhitelist',
  brandAutomation: 'brandAutomation',
  gameSegments: 'gameSegments',
  playerSegments: 'playerSegments',
  balanceAdjustment: 'balanceAdjustment',
  blacklist: 'blacklist',
  closedAccounts: 'closedAccounts',
  partners: 'partners',
  financials: 'financials',
  deposits: 'deposits',
  withdrawals: 'withdrawals',
  financialReports: 'financialReports',
  balanceAdjustments: 'balanceAdjustments',
  playersWallets: 'playersWallets',
  contentManagement: 'contentManagement',
  cashierDepositAndWithdrawal: 'cashierDepositandWithdrawal',
  cashierInventory: 'cashierInventory',
  cashierSettings: 'cashierSettings',
  fileManager: 'fileManager',
  translations: 'translations',
  compliance: 'compliance',
  largeTransaction: 'largeTransaction',
  negativeBalance: 'negativeBalance',
  communications: 'communications',
  inboxMessages: 'inboxMessages',
  massMessages: 'massMessages',
  notificationTemplates: 'notificationTemplates',
  systemNotifications: 'systemNotifications',
  businessIntelligence: 'businessIntelligence',
  betAggregations: 'betAggregations',
  paymentAggregations: 'paymentAggregations',
  bonusManagement: 'bonusManagement',
  bonusTemplates: 'bonusTemplates',
  bonus: 'bonus',
  cashback: 'cashback',
  promoCodes: 'promoCodes',
  referralBonus: 'referralBonus',
  bets: 'bets',
  casinoBets: 'casinoBets',
  sportBets: 'sportBets',
  auditLogs: 'auditLogs',
  playerLogs: 'playerLogs',
  userLogs: 'userLogs',
  userManagementLogs: 'userManagementLogs',
  thirdPartyServices: '3rdPartyServices',
  conversionSettingsDepositAndWithdrawals: 'conversionSettingsDepositandWithdrawals',
  conversionSettingsOthers: 'conversionSettingsOthers',
  failedPayments: 'failedPayments',
  largeTransactions: 'largeTransactions',
  playerLimits: 'playerLimits',
  legal: 'legal',
  locationServices: 'locationServices',
  mainSettings: 'mainSettings',
  notificationSettings: 'notificationSettings',
  payments: 'payments',
  casinoProduct: 'casinoProduct',
  sportsBook: 'sportsbook',
  standards: 'standards',
  verificationConfigurations: 'verificationConfigurations',
  verificationPeriodConfigurations: 'verificationPeriodConfigurations',
  players: 'players',
  verticalTags: 'verticalTags',
  accountUpdates: 'accountUpdates',
  actionLogs: 'actionLogs',
  adjustments: 'adjustments',
  horizontalTags: 'horizontalTags',
  kyc: 'kyc',
  legalAgreements: 'legalAgreements',
  blockedProviders: 'blockedProviders',
  blockedPayments: 'blockedPayments',
  limits: 'limits',
  playerNotes: 'playerNotes',
  previousAccounts: 'previousAccounts',
  referees: 'referees',
  sessionLogs: 'sessionLogs',
  testPlayers: 'testPlayers',
  walletHistory: 'walletHistory',
  performanceIndicators: 'performanceIndicators',
  dashboards: 'dashboards',
  customersData: 'customersData',
  bettingData: 'bettingData',
  paymentsData: 'paymentsData',
  bonusesData: 'bonusesData',
  financialTrends: 'financialTrends',
  share: 'share',
  gameManagement: 'gameManagement',
  seoSettings: 'seoSettings',
  systemSettings: 'systemSettings',
  systemConversion: 'systemConversion',
  playerCasinoBets: 'playerCasinoBets',
  playerSportBets: 'playerSportBets',
  playerDeposits: 'playerDeposits',
  playerWithdrawals: 'playerWithdrawals',
  playerMassMessages: 'playerMassMessages',
  playerBonuses: 'playerBonuses',
  playerCashBack: 'playerCashback',
  playerReferralBonus: 'playerReferralBonus',
  games: 'games',
  groups: 'groups',
  overview: 'overview',
  reasonsSystem: 'reasonsSystem',
  taxes: 'taxes',
  taxOnDeposit: 'taxonDeposit',
  taxOnWithdrawal: 'taxonWithdrawal',
  taxOnBetsProfits: 'taxonBetsProfits',
  playerHorizontalTags: 'playerHorizontalTags',
  playerVerticalTags: 'playerVerticalTags',
  uiCustomization: 'uiCustomization',
  businessRulesManagement: 'businessRulesManagement',
  promotionManager: 'promotionManager',
  uiBackgroundImages: 'uiBackgroundImages',
  uiBanner: 'uiBanner',
  uiButtons: 'uiButtons',
  uiColor: 'uiColor',
  uiFont: 'uiFont',
  uiForms: 'uiForms',
  uiIcon: 'uiIcon',
  uiInputs: 'uiInputs',
  uiLogos: 'uiLogos',
  uiMenu: 'uiMenu',
  uiPagesContent: 'uiPagesContent',
  uiPagesList: 'uiPagesList',
  taxSettings: 'taxSettings',
  agSentData: 'agSentData',
  debtWallet: 'debtWallet',
  lobbyManagement: 'lobbyManagement',
  providersAndGamesManagement: 'providersandGamesManagement',
  gamification: 'gamification',
  providers: 'providers',
  providersReport: 'providersReport',
  domainConfigurations: 'domainConfigurations',
  casinoSettings: 'casinoSettings',
  login2FA: 'login2FA',
  pushNotifications: 'pushNotifications',
  playerPushNotifications: 'playerPushNotifications',
  playerBonusNotifications: 'playerBonusNotifications',
  firstDeposits: 'firstDeposits',
  securityLevels: 'securityLevels',
  taxation: 'taxation',
  bonusesReport: 'bonusesReport',
  casinoBetCorrection: 'casinoBetCorrection',
  inactiveAccounts: 'inactiveAccounts',
  playerTagsLogs: 'playerTagsLogs',
  romanianRegulatory: 'romanianRegulatory',
  cypriotRegulatory: 'cypriotRegulatory',
  bettingAndSelfProtection: 'bettingandSelfProtection',
  topPlayers: 'topPlayers',
  releaseNotes: 'releaseNotes',
  gameTypes: 'gameTypes',
  gamificationPlayer: 'gamificationPlayer',
  playerGamificationSpinsAdjustments: 'playerGamificationSpinsAdjustments',
  gamificationSpinsAdjustments: 'gamificationSpinsAdjustments',
  smtpConfigurations: 'smtpConfigurations',
  manageMaintenance: 'manageMaintenance',
  deviceSessions: 'deviceSessions',
  groupsManagement: 'groupsManagement',
  blockAndUnblockPayments: 'blockandUnblockPayments',
  widgets: 'widgets',
  brandJackpots: 'brandJackpots',
  playerComplaints: 'playerComplaints',
  selfAssessments: 'selfAssessments',
  greekRegulatory: 'greekRegulatory',
  greekRegulatoryReport: 'greekRegulatoryReport',
  taxSessionLogs: 'taxSessionLogs',
};

export { types, resource };
