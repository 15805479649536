import BetActionType from './betActionType';

export const getCasinoBets = (data) => ({
  type: BetActionType.GET_CASINO_BETS,
  payload: { data },
});

export const saveCasinoBets = (data) => ({
  type: BetActionType.SAVE_CASINO_BETS,
  payload: { data },
});

export const resetCasinoBets = () => ({
  type: BetActionType.RESET_CASINO_BETS,
});

export const generateCasinoBetsReport = (data) => ({
  type: BetActionType.GENERATE_CASINO_BETS_REPORT,
  payload: { data },
});

export const getPlayerCasinoBets = (playerId, data) => ({
  type: BetActionType.GET_PLAYER_CASINO_BETS,
  payload: { playerId, data },
});

export const savePlayerCasinoBets = (data) => ({
  type: BetActionType.SAVE_PLAYER_CASINO_BETS,
  payload: { data },
});

export const resetPlayerCasinoBets = () => ({
  type: BetActionType.RESET_PLAYER_CASINO_BETS,
});

export const exportPlayerCasinoBets = (playerId, data) => ({
  type: BetActionType.EXPORT_PLAYER_CASINO_BETS,
  payload: { playerId, data },
});

export const getSportBets = (params) => ({
  type: BetActionType.GET_SPORT_BETS,
  payload: { params },
});

export const saveSportBets = (data) => ({
  type: BetActionType.SAVE_SPORT_BETS,
  payload: { data },
});

export const resetSportBets = () => ({
  type: BetActionType.RESET_SPORT_BETS,
});

export const generateSportBetsReport = (params) => ({
  type: BetActionType.GENERATE_SPORT_BETS_REPORT,
  payload: { params },
});

export const getPlayerSportBets = (playerId, params) => ({
  type: BetActionType.GET_PLAYER_SPORT_BETS,
  payload: { playerId, params },
});

export const savePlayerSportBets = (data) => ({
  type: BetActionType.SAVE_PLAYER_SPORT_BETS,
  payload: { data },
});

export const resetPlayerSportBets = () => ({
  type: BetActionType.RESET_PLAYER_SPORT_BETS,
});

export const exportPlayerSportBets = (playerId, params) => ({
  type: BetActionType.EXPORT_PLAYER_SPORT_BETS,
  payload: { playerId, params },
});

export const getSportBetDetails = (betId) => ({
  type: BetActionType.GET_SPORT_BET_DETAILS,
  payload: {
    betId,
  },
});

export const saveSportBetDetails = (data) => ({
  type: BetActionType.SAVE_SPORT_BET_DETAILS,
  payload: {
    data,
  },
});

export const resetSportBetDetails = () => ({
  type: BetActionType.RESET_SPORT_BET_DETAILS,
});

export const getSportBetV2Details = (betId) => ({
  type: BetActionType.GET_SPORT_BET_V2_DETAILS,
  payload: {
    betId,
  },
});

export const getCasinoBetDetails = (betId) => ({
  type: BetActionType.GET_CASINO_BET_DETAILS,
  payload: {
    betId,
  },
});

export const saveCasinoBetDetails = (data) => ({
  type: BetActionType.SAVE_CASINO_BET_DETAILS,
  payload: {
    data,
  },
});

export const resetCasinoBetDetails = () => ({
  type: BetActionType.RESET_CASINO_BET_DETAILS,
});

export const getCasinoBetV2Details = (betId) => ({
  type: BetActionType.GET_CASINO_BET_V2_DETAILS,
  payload: { betId },
});

export const getSportBet = (betId) => ({
  type: BetActionType.GET_SPORT_BET,
  payload: { betId },
});

export const saveSportBet = (data) => ({
  type: BetActionType.SAVE_SPORT_BET,
  payload: { data },
});

export const resetSportBet = () => ({
  type: BetActionType.RESET_SPORT_BET,
});

export const getCasinoBet = (betId) => ({
  type: BetActionType.GET_CASINO_BET,
  payload: { betId },
});

export const saveCasinoBet = (data) => ({
  type: BetActionType.SAVE_CASINO_BET,
  payload: { data },
});

export const resetCasinoBet = () => ({
  type: BetActionType.RESET_CASINO_BET,
});
