import PropTypes from 'prop-types';
import { LabelWidthSubText, StyledHintText } from './Info.styled';

const HintLabelInfo = (props) => {
  const { label, hintText, isUpperCase } = props;
  return (
    <LabelWidthSubText className="hint-label">
      <p>{label}</p>
      <StyledHintText isUpperCase={isUpperCase}>{hintText}</StyledHintText>
    </LabelWidthSubText>
  );
};

HintLabelInfo.propTypes = {
  label: PropTypes.string.isRequired,
  hintText: PropTypes.string.isRequired,
  isUpperCase: PropTypes.bool,
};

HintLabelInfo.defaultProps = {
  isUpperCase: true,
};

export default HintLabelInfo;
