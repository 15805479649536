import styled from 'styled-components';
import { truncate } from 'utils/styledHelpers';

const LabelWidthSubText = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
  p {
    color: inherit;
  }
`;

const StyledHintText = styled.span`
  text-transform: lowercase;
  color: ${(props) => props.theme.colors.filterLabelColor};
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
  text-align: right;
`;

const StyledLabelInfoAndHint = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  gap: 0 0.57rem;

  > div:first-child {
    ${truncate()}
    & > div:first-child {
      ${truncate()}
      &:after {
        content: '*';
        margin-left: 2px;
      }
    }
  }
`;

const StyledBorderedInfo = styled.div`
  display: ${(props) => (props.isInline ? 'inline-flex' : 'flex')};
  align-items: flex-start;
  width: ${(props) => props.width};
  padding: 0.85rem 1.71rem;
  border: ${(props) => (props.noBorder ? 'none' : `1px solid ${props.theme.colors.secondaryBorder}`)};
  color: ${(props) => props.theme.colors.primaryText};
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  margin-bottom: ${(props) => (props.noMarginBottom ? 0 : '1.71rem')};
  margin-top: ${(props) => (props.marginTop ? '.57rem' : '0')};
  background-color: ${(props) => (props.hasBg ? props.theme.colors.secondaryBg : 'unset')};
  max-width: 100%;
  svg {
    margin-right: 1.14rem;
  }
`;
const StyledBorderedInfoText = styled.div`
  font-size: 1rem;
  line-height: 1.57;
  margin-top: ${(props) => (props.marginTop ? '1rem' : '0')};
  text-align: left;
  > div {
    font-size: 1rem;
    text-align: left;
    line-height: 1.57;
  }
`;

export {
  LabelWidthSubText, StyledHintText, StyledLabelInfoAndHint, StyledBorderedInfo, StyledBorderedInfoText,
};
