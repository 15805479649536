import { AppConstants } from 'constants/index';
import { i18n, NetworkService } from 'services';
import { saveRegulatoryReport } from 'actions/regulatory/regulatoryActionCreator';
import qs from 'qs';
import {
  put, all, call, takeLatest,
} from 'redux-saga/effects';
import mime from 'mimetypes';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import RegulatoryActionType from 'actions/regulatory/regulatoryActionType';
import { downloadFile } from 'utils/utils';

import { getError, getMessage } from 'utils/helpers';

function* getSoftwareChanges({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Game, Changes,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Game, Changes], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportSoftwareChanges({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Game, Changes, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Game, Changes, Export], options);
    downloadFile(data, i18n.t('regulatory:softwareChanges'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getGameRatio({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, External, Financial } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Financial], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportGameRatio({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Financial, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Financial, Export], options);
    downloadFile(data, i18n.t('regulatory:gameRatio'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerExclusion({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Player, Exclusions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Player, Exclusions], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPlayerExclusion({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Player, Exclusions, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Player, Exclusions, Export], options);
    downloadFile(data, i18n.t('regulatory:playerExclusion'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getGameTypes({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Game, Types,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Game, Types], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportGameTypes({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Game, Types, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Game, Types, Export], options);
    downloadFile(data, i18n.t('regulatory:gameTypes'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBalanceAdjustment({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Financial, BalanceAdjustment,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Financial, BalanceAdjustment], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBalanceAdjustment({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Financial, BalanceAdjustment, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Financial, BalanceAdjustment, Export], options);
    downloadFile(data, i18n.t('regulatory:balanceAdjustment'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSessions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, External, Sessions } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Sessions], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportSessions({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Sessions, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Sessions, Export], options);
    downloadFile(data, i18n.t('sessions'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSafeServer({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Server, Safe, Reports,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Safe, Reports], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* exportSafeServer({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Server, Safe, Reports, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Safe, Reports, Export], options);
    downloadFile(data, i18n.t('regulatory:safeServer'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getMirroringServer({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportMirroringServer({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, Export], options);
    downloadFile(data, i18n.t('regulatory:mirroringServer'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addChangeNotification({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, ChangeNotification,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, External, Server, Mirroring, Reports, ChangeNotification], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('regulatory:change'), i18n.t('notification:slAdded'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* getChangeNotification({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, ChangeNotification,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, ChangeNotification], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportChangeNotification({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, ChangeNotification, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, ChangeNotification, Export], options);
    downloadFile(data, i18n.t('regulatory:changeNotification'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getInaccessibilityServer({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, Inaccessibility,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, Inaccessibility], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportInaccessibilityServer({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, Inaccessibility, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, Inaccessibility, Export], options);
    downloadFile(data, i18n.t('regulatory:inaccessibilityServer'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addInaccessibilityServer({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, Inaccessibility,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, External, Server, Mirroring, Reports, Inaccessibility], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteInaccessibilityServer({ payload }) {
  try {
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, Inaccessibility,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [ReportingAdmin, External, Server, Mirroring, Reports, Inaccessibility, payload.id]);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSignificantEventsLargeTransactions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, LargeTransactions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, LargeTransactions], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportSignificantEventsLargeTransactions({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, LargeTransactions, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, LargeTransactions, Export], options);
    downloadFile(data, i18n.t('title:largeTransactions'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSignificantEventsRestrictions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, Restrictions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, Restrictions], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportSignificantEventsRestrictions({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, Restrictions, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, Restrictions, Export], options);
    downloadFile(data, i18n.t('title:restrictions'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getJackPotWinRatio({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, JackpotWinRatio,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, JackpotWinRatio], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportJackPotWinRatio({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, JackpotWinRatio, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, JackpotWinRatio, Export], options);
    downloadFile(data, i18n.t('regulatory:jackpotWinRatio'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getJackPotConfiguration({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, JackpotConfiguration,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, JackpotConfiguration], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportJackPotConfiguration({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Server, Mirroring, Reports, JackpotConfiguration, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Server, Mirroring, Reports, JackpotConfiguration, Export], options);
    downloadFile(data, i18n.t('regulatory:jackpotConfiguration'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotionalItems({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, External, PromotionalItems } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, PromotionalItems], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPromotionalItems({ payload }) {
  try {
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, PromotionalItems, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, PromotionalItems, Export], options);
    downloadFile(data, i18n.t('regulatory:promotionalItems'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTaxationDaily({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, External, Taxation } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Taxation], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportTaxationDaily({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Taxation, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Taxation, Export], options);
    downloadFile(data, i18n.t('regulatory:taxationDaily'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTaxationMonthly({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const {
      ReportingAdmin, External, Taxation, Totals,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Taxation, Totals], options);
    yield put(saveRegulatoryReport({ data }));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportTaxationMonthly({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Taxation, Totals, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Taxation, Totals, Export], options);
    downloadFile(data, i18n.t('regulatory:taxationMonthly'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBettingSelfProtectionReport({ payload }) {
  try {
    const { name } = payload;
    const options = {
      params: payload.params,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Regulatory, BetSelfProtection, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Regulatory, BetSelfProtection, Export], options);
    downloadFile(data, i18n.t(name), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getExclusions({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, External, Exclusions } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Exclusions], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportExclusions({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, Exclusions, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, Exclusions, Export], options);
    downloadFile(data, i18n.t('regulatory:exclusions'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getMonthlyEconomicReport({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, External, MonthlyEconomicReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, MonthlyEconomicReport], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportMonthlyEconomicReport({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, MonthlyEconomicReport, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, MonthlyEconomicReport, Export], options);
    downloadFile(data, i18n.t('regulatory:monthlyEconomic'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getJackpotWinnings({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, External, DailyJackpotWinningsReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, DailyJackpotWinningsReport], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportJackpotWinnings({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, DailyJackpotWinningsReport, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, DailyJackpotWinningsReport, Export], options);
    downloadFile(data, i18n.t('regulatory:jackpotWinnings'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getMonthlyEconomicBetting2Report({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, External, MonthlyEconomicBettingReport2 } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, MonthlyEconomicBettingReport2], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportMonthlyEconomicBetting2Report({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, MonthlyEconomicBettingReport2, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, MonthlyEconomicBettingReport2, Export], options);
    downloadFile(data, i18n.t('regulatory:monthlyEconomicBetting2'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlaySession({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, External, PlaySessionReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, PlaySessionReport], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPlaySession({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, PlaySessionReport, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, PlaySessionReport, Export], options);
    downloadFile(data, i18n.t('regulatory:playSession'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getMonthlyEconomicPlayerTaxes({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, External, MonthlyEconomicPlayersTaxReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, MonthlyEconomicPlayersTaxReport], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportMonthlyEconomicPlayerTaxes({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, MonthlyEconomicPlayersTaxReport, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, MonthlyEconomicPlayersTaxReport, Export], options);
    downloadFile(data, i18n.t('regulatory:monthlyEconomicPlayerTaxes'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getMonthlyEconomicBetting1Report({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, External, MonthlyEconomicBettingReport1 } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, MonthlyEconomicBettingReport1], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportMonthlyEconomicBetting1Report({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, MonthlyEconomicBettingReport1, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, MonthlyEconomicBettingReport1, Export], options);
    downloadFile(data, i18n.t('regulatory:monthlyEconomicBetting1'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayersLimits({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, External, DailyLimitReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, DailyLimitReport], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPlayersLimits({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, DailyLimitReport, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, DailyLimitReport, Export], options);
    downloadFile(data, i18n.t('regulatory:limits'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getGameSessions({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, External, GameSessionsReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, GameSessionsReport], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportGameSessions({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, GameSessionsReport, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, GameSessionsReport, Export], options);
    downloadFile(data, i18n.t('regulatory:gameSessions'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getMonthlyGGRReport({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, External, GgrMonthlyReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, GgrMonthlyReport], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportMonthlyGGRReport({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, GgrMonthlyReport, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, GgrMonthlyReport, Export], options);
    downloadFile(data, i18n.t('regulatory:monthlyGGR'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

// function* getBetEvents({ payload }) {
//   try {
//     const options = {
//       params: payload.params,
//       paramsSerializer: (param) => qs.stringify(param),,
//     };
//     const { ReportingAdmin, External, BetEvents } = AppConstants.api;
//     yield put(changeLoadingState(true));
//     const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, BetEvents], options);
//     yield put(saveRegulatoryReport(data));
//     yield put(changeLoadingState(false));
//   } catch (err) {
//     if (err) {
//       const error = getError(err);
//       yield put(setErrorMessage(error));
//     }
//     yield put(changeLoadingState(false));
//   }
// }
//
// function* exportBetEvents({ payload }) {
//   try {
//     const options = {
//       params: payload.params,
//       paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
//       responseType: 'blob',
//     };
//     yield put(changeLoadingState(true));
//     const {
//       ReportingAdmin, External, BetEvents, Export,
//     } = AppConstants.api;
//     const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, BetEvents, Export], options);
//     downloadFile(data, i18n.t('regulatory:gameSessions'), mime.detectExtension(headers['content-type']));
//     yield put(changeLoadingState(false));
//   } catch (err) {
//     if (err) {
//       const error = getError(err);
//       yield put(setErrorMessage(error));
//     }
//     yield put(changeLoadingState(false));
//   }
// }

function* getBonusSchemas({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, External, BonusSchemasReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, BonusSchemasReport], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBonusSchemas({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, BonusSchemasReport, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, BonusSchemasReport, Export], options);
    downloadFile(data, i18n.t('regulatory:exclusions'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getOnlineAccounts({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, External, DailyPlayerAccountReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, DailyPlayerAccountReport], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportOnlineAccounts({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, DailyPlayerAccountReport, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, DailyPlayerAccountReport, Export], options);
    downloadFile(data, i18n.t('regulatory:onlineAccounts'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayersBetting({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, External, DailyPlayersBettingReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, DailyPlayersBettingReport], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPlayersBetting({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, DailyPlayersBettingReport, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, DailyPlayersBettingReport, Export], options);
    downloadFile(data, i18n.t('regulatory:playerTaxes'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBettingPerformance({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    };
    const { ReportingAdmin, External, DailyBettingPerformanceReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, DailyBettingPerformanceReport], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBettingPerformance({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, DailyBettingPerformanceReport, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, DailyBettingPerformanceReport, Export], options);
    downloadFile(data, i18n.t('regulatory:bettingPerformance'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getGamePerformanceDaily({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, External, DailyGamePerformanceReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, DailyGamePerformanceReport], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportGamePerformanceDaily({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, DailyGamePerformanceReport, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, DailyGamePerformanceReport, Export], options);
    downloadFile(data, i18n.t('regulatory:playerTaxes'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getJackpotSettings({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, External, DailyJackpotSettingsReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, DailyJackpotSettingsReport], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportJackpotSettings({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, DailyJackpotSettingsReport, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, DailyJackpotSettingsReport, Export], options);
    downloadFile(data, i18n.t('regulatory:jackpotSettings'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTotalBalances({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, External, TotalBalancesReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, TotalBalancesReport], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportTotalBalances({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, TotalBalancesReport, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, TotalBalancesReport, Export], options);
    downloadFile(data, i18n.t('regulatory:totalBalances'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBetEvents({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, External, BetEventsReport } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, BetEventsReport], options);
    yield put(saveRegulatoryReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBetEvents({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, External, BetEventsReport, Export,
    } = AppConstants.api;
    const { headers, data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, External, BetEventsReport, Export], options);
    downloadFile(data, i18n.t('regulatory:betEvents'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* regulatorySaga() {
  yield all([
    takeLatest(RegulatoryActionType.GET_SOFTWARE_CHANGES, getSoftwareChanges),
    takeLatest(RegulatoryActionType.EXPORT_SOFTWARE_CHANGES, exportSoftwareChanges),
    takeLatest(RegulatoryActionType.GET_GAME_RATIO, getGameRatio),
    takeLatest(RegulatoryActionType.EXPORT_GAME_RATIO, exportGameRatio),
    takeLatest(RegulatoryActionType.GET_PLAYER_EXCLUSION, getPlayerExclusion),
    takeLatest(RegulatoryActionType.EXPORT_PLAYER_EXCLUSION, exportPlayerExclusion),
    takeLatest(RegulatoryActionType.GET_GAME_TYPES_REPORT, getGameTypes),
    takeLatest(RegulatoryActionType.EXPORT_GAME_TYPES_REPORT, exportGameTypes),
    takeLatest(RegulatoryActionType.GET_BALANCE_ADJUSTMENT, getBalanceAdjustment),
    takeLatest(RegulatoryActionType.EXPORT_BALANCE_ADJUSTMENT, exportBalanceAdjustment),
    takeLatest(RegulatoryActionType.GET_SESSIONS, getSessions),
    takeLatest(RegulatoryActionType.EXPORT_SESSIONS, exportSessions),
    takeLatest(RegulatoryActionType.GET_MIRRORING_SERVER, getMirroringServer),
    takeLatest(RegulatoryActionType.EXPORT_MIRRORING_SERVER, exportMirroringServer),
    takeLatest(RegulatoryActionType.GET_SAFE_SERVER, getSafeServer),
    takeLatest(RegulatoryActionType.EXPORT_SAFE_SERVER, exportSafeServer),
    takeLatest(RegulatoryActionType.GET_CHANGE_NOTIFICATION, getChangeNotification),
    takeLatest(RegulatoryActionType.EXPORT_CHANGE_NOTIFICATION, exportChangeNotification),
    takeLatest(RegulatoryActionType.ADD_CHANGE_NOTIFICATION, addChangeNotification),
    takeLatest(RegulatoryActionType.GET_INACCESSIBILITY_SERVER, getInaccessibilityServer),
    takeLatest(RegulatoryActionType.EXPORT_INACCESSIBILITY_SERVER, exportInaccessibilityServer),
    takeLatest(RegulatoryActionType.ADD_INACCESSIBILITY_SERVER, addInaccessibilityServer),
    takeLatest(RegulatoryActionType.DELETE_INACCESSIBILITY_SERVER, deleteInaccessibilityServer),
    takeLatest(RegulatoryActionType.GET_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS, getSignificantEventsLargeTransactions),
    takeLatest(RegulatoryActionType.EXPORT_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS, exportSignificantEventsLargeTransactions),
    takeLatest(RegulatoryActionType.GET_SIGNIFICANT_EVENTS_RESTRICTIONS, getSignificantEventsRestrictions),
    takeLatest(RegulatoryActionType.EXPORT_SIGNIFICANT_EVENTS_RESTRICTIONS, exportSignificantEventsRestrictions),
    takeLatest(RegulatoryActionType.GET_JACKPOT_WIN_RATIO, getJackPotWinRatio),
    takeLatest(RegulatoryActionType.EXPORT_JACKPOT_WIN_RATIO, exportJackPotWinRatio),
    takeLatest(RegulatoryActionType.GET_JACKPOT_CONFIGURATION, getJackPotConfiguration),
    takeLatest(RegulatoryActionType.EXPORT_JACKPOT_CONFIGURATION, exportJackPotConfiguration),
    takeLatest(RegulatoryActionType.GET_PROMOTIONAL_ITEMS, getPromotionalItems),
    takeLatest(RegulatoryActionType.EXPORT_PROMOTIONAL_ITEMS, exportPromotionalItems),
    takeLatest(RegulatoryActionType.GET_TAXATION_DAILY, getTaxationDaily),
    takeLatest(RegulatoryActionType.EXPORT_TAXATION_DAILY, exportTaxationDaily),
    takeLatest(RegulatoryActionType.GET_TAXATION_MONTHLY, getTaxationMonthly),
    takeLatest(RegulatoryActionType.EXPORT_TAXATION_MONTHLY, exportTaxationMonthly),
    takeLatest(RegulatoryActionType.EXPORT_BETTING_SELF_PROTECTION_REPORT, exportBettingSelfProtectionReport),
    takeLatest(RegulatoryActionType.GET_EXCLUSIONS, getExclusions),
    takeLatest(RegulatoryActionType.EXPORT_EXCLUSIONS, exportExclusions),
    takeLatest(RegulatoryActionType.GET_MONTHLY_ECONOMIC_REPORT, getMonthlyEconomicReport),
    takeLatest(RegulatoryActionType.EXPORT_MONTHLY_ECONOMIC_REPORT, exportMonthlyEconomicReport),
    takeLatest(RegulatoryActionType.GET_JACKPOT_WINNINGS, getJackpotWinnings),
    takeLatest(RegulatoryActionType.EXPORT_JACKPOT_WINNINGS, exportJackpotWinnings),
    takeLatest(RegulatoryActionType.GET_MONTHLY_ECONOMIC_BETTING_2_REPORT, getMonthlyEconomicBetting2Report),
    takeLatest(RegulatoryActionType.EXPORT_MONTHLY_ECONOMIC_BETTING_2_REPORT, exportMonthlyEconomicBetting2Report),
    takeLatest(RegulatoryActionType.GET_PLAY_SESSIONS, getPlaySession),
    takeLatest(RegulatoryActionType.EXPORT_PLAY_SESSIONS, exportPlaySession),
    takeLatest(RegulatoryActionType.GET_MONTHLY_ECONOMIC_PLAYER_TAXES, getMonthlyEconomicPlayerTaxes),
    takeLatest(RegulatoryActionType.EXPORT_MONTHLY_ECONOMIC_PLAYER_TAXES, exportMonthlyEconomicPlayerTaxes),
    takeLatest(RegulatoryActionType.GET_MONTHLY_ECONOMIC_BETTING_1_REPORT, getMonthlyEconomicBetting1Report),
    takeLatest(RegulatoryActionType.EXPORT_MONTHLY_ECONOMIC_BETTING_1_REPORT, exportMonthlyEconomicBetting1Report),
    takeLatest(RegulatoryActionType.GET_PLAYERS_LIMITS, getPlayersLimits),
    takeLatest(RegulatoryActionType.EXPORT_PLAYERS_LIMITS, exportPlayersLimits),
    takeLatest(RegulatoryActionType.GET_GAME_SESSIONS, getGameSessions),
    takeLatest(RegulatoryActionType.EXPORT_GAME_SESSIONS, exportGameSessions),
    takeLatest(RegulatoryActionType.GET_GGR_MONTHLY_REPORT, getMonthlyGGRReport),
    takeLatest(RegulatoryActionType.EXPORT_GGR_MONTHLY_REPORT, exportMonthlyGGRReport),
    // takeLatest(RegulatoryActionType.GET_GAME_SESSIONS, getBetEvents),
    // takeLatest(RegulatoryActionType.EXPORT_GAME_SESSIONS, exportBetEvents),
    takeLatest(RegulatoryActionType.GET_BONUS_SCHEMAS, getBonusSchemas),
    takeLatest(RegulatoryActionType.EXPORT_BONUS_SCHEMAS, exportBonusSchemas),
    takeLatest(RegulatoryActionType.GET_ONLINE_ACCOUNTS, getOnlineAccounts),
    takeLatest(RegulatoryActionType.EXPORT_ONLINE_ACCOUNTS, exportOnlineAccounts),
    takeLatest(RegulatoryActionType.GET_PLAYERS_BETTING, getPlayersBetting),
    takeLatest(RegulatoryActionType.EXPORT_PLAYERS_BETTING, exportPlayersBetting),
    takeLatest(RegulatoryActionType.GET_BETTING_PERFORMANCE, getBettingPerformance),
    takeLatest(RegulatoryActionType.EXPORT_BETTING_PERFORMANCE, exportBettingPerformance),
    takeLatest(RegulatoryActionType.GET_GAME_PERFORMANCE_DAILY, getGamePerformanceDaily),
    takeLatest(RegulatoryActionType.EXPORT_GAME_PERFORMANCE_DAILY, exportGamePerformanceDaily),
    takeLatest(RegulatoryActionType.GET_JACKPOT_SETTINGS, getJackpotSettings),
    takeLatest(RegulatoryActionType.EXPORT_JACKPOT_SETTINGS, exportJackpotSettings),
    takeLatest(RegulatoryActionType.GET_TOTAL_BALANCES, getTotalBalances),
    takeLatest(RegulatoryActionType.EXPORT_TOTAL_BALANCES, exportTotalBalances),
    takeLatest(RegulatoryActionType.GET_BET_EVENTS, getBetEvents),
    takeLatest(RegulatoryActionType.EXPORT_BET_EVENTS, exportBetEvents),
  ]);
}
