import { createSelector } from 'reselect';

const selectCashBacksV2 = (state) => state.cashBacksV2;
export const selectCashBacksV2Data = (state) => selectCashBacksV2(state).data;
export const selectCashBacksV2Total = (state) => selectCashBacksV2(state).total;

export const selectCashBackV2 = (state) => state.cashBackV2;
export const selectCashBackV2Name = (state) => selectCashBackV2(state).name;

const selectPlayerCashBacksV2 = (state) => state.playerCashBacksV2;
export const selectPlayerCashBacksV2Data = (state) => selectPlayerCashBacksV2(state)?.data;
export const selectPlayerCashBacksV2Total = (state) => selectPlayerCashBacksV2(state)?.total;
export const selectPlayerCashBackV2Periods = (state) => state.playerCashBackV2Periods;
export const selectPlayerAccountCashBackV2Period = (state, id) => createSelector(
  () => selectPlayerCashBackV2Periods(state),
  (data) => data[id] || [],
)();
const selectCashBackV2Statistics = (state) => state.statistics;
export const selectCashBackV2StatisticsData = (state) => selectCashBackV2Statistics(state).data;
export const selectCashBackV2StatisticsTotal = (state) => selectCashBackV2Statistics(state).total;
const selectCashBackV2Players = (state) => state.players;
export const selectCashBackV2PlayersData = (state) => selectCashBackV2Players(state).data;
export const selectCashBackV2PlayersTotal = (state) => selectCashBackV2Players(state).total;
export const selectCashBackV2IncludedPlayers = (state) => state.includedPlayers;
