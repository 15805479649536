const PlayerSessionActionType = {
  GET_PLAYER_SESSIONS: 'GET_PLAYER_SESSIONS',
  SAVE_PLAYER_SESSIONS: 'SAVE_PLAYER_SESSIONS',
  RESET_PLAYER_SESSIONS: 'RESET_PLAYER_SESSIONS',

  GET_PLAYER_ACTIVE_SESSIONS: 'GET_PLAYER_ACTIVE_SESSIONS',
  SAVE_PLAYER_ACTIVE_SESSIONS: 'SAVE_PLAYER_ACTIVE_SESSIONS',
  RESET_PLAYER_ACTIVE_SESSIONS: 'RESET_PLAYER_ACTIVE_SESSIONS',

  END_PLAYER_SESSIONS: 'END_PLAYER_SESSIONS',

  GET_WEB_SESSIONS: 'GET_WEB_SESSIONS',
  SAVE_WEB_SESSIONS: 'SAVE_WEB_SESSIONS',
  RESET_WEB_SESSIONS: 'RESET_WEB_SESSIONS',
  GENERATE_WEB_SESSIONS: 'GENERATE_WEB_SESSIONS',

  GET_APPLICATION_SESSIONS: 'GET_APPLICATION_SESSIONS',
  SAVE_APPLICATION_SESSIONS: 'SAVE_APPLICATION_SESSIONS',
  RESET_APPLICATION_SESSIONS: 'RESET_APPLICATION_SESSIONS',
  GENERATE_APPLICATION_SESSIONS: 'GENERATE_APPLICATION_SESSIONS',

  GET_PLAYER_TAX_SESSIONS: 'GET_PLAYER_TAX_SESSIONS',
  SAVE_PLAYER_TAX_SESSIONS: 'SAVE_PLAYER_TAX_SESSIONS',
  RESET_PLAYER_TAX_SESSIONS: 'RESET_PLAYER_TAX_SESSIONS',
};

export default PlayerSessionActionType;
