import PlayerSessionActionType from 'actions/playerSession/playerSessionActionType';

const initialState = {
  sessions: {},
  playerActiveSessions: [],
  applicationSessions: {},
  webSessions: {},
  taxSessions: {},
};

const playerSessionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case PlayerSessionActionType.SAVE_PLAYER_SESSIONS:
    return {
      ...state,
      sessions: payload.data,
    };
  case PlayerSessionActionType.RESET_PLAYER_SESSIONS:
    return {
      ...state,
      sessions: {},
    };
  case PlayerSessionActionType.SAVE_PLAYER_ACTIVE_SESSIONS:
    return {
      ...state,
      playerActiveSessions: payload.data,
    };
  case PlayerSessionActionType.RESET_PLAYER_ACTIVE_SESSIONS:
    return {
      ...state,
      playerActiveSessions: [],
    };
  case PlayerSessionActionType.SAVE_APPLICATION_SESSIONS:
    return {
      ...state,
      applicationSessions: payload.data,
    };
  case PlayerSessionActionType.RESET_APPLICATION_SESSIONS:
    return {
      ...state,
      applicationSessions: {},
    };
  case PlayerSessionActionType.SAVE_WEB_SESSIONS:
    return {
      ...state,
      webSessions: payload.data,
    };
  case PlayerSessionActionType.RESET_WEB_SESSIONS:
    return {
      ...state,
      webSessions: {},
    };
  case PlayerSessionActionType.SAVE_PLAYER_TAX_SESSIONS:
    return {
      ...state,
      taxSessions: payload.data,
    };
  case PlayerSessionActionType.RESET_PLAYER_TAX_SESSIONS:
    return {
      ...state,
      taxSessions: {},
    };
  default:
    return state;
  }
};

export default playerSessionReducer;
