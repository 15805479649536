import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import PlayerSessionActionType from 'actions/playerSession/playerSessionActionType';
import NetworkService from 'services/networkService';
import {
  saveApplicationSessions, savePlayerActiveSessions, savePlayerSessions, savePlayerTaxSessions, saveWebSessions,
} from 'actions/playerSession/playerSessionActionCreator';
import { AppConstants } from 'constants/index';
import {
  changeGenerateReportEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import { getError, getMessage } from 'utils/helpers';
import { i18n } from 'services';
import { changePlayerActionEndedStatus } from 'actions/player/playerActionCreator';
import qs from 'qs';

function* getPlayerSessions({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Player, Sessions } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Player, Sessions, playerId], options);
    yield put(savePlayerSessions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerActiveSession({ payload }) {
  try {
    const { playerId } = payload;
    yield put(changeLoadingState(true));
    const { PlayersAdmin, Actions, ActiveSessions } = AppConstants.api;
    const response = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Actions, ActiveSessions, playerId], {
      params: payload.data,
    });
    yield put(savePlayerActiveSessions(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* endPlayerSessions({ payload }) {
  try {
    const { playerId, data } = payload;
    const options = {
      data,
    };
    const {
      PlayersAdmin, Actions, ActiveSessions, End,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [PlayersAdmin, Actions, ActiveSessions, playerId, End], options);
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('sessions'), i18n.t('notification:plEnded'))));
    yield put(changePlayerActionEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getApplicationSessions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, DeviceSession, ApplicationSessions } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, DeviceSession, ApplicationSessions], options);
    yield put(changeLoadingState(false));
    yield put(saveApplicationSessions(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateApplicationSessions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, DeviceSession, ApplicationSessions, Generate,
    } = AppConstants.api;

    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, DeviceSession, ApplicationSessions, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getWebSessions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, DeviceSession, WebSessions } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, DeviceSession, WebSessions], options);
    yield put(changeLoadingState(false));
    yield put(saveWebSessions(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateWebSessions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, DeviceSession, WebSessions, Generate,
    } = AppConstants.api;

    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, DeviceSession, WebSessions, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerTaxSessions({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Player, V2, TaxSessions,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, V2, TaxSessions, playerId], options);
    yield put(savePlayerTaxSessions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* playerSessionSaga() {
  yield all([
    takeLatest(PlayerSessionActionType.GET_PLAYER_SESSIONS, getPlayerSessions),
    takeLatest(PlayerSessionActionType.GET_PLAYER_ACTIVE_SESSIONS, getPlayerActiveSession),
    takeLatest(PlayerSessionActionType.END_PLAYER_SESSIONS, endPlayerSessions),
    takeLatest(PlayerSessionActionType.GET_APPLICATION_SESSIONS, getApplicationSessions),
    takeLatest(PlayerSessionActionType.GENERATE_APPLICATION_SESSIONS, generateApplicationSessions),
    takeLatest(PlayerSessionActionType.GET_WEB_SESSIONS, getWebSessions),
    takeLatest(PlayerSessionActionType.GENERATE_WEB_SESSIONS, generateWebSessions),
    takeLatest(PlayerSessionActionType.GET_PLAYER_TAX_SESSIONS, getPlayerTaxSessions),
  ]);
}
