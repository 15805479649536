import _ from 'lodash';

const systemPartnerId = 1;

const channels = {
  SMS: 1,
  Email: 2,
  Inbox: 3,
  PushNotification: 4,
};

const messageCategories = {
  Promotion: 2,
  Newsletter: 3,
  Notification: 4,
  Bonus: 5,
  Other: 1,
};

const notificationPreferences = {
  Deposit: 1,
  Withdrawal: 2,
  Promotion: 4,
  Bonuses: 8,
};

const notificationTypes = {
  Sms: 1,
  Email: 2,
  PushNotification: 4,
};

const playerStatuses = {
  Active: 1,
  Blocked: 2,
  BlockedForSport: 4,
  BlockedForCasino: 8,
  BlockedForWithdrawals: 16,
  BlockedForTransactions: 32,
  BlockedForDeposits: 64,
  BlockedForUnverified: 128,
};

const playerBlockedStatuses = _.pickBy(playerStatuses, (val) => [
  playerStatuses.Blocked,
  playerStatuses.BlockedForSport,
  playerStatuses.BlockedForCasino,
  playerStatuses.BlockedForWithdrawals,
  playerStatuses.BlockedForTransactions,
  playerStatuses.BlockedForDeposits,
  playerStatuses.BlockedForUnverified,
].includes(val));

const templateStatus = {
  Ready: 0,
  Draft: 1,
};

const massMessageStatuses = {
  Sent: 3,
  Failed: 4,
  Sending: 6,
};

const inboxStatuses = {
  New: 1,
  SendByUser: 2,
  ReadByPlayer: 3,
  Deleted: 4,
};

const genders = {
  Male: 1,
  Female: 2,
};

const marketingStatuses = {
  New: 1,
  Common: 2,
  Active: 3,
  Dormant: 4,
  Deactivated: 5,
  Returned: 6,
  Churn: 7,
};

const senderTypes = {
  Player: 0,
  System: 1,
  User: 2,
};

const domainStatuses = {
  Active: 0,
  Pending: 1,
  Verified: 2,
  Failed: 3,
};

const bonuses = {
  Casino: 1,
  Sport: 2,
  Special: 3,
  Spin: 4,
};

const allBonusTypes = {
  FreeBet: 101,
  WagerStandard: 102,
  WagerReal: 103,

  SportWagering: 200,
  SportFreeBet: 201,
  SportRealWagering: 202,

  RealMoney: 300,

  FreeSpin: 400,
  FreeAmount: 401,
};

const bonusStatuses = {
  Scheduled: 0,
  Active: 1,
  Canceled: 2,
  PromotionCanceled: 3,
  Finished: 4,
  PartialCancelled: 5,
  Pending: 6,
  InProcess: 7,
};

const campaignV2Statuses = {
  Draft: 0,
  Scheduled: 1,
  Active: 2,
  // Canceled: 3,
  // PromotionCanceled: 4,
  Finished: 5,
  // PartialCancelled: 6,
  Pending: 7,
  InProcess: 8,
};

const playerBonusStatuses = {
  New: 1,
  Active: 2,
  Finished: 3,
  Canceled: 4,
  Expired: 5,
  Pending: 6,
  Paused: 7,
};

const kycDocumentTypes = {
  Identity: 1,
  UtilityBill: 2,
  Other: 3,
  Citizenship: 4,
};

const periods = {
  Daily: 0,
  Weekly: 1,
  Monthly: 2,
};

const kycDocumentStates = {
  Pending: 1,
  InProcess: 2,
  Approved: 3,
  Rejected: 4,
  Verified: 5,
  Archived: 6,
  Expired: 7,
};

const promoCodeTypes = {
  Single: 0,
  Multiple: 1,
  External: 2,
};

const markAttentionLevels = {
  None: 0,
  Standard: 1,
  High: 2,
  Critical: 3,
};

const smsProviders = {
  AfricasTalking: 1,
  Gateway: 2,
  Twilio: 3,
  Blackbox: 4,
  MobiPace: 5,
  Nikita: 6,
  Dexatel: 7,
  Orchard: 8,
  BudgetSMS: 9,
  SmartSMSSolutions: 10,
  betaSMS: 11,
  RPMMobile: 12,
  InfoBip: 13,
  iCell: 14,
};

const smtpProviders = {
  Digitain: 1,
  Custom: 2,
};

const transactionTypes = {
  CreditToClient: -1,
  DebitFromClient: 1,
};

const adjustmentOperationType = {
  ReferralCommission: 1,
  Cashback: 3,
  Correction: 4,
  TournamentPrize: 5,
  Deposit: 6,
  Withdrawal: 7,
  Jackpot: 8,
  TaxOnDeposit: 9,
  TaxOnWithdrawal: 10,
  DebtBalance: 11,
  TaxOnBetProfit: 12,
};

const playerActions = {
  EndSession: 0,
  BlockSportsbook: 1,
  BlockCasino: 2,
  BlockTransactions: 3,
  BlockWithdrawals: 4,
  BlockAccount: 5,
  BlockMessages: 6,
  Unblock: 7,
  MarkAttention: 8,
  RemoveAttention: 9,
  Unlock: 10,
  AccountClosure: 11,
  Inactive: 12,
  Activate: 13,
  Lock: 14,
  Blacklist: 15,
  Whitelist: 16,
  BlockDeposits: 19,

  UnblockWithdrawals: 17,
  UnblockDeposits: 18,
  UnblockMessages: 20,
  DisableLogin2FA: 21,
  EnableLogin2FA: 22,
  BlockUnverify: 23,
  UnblockUnverify: 24,

  UnblockSportsbook: 1000,
  UnblockCasino: 1001,
  UnblockTransactions: 1002,
  UnblockAccount: 1003,
  VerifyEmail: 1004,
  UnVerifyEmail: 1005,
  VerifyIdentity: 1006,
  UnVerifyIdentity: 1007,
  VerifyMobile: 1008,
  UnVerifyMobile: 1009,
  VerifyAddress: 1010,
  UnVerifyAddress: 1011,
  CompletePhysicalVerification: 1012,
  IncompletePhysicalVerification: 1013,
  VerifyCitizenship: 1014,
  UnVerifyCitizenship: 1015,
};

const walletType = {
  Rollover: 1,
  Unused: 2,
  Used: 3,
  Bonus: 4,
  Booked: 5,
  Debt: 6,
  SportBonus: 90,
};

const thirdPartyCodeType = {
  script: 1,
  style: 2,
};

const thirdPartyCodePosition = {
  head: 1,
  body: 2,
};

const providerSpecificType = {
  SportBooks: 1,
  Casino: 2,
};

const playerVerification = {
  Email: 1,
  Mobile: 2,
  Address: 4,
  Identity: 8,
  Physical: 16,
  Citizenship: 32,
};

const cashBackType = {
  Fixed: 1,
  Dynamic: 2,
  Series: 3,
};

const cashbackTransferType = {
  Manual: 2,
  Automatic: 1,
};

const captchaType = {
  Digitain: 1,
  Google2: 2,
  Google3: 3,
};

const bonusCommonStatuses = {
  Scheduled: 1,
  Active: 2,
  Disabled: 3,
  Finished: 4,
};

const cashBackReferralPeriodStatus = {
  Accumulated: 1,
  Transferred: 2,
  Failed: 3,
};

const brandStatuses = {
  Active: 1,
  Disabled: 2,
  ActivitiesDisabled: 3,
  VersionNamesDisabled: 4,
  Maintenance: 5,
};

const sportsBookReasons = {
  Malfunction: 1,
  Maintenance: 2,
  ContractIssue: 3,
  Other: 4,
};

const testPlayerAccountType = {
  Temporary: 1,
  Permanent: 2,
};

const testPlayerAccountStatus = {
  Free: 1,
  Busy: 2,
  Closed: 3,
};

const similarAccountDetectionArea = {
  Session: 1,
  Registration: 2,
};

const similarAccountParameterType = {
  Ip: 1,
  DeviceId: 2,
  BrowserFamily: 3,
};

const referralFinancialActivity = {
  Deposits: 0,
  Bets: 1,
  GGR: 2,
};

const referralReferees = {
  All: 1,
  New: 2,
};

const referralBonusType = {
  Dynamic: 1,
  Progressive: 2,
  Prize: 3,
};

const referralBonusAmount = {
  perReferee: 0,
  allReferees: 1,
};

const referralActivities = {
  signUp: 0,
  verification: 1,
  deposit: 2,
  bet: 3,
};

const selfExclusionPeriods = {
  None: 0,
  Months2: 1,
  Months3: 2,
  Months6: 3,
  Months8: 4,
  Year1: 5,
  Years2: 6,
  Years5: 7,
  Indefinite: 100,
};

const playerReasons = {
  ActionControl: 1,
  AccountClosure: 4,
  DepositLimit: 8,
  BetLimit: 16,
  WithdrawalLimit: 32,
  SelfExclusion: 64,
  Timeout: 128,
};

const timeoutPeriod = {
  None: 0,
  Day1: 1,
  Days2: 2,
  Days3: 3,
  Days4: 4,
  Days5: 5,
  Days6: 6,
  Week1: 7,
  Weeks2: 8,
  Weeks3: 9,
  Month1: 10,
};

const limitPeriod = {
  Day1: 0,
  Days7: 1,
  Month1: 2,
  Year1: 3,
};

const limitationTypes = {
  SelfExclusion: 0,
  Timeout: 1,
  Deposit: 2,
  Withdrawal: 3,
  Bet: 4,
  RealityCheck: 5,
  AccessLimit: 6,
  LossLimit: 7,
  SingleBet: 8,
  GameTimeLimit: 9,
};

const sportBonusBetType = {
  Single: 2,
  Multi: 3,
  Express: 1,
};

const expressOddType = {
  Total: 1,
  Minimum: 2,
};

const requestMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const paymentRequestStatuses = {
  Initialized: 0,
  Pending: 1,
  InProcess: 2,
  CancelledByUser: 3,
  CancelledByPlayer: 4,
  Failed: 5,
  Declined: 7,
  Frozen: 8,
  PayPending: 9,
  Paid: 10,
  OnHold: 11,
};

const betStates = {
  New: 1,
  Won: 2,
  Lost: 3,
  Cancelled: 4,
};

const sportBetTypes = {
  Single: 1,
  System: 2,
  Express: 3,
};

const largeTransactionTypes = {
  Deposit: 1,
  Bet: 2,
  Win: 3,
  Withdrawal: 4,
  Other: 5,
};

const playerLimitsPeriods = {
  Immediately: 0,
  After24Hours: 1,
  After48Hours: 2,
  After7Days: 3,
};

const paymentTransactionTypes = {
  Deposit: 1,
  Withdrawal: 2,
  All: 3,
};

const playerPaymentRelations = {
  AllPaymentMethods: 1,
  UnusedPaymentMethods: 2,
};

const legalAgreementVersionStatuses = {
  Pending: 1,
  Current: 2,
  Old: 3,
};

const brandVerificationDocumentTypes = {
  Passport: 'passport',
  IdCard: 'idCard',
  DrivingLicense: 'drivingLicense',
  Selfie: 'selfie',
  BankCard: 'bankCard',
  UtilityBills: 'utilityBills',
  TaxBills: 'taxBills',
  BankStatement: 'bankStatement',
  RentAgreement: 'rentAgreement',
  InsuranceAgreement: 'insuranceAgreement',
  ResidencyCard: 'residencyCard',
  PaymentMethod: 'paymentMethod',
  Other: 'other',
};

const kycDocumentOptions = {
  Passport: 1,
  IDCard: 2,
  DrivingLicense: 4,
  Selfie: 8,
  BankCard: 16,
  UtilityBills: 32,
  TaxBills: 64,
  BankStatement: 128,
  RentAgreement: 256,
  InsuranceAgreement: 512,
  ResidencyCard: 1024,
  PaymentMethod: 2048,
  Other: 4096,
};

const paymentType = {
  Online: 0,
  Terminal: 1,
  Offline: 2,
  Agent: 3,
};

const paymentActivities = {
  Both: 0,
  Deposit: 1,
  Withdrawal: 2,
};

const processingTypes = {
  ReceiptConfirmTerminal: 1,
  ReceiptConfirmWeb: 2,
  DepositUpdateOneStep: 3,
  DepositUpdateTwoStep: 4,
  StandardWithdrawal: 5,
  StandardWithdrawalWithCheckPayeeData: 6,
  TwoStepWithdrawal: 7,
  TwoStepWithdrawalInstantRedirection: 8,
};

const paymentMethodsFeesTypes = {
  OperatorDepositFee: 1,
  OperatorWithdrawalFee: 2,
  OperatorWithdrawalFeeForUsed: 3,
};

const paymentMethodLimitTypes = {
  MinDepositAmount: 1,
  MaxDepositAmount: 2,
  MinWithdrawalAmount: 3,
  MaxWithdrawalAmount: 4,
};

const paymentMethodActionTypes = {
  Block: 1,
  Unblock: 2,
};

const paymentInventoryShowInfo = {
  Deposit: 1,
  Withdrawal: 2,
};

const paymentProcessingOption = {
  None: 1,
  Immediately: 2,
  Custom: 3,
};

const paymentProcessingRangeUnit = {
  Minute: 1,
  Hour: 2,
};

const cashierOtherId = 0;

const tagNoCategoryId = 99999;

const cashierPaymentSettings = {
  DepositLimits: 1,
  DepositFee: 2,
  MinMaxDepositAmount: 4,
  DepositFeeCalculator: 8,
  PlayerWithdrawalLimit: 16,
  UnusedBalanceWithdrawalFee: 32,
  UsedBalanceWithdrawalFee: 64,
  MinMaxWithdrawalAmount: 128,
  WithdrawableAmount: 256,
  WithdrawalFeeCalculator: 512,
};

const cashbackCalculationFrom = {
  FromBets: 1,
  FromNetDeposits: 2,
};

const cashbackParticipateRules = {
  ExcludePlayersNegativeLifetimeNet: 1,
  IncludeBonusRedeems: 2,
  IncludeRealMoneyBonuses: 4,
};

const allDeviceTypes = {
  Unknown: 1,
  Betshop: 2,
  WebDesktop: 4,
  WebMobile: 8,
  Wap: 16,
  Terminal: 32,
  Android: 64,
  Ios: 128,
  POS: 256,
  LiteBet: 512,
  WebOldMobile: 1024,
  PwaApp: 2048,
};

const deviceTypes = {
  Other: 1,
  WebDesktop: 4,
  WebMobile: 8,
  Android: 64,
  Ios: 128,
  PwaApp: 2048,
};

const selectedPlayersErrorStatus = {
  TimeOut: 1,
  InvalidKey: 2,
  MissingCurrency: 4,
  SelfExcluded: 8,
  Blocked: 16,
  BlockTransactions: 32,
  BlockedInSport: 64,
  BlockedInCasino: 128,
  Deleted: 256,
  BlockForDeposit: 512,
  BlockForWithdrawal: 1024,
};

const quickSearchResponseItemType = {
  Player: 1,
  Deposit: 2,
  Withdrawal: 3,
  SportBet: 4,
  CasinoBet: 5,
};

const walletTransactionTypes = {
  Sport: 0,
  Casino: 1,
  Deposit: 2,
  Withdrawal: 3,
  BalanceAdjustment: 4,
  CashBack: 5,
  SportBonus: 6,
  CasinoBonus: 7,
  SpecialBonus: 8,
};

const dashboardIcons = {
  FinancialIcon: 1,
  AnalyticsIcon: 2,
  PlayersIcon: 3,
  CasinoIcon: 4,
  SportTennisIcon: 5,
  BonusEngineIcon: 7,
  OtherIcon: 6,
};

const dashboardWidgetDataType = {
  Registrations: 1,
  Sessions: 2,
  DistinctSessions: 4,
  NewDepositors: 8,
  Referees: 16,
  TotalBonuses: 32,
  TotalRedeem: 64,
  TotalBets: 128,
  Wins: 256,
  Ggr: 512,
  Ngr: 1024,
  Deposits: 2048,
  Withdrawals: 4096,
  Hold: 8192,
  NewBets: 16384,
  LostBets: 32768,
  CancelledBets: 65536,
};

const dashboardWidgetAggregationCriteria = {
  Country: 1,
  PaymentMethod: 2,
  Game: 3,
  Provider: 4,
  DeviceType: 5,
  Category: 6,
  Status: 7,
  BonusType: 8,
  BonusFamily: 9,
  City: 10,
};

const dashboardWidgetLimitType = {
  Top: 1,
  Bottom: 2,
  Custom: 3,
};

const dashboardWidgetChartType = {
  LineChart: 1,
  BarChart: 2,
  ColumnChart: 3,
  PieChart: 4,
  AreaChart: 5,
  StackedBarChart: 6,
  NumericWidget: 7,
};

const dashboardWidgetPeriod = {
  Today: 1,
  CurrentWeek: 2,
  LastWeek: 3,
  CurrentMonth: 4,
  LastMonth: 5,
  LastQuarter: 6,
  CurrentQuarter: 7,
  CurrentYear: 8,

  YearPeriod: 9,
  Last6MonthPeriod: 10,
  QuarterPeriod: 11,
  MonthPeriod: 12,
  WeekPeriod: 13,
};

const lobbyWidgetTypes = {
  GameWidget: 1,
  GroupWidget: 2,
  ProviderWidget: 3,
  ActionLinkWidgets: 4,
  TopWinWidget: 5,
  TopXWidget: 6,
  RandomGameWidget: 7,
  ProvidersAndGamesWidget: 8,
  SingleGameWidget: 9,
  JackpotWidget: 10,
  ImgWidget: 11,
  CollectionWidget: 12,
  BannerWidget: 13,
  StoryWidget: 14,
};

const lobbyWidgetGridTypes = {
  Standard: 1,
  Advanced: 2,
  Vertical: 3,
  Horizontal: 4,
};

const lobbyWidgetTopWinsTypes = {
  DailyWins: 1,
  MonthlyWins: 2,
  MyWins: 3,
};

const lobbyWidgetSectionTypes = {
  Home: 1,
  Navigation: 2,
};

const dashboardAggregationPeriodBy = {
  SemiAnnual: 1,
  Quarterly: 2,
  Monthly: 3,
  Weekly: 4,
  Daily: 5,
};

const dashboardValueTypes = {
  Amount: 1,
  Count: 2,
};

const betTypes = {
  Casino: 1,
  Sport: 2,
};

const logicTypes = {
  Real: 1,
  Bonus: 2,
};

const seoSocialMediaType = {
  OpenGraph: 1,
  Twitter: 2,
};

const gameState = {
  New: 7,
  Promo: 8,
  Fixed: 9,
};

const lobbyTypes = {
  Casino: 1,
  LiveCasino: 2,
  VirtualSports: 4,
  TVGames: 8,
  SkillGames: 16,
};

const gameOrderTypes = {
  GGR: 1,
  PlayedRounds: 2,
  Likes: 3,
  Name: 4,
  CreationDate: 5,
  DistinctPlayerCount: 6,
  Favorites: 7,
  CustomOrdering: 8,
};

const playerContactInfoVerificationTypes = {
  Email: 1,
  Mobile: 2,
};

const playerPhysicalVerificationTypes = {
  Address: 1,
  Identity: 2,
  Physical: 3,
  Citizenship: 4,
};

const sportPlayerStatus = {
  Common: 1,
  New: 2,
  Female: 3,
  VipUser: 4,
  Terminal: 5,
  Arbitage: 6,
  Problem: 7,
  Special: 8,
  Marker: 9,
  TradingAnalysing: 10,
  LateBetting: 11,
  Middler: 12,
  Betshop: 13,
  Professional: 14,
  HighValue: 15,
  VipSpecial: 16,
  BonusHunter: 17,
  Capper: 18,
};

const boolean = {
  No: 0,
  Yes: 1,
};

const seoSchemaType = { Organization: 1 };

const sessionEndReason = {
  Logout: 1,
  OtherSessionCreate: 2,
  TimeOut: 3,
  Blocked: 4,
  Closed: 5,
};

const brandAutomationType = {
  PerformedWithdrawals: 1,
  PerformedDeposits: 2,
  PerformedBets: 3,
  Revenue: 4,
  WithdrawalTransaction: 5,
  SignUp: 6,
  Verify: 7,
  UnVerify: 8,
  AccountUpdate: 9,
};

const brandAutomationPeriod = {
  None: 0,
  Hour: 1,
  Hours6: 2,
  Hours12: 3,
  Day: 4,
  Days7: 5,
  Days30: 6,
  Months2: 7,
  Months3: 8,
  Months6: 9,
};

const brandAutomationConditionType = {
  PaymentMethod: 1,
  PaymentRestrictions: 2,
  Amount: 3,
  Country: 4,
  Verification: 5,
  AccountStatus: 6,
  SportStatus: 7,
  Segment: 8,
  Tag: 10,
  GGR: 11,
  NGR: 12,
  SuccessfulWithdrawalsAmount: 13,
  FailedWithdrawalsAmount: 14,
  SuccessfulDepositsAmount: 15,
  FailedDepositsAmount: 16,
  SuccessfulDepositsCount: 17,
  FailedDepositsCount: 18,
  SuccessfulWithdrawalsCount: 19,
  FailedWithdrawalsCount: 20,
  CasinoBetCount: 21,
  SportBetCount: 22,
  PeriodCount: 23,
  PeriodAmount: 24,
  TransactionType: 25,
  GameCategory: 26,
  Balance: 27,
  DelayForPerformedWithdrawals: 28,
  SignUpDevice: 29,
  Btag: 30,
  Citizenship: 31,
};

const brandAutomationActionType = {
  ManageWithdrawalTransaction: 1,
  SendEmail: 2,
  Segment: 3,
  HorizontalTag: 4,
  VerticalTag: 5,
  SendMassMessage: 6,
  TagTransaction: 7,
};

const brandAutomationOperator = {
  In: 1,
  NotIn: 2,
  Equals: 3,
  Greater: 4,
  Less: 5,
  GreaterOrEqual: 6,
  LessOrEqual: 7,
  Between: 8,
  All: 9,
  Any: 10,
  NotAny: 11,
};

const brandAutomationRevenueOperationType = {
  Win: 1,
  CashBack: 2,
  ReferralCommission: 3,
  Jackpot: 4,
  TournamentPrize: 5,
  BonusRedeem: 6,
  GeneralCorrection: 7,
};

const brandAutomationManageWithdrawal = {
  Approve: 1,
  Decline: 2,
  Freeze: 3,
  Cancel: 4,
};

const brandAutomationConditionPeriodType = {
  OneHour: 1,
  SixHours: 2,
  TwelveHours: 3,
  OneDay: 4,
  SevenDays: 5,
  ThirtyDays: 6,
  TwoMonths: 7,
  Lifetime: 8,
};

// reasons Resources
const resource = {
  BlockProviders: 1,
  AccountClosure: 2,
  BalanceAdjustment: 3,
  GameManagement: 4,
  PlayerAccountMarkAttention: 6,
  PlayersBetLimitation: 7,
  PlayersDepositLimitation: 8,
  PlayersStatusUpdate: 9,
  PlayersTimeOut: 10,
  PlayersWithdrawals: 11,
  VerticalTags: 12,
  PlayersSelfExclusion: 13,
  WithdrawalsStatusChange: 14,
  PlayersBlockControl: 16,
  PlayersLossLimitation: 18,
  PlayersSingleBetLimitation: 19,
  BlockPaymentMethods: 20,
  ExchangeMaterialPrize: 21,
  WebsiteSelfExclusion: 22,
  WebsiteAccountTimeOut: 23,
  PlayersGameTimeLimitation: 24,
};

const reasonsType = {
  Default: 1,
  Custom: 2,
  System: 3,
};

const campaignTriggerTypes = {
  NoTrigger: 0,
  SignUp: 1,
  Verify: 2,
  Deposit: 3,
  SignIn: 5,
};

const campaignParticipantTypes = {
  AllPlayers: 0,
  Players: 1,
  PlayersWithAmount: 2,
  PromoCode: 3,
  Join: 4,
};

const bonusCountTypes = {
  Single: 1,
  Multi: 2,
};

const campaignTriggerPeriodTypes = {
  Every: 1,
  Daily: 2,
  Lifetime: 3,
};

const campaignTriggerOption = {
  Every: 1,
  NumberOf: 2,
};

const campaignConditionTypes = {
  Country: 1,
  Payments: 2,
  Verify: 3,
  WeekDays: 4,
  AccountStatus: 5,
  SportStatus: 6,
  Segment: 7,
  Tag: 8,
  TriggerDeviceType: 9,
  SignUpDeviceType: 10,
  MonthlyDates: 11,
};

const weekDays = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

const campaignOperators = {
  In: 1,
  NotIn: 2,
  All: 3,
};

const tagCategoryType = {
  Horizontal: 1,
  Vertical: 2,
};

const politicallyExposedPerson = {
  None: 0,
  Domestic: 1,
  Foreign: 2,
  International: 3,
};

const sportsBookMobileIntegrationType = {
  Iframe: 1,
  Native: 2,
};

const sportsBookLayoutTypes = {
  Standard: 1,
  African: 2,
  Latin: 3,
  Asian: 4,
};

const realityCheckRepetitionPeriod = {
  None: 0,
  Minutes10: 1,
  Minutes20: 2,
  Minutes30: 3,
  Minutes40: 4,
  Minutes50: 5,
  Hours1: 6,
  Hours2: 7,
  Hours3: 8,
  Hours4: 9,
  Hours5: 10,
  Hours6: 11,
};

const affiliateTypes = {
  Everymatrix: 1,
  Smartico: 2,
  Digitain: 3,
  Alanbase: 4,
  MyAffiliates: 5,
};

const analyticTypes = {
  Hotjar: 1,
  GoogleAnalytics: 2,
  GoogleSearchConsole: 3,
};

const liveChatSolutionTypes = {
  LiveChat: 1,
  LiveAgent: 2,
  Zendesk: 3,
  Intercom: 4,
  WhatsApp: 5,
};

const agSolutionTypes = {
  Digitain: 1,
  Everymatrix: 2,
};

const crmTypes = {
  Smartico: 1,
  Digitain: 2,
  Optimove: 3,
};

const gamificationTypes = {
  Smartico: 1,
  ThunderBite: 2,
};

const authenticationTypes = {
  Firebase: 1,
};

const seoPageGroups = {
  Seo: 1,
  RulesSeo: 2,
  Promotion: 3,
};

const agDataStatuses = {
  Pending: 1,
  Failed: 2,
  Success: 3,
};

const retailSolutionTypes = {
  Digitain: 1,
};

const kycTypes = {
  InternalDocuments: 1,
  MetaMap: 3,
};

const userClaimTypes = {
  SuperAdmin: 1,
  Developer: 3,
};

const campaignUseTypes = {
  common: 1,
  crossPlatform: 2,
};

const campaignV2UseTypes = {
  Common: 1,
  CrossPlatform: 2,
};

const lobbyGridTypes = {
  Standard: 1,
  Advanced: 2,
  Special: 3,
  Vertical: 4,
};

const lobbyFilterTypes = {
  Theme: 1,
  StakeRange: 2,
};

const lobbyGamesViewTypes = {
  GameDetailsPreview: 1,
  GameDetailsCard: 2,
};

const lobbyProvidersPositions = {
  ExtendedTab: 1,
  Slider: 2,
  DropDown: 3,
};

const lobbyViewTypes = {
  Text: 1,
  Icon: 2,
};

const lobbyProvidersViewTypes = {
  Text: 1,
  Logo: 2,
};

const lobbyGroupsViewTypes = {
  Text: 1,
  Icon: 2,
};

const lobbyCategories = {
  Slot: 1,
  TableGames: 2,
  LiveCasino: 4,
  VirtualSport: 8,
  TvGames: 16,
  TurboGames: 32,
};

const lobbyGameDetailsOnHoverTypes = {
  GameName: 1,
  ProviderName: 2,
};

const promotionSpinDistributingTypes = {
  FreeActivation: 1,
  Deposit: 2,
  ActivationOnCollect: 3,
  Bet: 4,
  BuyActivation: 5,
};

const promoParticipantType = {
  Public: 1,
  Private: 2,
};

const promotionStatus = {
  Draft: 1,
  Scheduled: 2,
  Active: 3,
  Finished: 4,
};

const promotionPrizeType = {
  TryAgain: 1,
  Bonus: 2,
  Material: 3,
  MachineSpin: 4,
  BalanceMultiplier: 5,
};

const promotionMachineType = {
  Wheel: 1,
  Grid: 2,
  Box: 3,
};

const promotionConditionType = {
  AllPlayers: 1,
  Condition: 2,
  // SelectedPlayers: 2,
};

const promoPlayingParticipantsConditions = {
  Verify: 3,
};

const promoGroupTypes = {
  Diamond: 1,
  Platinum: 2,
  Gold: 3,
  Silver: 4,
  Bronze: 5,
};

const promotionGroupLimitPeriodType = {
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
  AllPeriod: 4,
};

const promotionContentType = {
  Default: 1,
  Currency: 2,
};

const brandVerificationPeriod = {
  FirstDepositDate: 0,
  RegistrationDate: 1,
};

const maxExpressOddTypes = {
  Total: 1,
  Maximum: 3,
};

const promotionalItemType = {
  Promotion: 1,
  Campaign: 2,
};

const promotionalItemStatus = {
  Active: 1,
  Finished: 2,
};

const rgsDeviceIds = {
  All: 4,
  Desktop: 5,
  Mobile: 6,
};

const exportReportStates = {
  InQueue: 1,
  Generating: 2,
  Generated: 3,
  Expired: 4,
  Cancelled: 5,
  Failed: 6,
};

const promotionSpinProvisionType = {
  System: 1,
  Manual: 2,
};

const bonusCancellerTypes = {
  System: 1,
  Player: 2,
  User: 3,
};

const bonusActivationTypes = {
  Player: 1,
  System: 2,
};

const promotionContentGenerationType = {
  Promotion: 1,
  Machine: 2,
  SpinDistribution: 3,
  Prize: 4,
};

const promotionPageBackgroundFitType = {
  Fill: 1,
  Repeat: 2,
  Fit: 3,
};

const promotionPageBackgroundPositionType = {
  Standard: 1,
  Fixed: 2,
};

const promotionTriggerOption = {
  Every: 1,
  NumberOf: 2,
  Cumulative: 3,
};

const promotionTriggerPeriodTypes = {
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
  PromotionPeriod: 4,
  Lifetime: 5,
};

const devToolDbSyncMergeModes = {
  Delta: 0,
  Full: 1,
  InsertsAndUpdates: 2,
  InsertsOnly: 3,
  UpdatesOnly: 4,
};

const pushNotificationRecipients = {
  Active: 1,
  Guest: 2,
  All: 3,
};

const pushNotificationStatuses = {
  Sent: 3,
  Failed: 4,
  Scheduled: 5,
  Sending: 6,
  Preparing: 7,
};

const playerPushNotificationStatuses = {
  Failed: 1,
  Scheduled: 2,
  Sent: 3,
  Read: 4,
  Expired: 5,
};

const promotionPrizeStatus = {
  New: 1,
  Received: 2,
  Pending: 3,
  Cancelled: 4,
};

const permissionSecurityLevel = {
  Lowest: 1,
  Low: 2,
  Medium: 3,
  High: 4,
  Higher: 5,
  VeryHigh: 6,
  Maximum: 7,
};

const campaignSourceTypes = {
  Internal: 1,
  Sport: 2,
};

const deepLinkSections = {
  Login: 1,
  Registration: 2,
  MyProfile: 3,
  Deposit: 4,
  Withdrawal: 5,
  Casino: 6,
  Sport: 7,
  InboxMessages: 8,
  Verification: 9,
  OffersSport: 10,
  OffersCasino: 11,
  PromoCodes: 12,
  Tournament: 13,
  EventId: 14,
  CasinoProvider: 15,
  LiveCasinoProvider: 16,
  TVGames: 17,
  VirtualSport: 18,
  CasinoGame: 19,
  CustomPage: 20,
  SportsPrematch: 21,
  SportsLive: 22,
  EsportMain: 23,
  BetRace: 24,
  CasinoLobbyGamesGroup: 25,
  LiveCasinoLobbyGamesGroup: 26,
  TVGamesLobbyGamesGroup: 27,
  FastGamesLobbyGamesGroup: 28,
  VirtualSportLobbyGamesGroup: 29,
  LiveCasinoGame: 30,
  TVGamesGame: 31,
  VirtualSportGame: 32,
  FastGamesGame: 33,
  Gamification: 34,
};

const playerAccessLimitTypes = {
  None: 1,
  Repetitive: 2,
  SpecificDate: 3,
};

const betCorrectionTypes = {
  Refund: 1,
  WinLost: 2,
};

const roundStates = {
  New: 1,
  Won: 2,
  Lost: 3,
  Cancelled: 4,
};

const bulkBetCorrectionStatuses = {
  InProgress: 1,
  Failed: 2,
  Success: 3,
};

const inactivityTriggerTypes = {
  None: 0,
  Login: 1,
  Transaction: 2,
};

const inactivePeriodMonths = {
  Months3: 3,
  Months6: 6,
  Months12: 12,
  Months24: 24,
};

const failedBonusReasons = {
  BlockedAccount: 1,
  BlockedForTransactions: 2,
  BlockedForSport: 3,
  BlockedForCasino: 4,
  BlockedForDeposit: 5,
  BlockedForWithdrawals: 6,
  CurrencyMismatch: 7,
  Country: 8,
  TriggerDeviceType: 9,
  Verification: 10,
  AccountStatus: 11,
  SportStatus: 12,
  Segment: 13,
  Tag: 14,
  DepositNumber: 15,
  MinDepositAmount: 16,
  MaxTriggerCount: 17,
  MaxTriggerCountDaily: 18,
  Weekdays: 19,
  SignUpDeviceType: 20,
  DisconnectTrigger: 21,
  Payments: 22,
  AlreadyReceived: 23,
  NumberOf: 24,
  NumberOfFinal: 25,
  NumberOfDailyFinal: 26,
};

const tagAction = {
  Add: 1,
  Update: 2,
  Delete: 3,
  Enable: 4,
  Disable: 5,
  Merge: 6,
  Move: 7,
  Tagged: 8,
  Untagged: 9,
};

const gamesMobileLaunchTypes = {
  FullScreen: 0,
  HeaderFooter: 1,
  QuickActionsBar: 2,
};

const freeGameWinType = {
  GrossWin: 1,
  PureWin: 2,
};

const inactivePlayerMoneyTransferTypes = {
  None: 1,
  Government: 2,
  Player: 3,
};

const inactivePlayerCredentialsProvidedTypes = {
  None: 0,
  Provided: 1,
  NonProvided: 2,
};

const brandMarkets = {
  None: 1,
  Armenia: 2,
  Malta: 3,
  Cyprus: 4,
};

const releaseNoteLabels = {
  Bug: 1,
  Improvement: 2,
  Feature: 3,
};

const promotionSectionTypes = {
  MainSettings: 1,
  Prize: 2,
  MachineConfig: 3,
  GroupLimit: 4,
};

const lobbyJackpotAmountViewTypes = {
  OnHover: 1,
  OnThumbnail: 2,
};

const crossCampaignSourceTypes = {
  // Smartico: 1,
  Gamification: 2,
  // PushNotification: 3,
  // CRM: 4,
  // Betera: 5,
  // Thunderbyte: 6,
  Campaign: 7,
};

const crossCampaignSourceStatuses = {
  Active: 1,
  Finished: 2,
};

const thirdPartyServicesStatuses = {
  NotIntegrated: 0,
  Enabled: 1,
  Disabled: 2,
};

const promotionMaterialPrizeActions = {
  Approve: 1,
  Decline: 2,
  Cancel: 3,
};

const promotionChosenMaterialPrizeTypes = {
  Material: 1,
  Amount: 2,
};

const jackpotFeedTypes = {
  Single: 1,
  Multi: 2,
};

const campaignV2ParticipantTypes = {
  AllPlayers: 0,
  Players: 1,
  PlayersWithAmount: 2,
  PromoCode: 3,
};

const campaignV2ParticipantConditionTypes = {
  Country: 1,
  Verify: 2,
  AccountStatus: 3,
  SportStatus: 4,
  Segment: 5,
  Tag: 6,
  TriggerDeviceType: 7,
  SignUpDeviceType: 8,
};

const campaignV2Operators = {
  In: 1,
  NotIn: 2,
  All: 3,
};

const campaignV2TriggerTypes = {
  NoTrigger: 0,
  SignUp: 1,
  Verify: 2,
  Deposit: 3,
  SignIn: 4,
  Bet: 5,
  GameLaunch: 6,
};

const campaignV2PromoCodeTypes = {
  Single: 0,
  Multiple: 1,
  External: 2,
};

const playerComplaintCategories = {
  GameBettingOdds: 1,
  Cancellation: 2,
  Exclusion: 3,
  Winnings: 4,
  Bonus: 5,
  IllegalConduct: 6,
  CashOut: 7,
  CommercialCommunication: 8,
  TermsOfParticipation: 9,
  GamingLimits: 10,
  PaymentsOfWinnings: 11,
  IdentificationAndVerification: 12,
  ResponsibleGaming: 13,
  AccountBlocking: 14,
  Other: 15,
};

const playerComplaintChannels = {
  Email: 1,
  Letter: 2,
};

const playerComplaintStatuses = {
  New: 1,
  Replied: 2,
};

const campaignV2BonusFamilies = {
  FreeBet: 1,
  Spin: 2,
  Combined: 3,
  Special: 4,
};

const campaignV2Bonuses = {
  FreeBetCasino: 1,
  FreeBetSport: 2,
  WagerCombined: 3,
  RealMoney: 6,
  FreeGame: 4,
};

const campaignV2MixedBonusSubTypes = {
  FreeSpin: 1,
  FreeAmount: 2,
  CombinationSpin: 4,
};

const campaignV2GameTypes = {
  All: 1,
  Custom: 2,
};

const campaignV2RgsDeviceIds = {
  All: 4,
  Desktop: 5,
  Mobile: 6,
};

const campaignV2TriggerConditionTypes = {
  Recurring: 1,
  TriggerDeviceType: 2,
  Payments: 3,
  SignUpDeviceType: 4,
  Games: 5,
  CasinoBets: 6,
  SportBets: 7,
};

const campaignV2RecurringTriggerConditionTypes = {
  WeekDays: 1,
  MonthlyDays: 2,
};

const translationsDefaultSectionGroups = {
  mainContent: 0,
  errorMessages: 1,
  reasonsContent: 3,
};

const campaignV2SportBonusBetTypes = {
  Single: 1,
  Multi: 2,
  SingleAndMulti: 3,
};

const campaignV2WageringAmountTypes = {
  Trigger: 1,
  Bonus: 2,
  Both: 3,
};

const campaignV2WageringParticipationTypes = {
  Bonus: 1,
  Real: 2,
  Both: 3,
};

const campaignV2FirstDebitedWalletTypes = {
  Bonus: 1,
  Real: 2,
};

const campaignV2LockAmountTypes = {
  None: 0,
  Trigger: 1,
  Full: 2,
};

const campaignV2TriggerOptions = {
  NumberOf: 1,
  Every: 2,
  Cumulative: 3,
};

const campaignV2TriggerOptionPeriods = {
  Current: 1,
  Daily: 2,
  LifeTime: 3,
  Weekly: 4,
  Monthly: 5,
};

const massMessageSourceTypes = {
  Internal: 1,
  DigitainCrm: 2,
};

const sportBetProcessingTypes = {
  Standard: 0,
  AutoBet: 1,
  MegaBet: 2,
};

const campaignV2WagerProductTypes = {
  Sport: 1,
  Casino: 2,
  Both: 3,
};

const campaignV2BonusCancellerTypes = {
  Player: 1,
  System: 2,
  User: 3,
};

const brandAutomationAccountFields = {
  Citizenship: 1,
};

const playerBonusV2Statuses = {
  New: 1,
  Active: 2,
  Finished: 3,
  Canceled: 4,
  Expired: 5,
  Pending: 6,
};

const promoCodeV2Types = {
  Single: 0,
  Multiple: 1,
  External: 2,
};

const playerBlockActions = {
  SportBooks: 1,
  Casino: 2,
  Withdrawals: 4,
  Deposit: 8,
  Transaction: 16,
  Messages: 32,
  Account: 64,
  Unverified: 128,
};

const campaignV2FreeSpinWinTypes = {
  RealMoney: 1,
  Bonus: 2,
};

const jackpotSetStatuses = {
  Active: 1,
  Inactive: 2,
  Deleted: 3,
};

const widgetGameSelectionTypes = {
  GameUpload: 0,
  GroupSelection: 1,
};

const campaignV2BetContributionTypes = {
  Real: 1,
  RealBonus: 2,
};

const regulatoryExclusionTypes = {
  Exclusion: 1,
  SelfExclusion: 2,
};

const regulatoryExclusionChannels = {
  Other: 0,
  Licensee: 1,
  HGC: 2,
};

const regulatoryExclusionDurationCategories = {
  Other: 0,
  IndefiniteExclusion: 1,
  TemporaryExclusion: 2,
  Hours24Exclusion: 3,
};

const sportTreeRuleInvolvements = {
  Include: 1,
  Exclude: 2,
};

const cashbackV2CalculationFrom = {
  Bets: 3,
};

const cashbackV2WinTypes = {
  RealMoney: 1,
  Bonus: 2,
};

const cashbackV2PrizeTypes = {
  Fixed: 1,
  Percent: 2,
};

const registrationUniquenessTypes = {
  FirstName: 1,
  LastName: 2,
  Birthday: 4,
};

const campaignV2SourceTypes = {
  Internal: 1,
  Optimove: 2,
};

const betWalletTypes = {
  Rollover: 1,
  Unused: 2,
  Used: 3,
  Booked: 5,
  Debt: 6,
  FreeBetCasino: 501,
  FreeBetSport: 502,
  SportWager: 503,
  CasinoWager: 504,
  MixWager: 505,
};

const regulatoryBetEventCategoriesA = {
  SportsEvents: 1,
  FantasySports: 2,
  VirtualEvents: 3,
  OtherEvents: 4,
};

const regulatoryPlayerLimitCategories = {
  Other: 0,
  DailyTimeLimit: 1,
  WeeklyTimeLimit: 2,
  MonthlyTimeLimit: 3,
  DailyDepositLimit: 4,
  WeeklyDepositLimit: 5,
  MonthlyDepositLimit: 6,
  DailyLossLimit: 7,
  WeeklyLossLimit: 8,
  MonthlyLossLimit: 9,
};

const regulatoryBetEventTypes = {
  Main: 1,
  Secondary: 2,
};

const regulatoryGameCategories = {
  CasinoGames: 2,
  PeerToPeerGames: 4,
};

const regulatoryBonusCategoryA = {
  RedeemableBonusSchema: 0,
  NonRedeemableBonusSchema: 1,
};

const regulatoryBonusCategoryB = {
  Cashback: 1,
  FreeBet: 2,
  FreeRound: 3,
  KambiFreeBet: 4,
  Standard: 5,
  Tournament: 6,
  TournamentTicket: 7,
  Wagering: 8,
};

const playersBettingAggregationCriteriaTypes = {
  GamingDay: 1,
  BetEventId: 2,
  BetType: 3,
  BetsOver1000Euros: 4,
};

const bettingPerformanceCriteriaTypes = {
  GamingDay: 1,
  BetEventCategoryB: 2,
  BetType: 3,
};

const bettingEventCategories = {
  PreGame: 1,
  Live: 2,
  PreGameAndLive: 3,
};

const bettingPerformanceBetTypes = {
  Single: 1,
  Parlay: 2,
  Multiples: 3,
  Other: 4,
};

const taxationTypes = {
  Cumulative: 1,
  PerTransaction: 2,
};

const taxationProductCategories = {
  P2P: 1,
  Slot: 2,
  LiveCasino: 4,
  VirtualSport: 8,
  TableGames: 16,
  Poker: 32,
  TurboGames: 64,
  TVGames: 128,
  FantasySport: 256,
  LotteryGames: 512,
  Other: 1073741824,
};

const regulators = {
  Cypriot: 1,
  Romanian: 2,
  Greece: 3,
};

const taxationAggregationCriteria = {
  Monthly: 1,
  Daily: 2,
};

const campaignV2SportEventTypes = {
  All: 1,
  PreMatch: 2,
  Live: 3,
};

const enumTypes = {
  channels,
  messageCategories,
  playerStatuses,
  templateStatus,
  massMessageStatuses,
  inboxStatuses,
  notificationPreferences,
  notificationTypes,
  senderTypes,
  domainStatuses,
  genders,
  bonuses,
  bonusStatuses,
  playerBonusStatuses,
  marketingStatuses,
  kycDocumentTypes,
  kycDocumentStates,
  periods,
  promoCodeTypes,
  markAttentionLevels,
  smsProviders,
  smtpProviders,
  playerActions,
  transactionTypes,
  adjustmentOperationType,
  walletType,
  thirdPartyCodeType,
  thirdPartyCodePosition,
  providerSpecificType,
  playerVerification,
  cashBackType,
  cashbackTransferType,
  captchaType,
  brandStatuses,
  cashBackReferralPeriodStatus,
  sportsBookReasons,
  allBonusTypes,
  testPlayerAccountType,
  testPlayerAccountStatus,
  similarAccountDetectionArea,
  similarAccountParameterType,
  referralFinancialActivity,
  referralReferees,
  referralBonusType,
  referralBonusAmount,
  referralActivities,
  selfExclusionPeriods,
  playerReasons,
  timeoutPeriod,
  limitPeriod,
  limitationTypes,
  sportBonusBetType,
  expressOddType,
  requestMethods,
  paymentRequestStatuses,
  betStates,
  sportBetTypes,
  largeTransactionTypes,
  playerLimitsPeriods,
  paymentTransactionTypes,
  playerPaymentRelations,
  legalAgreementVersionStatuses,
  brandAutomationType,
  brandAutomationPeriod,
  brandAutomationOperator,
  brandVerificationDocumentTypes,
  kycDocumentOptions,
  paymentType,
  processingTypes,
  paymentActivities,
  paymentMethodsFeesTypes,
  paymentMethodLimitTypes,
  paymentMethodActionTypes,
  paymentInventoryShowInfo,
  paymentProcessingOption,
  paymentProcessingRangeUnit,
  cashierOtherId,
  cashierPaymentSettings,
  bonusCommonStatuses,
  cashbackCalculationFrom,
  cashbackParticipateRules,
  tagNoCategoryId,
  allDeviceTypes,
  deviceTypes,
  selectedPlayersErrorStatus,
  quickSearchResponseItemType,
  walletTransactionTypes,
  dashboardIcons,
  dashboardWidgetDataType,
  dashboardWidgetPeriod,
  lobbyWidgetTypes,
  lobbyWidgetGridTypes,
  lobbyWidgetTopWinsTypes,
  dashboardWidgetAggregationCriteria,
  dashboardWidgetLimitType,
  dashboardWidgetChartType,
  dashboardAggregationPeriodBy,
  dashboardValueTypes,
  betTypes,
  logicTypes,
  seoSocialMediaType,
  gameState,
  lobbyTypes,
  gameOrderTypes,
  playerContactInfoVerificationTypes,
  playerPhysicalVerificationTypes,
  sportPlayerStatus,
  brandAutomationConditionType,
  brandAutomationActionType,
  brandAutomationRevenueOperationType,
  brandAutomationManageWithdrawal,
  brandAutomationConditionPeriodType,
  boolean,
  seoSchemaType,
  sessionEndReason,
  resource,
  reasonsType,
  bonusCountTypes,
  campaignTriggerPeriodTypes,
  campaignTriggerOption,
  campaignTriggerTypes,
  campaignParticipantTypes,
  campaignConditionTypes,
  weekDays,
  campaignOperators,
  politicallyExposedPerson,
  tagCategoryType,
  playerBlockedStatuses,
  sportsBookMobileIntegrationType,
  sportsBookLayoutTypes,
  realityCheckRepetitionPeriod,
  affiliateTypes,
  analyticTypes,
  liveChatSolutionTypes,
  agSolutionTypes,
  seoPageGroups,
  agDataStatuses,
  crmTypes,
  retailSolutionTypes,
  kycTypes,
  userClaimTypes,
  campaignUseTypes,
  lobbyGridTypes,
  lobbyFilterTypes,
  lobbyViewTypes,
  lobbyProvidersPositions,
  lobbyGamesViewTypes,
  lobbyCategories,
  lobbyGameDetailsOnHoverTypes,
  promotionSpinDistributingTypes,
  promoParticipantType,
  promotionStatus,
  promotionPrizeType,
  promotionMachineType,
  promotionConditionType,
  promoPlayingParticipantsConditions,
  promoGroupTypes,
  promotionGroupLimitPeriodType,
  promotionContentType,
  maxExpressOddTypes,
  brandVerificationPeriod,
  promotionalItemType,
  promotionalItemStatus,
  rgsDeviceIds,
  exportReportStates,
  promotionSpinProvisionType,
  bonusCancellerTypes,
  bonusActivationTypes,
  promotionContentGenerationType,
  promotionPageBackgroundFitType,
  promotionPageBackgroundPositionType,
  promotionTriggerOption,
  promotionTriggerPeriodTypes,
  devToolDbSyncMergeModes,
  pushNotificationRecipients,
  pushNotificationStatuses,
  playerPushNotificationStatuses,
  promotionPrizeStatus,
  permissionSecurityLevel,
  campaignSourceTypes,
  deepLinkSections,
  playerAccessLimitTypes,
  betCorrectionTypes,
  roundStates,
  bulkBetCorrectionStatuses,
  inactivityTriggerTypes,
  inactivePeriodMonths,
  failedBonusReasons,
  tagAction,
  gamesMobileLaunchTypes,
  freeGameWinType,
  inactivePlayerMoneyTransferTypes,
  inactivePlayerCredentialsProvidedTypes,
  brandMarkets,
  releaseNoteLabels,
  systemPartnerId,
  promotionSectionTypes,
  lobbyJackpotAmountViewTypes,
  gamificationTypes,
  authenticationTypes,
  crossCampaignSourceTypes,
  crossCampaignSourceStatuses,
  lobbyProvidersViewTypes,
  lobbyGroupsViewTypes,
  thirdPartyServicesStatuses,
  jackpotFeedTypes,
  promotionMaterialPrizeActions,
  promotionChosenMaterialPrizeTypes,
  campaignV2ParticipantTypes,
  campaignV2ParticipantConditionTypes,
  campaignV2TriggerConditionTypes,
  campaignV2RecurringTriggerConditionTypes,
  campaignV2Operators,
  campaignV2TriggerTypes,
  campaignV2PromoCodeTypes,
  campaignV2Statuses,
  campaignV2UseTypes,
  campaignV2BonusFamilies,
  campaignV2Bonuses,
  campaignV2GameTypes,
  campaignV2RgsDeviceIds,
  playerComplaintCategories,
  playerComplaintChannels,
  playerComplaintStatuses,
  translationsDefaultSectionGroups,
  campaignV2SportBonusBetTypes,
  campaignV2WageringAmountTypes,
  campaignV2WageringParticipationTypes,
  campaignV2FirstDebitedWalletTypes,
  campaignV2LockAmountTypes,
  campaignV2TriggerOptions,
  campaignV2TriggerOptionPeriods,
  massMessageSourceTypes,
  sportBetProcessingTypes,
  campaignV2WagerProductTypes,
  campaignV2BonusCancellerTypes,
  brandAutomationAccountFields,
  playerBonusV2Statuses,
  playerBlockActions,
  campaignV2FreeSpinWinTypes,
  promoCodeV2Types,
  lobbyWidgetSectionTypes,
  jackpotSetStatuses,
  widgetGameSelectionTypes,
  campaignV2BetContributionTypes,
  regulatoryExclusionTypes,
  regulatoryExclusionChannels,
  regulatoryExclusionDurationCategories,
  campaignV2MixedBonusSubTypes,
  cashbackV2CalculationFrom,
  sportTreeRuleInvolvements,
  cashbackV2WinTypes,
  cashbackV2PrizeTypes,
  registrationUniquenessTypes,
  campaignV2SourceTypes,
  regulatoryBetEventCategoriesA,
  betWalletTypes,
  regulatoryPlayerLimitCategories,
  regulatoryGameCategories,
  regulatoryBetEventTypes,
  regulatoryBonusCategoryA,
  regulatoryBonusCategoryB,
  playersBettingAggregationCriteriaTypes,
  bettingPerformanceCriteriaTypes,
  bettingEventCategories,
  bettingPerformanceBetTypes,
  taxationTypes,
  taxationProductCategories,
  regulators,
  taxationAggregationCriteria,
  campaignV2SportEventTypes,
};

export default enumTypes;
