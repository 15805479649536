import PropTypes from 'prop-types';
import IconSvg from 'components/common/icon/IconSvg';
import { StyledBorderedInfo, StyledBorderedInfoText } from './Info.styled';

const Info = (props) => {
  const {
    content, width, isInline, hasBg, marginTop, noMarginBottom,
  } = props;
  return (
    <StyledBorderedInfo width={width} isInline={isInline} hasBg={hasBg} marginTop={marginTop} noMarginBottom={noMarginBottom}>
      <IconSvg icon="InfoIcon" />
      <StyledBorderedInfoText>{content}</StyledBorderedInfoText>
    </StyledBorderedInfo>
  );
};

Info.propTypes = {
  content: PropTypes.node.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isInline: PropTypes.bool,
  hasBg: PropTypes.bool,
  marginTop: PropTypes.bool,
  noMarginBottom: PropTypes.bool,
};

Info.defaultProps = {
  width: 'auto',
  isInline: false,
  hasBg: false,
  marginTop: false,
  noMarginBottom: false,
};

export default Info;
