import { DatePicker } from 'antd-old';
import styled from 'styled-components';
import { media } from 'utils/styledHelpers';

const { RangePicker } = DatePicker;

const StyledDatepicker = styled(DatePicker)`
  height: ${(props) => (props.midSize ? '2.29rem' : `${props.smallSize ? '2rem' : '2.57rem'}`)};
  width: 100%;
  .ant-picker-input > input {
    color: ${(props) => props.theme.colors.primaryText};
    font-size: 1rem;
  }
  .ant-picker-input input::placeholder {
    color: ${(props) => (props.isSubmissionDate ? props.theme.colors.primaryText : props.theme.colors.filterLabelColor)};
    font-size: 1rem;
  }
  &.ant-picker.ant-picker-disabled {
    background: ${(props) => props.theme.colors.grayButton};
    border-color: ${(props) => props.theme.colors.primaryBorder}80;
  }
  .ant-picker-suffix {
    svg {
      width: 1.1rem;
      height: 1.1rem;
    }
  }
`;

const StyledRangePicker = styled(RangePicker)`
  &.ant-picker.ant-picker-range {
    height: ${(props) => (props.midSize ? '2.29rem' : `${props.smallSize ? '2rem' : '2.57rem'}`)};
    width: ${(props) => (props.fullWidth ? '100%' : '')};
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    padding: 9px 11px 9px;
    &.ant-picker-focused {
      border: 1px solid ${(props) => props.theme.colors.blue};
    }
    .ant-picker-input:first-child input::placeholder {
      color: ${(props) => (props.isSubmissionDate ? props.theme.colors.primaryText : props.theme.colors.filterLabelColor)};
    }
    input {
      color: ${(props) => props.theme.colors.primaryText};
      font-size: 1rem;

      &[disabled] {
        color: ${(props) => props.theme.colors.filterLabelColor};
      }
    }
    .ant-picker-input input[disabled]::placeholder {
      color: ${(props) => props.theme.colors.filterLabelColor};
    }
    .ant-picker-suffix {
      svg {
        width: 1.1rem;
        height: 1.1rem;
      }
    }
  }
  ${media.md`
    & + .ant-picker-dropdown {
      .ant-picker-content.ant-picker-content {
       flex-direction: column;
       height: auto;
      }
      
      .ant-picker-panel-container {
        width: 288px;
      }
      
      .ant-picker-panel, .ant-picker-panels { 
        width: 100%;
      }
        
      .ant-picker-panel-layout > div {
        width: 100%;
        .ant-picker-datetime-panel {
          flex-direction: column;
          display: flex;
          & .ant-picker-time-panel-column {
            overflow-x: auto;
            width: 100%;
            display: flex;
            align-items: flex-start;
    
            & > li {
              margin-inline: 0;
            }
            &:after {
              height: 0;
            }
          }
        } 
      }
    }
  `}
`;
const StyledDatepickerAndTime = styled.div`
  height: 100%;
  width: 100%;
  .ant-picker {
    width: 100%;
    height: 100%;
  }
`;

export { StyledDatepicker, StyledRangePicker, StyledDatepickerAndTime };
