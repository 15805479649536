const LobbyManagementActionType = {
  GET_LOBBIES: 'GET_LOBBIES',
  SAVE_LOBBIES: 'SAVE_LOBBIES',
  RESET_LOBBIES: 'RESET_LOBBIES',

  GET_LOBBY: 'GET_LOBBY',
  SAVE_LOBBY: 'SAVE_LOBBY',
  RESET_LOBBY: 'RESET_LOBBY',

  EDIT_LOBBY: 'EDIT_LOBBY',

  CHANGE_LOBBY_STATUS: 'CHANGE_LOBBY_STATUS',

  GET_LOBBY_PROVIDERS: 'GET_LOBBY_PROVIDERS',
  SAVE_LOBBY_PROVIDERS: 'SAVE_LOBBY_PROVIDERS',
  RESET_LOBBY_PROVIDERS: 'RESET_LOBBY_PROVIDERS',

  CHANGE_LOBBY_PROVIDER_STATUS: 'CHANGE_LOBBY_PROVIDER_STATUS',

  GET_LOBBY_PROVIDER: 'GET_LOBBY_PROVIDER',
  SAVE_LOBBY_PROVIDER: 'SAVE_LOBBY_PROVIDER',
  RESET_LOBBY_PROVIDER: 'RESET_LOBBY_PROVIDER',

  EDIT_LOBBY_PROVIDER: 'EDIT_LOBBY_PROVIDER',

  EXPORT_LOBBY_PROVIDERS: 'EXPORT_LOBBY_PROVIDERS',

  GET_LOBBY_PROVIDERS_ORDERS: 'GET_LOBBY_PROVIDERS_ORDERS',
  SAVE_LOBBY_PROVIDERS_ORDERS: 'SAVE_LOBBY_PROVIDERS_ORDERS',
  RESET_LOBBY_PROVIDERS_ORDERS: 'RESET_LOBBY_PROVIDERS_ORDERS',

  EDIT_LOBBY_PROVIDERS_ORDERS: 'EDIT_LOBBY_PROVIDERS_ORDERS',

  GET_CSV_LOBBY_PROVIDERS_ORDERS: 'GET_CSV_LOBBY_PROVIDERS_ORDERS',
  SAVE_CSV_LOBBY_PROVIDERS_ORDERS: 'SAVE_CSV_LOBBY_PROVIDERS_ORDERS',
  RESET_CSV_LOBBY_PROVIDERS_ORDERS: 'RESET_CSV_LOBBY_PROVIDERS_ORDERS',

  GET_LOBBY_PROVIDER_GAMES_ORDERS: 'GET_LOBBY_PROVIDER_GAMES_ORDERS',
  SAVE_LOBBY_PROVIDER_GAMES_ORDERS: 'SAVE_LOBBY_PROVIDER_GAMES_ORDERS',
  RESET_LOBBY_PROVIDER_GAMES_ORDERS: 'RESET_LOBBY_PROVIDER_GAMES_ORDERS',

  EDIT_LOBBY_PROVIDER_GAMES_ORDERS: 'EDIT_LOBBY_PROVIDER_GAMES_ORDERS',

  GET_CSV_LOBBY_PROVIDER_GAMES_ORDERS: 'GET_CSV_LOBBY_PROVIDER_GAMES_ORDERS',
  SAVE_CSV_LOBBY_PROVIDER_GAMES_ORDERS: 'SAVE_CSV_LOBBY_PROVIDER_GAMES_ORDERS',
  RESET_CSV_LOBBY_PROVIDER_GAMES_ORDERS: 'RESET_CSV_LOBBY_PROVIDER_GAMES_ORDERS',

  GET_LOBBY_LOGS: 'GET_LOBBY_LOGS',
  SAVE_LOBBY_LOGS: 'SAVE_LOBBY_LOGS',
  RESET_LOBBY_LOGS: 'RESET_LOBBY_LOGS',

  GET_LOBBY_GAME_GROUPS: 'GET_LOBBY_GAME_GROUPS',
  SAVE_LOBBY_GAME_GROUPS: 'SAVE_LOBBY_GAME_GROUPS',
  RESET_LOBBY_GAME_GROUPS: 'RESET_LOBBY_GAME_GROUPS',

  REORDER_LOBBY_GAME_GROUP: 'REORDER_LOBBY_GAME_GROUP',
  CHANGE_LOBBY_GAME_GROUP_STATUS: 'CHANGE_LOBBY_GAME_GROUP_STATUS',

  GET_LOBBY_GAME_GROUP: 'GET_LOBBY_GAME_GROUP',
  SAVE_LOBBY_GAME_GROUP: 'SAVE_LOBBY_GAME_GROUP',
  RESET_LOBBY_GAME_GROUP: 'RESET_LOBBY_GAME_GROUP',

  EDIT_LOBBY_GAME_GROUP: 'EDIT_LOBBY_GAME_GROUP',

  GET_LOBBY_GENERAL_WIDGETS: 'GET_LOBBY_GENERAL_WIDGETS',
  SAVE_LOBBY_GENERAL_WIDGETS: 'SAVE_LOBBY_GENERAL_WIDGETS',
  RESET_LOBBY_GENERAL_WIDGETS: 'RESET_LOBBY_GENERAL_WIDGETS',

  GET_LOBBY_JACKPOT_WIDGETS: 'GET_LOBBY_JACKPOT_WIDGETS',
  SAVE_LOBBY_JACKPOT_WIDGETS: 'SAVE_LOBBY_JACKPOT_WIDGETS',
  RESET_LOBBY_JACKPOT_WIDGETS: 'RESET_LOBBY_JACKPOT_WIDGETS',

  GET_LOBBY_GENERAL_WIDGET: 'GET_LOBBY_GENERAL_WIDGET',
  SAVE_LOBBY_GENERAL_WIDGET: 'SAVE_LOBBY_GENERAL_WIDGET',
  RESET_LOBBY_GENERAL_WIDGET: 'RESET_LOBBY_GENERAL_WIDGET',

  CHANGE_LOBBY_GENERAL_WIDGET_STATUS: 'CHANGE_LOBBY_GENERAL_WIDGET_STATUS',

  GET_LOBBY_JACKPOT_WIDGET: 'GET_LOBBY_JACKPOT_WIDGET',
  SAVE_LOBBY_JACKPOT_WIDGET: 'SAVE_LOBBY_JACKPOT_WIDGET',
  RESET_LOBBY_JACKPOT_WIDGET: 'RESET_LOBBY_JACKPOT_WIDGET',

  CHANGE_LOBBY_JACKPOT_WIDGET_STATUS: 'CHANGE_LOBBY_JACKPOT_WIDGET_STATUS',

  MANAGE_LOBBY_GENERAL_WIDGET: 'MANAGE_LOBBY_GENERAL_WIDGET',
  MANAGE_LOBBY_JACKPOT_WIDGET: 'MANAGE_LOBBY_JACKPOT_WIDGET',

  GET_LOBBY_WIDGETS: 'GET_LOBBY_WIDGETS',
  SAVE_LOBBY_WIDGETS: 'SAVE_LOBBY_WIDGETS',
  RESET_LOBBY_WIDGETS: 'RESET_LOBBY_WIDGETS',
  REORDER_LOBBY_WIDGETS: 'REORDER_LOBBY_WIDGETS',

  GET_LOBBY_HOME_GAME_WIDGET: 'GET_LOBBY_HOME_GAME_WIDGET',
  SAVE_LOBBY_WIDGET: 'SAVE_LOBBY_WIDGET',
  RESET_LOBBY_WIDGET: 'RESET_LOBBY_WIDGET',
  CREATE_LOBBY_HOME_GAME_WIDGET: 'CREATE_LOBBY_HOME_GAME_WIDGET',
  EDIT_LOBBY_HOME_GAME_WIDGET: 'EDIT_LOBBY_HOME_GAME_WIDGET',
  DELETE_LOBBY_HOME_GAME_WIDGET: 'DELETE_LOBBY_HOME_GAME_WIDGET',

  GET_LOBBY_HOME_TOP_X_WIDGET: 'GET_LOBBY_HOME_TOP_X_WIDGET',
  CREATE_LOBBY_HOME_TOP_X_WIDGET: 'CREATE_LOBBY_HOME_TOP_X_WIDGET',
  EDIT_LOBBY_HOME_TOP_X_WIDGET: 'EDIT_LOBBY_HOME_TOP_X_WIDGET',
  DELETE_LOBBY_HOME_TOP_X_WIDGET: 'DELETE_LOBBY_HOME_TOP_X_WIDGET',

  GET_LOBBY_HOME_PROVIDERS_AND_GAMES_WIDGET: 'GET_LOBBY_HOME_PROVIDERS_AND_GAMES_WIDGET',
  CREATE_LOBBY_HOME_PROVIDERS_AND_GAMES_WIDGET: 'CREATE_LOBBY_HOME_PROVIDERS_AND_GAMES_WIDGET',
  EDIT_LOBBY_HOME_PROVIDERS_AND_GAMES_WIDGET: 'EDIT_LOBBY_HOME_PROVIDERS_AND_GAMES_WIDGET',
  DELETE_LOBBY_HOME_PROVIDERS_AND_GAMES_WIDGET: 'DELETE_LOBBY_HOME_PROVIDERS_AND_GAMES_WIDGET',

  CHANGE_LOBBY_WIDGET_STATUS: 'CHANGE_LOBBY_WIDGET_STATUS',

  GET_LOBBY_HOME_TOP_WIN_WIDGET: 'GET_LOBBY_HOME_TOP_WIN_WIDGET',
  CREATE_LOBBY_HOME_TOP_WIN_WIDGET: 'CREATE_LOBBY_HOME_TOP_WIN_WIDGET',
  EDIT_LOBBY_HOME_TOP_WIN_WIDGET: 'EDIT_LOBBY_HOME_TOP_WIN_WIDGET',
  DELETE_LOBBY_HOME_TOP_WIN_WIDGET: 'DELETE_LOBBY_HOME_TOP_WIN_WIDGET',

  GET_LOBBY_ACTION_LINK_WIDGET: 'GET_LOBBY_ACTION_LINK_WIDGET',
  CREATE_LOBBY_ACTION_LINK_WIDGET: 'CREATE_LOBBY_ACTION_LINK_WIDGET',
  EDIT_LOBBY_ACTION_LINK_WIDGET: 'EDIT_LOBBY_ACTION_LINK_WIDGET',
  DELETE_LOBBY_ACTION_LINK_WIDGET: 'DELETE_LOBBY_ACTION_LINK_WIDGET',

  GET_LOBBY_GROUP_WIDGET: 'GET_LOBBY_GROUP_WIDGET',
  CREATE_LOBBY_GROUP_WIDGET: 'CREATE_LOBBY_GROUP_WIDGET',
  EDIT_LOBBY_GROUP_WIDGET: 'EDIT_LOBBY_GROUP_WIDGET',
  DELETE_LOBBY_GROUP_WIDGET: 'DELETE_LOBBY_GROUP_WIDGET',

  GET_LOBBY_HOME_SINGLE_GAME_WIDGET: 'GET_LOBBY_HOME_SINGLE_GAME_WIDGET',
  CREATE_LOBBY_HOME_SINGLE_GAME_WIDGET: 'CREATE_LOBBY_HOME_SINGLE_GAME_WIDGET',
  EDIT_LOBBY_HOME_SINGLE_GAME_WIDGET: 'EDIT_LOBBY_HOME_SINGLE_GAME_WIDGET',
  DELETE_LOBBY_HOME_SINGLE_GAME_WIDGET: 'DELETE_LOBBY_HOME_SINGLE_GAME_WIDGET',

  GET_LOBBY_PROVIDER_WIDGET: 'GET_LOBBY_PROVIDER_WIDGET',
  CREATE_LOBBY_PROVIDER_WIDGET: 'CREATE_LOBBY_PROVIDER_WIDGET',
  EDIT_LOBBY_PROVIDER_WIDGET: 'EDIT_LOBBY_PROVIDER_WIDGET',
  DELETE_LOBBY_PROVIDER_WIDGET: 'DELETE_LOBBY_PROVIDER_WIDGET',

  GET_LOBBY_HOME_CSV_PROVIDERS: 'GET_LOBBY_HOME_CSV_PROVIDERS',
  SAVE_LOBBY_HOME_CSV_PROVIDERS: 'SAVE_LOBBY_HOME_CSV_PROVIDERS',
  RESET_LOBBY_HOME_CSV_PROVIDERS: 'RESET_LOBBY_HOME_CSV_PROVIDERS',

  GET_LOBBY_HOME_RANDOM_GAME_WIDGET: 'GET_LOBBY_HOME_RANDOM_GAME_WIDGET',
  CREATE_LOBBY_HOME_RANDOM_GAME_WIDGET: 'CREATE_LOBBY_HOME_RANDOM_GAME_WIDGET',
  EDIT_LOBBY_HOME_RANDOM_GAME_WIDGET: 'EDIT_LOBBY_HOME_RANDOM_GAME_WIDGET',
  DELETE_LOBBY_HOME_RANDOM_GAME_WIDGET: 'DELETE_LOBBY_HOME_RANDOM_GAME_WIDGET',

  GET_LOBBY_HOME_JACKPOT_WIDGET: 'GET_LOBBY_HOME_JACKPOT_WIDGET',
  CREATE_LOBBY_HOME_JACKPOT_WIDGET: 'CREATE_LOBBY_HOME_JACKPOT_WIDGET',
  EDIT_LOBBY_HOME_JACKPOT_WIDGET: 'EDIT_LOBBY_HOME_JACKPOT_WIDGET',
  DELETE_LOBBY_HOME_JACKPOT_WIDGET: 'DELETE_LOBBY_HOME_JACKPOT_WIDGET',

  GET_LOBBY_HOME_COLLECTION_WIDGET: 'GET_LOBBY_HOME_COLLECTION_WIDGET',
  CREATE_LOBBY_HOME_COLLECTION_WIDGET: 'CREATE_LOBBY_HOME_COLLECTION_WIDGET',
  EDIT_LOBBY_HOME_COLLECTION_WIDGET: 'EDIT_LOBBY_HOME_COLLECTION_WIDGET',
  DELETE_LOBBY_HOME_COLLECTION_WIDGET: 'DELETE_LOBBY_HOME_COLLECTION_WIDGET',

  GET_LOBBY_HOME_IMAGE_WIDGET: 'GET_LOBBY_HOME_IMAGE_WIDGET',
  CREATE_LOBBY_HOME_IMAGE_WIDGET: 'CREATE_LOBBY_HOME_IMAGE_WIDGET',
  EDIT_LOBBY_HOME_IMAGE_WIDGET: 'EDIT_LOBBY_HOME_IMAGE_WIDGET',
  DELETE_LOBBY_HOME_IMAGE_WIDGET: 'DELETE_LOBBY_HOME_IMAGE_WIDGET',

  GET_LOBBY_HOME_BANNER_WIDGET: 'GET_LOBBY_HOME_BANNER_WIDGET',
  CREATE_LOBBY_HOME_BANNER_WIDGET: 'CREATE_LOBBY_HOME_BANNER_WIDGET',
  EDIT_LOBBY_HOME_BANNER_WIDGET: 'EDIT_LOBBY_HOME_BANNER_WIDGET',
  DELETE_LOBBY_HOME_BANNER_WIDGET: 'DELETE_LOBBY_HOME_BANNER_WIDGET',

  GET_LOBBY_HOME_STORY_WIDGET: 'GET_LOBBY_HOME_STORY_WIDGET',
  CREATE_LOBBY_HOME_STORY_WIDGET: 'CREATE_LOBBY_HOME_STORY_WIDGET',
  EDIT_LOBBY_HOME_STORY_WIDGET: 'EDIT_LOBBY_HOME_STORY_WIDGET',
  DELETE_LOBBY_HOME_STORY_WIDGET: 'DELETE_LOBBY_HOME_STORY_WIDGET',
};
export default LobbyManagementActionType;
