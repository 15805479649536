import { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import NoAccess from 'containers/common/NoAccess';
import { Loading } from 'containers/layout';
import { ErrorBoundary, BreadcrumbNavigation } from 'components/layout';
import { useLayout } from 'hooks';

const Pages = (props) => {
  const {
    component: Component, breadcrumb, rest, breadcrumbParams, setActiveMenu, activeMenu, resetBreadCrumbParams, hide, isNewVersion, partnerAccessIds,
  } = props;

  const {
    partnerSettings, brandSettings, showConversion, timeZoneSettings,
  } = rest;

  const {
    setPartnerSettings, setBrandSettings, setShowConversion, setTimeZoneSettings,
  } = useLayout();

  useEffect(() => {
    setPartnerSettings?.(partnerSettings);
  }, [setPartnerSettings, partnerSettings]);

  useEffect(() => {
    setBrandSettings?.(brandSettings);
  }, [setBrandSettings, brandSettings]);

  useEffect(() => {
    setShowConversion?.(showConversion);
  }, [showConversion, setShowConversion]);

  useEffect(() => {
    setTimeZoneSettings?.(timeZoneSettings);
  }, [timeZoneSettings, setTimeZoneSettings]);

  useEffect(() => {
    if (activeMenu) {
      setActiveMenu(activeMenu);
    }
  }, [activeMenu, setActiveMenu]);

  const getBreadcrumb = () => breadcrumb.map((bc) => {
    if (bc.key) {
      return {
        ...bc,
        name: breadcrumbParams[bc.key],
      };
    }
    return bc;
  });

  const page = (
    <>
      {!!breadcrumb.length && <BreadcrumbNavigation breadcrumb={getBreadcrumb()} resetBreadCrumbParams={resetBreadCrumbParams} />}
      <ErrorBoundary>
        <Suspense fallback={<span />}>
          <Loading />
          <Component {...rest} />
        </Suspense>
      </ErrorBoundary>
    </>
  );

  // todo temp solution to remove in further
  const isHidden = hide?.({ isNewVersion, partnerAccessIds });
  if (isHidden && brandSettings?.isNewVersion) {
    return '';
  }

  return isHidden ? <NoAccess /> : page;
};

Pages.propTypes = {
  component: PropTypes.elementType.isRequired,
  brandId: PropTypes.number,
  partnerId: PropTypes.number,
  setActiveMenu: PropTypes.func.isRequired,
  rest: PropTypes.object.isRequired,
  breadcrumb: PropTypes.array,
  permission: PropTypes.object,
  breadcrumbParams: PropTypes.object,
  activeMenu: PropTypes.number.isRequired,
  resetBreadCrumbParams: PropTypes.func.isRequired,
  claims: PropTypes.array,
  hide: PropTypes.func,
  partnerAccessIds: PropTypes.array,
  isNewVersion: PropTypes.bool,
};

Pages.defaultProps = {
  breadcrumb: [],
  breadcrumbParams: {},
  permission: undefined,
  brandId: null,
  partnerId: null,
  claims: [],
  hide: null,
  partnerAccessIds: [],
  isNewVersion: false,
};

export default Pages;
