import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import qs from 'qs';
import mime from 'mimetypes';
import GameManagementActionType from 'actions/gameManagement/gameManagementActionType';
import { i18n, MessageService, NetworkService } from 'services';
import { downloadFile } from 'utils/utils';
import { AppConstants } from 'constants/index';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import {
  saveGames,
  saveGame,
  saveGameThemesResource,
  saveGameBadgesResource,
  saveGameGroupsResource,
  saveGameGroups,
  saveCsvGames,
  changeEndedStatus as changeGameManagementEndedStatus,
  saveGameGroup,
  saveGameType,
  saveGameTypes,
  saveCsvGameTypeGames,
} from 'actions/gameManagement/gameManagementActionCreator';
import { getError, getMessage } from 'utils/helpers';

function* getGames({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Game, Games } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Game, Games], options);
    yield put(saveGames(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportGames({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Game, Games, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Game, Games, Export], options);
    downloadFile(data, i18n.t('games'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getGame({ payload }) {
  try {
    const { id, brandId } = payload;
    const { Transaction, Game, Games } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Game, Games, id, brandId]);
    yield put(saveGame(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* setGameMaintenance({ payload }) {
  try {
    const { id, brandId, data } = payload;
    const { gameName } = data;
    yield put(changeLoadingState(true));
    const {
      Transaction, Game, Games, Maintenance, Set,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Game, Games, id, brandId, Maintenance, Set], {
      data,
    });
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:lGame'), i18n.t('notification:slUpdated'), gameName)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* resetGameMaintenance({ payload }) {
  try {
    const { id, brandId, name } = payload;
    yield put(changeLoadingState(true));
    const {
      Transaction, Game, Games, Maintenance, Reset,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Game, Games, id, brandId, Maintenance, Reset]);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:lGame'), i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* setGamesMaintenance({ payload }) {
  try {
    const { brandId, data } = payload;
    yield put(changeLoadingState(true));
    const {
      Transaction, Game, Games, Maintenance, Set,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Game, Games, brandId, Maintenance, Set], {
      data,
    });
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('games'), i18n.t('notification:plUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* resetGamesMaintenance({ payload }) {
  try {
    const { brandId, data } = payload;
    yield put(changeLoadingState(true));
    const {
      Transaction, Game, Games, Maintenance, Reset,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Game, Games, brandId, Maintenance, Reset], {
      data,
    });
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('games'), i18n.t('notification:plUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editGame({ payload }) {
  try {
    const { id, brandId, data: dataSource } = payload;
    const { name, data } = dataSource;
    yield put(changeLoadingState(true));
    const { Transaction, Game, Games } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Game, Games, id, brandId], {
      data,
    });
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:lGame'), i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editGames({ payload }) {
  try {
    const { brandId, data } = payload;
    yield put(changeLoadingState(true));
    const { Transaction, Game, Games } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Game, Games, brandId], {
      data,
    });
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('games'), i18n.t('notification:plUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getGameThemesResource({ payload }) {
  const { isLoading } = payload;
  try {
    const { params } = payload;
    const {
      Transaction, Game, Games, Themes, Resource,
    } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Game, Games, Themes, Resource], { params });
    yield put(saveGameThemesResource(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getGameGroups({ payload }) {
  const { isLoading } = payload;
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, GameGroup } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, GameGroup], options);
    yield put(saveGameGroups(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getGameBadgesResource({ payload }) {
  const { isLoading } = payload;
  try {
    const {
      Transaction, Game, Badges, Resource,
    } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Game, Badges, Resource]);
    yield put(saveGameBadgesResource(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getGameGroupsResource({ payload }) {
  const { isLoading } = payload;
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { Transaction, GameGroup, Resource } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, GameGroup, Resource], options);
    yield put(saveGameGroupsResource(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* createGameGroup({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { Transaction, GameGroup } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, GameGroup], options);
    yield put(setSuccessMessage(getMessage(i18n.t('cms:lGroup'), i18n.t('notification:slCreated'), data.name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteGameGroup({ payload }) {
  try {
    const { id, params, name } = payload;
    const options = {
      params,
    };
    const { Transaction, GameGroup } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, GameGroup, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('cms:lGroup'), i18n.t('notification:slDeleted'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCsvGames({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { Transaction, Game, Import } = AppConstants.api;
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { data } = yield call(NetworkService.makeAPIPostRequest, [Transaction, Game, Import], options);
    yield put(saveCsvGames(data));
    yield put(changeGameManagementEndedStatus(true));
    yield put(changeLoadingState(false));
    if (!data.length) {
      MessageService.error(i18n.t('notification:emptyFile'));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
      yield put(changeGameManagementEndedStatus(true));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCsvOrderedGames({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const {
      Transaction, GameGroup, Games, Upload,
    } = AppConstants.api;
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { data } = yield call(NetworkService.makeAPIPostRequest, [Transaction, GameGroup, Games, Upload], options);
    yield put(saveCsvGames(data));
    yield put(changeGameManagementEndedStatus(true));
    yield put(changeLoadingState(false));
    if (!data.length) {
      MessageService.error(i18n.t('notification:emptyFile'));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
      yield put(changeGameManagementEndedStatus(true));
    }
    yield put(changeLoadingState(false));
  }
}

function* getGameGroup({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const { Transaction, GameGroup } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, GameGroup, id], options);
    yield put(saveGameGroup(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editGameGroup({ payload }) {
  try {
    const { id, data } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { Transaction, GameGroup } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, GameGroup, id], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:lGroup'), i18n.t('notification:slUpdated'), data.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeGameGroupStatus({ payload }) {
  try {
    const { id, data, name } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { Transaction, GameGroup, Status } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, GameGroup, id, Status], options);
    yield put(setSuccessMessage(getMessage(i18n.t('cms:lGroup'), i18n.t(!data.status ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeGameStatus({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { Transaction, Game, Status } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Game, Status], options);
    if (data.gameIds?.length > 1) {
      yield put(setSuccessMessage(getMessage(i18n.t('games'), i18n.t(!data.status ? 'notification:plDisabled' : 'notification:plEnabled'))));
    } else {
      yield put(setSuccessMessage(getMessage(i18n.t('game'), i18n.t(!data.status ? 'notification:slDisabled' : 'notification:slEnabled'))));
    }
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getGameTypes({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, GameTypes } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, GameTypes], options);
    yield put(saveGameTypes(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getGameType({ payload }) {
  try {
    const { id } = payload;
    const { Transaction, GameTypes } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, GameTypes, id]);
    yield put(saveGameType(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createGameType({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { Transaction, GameTypes } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, GameTypes], options);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slCreated'), data.name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}
function* editGameType({ payload }) {
  try {
    const { id, data } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { Transaction, GameTypes } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, GameTypes, id], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), data.name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteGameType({ payload }) {
  try {
    const { id, name } = payload;
    const { Transaction, GameTypes } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, GameTypes, id]);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slDeleted'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeGameTypeState({ payload }) {
  try {
    const { data, name, id } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { Transaction, GameTypes, Status } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, GameTypes, id, Status], options);
    yield put(setSuccessMessage(getMessage('', i18n.t(!data.status ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteGameTypeGames({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, GameTypes, Games } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, GameTypes, id, Games], options);
    yield put(setSuccessMessage(getMessage(i18n.t(params.gameIds?.length === 1 ? 'game' : 'games'), i18n.t(params.gameIds?.length === 1 ? 'notification:slDeleted' : 'notification:plDeleted'))));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCsvGameTypeGames({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const {
      Transaction, GameTypes, Games, Import,
    } = AppConstants.api;
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { data } = yield call(NetworkService.makeAPIPostRequest, [Transaction, GameTypes, Games, Import], options);
    yield put(saveCsvGameTypeGames(data));
    yield put(changeGameManagementEndedStatus(true));
    yield put(changeLoadingState(false));
    if (!data.length) {
      MessageService.error(i18n.t('notification:emptyFile'));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
      yield put(changeGameManagementEndedStatus(true));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* gameManagementSaga() {
  yield all([
    takeLatest(GameManagementActionType.GET_GAMES, getGames),
    takeLatest(GameManagementActionType.EXPORT_GAMES, exportGames),
    takeLatest(GameManagementActionType.GET_GAME, getGame),
    takeLatest(GameManagementActionType.SET_GAME_MAINTENANCE, setGameMaintenance),
    takeLatest(GameManagementActionType.SET_GAMES_MAINTENANCE, setGamesMaintenance),
    takeLatest(GameManagementActionType.RESET_GAME_MAINTENANCE, resetGameMaintenance),
    takeLatest(GameManagementActionType.RESET_GAMES_MAINTENANCE, resetGamesMaintenance),
    takeLatest(GameManagementActionType.EDIT_GAME, editGame),
    takeLatest(GameManagementActionType.EDIT_GAMES, editGames),
    takeLatest(GameManagementActionType.GET_GAME_THEMES_RESOURCE, getGameThemesResource),
    takeLatest(GameManagementActionType.GET_GAME_BADGES_RESOURCE, getGameBadgesResource),
    takeLatest(GameManagementActionType.GET_GAME_GROUPS_RESOURCE, getGameGroupsResource),
    takeLatest(GameManagementActionType.CREATE_GAME_GROUP, createGameGroup),
    takeLatest(GameManagementActionType.DELETE_GAME_GROUP, deleteGameGroup),
    takeLatest(GameManagementActionType.GET_GAME_GROUPS, getGameGroups),
    takeLatest(GameManagementActionType.GET_CSV_GAMES, getCsvGames),
    takeLatest(GameManagementActionType.GET_CSV_ORDERED_GAMES, getCsvOrderedGames),
    takeLatest(GameManagementActionType.GET_GAME_GROUP, getGameGroup),
    takeLatest(GameManagementActionType.EDIT_GAME_GROUP, editGameGroup),
    takeLatest(GameManagementActionType.CHANGE_GAME_GROUP_STATUS, changeGameGroupStatus),
    takeLatest(GameManagementActionType.CHANGE_GAME_STATUS, changeGameStatus),
    takeLatest(GameManagementActionType.GET_GAME_TYPES, getGameTypes),
    takeLatest(GameManagementActionType.GET_GAME_TYPE, getGameType),
    takeLatest(GameManagementActionType.CREATE_GAME_TYPE, createGameType),
    takeLatest(GameManagementActionType.EDIT_GAME_TYPE, editGameType),
    takeLatest(GameManagementActionType.DELETE_GAME_TYPE, deleteGameType),
    takeLatest(GameManagementActionType.CHANGE_GAME_TYPE_STATE, changeGameTypeState),
    takeLatest(GameManagementActionType.DELETE_GAME_TYPE_GAMES, deleteGameTypeGames),
    takeLatest(GameManagementActionType.GET_CSV_GAME_TYPE_GAMES, getCsvGameTypeGames),
  ]);
}
