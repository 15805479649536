import RegulatoryActionType from 'actions/regulatory/regulatoryActionType';

const initialState = {
  regulatoryReport: {
    data: [],
    total: 0,
  },
};
const regulatoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case RegulatoryActionType.SAVE_REGULATORY_REPORT:
    return {
      ...state,
      regulatoryReport: payload.data,
    };
  case RegulatoryActionType.RESET_REGULATORY_REPORT:
    return {
      ...state,
      regulatoryReport: {
        data: [],
        total: 0,
      },
    };
  default:
    return state;
  }
};

export default regulatoryReducer;
