import { createSelector } from 'reselect';

const selectCasinoBets = (state) => state.casinoBets;
export const selectCasinoBetsData = (state) => selectCasinoBets(state).data;
export const selectCasinoBetsTotal = (state) => selectCasinoBets(state).total;

const selectPlayerCasinoBets = (state) => state.playerCasinoBets;
export const selectPlayerCasinoBetsData = (state) => selectPlayerCasinoBets(state).data;
export const selectPlayerCasinoBetsTotal = (state) => selectPlayerCasinoBets(state).total;
export const selectCasinoBet = (state) => state.casinoBet;
export const selectSportBets = (state) => state.sportBets;
export const selectSportBetsHasNext = (state) => state.sportBetsHasNext;
export const selectPlayerSportBets = (state) => state.playerSportBets;
export const selectPlayerSportBetsHasNext = (state) => state.playerSportBetsTotalHasNext;
export const selectSportBet = (state) => state.sportBet;

export const selectCasinoBetsDetails = (state) => state.casinoBetsDetails;
export const selectCasinoBetDetails = (state, id) => createSelector(
  () => selectCasinoBetsDetails(state),
  (data) => data[id] || [],
)();

export const selectSportBetsDetails = (state) => state.sportBetsDetails;
export const selectSportBetDetails = (state, id) => createSelector(
  () => selectSportBetsDetails(state),
  (data) => data[id] || {},
)();
