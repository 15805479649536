const CashBackV2ActionType = {
  CREATE_CASH_BACK_V2: 'CREATE_CASH_BACK_V2',

  GET_CASH_BACKS_V2: 'GET_CASH_BACKS_V2',
  SAVE_CASH_BACKS_V2: 'SAVE_CASH_BACKS_V2',
  RESET_CASH_BACKS_V2: 'RESET_CASH_BACKS_V2',

  GET_CASH_BACK_V2: 'GET_CASH_BACK_V2',
  SAVE_CASH_BACK_V2: 'SAVE_CASH_BACK_V2',
  RESET_CASH_BACK_V2: 'RESET_CASH_BACK_V2',

  EDIT_CASH_BACK_V2: 'EDIT_CASH_BACK_V2',

  DELETE_CASH_BACK_V2: 'DELETE_CASH_BACK_V2',

  ENABLE_CASH_BACK_V2: 'ENABLE_CASH_BACK_V2',
  DISABLE_CASH_BACK_V2: 'DISABLE_CASH_BACK_V2',
  FINISH_CASH_BACK_V2: 'FINISH_CASH_BACK_V2',

  GET_PLAYER_CASH_BACKS_V2: 'GET_PLAYER_CASH_BACKS_V2',
  SAVE_PLAYER_CASH_BACKS_V2: 'SAVE_PLAYER_CASH_BACKS_V2',
  RESET_PLAYER_CASH_BACKS_V2: 'RESET_PLAYER_CASH_BACKS_V2',
  EXPORT_PLAYER_CASH_BACKS_V2: 'EXPORT_PLAYER_CASH_BACKS_V2',

  GET_CASH_BACK_V2_STATISTICS: 'GET_CASH_BACK_V2_STATISTICS',
  SAVE_CASH_BACK_V2_STATISTICS: 'SAVE_CASH_BACK_V2_STATISTICS',
  RESET_CASH_BACK_V2_STATISTICS: 'RESET_CASH_BACK_V2_STATISTICS',

  GET_PLAYER_CASH_BACK_V2_PERIODS: 'GET_PLAYER_CASH_BACK_V2_PERIODS',
  SAVE_PLAYER_CASH_BACK_V2_PERIODS: 'SAVE_PLAYER_CASH_BACK_V2_PERIODS',
  RESET_PLAYER_CASH_BACK_V2_PERIODS: 'RESET_PLAYER_CASH_BACK_V2_PERIODS',

  GET_CASH_BACK_V2_PLAYERS: 'GET_CASH_BACK_V2_PLAYERS',
  SAVE_CASH_BACK_V2_PLAYERS: 'SAVE_CASH_BACK_V2_PLAYERS',
  RESET_CASH_BACK_V2_PLAYERS: 'RESET_CASH_BACK_V2_PLAYERS',

  GET_CASH_BACK_V2_INCLUDED_PLAYERS: 'GET_CASH_BACK_V2_INCLUDED_PLAYERS',
  SAVE_CASH_BACK_V2_INCLUDED_PLAYERS: 'SAVE_CASH_BACK_V2_INCLUDED_PLAYERS',
  RESET_CASH_BACK_V2_INCLUDED_PLAYERS: 'RESET_CASH_BACK_V2_INCLUDED_PLAYERS',
  UPDATE_CASH_BACK_V2_INCLUDED_PLAYERS: 'UPDATE_CASH_BACK_V2_INCLUDED_PLAYERS',

  DELETE_CASH_BACK_V2_INCLUDED_PLAYER: 'DELETE_CASH_BACK_V2_INCLUDED_PLAYER',
};

export default CashBackV2ActionType;
