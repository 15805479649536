import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import { AppConstants } from 'constants/index';
import NetworkService from 'services/networkService';
import {
  saveBrandMarketGeneralSettings,
  saveBrandUnverifiedPlayersDepositLimits,
  saveBrandVerificationConfigurations,
  saveBrandVerificationDocumentTypes,
  saveBrandVerificationPeriod,
} from 'actions/brandMarket/brandMarketActionCreator';
import { getError, getMessage } from 'utils/helpers';
import { i18n } from 'services';
import BrandMarketActionType from 'actions/brandMarket/brandMarketActionType';

function* getBrandMarketGeneralSettings({ payload }) {
  const { brandId, isLoading } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }

    const { BrandAdmin, BrandMarketSetting, MarketGeneralSetting } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandMarketSetting, MarketGeneralSetting, brandId]);
    yield put(saveBrandMarketGeneralSettings(data));

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* editBrandMarketGeneralSettings({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const options = {
      data: payload.data,
    };
    const { BrandAdmin, BrandMarketSetting, MarketGeneralSetting } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, BrandMarketSetting, MarketGeneralSetting, payload.brandId], options);
    yield put(saveBrandMarketGeneralSettings(data));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:playerSettings'), i18n.t('notification:plUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandVerificationConfigurations({ payload }) {
  try {
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandMarketSetting, Verification } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandMarketSetting, Verification, payload.brandId]);
    yield put(saveBrandVerificationConfigurations(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandVerificationConfigurations({ payload }) {
  try {
    const { brandId, data } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandMarketSetting, Verification } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, BrandMarketSetting, Verification, brandId], options);
    yield put(saveBrandVerificationConfigurations(data));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:verificationConfigurations'), i18n.t('notification:plUpdated'))));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandVerificationDocumentTypes({ payload }) {
  const { brandId, isLoading } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { BrandAdmin, BrandMarketSetting, Documents } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandMarketSetting, Documents, brandId]);
    yield put(saveBrandVerificationDocumentTypes(data));

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* editBrandVerificationDocumentTypes({ payload }) {
  try {
    const { brandId } = payload;
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandMarketSetting, Documents } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, BrandMarketSetting, Documents, brandId], options);
    yield put(saveBrandVerificationDocumentTypes(data));
    yield put(setSuccessMessage(getMessage(i18n.t('brand:playerVerificationSettings'), i18n.t('notification:plUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandVerificationPeriod({ payload }) {
  try {
    const { brandId } = payload;
    yield put(changeLoadingState(true));
    const { BrandAdmin, BrandMarketSetting, VerificationPeriod } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandMarketSetting, VerificationPeriod, brandId]);
    yield put(saveBrandVerificationPeriod(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* addBrandVerificationPeriod({ payload }) {
  try {
    const { brandId, data } = payload;
    yield put(changeLoadingState(true));
    const options = {
      data,
    };
    const { BrandAdmin, BrandMarketSetting, VerificationPeriod } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [BrandAdmin, BrandMarketSetting, VerificationPeriod, brandId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('title:verificationRules'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBrandUnverifiedPlayersDepositLimits({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const {
      BrandAdmin, BrandMarketSetting, Limits, Deposit,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, BrandMarketSetting, Limits, Deposit], options);
    yield put(saveBrandUnverifiedPlayersDepositLimits(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editBrandUnverifiedPlayersDepositLimits({ payload }) {
  try {
    const { data, params } = payload;
    const options = {
      data,
      params,
    };
    yield put(changeLoadingState(true));
    const {
      BrandAdmin, BrandMarketSetting, Limits, Deposit,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [BrandAdmin, BrandMarketSetting, Limits, Deposit], options);
    yield put(setSuccessMessage(getMessage(i18n.t('limits'), i18n.t('notification:slUpdated'))));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* brandMarketSaga() {
  yield all([
    takeLatest(BrandMarketActionType.GET_BRAND_MARKET_GENERAL_SETTINGS, getBrandMarketGeneralSettings),
    takeLatest(BrandMarketActionType.EDIT_BRAND_MARKET_GENERAL_SETTINGS, editBrandMarketGeneralSettings),
    takeLatest(BrandMarketActionType.GET_BRAND_VERIFICATION_CONFIGURATIONS, getBrandVerificationConfigurations),
    takeLatest(BrandMarketActionType.EDIT_BRAND_VERIFICATION_CONFIGURATIONS, editBrandVerificationConfigurations),
    takeLatest(BrandMarketActionType.GET_BRAND_VERIFICATION_DOCUMENT_TYPES, getBrandVerificationDocumentTypes),
    takeLatest(BrandMarketActionType.EDIT_BRAND_VERIFICATION_DOCUMENT_TYPES, editBrandVerificationDocumentTypes),
    takeLatest(BrandMarketActionType.GET_BRAND_VERIFICATION_PERIOD, getBrandVerificationPeriod),
    takeLatest(BrandMarketActionType.ADD_BRAND_VERIFICATION_PERIOD, addBrandVerificationPeriod),
    takeLatest(BrandMarketActionType.GET_BRAND_UNVERIFIED_PLAYERS_DEPOSIT_LIMITS, getBrandUnverifiedPlayersDepositLimits),
    takeLatest(BrandMarketActionType.EDIT_BRAND_UNVERIFIED_PLAYERS_DEPOSIT_LIMITS, editBrandUnverifiedPlayersDepositLimits),
  ]);
}
