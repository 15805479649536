const TaxesActionType = {
  GET_DEPOSIT_TAXES: 'GET_DEPOSIT_TAXES',
  SAVE_DEPOSIT_TAXES: 'SAVE_DEPOSIT_TAXES',
  RESET_DEPOSIT_TAXES: 'RESET_DEPOSIT_TAXES',

  EDIT_DEPOSIT_TAXES: 'EDIT_DEPOSIT_TAXES',

  GET_WITHDRAWAL_TAXES: 'GET_WITHDRAWAL_TAXES',
  SAVE_WITHDRAWAL_TAXES: 'SAVE_WITHDRAWAL_TAXES',
  RESET_WITHDRAWAL_TAXES: 'RESET_WITHDRAWAL_TAXES',

  EDIT_WITHDRAWAL_TAXES: 'EDIT_WITHDRAWAL_TAXES',

  GET_BET_TAXES: 'GET_BET_TAXES',
  SAVE_BET_TAXES: 'SAVE_BET_TAXES',
  RESET_BET_TAXES: 'RESET_BET_TAXES',

  EDIT_BET_TAXES: 'EDIT_BET_TAXES',

  GET_TAX_SETTING: 'GET_TAX_SETTING',
  SAVE_TAX_SETTING: 'SAVE_TAX_SETTING',
  RESET_TAX_SETTING: 'RESET_TAX_SETTING',

  ADD_TAX_SETTING: 'ADD_TAX_SETTING',
};

export default TaxesActionType;
