import {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FormItemErrorMessage } from 'components/common';
import enumTypes from 'constants/enumTypes';
import { FormInputWrapper } from 'components/styledComponents/input/Input.styled';
import { StyledTabInfo, StyledChangeStatusCheckbox, StyledStatusChange } from 'components/brandSetting/BrandSettings.styled';
import { Info } from 'components/common/info';
import { StyledFormMainWrapper } from 'components/styledComponents/form/Form.styled';
import { AdminRangePicker, AdminSelect } from 'components/common/form';
import { AppConstants } from 'constants/index';
import { FormItemMarginTopWrapper } from 'Global.styled';
import { useTimeZone } from 'hooks';

const notes = {
  [enumTypes.brandStatuses.Disabled]: 'brand:statusDisabledInfo',
  [enumTypes.brandStatuses.ActivitiesDisabled]: 'brand:statusActivitiesDisabledInfo',
  [enumTypes.brandStatuses.VersionNamesDisabled]: 'brand:statusActivitiesDisabledInfo',
};

const ChangeStatusForm = (props) => {
  const {
    brand, form, locale, now, setNow,
  } = props;

  const { setFieldsValue } = form;

  const {
    getDateTz, getStartDate, getLocalDate, date,
  } = useTimeZone();

  const getDisabledDate = () => getDateTz().add(5, 'minutes');

  const disabledDate = (current) => current && getDateTz(current) < getStartDate(getDisabledDate());

  const disabledRangeTime = (current) => {
    if (!current) {
      return {
        disabledHours: () => _.range(24),
        disabledMinutes: () => _.range(60),
      };
    }
    const isToday = getDateTz(current).format(AppConstants.format.date) === getDisabledDate().format(AppConstants.format.date);
    return {
      disabledHours: () => (isToday ? _.range(24).slice(0, getDisabledDate().hour()) : []),
      disabledMinutes: () => (isToday && getDateTz(current).hours() === getDisabledDate().hours() ? _.range(60).slice(0, getDisabledDate().minutes()) : []),
    };
  };

  const { brandStatuses, deviceTypes } = locale;

  const versionsOptions = useMemo(
    () => Array.from(deviceTypes.keys()).map((el) => ({
      label: deviceTypes.get(el),
      value: el,
    })),
    [deviceTypes],
  );

  const { t } = useTranslation();

  const [status, setStatus] = useState();
  const [options, setOptions] = useState([]);

  const onCheckboxChange = useCallback(
    (value) => {
      if (value.length === Object.keys(enumTypes.deviceTypes).length - 1) {
        const newOptions = versionsOptions.map((el) => {
          if (!value.includes(el.value)) {
            return {
              ...el,
              disabled: true,
            };
          }
          return el;
        });
        setOptions(newOptions);
      } else {
        setOptions(versionsOptions);
      }
    },
    [versionsOptions],
  );

  useEffect(() => {
    if (!options.length && !_.isEmpty(brand)) {
      onCheckboxChange(brand.disabledVersionNames);
    }
  }, [onCheckboxChange, brand, options]);

  useEffect(() => {
    if (_.isUndefined(status) && !_.isEmpty(brand)) {
      setNow(false);
      setStatus(brand.maintenancePeriodFrom ? enumTypes.brandStatuses.Maintenance : brand.status);
    }
  }, [status, brand, setNow]);

  const onChange = (value) => {
    setStatus(value);
    setFieldsValue({
      maintenancePeriod: undefined,
      disabledVersionNames: undefined,
    });
    setNow(value === enumTypes.brandStatuses.Maintenance);
  };

  const statusesOptions = Object.keys(brandStatuses).map((el) => ({
    id: +el,
    name: brandStatuses[el],
  }));

  const handleDatePickerChange = (currentDate) => {
    if (!currentDate?.[0]) {
      setNow(true);
    }
  };

  return (
    <StyledStatusChange>
      {!!notes[status] && (
        <StyledTabInfo>
          <Info content={t(notes[status])} />
        </StyledTabInfo>
      )}
      <StyledFormMainWrapper
        layout="vertical"
        form={form}
        initialValues={
          brand.maintenancePeriodFrom
            ? {
              ...brand,
              status: enumTypes.brandStatuses.Maintenance,
              maintenancePeriod: [getLocalDate(brand.maintenancePeriodFrom), brand.maintenancePeriodTo ? getLocalDate(brand.maintenancePeriodTo) : null],
            }
            : brand
        }
      >
        <FormInputWrapper>
          <Form.Item
            name="status"
            label={t('status')}
            rules={[
              {
                required: true,
                message: <FormItemErrorMessage errorMassage={(translate) => translate('validate:requiredField', { fieldName: translate('status') })} />,
              },
            ]}
          >
            <AdminSelect onChange={onChange} size="" data={statusesOptions} isToString={false} />
          </Form.Item>
        </FormInputWrapper>
        {+status === enumTypes.brandStatuses.VersionNamesDisabled && (
          <StyledChangeStatusCheckbox>
            <Form.Item name="disabledVersionNames">
              <Checkbox.Group onChange={onCheckboxChange} options={options} />
            </Form.Item>
          </StyledChangeStatusCheckbox>
        )}
        {+status === enumTypes.brandStatuses.Maintenance && (
          <FormItemMarginTopWrapper>
            <Form.Item
              label={t('brand:maintenancePeriod')}
              name="maintenancePeriod"
              rules={[
                () => ({
                  validator(rule, value) {
                    const startDate = value && value[0] ? getDateTz(value[0]) : null;
                    const endDate = value && value[1] ? getDateTz(value[1]) : null;

                    if (!now && brand.status !== enumTypes.brandStatuses.Maintenance && startDate && startDate.diff(getDateTz()) < 0) {
                      return Promise.reject(
                        <FormItemErrorMessage errorMassage={(translate) => translate('validate:mustBeGreaterThan', { fieldName: translate('startDate'), min: translate('lNow') })} />,
                      );
                    }
                    if (startDate && endDate && endDate <= startDate) {
                      return Promise.reject(
                        <FormItemErrorMessage errorMassage={(translate) => translate('validate:mustBeGreaterThan', { fieldName: translate('endDate'), min: translate('startDate') })} />,
                      );
                    }
                    if (startDate && endDate && date.duration(endDate.diff(startDate)).hours() < 1) {
                      return Promise.reject(<FormItemErrorMessage errorMassage={(translate) => translate('validate:ahead1Hour')} />);
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <AdminRangePicker
                getPopupContainer={null}
                format={AppConstants.format.dateTime}
                disabledDate={disabledDate}
                disabledTime={disabledRangeTime}
                allowEmpty={[now, true]}
                showTime={{ defaultValue: [getDateTz().add(5, 'minutes'), null] }}
                midSize={false}
                now={now}
                name="date"
                form={form}
                setNow={setNow}
                onChange={handleDatePickerChange}
                disabled={[brand.status === enumTypes.brandStatuses.Maintenance, false]}
              />
            </Form.Item>
          </FormItemMarginTopWrapper>
        )}
      </StyledFormMainWrapper>
    </StyledStatusChange>
  );
};

ChangeStatusForm.propTypes = {
  brand: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  now: PropTypes.bool.isRequired,
  setNow: PropTypes.func.isRequired,
};

export default ChangeStatusForm;
