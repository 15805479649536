import { createSelector, lruMemoize } from 'reselect';
import _ from 'lodash';
import { generateMapByOrder } from 'utils/utils';
import { shallowEqual } from 'react-redux';

export const selectLocaleResources = (state) => state.resources;

const memoizeOptions = {
  equalityCheck: shallowEqual,
  maxSize: 10,
};

// resourceObj contain key: value  pairs, where key is locale resource name, value is initialValues, are used when the translation call does not work
export const selectLocaleResource = createSelector(
  [selectLocaleResources, (state, resourceObj) => resourceObj, (state, resourceObj, order) => order],
  (resources, resourceObj, order) => {
    const resourceKeys = Object.keys(resourceObj);
    const result = _.pick(resources, resourceKeys);
    return resourceKeys.reduce((res, curr) => {
      const currentEnum = _.invert(resourceObj[curr]) || {};
      res[curr] = {};
      if (order?.[curr]) {
        res[curr] = generateMapByOrder(order[curr], { ...currentEnum, ...result[curr] });
      } else {
        Object.keys(currentEnum).forEach((el) => {
          res[curr][el] = result[curr]?.[el] ?? currentEnum[el];
        });
      }
      return res;
    }, {});
  },
  {
    memoize: lruMemoize,
    memoizeOptions,
  },
);

export const selectLocaleLanguage = (state) => state.language;
