import { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const LayoutContext = createContext({
  partnerSettings: null,
  setPartnerSettings: null,
  brandSettings: null,
  setBrandSettings: null,
  showConversion: false,
  setShowConversion: null,
  timeZoneSettings: null,
  setTimeZoneSettings: null,
});

export const LayoutProvider = ({ children }) => {
  const [partnerSettings, setPartnerSettings] = useState(null);
  const [brandSettings, setBrandSettings] = useState(null);
  const [showConversion, setShowConversion] = useState(false);
  const [timeZoneSettings, setTimeZoneSettings] = useState(null);
  const [hideLayout, setHideLayout] = useState(false);

  const value = {
    partnerSettings,
    setPartnerSettings,
    brandSettings,
    setBrandSettings,
    showConversion,
    setShowConversion,
    timeZoneSettings,
    setTimeZoneSettings,
    hideLayout,
    setHideLayout,
  };

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

LayoutProvider.propTypes = {
  children: PropTypes.any,
};

LayoutProvider.defaultProps = {
  children: '',
};
