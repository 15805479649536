import styled from 'styled-components';
import { Button, Col } from 'antd';
import { flexContainer, media, truncate } from 'utils/styledHelpers';
import { FormItemWrapper } from 'components/styledComponents/filter/Filter.styled';
import { StyledSwitchBig } from 'components/styledComponents/switch/Switch.styled';

const StyledMessageWrapper = styled.div`
  ${flexContainer({ align: 'center', justify: 'flex-start' })};
  min-height: 2.57rem;
  padding: 0.57rem 1.14rem;
  box-sizing: border-box;
  z-index: 3;
  line-height: 1.5rem;
  span {
    margin-left: 0.57rem;
    color: ${(props) => props.theme.colors.cardBg};
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    white-space: pre-wrap;
    word-break: break-word;
    text-align: left;
  }
  > div {
    align-self: flex-start;
  }
`;

const StyledFlexCol = styled.div`
  ${flexContainer({
    align: 'initial',
    justify: 'flex-start',
    direction: 'column',
  })};
  height: 100%;
  padding: 0 1.14rem;

  ${media.sm`
    padding: 0;
  `}
`;

const AccountDetailsFormWrapper = styled.div`
  .ant-input[disabled] {
    background: transparent !important;
  }
  .ant-form {
    position: relative;
    > .ant-row {
      > .ant-col {
        > .ant-row {
          min-height: 40rem;
        }
      }
    }
  }

  .ant-input {
    ${truncate()};
    width: calc(100% - 1.5rem);
  }
  .ant-form-item-control-input {
    input:not([value='']) {
      & + .ant-input-suffix {
        display: none;
      }
    }
    &:hover {
      input:not([value='']) {
        & + .ant-input-suffix {
          display: block;
        }
      }
    }
  }
`;

const NotificationPreferencesWrapper = styled.div`
  .ant-card-body {
    padding: 1.14rem 1.14rem 0;
  }
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    margin-top: 1.71rem;
    .ant-checkbox-group-item {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      border-bottom: 1px solid #e5e5e5;
      padding: 0.85rem 0;
      margin: 0;
      &:last-child {
        border-bottom: none;
      }
      &:after {
        content: none;
      }
    }
  }
`;

const NotificationPreferencesItemLabel = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 1.14rem;
`;

const NotificationPreferencesItemWrapper = styled.div`
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  background-color: ${(props) => props.theme.colors.primaryBg};
  width: 4.57rem;
  min-width: 4.57rem;
  height: 4.57rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationPreferencesItemContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.14rem;
`;

const NotificationPreferencesItemTitle = styled.p`
  font-size: 1.28rem;
  font-weight: 600;
`;

const StyledVerificationList = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  margin: 2rem 0;

  ${media.sm`
    flex-direction: column;
  `}
`;
const StyledVerificationItem = styled.span`
  display: inline-block;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
  margin-right: 1.14rem;
  background-color: ${(props) => props.theme.colors.primaryBg};
  color: ${(props) => props.theme.colors.primaryText};
  padding: 0.36rem 0.57rem;
  margin-top: 0.86rem;
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
`;
const StyledVerificationWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 2px solid ${(props) => (props.isVerified ? '#4BAE4F' : '#FF5656')};
  padding-left: 0.71rem;

  > p {
    color: ${(props) => (props.isVerified ? '#4BAE4F' : '#FF5656')};
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
  }
`;

const PreferenceButtonsWrapper = styled.div`
  padding-top: 2.86rem;

  ${media.sm`
    position: static;
  `}
`;

const StyledCol = styled(Col)`
  border-right: ${(props) => (props.border ? `1px solid ${props.theme.colors.secondaryBorder}` : '')};
  margin: 2rem 0;
  ${media.xs`
    border-right: none;
  `}
`;

const AccountDetailsFormItem = styled(FormItemWrapper)`
  margin: 0 0 1.71rem 0;
  position: relative;

  ${StyledSwitchBig} {
    margin: 0 0 0.42rem 0.57rem;
    &,
    &:hover {
      background-color: #ff5656;
    }
    &.ant-switch-checked,
    &.ant-switch-checked:hover {
      background-color: #4bae4f;
    }
  }
`;

const StyledViewPermissionDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 1.14rem;
  }
`;
const StyledViewPermissionDetailsLabel = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.colors.secondaryColor};
`;
const StyledViewPermissionDetailsText = styled.div`
  font-size: 1rem;
  margin-left: 0.5rem;
  overflow: hidden;
  > div {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
`;
const StyledActionButton = styled(Button)`
  padding: 0;
  border-radius: 0;
  height: 2.57rem;
  background-color: transparent;
  border: none;
  svg path {
    fill: ${(props) => props.theme.colors.white};
  }

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }
`;
const LabelWithDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 6px;
  min-height: 24.8px;
  padding: 0 0 8px;
  line-height: 1.2;
  label {
    display: inline-block;
    ${truncate()}
  }
`;
const StyledTillDate = styled.span`
  color: ${(props) => props.theme.colors.filterLabelColor};
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
`;

export {
  StyledFlexCol,
  StyledMessageWrapper,
  AccountDetailsFormWrapper,
  NotificationPreferencesWrapper,
  NotificationPreferencesItemLabel,
  NotificationPreferencesItemWrapper,
  NotificationPreferencesItemContent,
  NotificationPreferencesItemTitle,
  StyledVerificationList,
  PreferenceButtonsWrapper,
  AccountDetailsFormItem,
  StyledViewPermissionDetailsWrapper,
  StyledViewPermissionDetailsLabel,
  StyledViewPermissionDetailsText,
  StyledCol,
  StyledVerificationItem,
  StyledVerificationWrap,
  StyledActionButton,
  LabelWithDate,
  StyledTillDate,
};
