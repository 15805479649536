export const title = {
  brandSetting: {
    verification: 'verification',
    country: 'country',
    currency: 'currency',
    language: 'language',
    domain: 'domain',

    captcha: 'captcha',
    player: 'player',

    seo: 'seo',
    socialNetwork: 'socialNetwork',
    schemaMarkup: 'schemaMarkup',
    robots: 'robots',

    openGraph: 'openGraph',
    twitter: 'twitter',

    sms: 'sms',
    smtp: 'smtp',
    email: 'email',
    preferences: 'preferences',

    affiliate: 'affiliate',
    gamification: 'gamification',
    analytics: 'analytics',
    liveChatSolution: 'liveChatSolution',
    customScript: 'customScript',
    agSolution: 'agSolution',
    crm: 'crm',
    retailSolution: 'retailSolution',
    authentication: 'authentication',

    deposit: 'deposit',
    withdrawal: 'withdrawal',
    other: 'other',
    bet: 'bet',

    blockedCountries: 'blockedCountries',
    blockedIPs: 'blockedIPs',

    largeTransactions: 'largeTransactions',
    playerLimits: 'playerLimits',
    responsibleGaming: 'responsibleGaming',

    failedPayments: 'failedPayments',
    methods: 'methods',
    settings: 'settings',

    fees: 'fees',
    limits: 'limits',

    playerVerification: 'playerVerification',
    verificationConf: 'verificationConf',
    unverifiedPlayersExclusions: 'unverifiedPlayersExclusions',
    documentType: 'documentType',

    layouts: 'layouts',
    walletsSettings: 'walletsSettings',
    sportStatusIcons: 'sportStatusIcons',
    verificationRules: 'verificationRules',

    providersAndGames: 'providersAndGames',
    jackpots: 'jackpots',

    financialConfigurations: 'financialConfigurations',
    paymentVisibility: 'paymentVisibility',
    currencyConfigs: 'currencyConfigs',
    currencySettings: 'currencySettings',
    inactivity: 'inactivity',
    kyc: 'kyc',
    mainDomains: 'mainDomains',
    sportDomains: 'sportDomains',
    general: 'general',
    wins: 'wins',
    periods: 'periods',
  },
  communication: {
    sent: 'sent',
    scheduled: 'scheduled',
    draft: 'draft',
  },
  translation: {
    mainContent: 'mainContent',
    errorMessages: 'errorMessages',
    reasonsContent: 'reasonsContent',
  },
  player: {
    accountDetails: 'accountDetails',
    limits: 'limits',
    payments: 'payments',
    bets: 'bets',
    adjustments: 'adjustments',
    bonuses: 'bonuses',
    wagers: 'wagers',
    monitoring: 'monitoring',
    tags: 'tags',
    comms: 'comms',
    notes: 'notes',
    kpis: 'kpis',
    attachments: 'attachments',
    history: 'history',
    details: 'details',
    preferences: 'preferences',
    segments: 'segments',
    referees: 'referees',
    sport: 'sport',
    casino: 'casino',
    deposits: 'deposits',
    withdrawals: 'withdrawals',
    responsibleGaming: 'responsibleGaming',
    logs: 'logs',
    cashbackBonus: 'cashbackBonus',
    referralBonus: 'referralBonus',
    massMessages: 'massMessages',
    inboxMessages: 'inboxMessages',
    actions: 'actions',
    sessions: 'sessions',
    userLogs: 'userLogs',
    playerLogs: 'playerLogs',
    previousAccounts: 'previousAccounts',
    similarAccounts: 'similarAccounts',
    legalAgreements: 'legalAgreements',
    blockedProviders: 'blockedProviders',
    blockedPayments: 'blockedPayments',
    accountUpdates: 'accountUpdates',
    systemNotifications: 'systemNotifications',
    overview: 'overview',
    vertical: 'vertical',
    horizontal: 'horizontal',
    promotionTool: 'promotionTool',
    spins: 'spins',
    transactions: 'transactions',
    pushNotifications: 'pushNotifications',
    bonusNotifications: 'bonusNotifications',
    bonusesV2: 'bonusesV2',
    taxSessions: 'taxSessions',
  },
  template: {
    systemTemplates: 'systemTemplates',
    customTemplates: 'customTemplates',
  },
  bonuses: {
    casino: 'casino',
    spins: 'spins',
    sport: 'sport',
    special: 'special',
  },
  bulkActions: {
    statusUpdates: 'statusUpdates',
    balanceAdjustments: 'balanceAdjustments',
    promoSpinsAdjustments: 'promoSpinsAdjustments',
    blockAccess: 'blockAccess',
    providers: 'providers',
    paymentMethods: 'paymentMethods',
  },
  cashBack: {
    accumulatedCashBack: 'accumulatedCashBack',
    transferredCashBack: 'transferredCashBack',
    failedCashBack: 'failedCashBack',
  },
  referral: {
    accumulated: 'accumulated',
    transferred: 'transferred',
    failed: 'failed',
  },
  auditLog: {
    userLogs: 'userLogs',
    playerLogs: 'playerLogs',
    userManagementLogs: 'userManagementLogs',
  },
  playerReports: {
    closedAccounts: 'closedAccounts',
    playersTotals: 'playersTotals',
    inactivePlayers: 'inactivePlayers',
    topPlayers: 'topPlayers',
    byGgr: 'byGgr',
    byDeposits: 'byDeposits',
    byWithdrawals: 'byWithdrawals',
  },
  financialReports: {
    balanceAdjustments: 'balanceAdjustments',
    playersWallets: 'playersWallets',
    firstDeposits: 'firstDeposits',
  },
  compliance: {
    largeTransactions: 'largeTransactions',
    negativeBalance: 'negativeBalance',

    byTransaction: 'byTransaction',
    byPlayer: 'byPlayer',
    byBrand: 'byBrand',
  },
  deviceSessions: {
    applicationSessions: 'applicationSessions',
    webSessions: 'webSessions',
  },
  bets: {
    casino: 'casino',
    sport: 'sport',
  },
  biReports: {
    betAggregations: 'betAggregations',
    financialAggregations: 'financialAggregations',
    byPlayer: 'byPlayer',
    byCurrency: 'byCurrency',
  },
  userManagement: {
    myGroups: 'myGroups',
    defaultGroups: 'defaultGroups',
  },
  cms: {
    inventory: 'inventory',
    deposit: 'deposit',
    withdrawal: 'withdrawal',
    settings: 'settings',

    games: 'games',
    groups: 'groups',
    gameTypes: 'gameTypes',

    general: 'general',
    jackpot: 'jackpot',
    lobbyHome: 'lobbyHome',

    navigation: 'navigation',
  },
  tools: {
    reasons: 'reasons',
    resources: 'resources',

    vertical: 'vertical',
    horizontal: 'horizontal',
    logs: 'logs',
  },
  devTools: {
    systemCaches: 'systemCaches',
    dbSynchronization: 'dbSynchronization',
    jobs: 'jobs',
    userConfig: 'userConfig',
  },
  campaigns: {
    campaigns: 'campaigns',
    crossPlatformCampaigns: 'crossPlatformCampaigns',

    received: 'received',
    failed: 'failed',
  },
  promotion: {
    transactions: 'transactions',
    machines: 'machines',
    prizes: 'prizes',
    players: 'players',
    spins: 'spins',
    exchanges: 'exchanges',
  },
  bonusReports: {
    byPlayer: 'byPlayer',
    byType: 'byType',
    byStatus: 'byStatus',
    byProvider: 'byProvider',
  },
  betStatusCorrections: {
    singleBets: 'singleBets',
    bulkBets: 'bulkBets',
  },
  machineConfigChangeLogs: {
    prizeAndProbabilitySection: 'prizeAndProbabilitySection',
    advancedProbability: 'advancedProbability',
  },
  groupLimitChangeLogs: {
    general: 'general',
    advanced: 'advanced',
  },
  campaignV2Bonuses: {
    freeBet: 'freeBet',
    spin: 'spin',
    combined: 'combined',
    special: 'special',
  },
};
