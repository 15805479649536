export default {
  en: {
    forgotTFA: 'Please fill your email for recovery protocol.QR code for 2 factor authentication will be sent to your email',
    forgotPassword: "Please, fill your email for recovery protocol. Recovery link will be sent to your account's email",
    setPassword: 'Your password has been set. Now you can log in to admin panel',
    resetPassword: 'Only passwords with minimum of 8 characters including at least 1 number and 1 letter are accepted',
    resendTFASuccess: 'QR code for 2 factor authentication has been sent to your email',
    userAddSuccess: 'User will get email to follow and set password for the account',
    TFARequired: 'The two-factor authentication required',
    unknownError: 'Something went wrong, please try again',
    noSubjectOrBody: 'Subject line and body must be provided for all selected channels and languages',
    noComposeSubject: 'You cannot sent message with empty subject line',
    noComposeContent: 'You cannot send empty messages',
    noComposeContentSubject: 'You cannot send empty messages or with empty subject line',
    recalledMessageSuccess: 'Message recalled by user {{fullName}}',
    sendEmptyMessageError: 'You cannot send empty messages',
    sessionExpired: 'Session Expired',
    atLeastOneCurrency: 'At least 1 currency must be configured',
    atLeastOneGame: 'At least 1 game must be selected',
    atLeastOnePlayer: 'At least 1 player must be selected',
    atLeastOneRanges: 'At least 1 range must be filled',
    atLeastOnePersona: 'At least 1 persona must be selected',
    maxUploadSize: 'File size cannot exceed ',
    maxSumUploadSize: 'Max allowed upload size cannot exceed ',
    maxUploadedFilesSize: 'Maximum size of the selected files cannot exceed ',
    selectedAllRestrictedCountries: 'You cannot select all restricted countries',
    wrongFileFormat: 'Wrong file format',
    playerInList: 'Player is already in the list',
    folderContainFile: 'File is already in this folder',
    folderContainFiles: 'Files are already in this folder',
    brandExist: 'Brand with this name already exists',
    automationRuleExist: 'Rule with this name already exists',
    messageTitleExist: 'Message with this name already exists',
    noAvailablePlayers: 'No available players for selected date range',
    passwordExpired: 'Password expired',
    colorNotEmpty: 'Color cannot be empty',
    tagNotEmpty: 'Tag cannot be empty',
    accountDeleted: 'Your account has been deleted. Now you will be logged out',
    accountDisabled: 'Your account has been disabled. Now you will be logged out',
    atLeastOneVersion: 'At least one version must be selected',
    fileUploaded: 'File has been uploaded',
    notMatchRegexRequirements: '{{name}} file does not match regex requirements',
    notMatchMaxSizeRequirement: '{{name}} file does not match max size requirement',
    copied: 'Copied',
    atLeastOneLimitMustBeUpdated: 'At least 1 limit must be updated',
    noAttachments: 'No attachments',
    tagDeletedFromPlayerAccount: '{{name}} tag has been deleted from the player account',
    bonusCanceledForPlayer: 'Bonus has been canceled for {{name}} player',
    generalMessage: '{{name}} {{model}} {{action}}',
    slUpdated: 'has been updated',
    plUpdated: 'have been updated',
    slDeleted: 'has been deleted',
    slExcluded: 'has been excluded',
    plDeleted: 'have been deleted',
    slActivated: 'has been activated',
    plActivated: 'have been activated',
    slDeactivated: 'has been deactivated',
    plDeactivated: 'have been deactivated',
    slImported: 'has been imported',
    slReset: 'has been reset',
    plReset: 'have been reset',
    slAdded: 'has been added',
    plAdded: 'have been added',
    slDisabled: 'has been disabled',
    plDisabled: 'have been disabled',
    slEnabled: 'has been enabled',
    slCreated: 'has been created',
    plCreated: 'have been created',
    plMoved: 'have been moved',
    plMerged: 'have been merged',
    plTagged: 'have been tagged',
    slUntagged: 'has been untagged',
    plUntagged: 'have been untagged',
    removedFromSegment: '{{name}} has been removed from segment',
    playerAddedToSegment: 'The player has been added to {{name}} segment',
    playerRemovedFromSegment: 'The player has been removed from {{name}} segment',
    slFinished: 'has been finished',
    slConfigured: 'has been configured',
    plSent: 'have been sent',
    plRemoved: 'has been removed',
    slSent: 'has been sent',
    setMarkAttention: 'Mark Attention has been set {{name}}',
    slRemoved: 'has been removed',
    slUnlocked: 'has been unlocked',
    accountBlockedForMessages: 'Account {{action}} for messages',
    slBlocked: 'has been blocked',
    plBlocked: 'have been blocked',
    plUnblocked: 'have been unblocked',
    slUnblocked: 'has been unblocked',
    slChanged: 'has been changed',
    slReactivated: 'has been reactivated',
    changeAccountStatus: 'Account has been {{action}}',
    bulkStatusUpdate: '{{action}} has been performed on selected players',
    slAdjusted: 'has been adjusted',
    plAdjusted: 'have been adjusted',
    slClosed: 'has been closed',
    slAssigned: 'has been assigned',
    plAssigned: 'have been assigned',
    finishedTestPlayersAccountUse: "Test player's account use has been finished",
    adjustedTestPlayersAccountBalance: "Test player's account balance has been adjusted",
    slVerified: 'has been verified',
    plVerified: 'has been verified',
    slUnverified: 'has been unverified',
    slSet: 'has been set',
    slIntegrated: 'has been integrated',
    slSavedAsDraft: 'has been saved as draft',
    slScheduledForDate: 'has been scheduled for {{date}}',
    movedToDraft: 'has been moved to draft',
    plRejected: 'have been rejected',
    playerAccountVerifiedWith: 'Player account has been verified with {{docType}}',
    plApproved: 'have been approved',
    plUploaded: 'have been uploaded',
    plArchived: 'have been archived',
    slStarted: 'has been started',
    slMoved: 'has been moved',
    slResent: 'has been resent',
    playerRemovedFromRestrictionList: '{{name}} player has been removed from restriction list',
    plBlacklisted: 'have been blacklisted',
    plWhiteListed: 'have been whitelisted',
    slBlacklisted: 'has been blacklisted',
    slWhiteListed: 'has been whitelisted',
    slCancelled: 'has been cancelled',
    playerDisconnectedFromBonus: '{{name}} player has been disconnected from bonus',
    playerRemovedFromBonus: '{{name}} player has been removed from bonus',
    playersAddedToBonus: 'The players have been added to the bonus',
    slIssued: 'has been issued',
    slScheduled: 'has been scheduled',
    slRenamed: 'has been renamed',
    slUploaded: 'has been uploaded',
    slConnected: 'has been connected',
    slDisconnected: 'has been disconnected',
    plConfigured: 'have been configured',
    mailSetAsDefault: '{{name}} email has been set as default',
    slRestricted: 'has been restricted',
    plRestricted: 'have been restricted',
    countryDeletedFromRestrictionList: '{{name}} country deleted from restriction list',
    ipAddressDeletedFromRestrictionList: '{{name}} IP address deleted from restriction list',
    playerChangedNotificationPreferences: 'Player changed notification preferences',
    playerNotEligible: 'The player is not eligible to receive a message',
    bodyIsRequired: 'Email body cannot be empty',
    playerCanceledFromBonus: 'Bonus has been canceled for {{name}} player',
    noRecipientMessage: 'No recipient to get message',
    atLeastOneDepositConfig: 'At least 1 deposit config must be configured',
    atLeastOneProvider: 'At least one provider must be selected',
    countOrAtLeastOneAmountConfig: 'Count or at least one currency amount must be configured',
    atLeastOneCurrencyAmountConfig: 'At least one currency amount config must be configured',
    allCurrencyAmountsConfigs: 'All currency amount configs must be configured',
    bonusNameExists: 'Bonus name already exists',
    slPromotionCancelled: 'promotion has been cancelled',
    atLeastOneSuccessPlayer: 'At least one player able to get bonuses must be provided',
    setAsDefault: '{{name}} has been set as default',
    promoCodeExist: 'The promo code already exists',
    dataSyncError: 'Data Synchronization in process',
    atLeastOneTable: 'At least 1 table must be selected',
    plSynchronized: 'have been synchronized',
    slSynchronized: 'has been synchronized',
    lobbyProviderOrderError: 'All providers should have order',
    lobbyProviderGameOrderError: 'All games should have order',
    atLeastOneCurrencyEnabled: 'At least one currency must be enabled',
    machineSectionCannotBeEmpty: "Machine's sections cannot be empty",
    atLeastOneCondition: 'At least one condition must be filled',
    allSectionsMustBeSelectedAndHasProbability: 'All sections must be selected and have probability',
    sumOfProbabilities: 'Sum of probabilities must be 100',
    prizeAndProbabilityConfigure: 'Prize and Probability section must be configure',
    slPublished: 'has been published',
    sectionImageRequired: 'A section image is required',
    titleAndDescriptionRequired: 'The title and the description are required for the Terms and Conditions',
    configureSelectedPrizeItems: 'Please config the selected prize items',
    atLeastOneGroupMustHasLimit: 'At least one group must has limit',
    atLeastOneGroupMustBeConfigured: 'At least one group must be configured',
    atLeastTwoPrize: 'At least two prize must be selected',
    allSectionsMustBeConfigured: 'All sections must be configured',
    mustSelectDefaultPrizeAndProbability: 'Default prize must be selected and has probability greater than 0',
    mustSelectDefaultPrize: 'Default prize must be selected',
    slTimeout: 'is currently on a timeout.',
    testPlayerResetPassword: "The new password has been sent to the user's email address",
    atLeastOneTag: 'At least one tag must be selected',
    groupNameAlreadyExist: 'The group name is already used for the active group. You can not have 2 groups with the same name in active status.',
    atLeastOnePromoCodeMustBeUploaded: 'At least 1 valid promo code must be uploaded',
    emptyFile: 'The file is empty. Please check it again.',
    websitePageBackgroundRequired: "The website's page background is required",
    mobilePageBackgroundRequired: "The mobile's page background is required",
    visibilityConfigurationUpdated: 'Visibility configurations for {{name}} payment method has been updated',
    atLeastOneTemplate: 'At least one template must be selected',
    plEnabled: 'have been enabled',
    atLeastOneContentSection: 'At least 1 of the opened sections should be configured',
    deepLink: 'Deep link',
    deepLinkSection: 'Deep Link Section',
    deepLinkIdOrName: 'Deep Link ID/Name',
    slCompleted: 'has been completed',
    passwordResetLinkSent:
      "A password reset link has successfully been sent to <0>{{email}}</0> email address, please check your inbox. <1/> If you haven't received an email in 5 minutes, check your spam or try to resend the recovery request again",
    atLeastOneFileMustBeUploaded: 'At least one file must be uploaded',
    slDone: 'has been done',
    statusCorrectionIsInProgress: 'Status Correction is in progress',
    mustBeUpdated: '{{fieldName}} must be updated',
    campaignMainLanguageMustBeConfigured: "Campaign content must be configured for brand's main language",
    atLeastOneReleaseNote: 'At least one release note must be configured',
    manageMaintenance: 'Maintenance setup has been completed for {{fieldName}} partner brands.',
    removeMaintenance: 'Maintenance has been removed for {{fieldName}} partner brands.',
    bulkManageMaintenance: 'Maintenance setup for the selected brands has been completed.',
    bulkRemoveMaintenance: 'Maintenance has been removed for the selected brands',
    plEnded: 'have been ended',
    atLeastOnePermissionGroup: 'At least 1 permission group must be selected',
    atLeastOneProduct: 'At least 1 product must be configured',
    atLeastOneItem: 'At least one item must be configured',
    titleAndSummaryRequired: 'The title and the summary are required for the Terms and Conditions',
    allProvidersMustBeConfigured: 'All providers must be configured',
    selectedGamesLimit: 'Max selected games count is {{count}}',
    collectionMustBeConfigured: 'At least one collection must be configured',
    desktopBackgroundRequired: 'Desktop background is required',
    mobileBackgroundRequired: 'Mobile background is required',
    desktopImageRequired: 'Desktop image is required',
    mobileImageRequired: 'Mobile image is required',
    atLeastOneEvent: 'At least one event must be selected',
    atLeastOneMarket: 'At least one market must be selected',
    atLeastOneEntityMustBeSelected: 'You must select at least one {{model1}} from the following {{model2}} or deselect the following {{model2}}',
    duplicateConfig: 'Duplicate configurations found, please correct them',
    atLeastOneInclude: 'At least one rule with inclusion should be configured!',
    playerRemovedFList: '{{name}} player has been removed from list',
  },
  es: {
    forgotTFA: 'Rellene su correo electrónico para el protocolo de recuperación. El código QR para la autenticación de 2 factores se enviará a su correo electrónico',
    forgotPassword: 'Rellene su correo electrónico para el protocolo de recuperación. El enlace de recuperación se enviará al correo electrónico de su cuenta',
    setPassword: 'Su contraseña ha sido establecida. Ahora puede entrar en el panel de administración',
    resetPassword: 'Sólo se aceptan contraseñas con un mínimo de 8 caracteres que incluyan al menos 1 número y 1 letra',
    resendTFASuccess: 'El código QR para la autenticación de 2 factores se ha enviado a su correo electrónico',
    userAddSuccess: 'El usuario recibirá un correo electrónico para seguir y establecer la contraseña de la cuenta',
    TFARequired: 'La autenticación de dos factores "requerida.',
    unknownError: 'Algo" ha ido mal inténtelo de "nuevo.""',
    noSubjectOrBody: 'La" línea de asunto y el cuerpo deben proporcionarse para todos los canales e idiomas seleccionados',
    noComposeSubject: 'No se puede enviar un mensaje con la línea de asunto vacía',
    noComposeContent: 'No se pueden enviar mensajes vacíos',
    noComposeContentSubject: 'No se pueden enviar mensajes vacíos o con la línea de asunto vacío',
    recalledMessageSuccess: 'Mensaje retirado por el usuario {{fullName}}',
    sendEmptyMessageError: 'No se pueden enviar mensajes vacíos',
    sessionExpired: 'La sesión ha expirado',
    atLeastOneCurrency: 'Debe configurarse al menos 1 moneda',
    atLeastOneGame: 'Debe configurarse al menos 1 juego',
    atLeastOnePlayer: 'Debe configurarse al menos 1 jugador',
    atLeastOneRanges: 'Debe rellenarse al menos 1 rango',
    atLeastOnePersona: 'Debe configurarse al menos 1 personaje',
    maxUploadSize: 'El tamaño del archivo no puede exceder ',
    maxSumUploadSize: 'El tamaño máximo de subida permitido no puede exceder ',
    maxUploadedFilesSize: 'El tamaño máximo de los archivos seleccionados no puede superar',
    selectedAllRestrictedCountries: 'No puede seleccionar todos los países restringidos',
    wrongFileFormat: 'Formato de archivo incorrecto',
    globalGamesEnabled: 'Se ha/han activado el/los juego(s) para todas las marcas',
    globalGamesDisabled: 'Se ha/han desactivado el/los juego(s) para todas las marcas',
    playerInList: 'El jugador ya aparece en la lista',
    folderContainFile: 'El jugador ya está en la lista',
    folderContainFiles: 'El archivo ya está en esta carpeta',
    brandExist: 'Ya existe una marca con este nombre',
    automationRuleExist: 'Ya existe una regla con este nombre',
    messageTitleExist: 'Ya existe un mensaje con este nombre',
    noAvailablePlayers: 'No hay jugadores disponibles para el rango de fechas seleccionado',
    passwordExpired: 'Contraseña caducada',
    colorNotEmpty: 'El color no puede estar vacío',
    tagNotEmpty: 'La etiqueta no puede estar vacía',
    accountDeleted: 'Su cuenta ha sido eliminada. Ahora se cerrará su sesión.',
    accountDisabled: 'Su cuenta ha sido desactivada. Ahora se cerrará su sesión.',
    atLeastOneVersion: 'Debe seleccionarse al menos una versión',
    fileUploaded: 'Se ha subido el archivo',
    notMatchRegexRequirements: 'El archivo {{name}} no coincide con los requisitos regex',
    notMatchMaxSizeRequirement: 'El archivo {{name}} no coincide con el requisito de tamaño máximo',
    copied: 'Copiado',
    atLeastOneLimitMustBeUpdated: 'Debe actulizarse al menos 1 límite',
    noAttachments: 'No hay archivos adjuntos',
    tagDeletedFromPlayerAccount: 'La etiqueta {{name}} ha sido eliminada de la cuenta del jugador',
    bonusCanceledForPlayer: 'Se ha cancelado el bono para el jugador "{{name}}',
    generalMessage: '{{name}} {{model}} {{action}}',
    slUpdated: 'se" ha actualizado',
    plUpdated: 'se han actualizado',
    slDeleted: 'se ha eliminado',
    slExcluded: 'se ha excluido',
    plDeleted: 'se han eliminado',
    slActivated: 'se ha activado',
    plActivated: 'se han activado',
    slDeactivated: 'se ha desactivado',
    plDeactivated: 'se han desactivado',
    slImported: 'se ha importado',
    slReset: 'se ha restablecido',
    plReset: 'se han restablecido',
    slAdded: 'se ha añadido',
    plAdded: 'se han añadido',
    slDisabled: 'se ha desactivado',
    plDisabled: 'se han desactivado',
    slEnabled: 'se ha activado',
    slCreated: 'se ha creado',
    plCreated: 'se han creado',
    plMoved: 'se han movido',
    plMerged: 'se han fusionado',
    plTagged: 'se han etiquetado',
    slUntagged: 'se ha desetiquetado',
    plUntagged: 'se han desetiquetado',
    removedFromSegment: '{{name}} se ha eliminado del segmento',
    playerAddedToSegment: 'El jugador ha sido añadido al segmento {{name}}',
    playerRemovedFromSegment: 'El jugador ha sido eliminado del segmento {{name}}',
    slFinished: 'se ha terminado',
    slConfigured: 'se ha configurado',
    plSent: 'se han enviado',
    plRemoved: 'se ha eliminado',
    slSent: 'se ha enviado',
    setMarkAttention: 'La atención de la marca se ha establecido {{name}}',
    slRemoved: 'se ha eliminado',
    slUnlocked: 'se ha desbloqueado',
    accountBlockedForMessages: 'Cuenta {{action}} para mensajes',
    slBlocked: 'se ha bloqueado',
    slUnblocked: 'se han desbloqueado',
    slChanged: 'se ha cambiado',
    slReactivated: 'se ha reactivado',
    changeAccountStatus: 'La cuenta se ha {{action}}',
    bulkStatusUpdate: 'Se ha realizado la {{action}} en jugadores seleccionados',
    slAdjusted: 'se ha ajustado',
    plAdjusted: 'se han ajustado',
    slClosed: 'se ha cerrado',
    slAssigned: 'se ha asignado',
    plAssigned: 'se han asignado',
    finishedTestPlayersAccountUse: 'El uso de la cuenta del jugador de prueba ha finalizado',
    adjustedTestPlayersAccountBalance: 'Se ha ajustado el saldo de la cuenta del jugador de prueba',
    slVerified: 'se ha verificado',
    plVerified: 'se ha verificado',
    slUnverified: 'no se ha verificado',
    slSet: 'se ha establecido',
    slIntegrated: 'se ha integrado',
    slSavedAsDraft: 'se ha guardado como borrador',
    slScheduledForDate: 'se ha programado para {{date}}',
    movedToDraft: 'se han movido a borradores',
    plRejected: 'se han rechazado',
    playerAccountVerifiedWith: 'La cuenta del jugador se ha verificado con {{docType}}',
    plApproved: 'se han aprobado',
    plUploaded: 'se han cargado',
    plArchived: 'se han archivado',
    slStarted: 'se ha iniciado',
    slMoved: 'se ha movido',
    slResent: 'se ha reenviado',
    playerRemovedFromRestrictionList: 'El jugador {{name}} ha sido eliminado de la lista de restricciones',
    plBlacklisted: 'han sido incluidos en la lista negra',
    plWhiteListed: 'han sido incluidos en la lista blanca',
    slBlacklisted: 'se han incluido en la lista negra',
    slWhiteListed: 'se han incluido en la lista blanca',
    slCancelled: 'ha sido cancelado',
    playerDisconnectedFromBonus: 'El jugador {{name}} ha sido desconectado del bono',
    playerRemovedFromBonus: '{{name}} jugador ha sido eliminado de la bonificación',
    playersAddedToBonus: 'Los jugadores se han añadido a la bonificación',
    slIssued: 'se ha expedido',
    slScheduled: 'se ha programado',
    slRenamed: 'ha cambiado de nombre a',
    slUploaded: 'se ha cargado',
    slConnected: 'se ha conectado',
    slDisconnected: 'se ha desconectado',
    plConfigured: 'Ha sido configurado',
    mailSetAsDefault: '{{name}} correo electrónico se ha establecido por defecto',
    slRestricted: 'se ha restringido',
    plRestricted: 'se han restringido',
    countryDeletedFromRestrictionList: '{{name}} país eliminado de la lista de restricciones',
    ipAddressDeletedFromRestrictionList: '{{nombre}} Dirección IP eliminada de la lista de restricciones',
    playerChangedNotificationPreferences: 'El jugador ha cambiado las preferencias de notificación',
    playerNotEligible: 'El jugador no puede recibir mensajes',
    bodyIsRequired: 'El cuerpo del correo electrónico no puede estar vacío',
    playerCanceledFromBonus: 'Se ha cancelado la bonificación para el jugador {{name}}',
    noRecipientMessage: 'El mensaje no ha llegado a ningún destinatario',
    atLeastOneDepositConfig: 'Debe configurarse al menos 1 depósito',
    atLeastOneProvider: 'Debe seleccionarse al menos un proveedor',
    countOrAtLeastOneAmountConfig: 'Debe configurarse el recuento o al menos un importe de divisa',
    atLeastOneCurrencyAmountConfig: 'Debe configurarse al menos un importe de divisa',
    bonusNameExists: 'El nombre de la bonificación ya existe',
    slPromotionCancelled: 'promoción cancelada',
    atLeastOneSuccessPlayer: 'Debe haber al menos un jugador capaz de obtener bonificaciones',
    setAsDefault: '{{nombre}} se ha establecido por defecto',
    promoCodeExist: 'El código promocional ya existe',
    dataSyncError: 'Sincronización de Datos en curso',
    atLeastOneTable: 'Debe seleccionarse al menos 1 mesa',
    plSynchronized: 'se han sincronizado',
    slSynchronized: 'se ha sincronizado',
    lobbyProviderOrderError: 'Todos los proveedores deben tener orden.',
    lobbyProviderGameOrderError: 'Todos los juegos deben tener orden.',
    atLeastOneCurrencyEnabled: 'Al menos una moneda debe estar habilitada.',
    machineSectionCannotBeEmpty: 'Las secciones de la máquina no pueden estar vacías.',
    atLeastOneCondition: 'Al menos una condición debe ser completada.',
    allSectionsMustBeSelectedAndHasProbability: 'Todas las secciones deben ser seleccionadas y tener probabilidad.',
    sumOfProbabilities: 'La suma de las probabilidades debe ser 100.',
    prizeAndProbabilityConfigure: 'La sección de Premio y Probabilidad debe ser configurada.',
    slPublished: 'ha sido publicado.',
    sectionImageRequired: 'Se requiere una imagen de sección.',
    titleAndDescriptionRequired: 'El título y la descripción son requeridos para los Términos y Condiciones.',
    configureSelectedPrizeItems: 'Por favor, configura los premios seleccionados.',
    atLeastOneGroupMustHasLimit: 'Al menos un grupo debe tener límite.',
    atLeastOneGroupMustBeConfigured: 'Al menos un grupo debe ser configurado.',
    atLeastTwoPrize: 'Al menos dos premios deben ser seleccionados.',
    allSectionsMustBeConfigured: 'Todas las secciones deben ser configuradas.',
    mustSelectDefaultPrizeAndProbability: 'El premio predeterminado debe ser seleccionado y tener una probabilidad mayor que 0',
    mustSelectDefaultPrize: 'El premio predeterminado debe ser seleccionado',
    slTimeout: 'actualmente está en un tiempo de espera',
    testPlayerResetPassword: 'La nueva contraseña ha sido enviada a la dirección de correo electrónico del usuario',
    atLeastOneTag: 'Al menos una etiqueta debe ser seleccionada',
    groupNameAlreadyExist: 'El nombre del grupo ya está en uso para el grupo activo. No puedes tener 2 grupos con el mismo nombre en estado activo',
    atLeastOnePromoCodeMustBeUploaded: 'Al menos 1 código promocional válido debe ser subido',
    emptyFile: 'El archivo está vacío. Por favor, revísalo de nuevo',
    mobilePageBackgroundRequired: 'Se requiere el fondo de la página móvil.',
    visibilityConfigurationUpdated: 'Las configuraciones de visibilidad para el método de pago {{name}} han sido actualizadas.',
    atLeastOneTemplate: 'Al menos una plantilla debe ser seleccionada.',
    plEnabled: 'han sido habilitados.',
    atLeastOneContentSection: 'Al menos 1 de las secciones abiertas debe ser configurada',
    deepLink: 'Enlace Directo',
    deepLinkSection: 'Sección de Enlace Directo',
    deepLinkIdOrName: 'ID/Nombre de Enlace Directo',
    slCompleted: 'ha sido completado.',
    passwordResetLinkSent:
      'El enlace para restablecer la contraseña ha sido enviado exitosamente a la dirección de correo electrónico <0>{{email}}</0>, por favor revisa tu bandeja de entrada. <1/> Si no ha recibido un correo en 5 minutos, revisa tu carpeta de spam o intenta enviar la solicitud de recuperación nuevamente.',
    atLeastOneFileMustBeUploaded: 'Al menos un archivo debe ser subido',
    slDone: 'ha sido hecho',
    statusCorrectionIsInProgress: 'La corrección de estado está en progreso',
    mustBeUpdated: '{{fieldName}} debe ser actualizado',
  },
  tr: {
    forgotTFA: 'Lütfen kurtarma protokolü için e-posta adresinizi girin. 2 faktörlü kimlik doğrulama için QR kodu e-postanıza gönderilecektir',
    forgotPassword: 'Kurtarma protokolü için lütfen e-postanızı doldurun. Kurtarma bağlantısı hesabınızın e-postasına gönderilecektir',
    setPassword: 'Şifreniz oluşturuldu. Artık yönetici paneline giriş yapabilirsiniz',
    resetPassword: 'Sadece en az 1 rakam ve 1 harf içeren minimum 8 karakterli şifreler kabul edilir',
    resendTFASuccess: '2 faktörlü kimlik doğrulama için QR kodu e-posta adresinize gönderildi',
    userAddSuccess: 'Kullanıcı e-posta alır ve hesap için şifre belirler',
    TFARequired: 'İki  faktörlü kimlik doğrulama gereklidir',
    unknownError: 'Bir hata oluştu, lütfen tekrar deneyin',
    noSubjectOrBody: 'Konu satırı ve gövdesi seçilen tüm kanallar ve diller için belirtilmeli',
    noComposeSubject: 'Konu satırı boş olarak mesaj gönderemezsiniz',
    noComposeContent: 'Boş mesaj gönderemezsiniz',
    noComposeContentSubject: 'Konu satırı boş olarak mesaj gönderemezsiniz',
    recalledMessageSuccess: 'Mesaj {{fullName}} tarafından geri çağrıldı',
    sendEmptyMessageError: 'Boş mesaj gönderemezsiniz',
    sessionExpired: 'Oturum Süresi Doldu',
    atLeastOneCurrency: 'En az 1 para birimi yapılandırılmalıdır',
    atLeastOneGame: 'En az 1 oyun seçilmelidir',
    atLeastOnePlayer: 'En az 1 oyuncu seçilmelidir',
    atLeastOneRanges: 'En az 1 aralık doldurulmalıdır',
    atLeastOnePersona: 'En az 1 persona seçilmelidir',
    maxUploadSize: 'Dosya boyutu geçilemez',
    maxSumUploadSize: 'İzin verilen maksimum yükleme boyutu aşılamaz',
    maxUploadedFilesSize: 'Seçilen dosyaların maksimum boyutu aşılamaz',
    selectedAllRestrictedCountries: 'Tüm kısıtlı ülkeleri seçemezsiniz',
    wrongFileFormat: 'Yanlış dosya formatı',
    globalGamesEnabled: 'Oyun(lar) tüm markalar için etkinleştirildi',
    globalGamesDisabled: 'Oyun(lar) tüm markalar için devre dışı bırakıldı',
    playerInList: 'Oyuncu zaten listede mevcut',
    folderContainFile: 'Dosya zaten bu klasörde mevcut',
    folderContainFiles: 'Dosyalar zaten bu klasörde mevcut',
    brandExist: 'Bu isimde bir marka zaten mevcut',
    automationRuleExist: 'Bu isimde bir kural zaten mevcut',
    messageTitleExist: 'Bu isimde bir mesaj zaten mevcut',
    noAvailablePlayers: 'Seçili tarih aralığı için uygun kullanıcı yok',
    passwordExpired: 'Şifrenin süresi doldu',
    colorNotEmpty: 'Renk boş olamaz',
    tagNotEmpty: 'Etiket boş olamaz',
    accountDeleted: 'Hesabınız silindi. Şimdi oturumunuz kapatılacak',
    accountDisabled: 'Hesabınız devre dışı bırakıldı. Şimdi oturumunuz kapatılacak',
    atLeastOneVersion: 'En az bir versiyon seçilmelidir',
    fileUploaded: 'Dosya yüklendi',
    notMatchRegexRequirements: '{{name}} adlı dosya regex gerekliliklerine uymuyor',
    notMatchMaxSizeRequirement: '{{name}} adlı dosya maksimum boyut gerekliliklerine uymuyor',
    copied: 'Kopyalandı',
    atLeastOneLimitMustBeUpdated: 'En az 1 limit güncellenmeli',
    noAttachments: 'Ek yok',
    tagDeletedFromPlayerAccount: '{{name}} etiketi oyuncunun hesabından silindi',
    bonusCanceledForPlayer: 'Bonus {{name}} adlı oyuncu için iptal edildi',
    generalMessage: '{{name}} {{model}} {{action}}',
    slUpdated: 'güncellendi',
    plUpdated: 'güncellendi',
    slDeleted: 'silindi',
    slExcluded: 'hariç tutuldu',
    plDeleted: 'silindi',
    slActivated: 'etkinleştirildi',
    plActivated: 'etkinleştirildi',
    slDeactivated: 'devre dışı bırakıldı',
    plDeactivated: 'devre dışı bırakıldı',
    slImported: 'içe aktarıldı',
    slReset: 'sıfırlandı',
    plReset: 'sıfırlandı',
    slAdded: 'eklendi',
    plAdded: 'eklendi',
    slDisabled: 'devre dışı bırakıldı',
    plDisabled: 'devre dışı bırakıldı',
    slEnabled: 'etkinleştirildi',
    slCreated: 'oluşturuldu',
    plCreated: 'oluşturuldu',
    plMoved: 'taşındı',
    plMerged: 'birleştirildi',
    plTagged: 'etiketlendi',
    slUntagged: 'etiketi kaldırıldı',
    plUntagged: 'etiketi kaldırıldı',
    removedFromSegment: '{{name}} bölümden kaldırıldı',
    playerAddedToSegment: 'Oyuncu {{name}} bölümüne eklendi',
    playerRemovedFromSegment: 'Oyuncu {{name}} bölümünden kaldırıldı',
    slFinished: 'bitirildi',
    slConfigured: 'yapılandırıldı',
    plSent: 'gönderildi',
    plRemoved: 'kaldırıldı',
    slSent: 'gönderildi',
    setMarkAttention: 'Dikkat İşareti ayarlandı {{name}}',
    slRemoved: 'kaldırıldı',
    slUnlocked: 'kilidi açıldı',
    accountBlockedForMessages: 'Mesajlar için hesap {{action}}',
    slBlocked: 'engellendi',
    slUnblocked: 'kilidi açıldı',
    slChanged: 'değiştirildi',
    slReactivated: 'tekrar aktive edildi',
    changeAccountStatus: 'Hesap {{action}}',
    bulkStatusUpdate: 'Seçili oyuncular üzerinde {{action}} gerçekleştirildi',
    slAdjusted: 'düzenlendi',
    plAdjusted: 'düzenlendi',
    slClosed: 'kapatıldı',
    slAssigned: 'atandı',
    plAssigned: 'atandı',
    finishedTestPlayersAccountUse: 'Test oyuncusu hesabı kullanımı bitirildi',
    adjustedTestPlayersAccountBalance: 'Test oyuncusunun hesap bakiyesi ayarlandı',
    slVerified: 'doğrulandı',
    plVerified: 'doğrulandı',
    slUnverified: 'doğrulanmadı',
    slSet: 'ayarlandı',
    slIntegrated: 'entegre edildi',
    slSavedAsDraft: 'taslak olarak kaydedildi',
    slScheduledForDate: '{{date}} tarihi için planlandı',
    movedToDraft: 'taslağa taşındı',
    plRejected: 'reddedildi',
    playerAccountVerifiedWith: 'Oyuncu hesabı {{docType}} ile doğrulandı',
    plApproved: 'onaylandı',
    plUploaded: 'yüklendi',
    plArchived: 'arşivlendi',
    slStarted: 'başlatıldı',
    slMoved: 'taşındı',
    slResent: 'tekrar gönderildi',
    playerRemovedFromRestrictionList: '{{name}} oyuncu kısıtlama listesinden kaldırıldı',
    plBlacklisted: 'kara listeye eklendi',
    plWhiteListed: 'beyaz listeye eklendi',
    slBlacklisted: 'kara listeye eklendi',
    slWhiteListed: 'beyaz listeye eklendi',
    slCancelled: 'iptal edildi',
    playerDisconnectedFromBonus: '{{name}} oyuncusunun bonus bağlantısı kesildi',
    playerRemovedFromBonus: '{{name}} oyuncu bonustan kaldırıldı',
    playersAddedToBonus: 'Oyuncular bonusa eklendi',
    slIssued: 'işlendi',
    slScheduled: 'planlandı',
    slRenamed: 'yeniden adlandırıldı',
    slUploaded: 'yüklendi',
    slConnected: 'bağlandı',
    slDisconnected: 'bağlantısı kesildi',
    plConfigured: 'yapılandırıldı',
    mailSetAsDefault: '{{name}} e-posta varsayılan olarak belirlendi',
    slRestricted: 'kısıtlandı',
    plRestricted: 'kısıtlandı',
    countryDeletedFromRestrictionList: '{{name}} ülke kısıtlama listesinden silindi',
    ipAddressDeletedFromRestrictionList: '{{name}} IP adresi kısıtlama listesinden silindi',
    playerChangedNotificationPreferences: 'Oyuncu bildirim tercihlerini değiştirdi',
    playerNotEligible: 'Oyuncu mesaj alamaz',
    bodyIsRequired: 'E-posta gövdesi boş olamaz',
    playerCanceledFromBonus: 'Bonus {{name}} oyuncusu için iptal edildi',
    noRecipientMessage: 'Mesaj alıcısı yok',
    atLeastOneDepositConfig: 'En az 1 para yatırma yapılandırması ayarlanmalıdır',
    atLeastOneProvider: 'En az bir sağlayıcı seçilmelidir',
    countOrAtLeastOneAmountConfig: 'Sayı veya en az bir para birimi tutarı yapılandırılmalıdır',
    atLeastOneCurrencyAmountConfig: 'En az bir para birimi tutarı yapılandırması ayarlanmalıdır',
    bonusNameExists: 'Bonus adı zaten mevcut',
    slPromotionCancelled: 'promosyon i̇ptal edi̇lmi̇şti̇r',
    atLeastOneSuccessPlayer: 'Bonus alabilecek en az bir oyuncu sağlanmalıdır',
    setAsDefault: '{{isim}} varsayılan olarak ayarlandı',
    promoCodeExist: 'Promosyon kodu zaten mevcut',
    dataSyncError: 'Veri Senkronizasyonu yapılıyor',
    atLeastOneTable: 'En az 1 tablo seçilmelidir',
    plSynchronized: 'senkronize edilmiştir',
    slSynchronized: 'senkronize edilmiştir',
    allCurrencyAmountsConfigs: 'Tüm para birimi tutarı yapılandırmaları ayarlanmalıdır',
    lobbyProviderOrderError: 'Tüm sağlayıcıların sırası olmalıdır',
    lobbyProviderGameOrderError: 'Tüm oyunların sırası olmalıdır',
    atLeastOneCurrencyEnabled: 'En az bir para birimi etkinleştirilmelidir',
    machineSectionCannotBeEmpty: 'Makine bölümleri boş olamaz',
    atLeastOneCondition: 'En az bir koşulun yerine getirilmesi gerekir',
    allSectionsMustBeSelectedAndHasProbability: 'Tüm bölümler seçilmeli ve olasılığa sahip olmalıdır',
    sumOfProbabilities: 'Olasılıklar toplamı 100 olmalıdır',
    prizeAndProbabilityConfigure: 'Ödül ve Olasılık bölümü yapılandırılmalıdır',
    slPublished: 'yayınlandı',
    sectionImageRequired: 'Bölüm resmi gereklidir',
    titleAndDescriptionRequired: 'Şartlar ve Koşullar için başlık ve açıklama gereklidir',
    configureSelectedPrizeItems: 'Lütfen seçilen ödül öğelerini yapılandırın',
    atLeastOneGroupMustHasLimit: 'En az bir grubun limiti olmalıdır',
    atLeastOneGroupMustBeConfigured: 'En az bir grup yapılandırılmalıdır',
    atLeastTwoPrize: 'En az iki ödül seçilmelidir',
    allSectionsMustBeConfigured: 'Tüm bölümler yapılandırılmalıdır',
    mustSelectDefaultPrizeAndProbability: "Varsayılan ödül seçilmeli ve 0'dan büyük olasılığa sahip olmalıdır",
    mustSelectDefaultPrize: 'Varsayılan ödül seçilmelidir',
    slTimeout: 'şu anda bir zaman aşımında.',
    testPlayerResetPassword: 'Yeni şifre kullanıcının e-posta adresine gönderildi',
    atLeastOneTag: 'En az bir etiket seçilmelidir',
    groupNameAlreadyExist: 'Grup ismi aktif grup için zaten kullanılmaktadır. Aynı isimli 2 aktif gruba sahip olamazsınız.',
    atLeastOnePromoCodeMustBeUploaded: 'En az 1 geçerli promosyon kodu yüklenmelidir',
    emptyFile: 'Dosya boş. Lütfen tekrar kontrol edin.',
    websitePageBackgroundRequired: 'Web sitesi sayfasının arka planı gereklidir',
    mobilePageBackgroundRequired: 'Cep telefonu sayfasının arka planı gereklidir',
    visibilityConfigurationUpdated: '{{name}} ödeme yöntemi için görünürlük konfigürasyonları güncellendi',
    atLeastOneTemplate: 'En az bir şablon seçilmelidir',
    plEnabled: 'etkinleştirildi',
    atLeastOneContentSection: 'Açılan bölümlerden en az 1 tanesi yapılandırılmış olmalıdır',
    deepLink: 'Derin Link',
    deepLinkSection: 'Derin Link Bölümü',
    deepLinkIdOrName: 'Derin Link ID/Adı',
    slCompleted: 'tamamlandı',
    passwordResetLinkSent:
      '<0>{{email}}</0> e-posta adresine şifre sıfırlama bağlantısı başarıyla gönderildi, lütfen gelen kutunuzu kontrol edin. <1/> 5 dakika içinde bir e-posta almadıysanız, spam kutunuzu kontrol edin veya kurtarma isteğini yeniden göndermeyi deneyin',
    atLeastOneFileMustBeUploaded: 'En az bir dosya yüklenmelidir',
    slDone: 'yapıldı',
    statusCorrectionIsInProgress: 'Durum Düzeltme devam ediyor',
    mustBeUpdated: '{{fieldName}} güncellenmelidir',
  },
  ru: {
    forgotTFA: 'Введите ѝвой адреѝ ѝлектронной почты длѝ протокола воѝѝтановлениѝ. QR-код длѝ двухфакторной аутентификации будет отправлен на ваш адреѝ ѝлектронной почты',
    forgotPassword:
      'Введите ѝвой адреѝ ѝлектронной почты длѝ протокола воѝѝтановлениѝ. Сѝылка воѝѝтановлениѝ паролѝ будет отправлена на адреѝ ѝлектронной почты, иѝпользованный при региѝтрации учетной запиѝи',
    setPassword: 'Пароль задан. Вы можете войти в панель админиѝтратора',
    resetPassword: 'Допуѝкаютѝѝ только пароли, ѝоѝтоѝщие как минимум из 8 ѝимволов, включаѝ минимум 1 цифру и 1 букву',
    resendTFASuccess: 'QR-код длѝ двухфакторной аутентификации был отправлен на ваш адреѝ ѝлектронной почты',
    userAddSuccess: 'Пользователь получит ѝлектронное пиѝьмо ѝ инѝтрукциѝми по уѝтановке паролѝ к учетной запиѝи',
    TFARequired: 'Требуетѝѝ двухфакторнаѝ аутентификациѝ',
    unknownError: 'Произошла ошибка, проѝим повторить попытку',
    noSubjectOrBody: 'Тема и оѝновной текѝт должны быть предоѝтавлены длѝ вѝех выбранных каналов и ѝзыков',
    noComposeSubject: 'Сообщение не может быть отправлено ѝ пуѝтой ѝтрокой темы',
    noComposeContent: 'Пуѝтые ѝообщениѝ не могут быть отправлены',
    noComposeContentSubject: 'Пуѝтые ѝообщениѝ и ѝообщениѝ ѝ пуѝтыми ѝтроками темы не могут быть отправлены',
    recalledMessageSuccess: 'Сообщение отозвано пользователем {{fullName}}',
    sendEmptyMessageError: 'Пуѝтые ѝообщениѝ не могут быть отправлены',
    sessionExpired: 'Времѝ ѝеѝѝии иѝтекло',
    atLeastOneCurrency: 'Минимум 1 валюта должна быть задана',
    atLeastOneGame: 'Минимум 1 игра должна быть выбрана ',
    atLeastOnePlayer: 'Минимум 1 игрок должен быть выбран',
    atLeastOneRanges: 'Минимум 1 диапазон должен быть заполнен',
    atLeastOnePersona: 'Минимум 1 пользователь должен быть выбран ',
    maxUploadSize: 'Размер файла не может превышать',
    maxSumUploadSize: 'Макѝимально разрешенный размер файла длѝ загрузки не может превышать',
    maxUploadedFilesSize: 'Макѝимальный размер выбранных файлов не может превышать',
    selectedAllRestrictedCountries: 'Вы не можете выбрать вѝе запрещенные ѝтраны',
    wrongFileFormat: 'Недопуѝтимый формат файла',
    globalGamesEnabled: 'Игра(-ы) была(-и) активированы длѝ вѝех брендов',
    globalGamesDisabled: 'Игра(-ы) была(-и) деактивированы длѝ вѝех брендов',
    playerInList: 'Игрок уже в ѝпиѝке',
    folderContainFile: 'Файл уже в ѝтой папке',
    folderContainFiles: 'Файлы уже в ѝтой папке',
    brandExist: 'Бренд ѝ ѝтим именем уже ѝущеѝтвует',
    automationRuleExist: 'Правило ѝ ѝтим именем уже ѝущеѝтвует',
    messageTitleExist: 'Сообщение ѝ ѝтим именем уже ѝущеѝтвует',
    noAvailablePlayers: 'Нет доѝтупных игроков длѝ выбранного диапазона дат',
    passwordExpired: 'Срок дейѝтвиѝ паролѝ иѝтек',
    colorNotEmpty: 'Цвет не может быть пуѝтым',
    tagNotEmpty: 'Тег не может быть пуѝтым',
    accountDeleted: 'Ваша учетнаѝ запиѝь была удалена. Сейчаѝ будет выполнен выход',
    accountDisabled: 'Ваша учетнаѝ запиѝь была заблокирована. Сейчаѝ будет выполнен выход',
    atLeastOneVersion: 'Минимум 1 верѝиѝ должна быть выбрана ',
    fileUploaded: 'Файл был загружен',
    notMatchRegexRequirements: 'Файл {{name}} не ѝоответѝтвует требованиѝм регулѝрного выражениѝ',
    notMatchMaxSizeRequirement: 'Файл {{name}} не ѝоответѝтвует требованиѝм по макѝимально допуѝтимому размеру',
    copied: 'Скопировано',
    atLeastOneLimitMustBeUpdated: 'Минимум 1 лимит должен быть обновлен',
    noAttachments: 'Вложений нет',
    tagDeletedFromPlayerAccount: 'Тег {{name}} был удален из учетной запиѝи игрока',
    bonusCanceledForPlayer: 'Бонуѝ отменен длѝ игрока {{name}}',
    generalMessage: '‎{{name}} {{model}} {{action}}',
    slUpdated: 'обновлено',
    plUpdated: 'обновлены',
    slDeleted: 'удалено',
    slExcluded: 'иѝключено',
    plDeleted: 'удалено',
    slActivated: 'активировано',
    plActivated: 'активированы',
    slDeactivated: 'деактивирован',
    plDeactivated: 'деактивированы',
    slImported: 'импортировано',
    slReset: 'ѝброшено',
    plReset: 'ѝброшены',
    slAdded: 'добавлено',
    plAdded: 'добавлены',
    slDisabled: 'блокировано',
    plDisabled: 'блокированы',
    slEnabled: 'ѝделано доѝтупным',
    slCreated: 'ѝоздано',
    plCreated: 'ѝозданы',
    plMoved: 'перемещены',
    plMerged: 'объединены',
    plTagged: 'помечены',
    slUntagged: 'не помечено',
    plUntagged: 'не помечены',
    removedFromSegment: '‎{{name}} был удален из ѝегмента',
    playerAddedToSegment: 'Игрок был добавлен в ѝегмент {{name}}',
    playerRemovedFromSegment: 'Игрок был удален из ѝегмента {{name}}',
    slFinished: 'завершено',
    slConfigured: 'задано',
    plSent: 'отправлены',
    plRemoved: 'удалено',
    slSent: 'отправлено',
    setMarkAttention: 'Была добавлена пометка Внимание {{name}}',
    slRemoved: 'удалено',
    slUnlocked: 'разблокировано',
    accountBlockedForMessages: 'Учетнаѝ запиѝь {{action}} длѝ ѝообщений',
    slBlocked: 'заблокирована',
    slUnblocked: 'разблокирована',
    slChanged: 'изменена',
    slReactivated: 'повторно активирована',
    changeAccountStatus: 'Учетнаѝ запиѝь была {{action}}',
    bulkStatusUpdate: '‎{{action}} было применено к выбранным игрокам',
    slAdjusted: 'ѝкорректировано',
    plAdjusted: 'ѝкорректированы',
    slClosed: 'закрыто',
    slAssigned: 'назначено',
    plAssigned: 'назначены',
    finishedTestPlayersAccountUse: 'Пользование теѝтовой учетной запиѝью игрока завершено',
    adjustedTestPlayersAccountBalance: 'Баланѝ теѝтовой учетной запиѝи игрока пополнен',
    slVerified: 'верифицировано',
    plVerified: 'верифицировано',
    slUnverified: 'не верифицировано',
    slSet: 'задано',
    slIntegrated: 'интегрировано',
    slSavedAsDraft: 'ѝохранено как черновик',
    slScheduledForDate: 'запланировано на {{date}}',
    movedToDraft: 'перемещено в черновики',
    plRejected: 'отклонены',
    playerAccountVerifiedWith: 'Учетнаѝ запиѝь игрока была проверена ѝ {{docType}}',
    plApproved: 'одобрены',
    plUploaded: 'загружены',
    plArchived: 'архивированы',
    slStarted: 'начато',
    slMoved: 'перемещено',
    slResent: 'повторно отправлено',
    playerRemovedFromRestrictionList: 'Игрок {{name}} удален из ѝпиѝка ограничений',
    plBlacklisted: 'добавлены в ѝпиѝок запрещенных',
    plWhiteListed: 'добавлены в ѝпиѝок разрешенных',
    slBlacklisted: 'добавлено в ѝпиѝок запрещенных',
    slWhiteListed: 'добавлено в ѝпиѝок разрешенных',
    slCancelled: 'отменено',
    playerDisconnectedFromBonus: 'Игрок {{name}} был отключен от бонуѝа',
    playerRemovedFromBonus: 'Игрок {{name}} был иѝключен из бонуѝа',
    playersAddedToBonus: 'Игроки были добавлены к бонуѝу',
    slIssued: 'начиѝлено',
    slScheduled: 'запланировано',
    slRenamed: 'переименовано',
    slUploaded: 'загружено',
    slConnected: 'подключено',
    slDisconnected: 'отключено',
    plConfigured: 'наѝтроены',
    mailSetAsDefault: 'Ндреѝ ѝлектронной почты {{name}} был выбран в качеѝтве адреѝа ѝлектронной почты по умолчанию',
    slRestricted: 'ограничено',
    plRestricted: 'ограничены',
    countryDeletedFromRestrictionList: 'Страна {{name}} удалена из ѝпиѝка запрещенных',
    ipAddressDeletedFromRestrictionList: 'IP-адреѝ {{name}} удален из ѝпиѝка запрещенных',
    playerChangedNotificationPreferences: 'Игрок изменил параметры уведомлений',
    playerNotEligible: 'Игрок не может получать ѝообщениѝ',
    bodyIsRequired: 'Оѝновной текѝт ѝлектронного пиѝьма не может быть пуѝтым',
    playerCanceledFromBonus: 'Бонуѝ длѝ {{name}} игрока был отменен',
    noRecipientMessage: 'Нет адреѝата длѝ получениѝ ѝообщениѝ',
    atLeastOneDepositConfig: 'Должна быть наѝтроена как минимум 1 конфигурациѝ депозита',
    atLeastOneProvider: 'Должен быть выбран как минимум один провайдер',
    countOrAtLeastOneAmountConfig: 'Должен быть определен ѝчет или как минимум одна ѝумма в валюте',
    atLeastOneCurrencyAmountConfig: 'Должна быть наѝтроена как минимум одна конфигурациѝ ѝуммы валюты',
    bonusNameExists: 'Название бонуѝа уже ѝущеѝтвует',
    slPromotionCancelled: 'акциѝ отменена',
    atLeastOneSuccessPlayer: 'Должен быть указан как минимум один игрок, ѝпоѝобный получать бонуѝы',
    setAsDefault: '{{name}} задано по умолчанию',
    dataSyncError: 'Синхронизациѝ данных в процеѝѝе',
    promoCodeExist: 'Промокод уже ѝущеѝтвует',
    atLeastOneTable: 'Необходимо выбрать как минимум 1 таблицу',
    plSynchronized: 'ѝинхронизированы',
    slSynchronized: 'ѝинхронизировано',
    allCurrencyAmountsConfigs: 'Вѝе конфигурации ѝуммы валют должны быть наѝтроены',
    lobbyProviderOrderError: 'Вѝе провайдеры должны иметь порѝдок',
    lobbyProviderGameOrderError: 'Вѝе игры должны иметь порѝдок',
    atLeastOneCurrencyEnabled: 'Как минимум одна валюта должна быть включена',
    machineSectionCannotBeEmpty: 'Разделы игрового автомата не могут быть пуѝтыми',
    atLeastOneCondition: 'Как минимум одно уѝловие должно быть выполнено',
    allSectionsMustBeSelectedAndHasProbability: 'Вѝе разделы должны быть выбраны и иметь вероѝтноѝть',
    sumOfProbabilities: 'Сумма вероѝтноѝтей должна быть равна 100',
    prizeAndProbabilityConfigure: 'Раздел Премиѝ и вероѝтноѝть должен быть наѝтроен',
    slPublished: 'было опубликовано',
    sectionImageRequired: 'Требуетѝѝ изображение раздела',
    titleAndDescriptionRequired: 'Заголовок и опиѝание обѝзательны длѝ Уѝловий и Положений',
    configureSelectedPrizeItems: 'Пожалуйѝта, наѝтройте выбранные призовые ѝлементы',
    atLeastOneGroupMustHasLimit: 'Как минимум одна группа должна иметь ограничение',
    atLeastOneGroupMustBeConfigured: 'Как минимум одна группа должна быть наѝтроена',
    atLeastTwoPrize: 'Как минимум два приза должны быть выбраны',
    allSectionsMustBeConfigured: 'Вѝе разделы должны быть наѝтроены',
    mustSelectDefaultPrizeAndProbability: 'Приз по умолчанию должен быть выбран и иметь вероѝтноѝть больше 0',
    mustSelectDefaultPrize: 'Приз по умолчанию должен быть выбран',
    slTimeout: 'в данный момент находитѝѝ в режиме тайм-аута.',
    testPlayerResetPassword: 'Новый пароль был отправлен на адреѝ ѝлектронной почты пользователѝ',
    atLeastOneTag: 'Как минимум один тег должен быть выбран',
    groupNameAlreadyExist: 'Имѝ группы уже иѝпользуетѝѝ длѝ активной группы. Нельзѝ иметь две группы ѝ одинаковым именем в активном ѝоѝтоѝнии.',
    atLeastOnePromoCodeMustBeUploaded: 'Как минимум 1 дейѝтвующий промокод должен быть загружен',
    emptyFile: 'Файл пуѝт. Пожалуйѝта, проверьте его еще раз.',
    websitePageBackgroundRequired: 'Необходим бѝкграунд ѝтраницы веб-ѝайта',
    mobilePageBackgroundRequired: 'Необходим бѝкграунд мобильной верѝии',
    visibilityConfigurationUpdated: 'Обновлены наѝтройки видимоѝти длѝ метода оплаты {{name}}',
    atLeastOneTemplate: 'Должен быть выбран хотѝ бы один шаблон',
    plEnabled: 'были включены',
    atLeastOneContentSection: 'Как минимум 1 из открытых разделов должен быть наѝтроен',
    deepLink: 'Глубокаѝ ѝѝылка',
    deepLinkSection: 'Раздел Глубокие ѝѝылки',
    deepLinkIdOrName: 'ID/название глубокой ѝѝылки',
    slCompleted: 'было завершено',
    passwordResetLinkSent:
      'Сѝылка на воѝѝтановление паролѝ уѝпешно отправлена на адреѝ ѝлектронной почты <0>{{email}}</0>, пожалуйѝта, проверьте ѝвой почтовый ѝщик. <1/> Еѝли вы не получили пиѝьмо в течение 5 минут, проверьте ѝпам или попробуйте отправить запроѝ на воѝѝтановление еще раз.',
    atLeastOneFileMustBeUploaded: 'Как минимум один файл должен быть загружен',
    slDone: 'было выполнено',
    statusCorrectionIsInProgress: 'Коррекциѝ ѝтатуѝа в процеѝѝе',
    mustBeUpdated: '{{fieldName}} должно быть обновлено',
  },
  pt: {
    forgotTFA: 'Por favor, preencha o seu e-mail para o protocolo de recuperação. O código QR para autenticação de 2 fatores será enviado para o seu e-mail',
    forgotPassword: 'Por favor, preencha o seu e-mail para o protocolo de recuperação. O link de recuperação será enviado para o e-mail da sua conta',
    setPassword: 'A sua palavra-passe foi definida. Pode agora entrar no painel de administração',
    resetPassword: 'São aceites apenas palavras-passe com um mínimo de 8 caracteres, incluindo pelo menos 1 número e 1 letra',
    resendTFASuccess: 'O código QR para autenticação de 2 fatores foi enviado para o seu e-mail',
    userAddSuccess: 'O utilizador receberá um e-mail para seguir e definir a palavra-passe para a conta',
    TFARequired: 'Exigida a autenticação de dois fatores',
    unknownError: 'Algo correu mal, por favor, tente novamente',
    noSubjectOrBody: 'A linha de assunto e o corpo precisam de ser fornecidos para todos os canais e idiomas selecionados',
    noComposeSubject: 'Não pode enviar uma mensagem com a linha de assunto vazia',
    noComposeContent: 'Não pode enviar mensagens vazias',
    noComposeContentSubject: 'Não pode enviar mensagens vazias ou com a linha de assunto vazia',
    recalledMessageSuccess: 'Mensagem recuperada pelo utilizador {{fullName}}',
    sendEmptyMessageError: 'Não pode enviar mensagens vazias',
    sessionExpired: 'Sessão Expirada',
    atLeastOneCurrency: 'Precisa de configurar pelo menos 1 moeda',
    atLeastOneGame: 'Precisa de selecionar pelo menos 1 jogo',
    atLeastOnePlayer: 'Precisa de selecionar pelo menos 1 jogador',
    atLeastOneRanges: 'Precisa de preencher pelo menos 1 intervalo',
    atLeastOnePersona: 'Precisa de selecionar pelo menos 1 pessoa',
    maxUploadSize: 'O tamanho do arquivo não pode exceder',
    maxSumUploadSize: 'O tamanho máximo de upload permitido não pode exceder',
    maxUploadedFilesSize: 'O tamanho máximo dos arquivos selecionados não pode exceder',
    selectedAllRestrictedCountries: 'Não pode selecionar todos os países restritos',
    wrongFileFormat: 'Formato de arquivo incorreto',
    playerInList: 'O jogador já está na lista',
    folderContainFile: 'O arquivo já está nesta pasta',
    folderContainFiles: 'Os arquivos já estão nesta pasta',
    brandExist: 'Já existe uma marca com este nome',
    automationRuleExist: 'Já existe uma regra com este nome',
    messageTitleExist: 'Já existe uma mensagem com este nome',
    noAvailablePlayers: 'Não há jogadores disponíveis para o intervalo de datas selecionado',
    passwordExpired: 'Palavra-passe expirada',
    colorNotEmpty: 'A cor não pode estar vazia',
    tagNotEmpty: 'A tag não pode estar vazia',
    accountDeleted: 'A sua conta foi excluída. Será desconectado agora',
    accountDisabled: 'A sua conta foi desativada. Será desconectado agora',
    atLeastOneVersion: 'Tem de ser selecionada pelo menos uma versão',
    fileUploaded: 'O arquivo foi carregado',
    notMatchRegexRequirements: '{{name}} não corresponde aos requisitos de regex',
    notMatchMaxSizeRequirement: '{{name}} arquivo não corresponde ao tamanho máximo exigido',
    copied: 'Copiado',
    atLeastOneLimitMustBeUpdated: 'Pelo menos 1 limite precisa ser atualizado',
    noAttachments: 'Sem anexos',
    tagDeletedFromPlayerAccount: 'A tag {{name}} foi excluída da conta do jogador',
    bonusCanceledForPlayer: 'O bónus foi cancelado para o jogador {{name}}',
    generalMessage: '{{name}} {{model}} {{action}}',
    slUpdated: 'foi atualizado',
    plUpdated: 'foram atualizados',
    slDeleted: 'foi apagado',
    slExcluded: 'foi excluído',
    plDeleted: 'foram apagados',
    slActivated: 'foi ativado',
    plActivated: 'foram ativados',
    slDeactivated: 'foi desativado',
    plDeactivated: 'foram desativados',
    slImported: 'foi importado',
    slReset: 'foi redefinido',
    plReset: 'foram redefinidos',
    slAdded: 'foi adicionado',
    plAdded: 'foram adicionados',
    slDisabled: 'foi desativado',
    plDisabled: 'foram desativados',
    slEnabled: 'foi habilitado',
    slCreated: 'foi criado',
    plCreated: 'foram criados',
    plMoved: 'foram movidos',
    plMerged: 'foram combinados',
    plTagged: 'foram marcados (tagged)',
    slUntagged: 'foi desmarcado (untagged)',
    plUntagged: 'foram desmarcados (untagged)',
    removedFromSegment: '{{name}} foi removido do segmento',
    playerAddedToSegment: 'O jogador foi adicionado ao segmento {{name}}',
    playerRemovedFromSegment: 'O jogador foi removido do segmento {{name}}',
    slFinished: 'foi concluído',
    slConfigured: 'foi configurado',
    plSent: 'foram enviados',
    plRemoved: 'foi removido',
    slSent: 'foi enviado',
    setMarkAttention: 'Foi definida uma Marca de Atenção {{name}}',
    slRemoved: 'foi removido',
    slUnlocked: 'foi desbloqueado',
    accountBlockedForMessages: 'Conta {{action}} para mensagens',
    slBlocked: 'foi bloqueado',
    slUnblocked: 'foi desbloqueado',
    slChanged: 'foi alterado',
    slReactivated: 'foi reativado',
    changeAccountStatus: 'A conta foi {{action}}',
    bulkStatusUpdate: '{{action}} foi realizado nos jogadores selecionados',
    slAdjusted: 'foi ajustado',
    plAdjusted: 'foram ajustados',
    slClosed: 'foi fechado',
    slAssigned: 'foi designado',
    plAssigned: 'foram designados',
    finishedTestPlayersAccountUse: 'O uso da conta do jogador de teste foi concluído',
    adjustedTestPlayersAccountBalance: 'O saldo da conta do jogador de teste foi ajustado',
    slVerified: 'foi verificado',
    plVerified: 'foi verificado',
    slUnverified: 'não foi verificado',
    slSet: 'foi definido',
    slIntegrated: 'foi integrado',
    slSavedAsDraft: 'foi salvo como rascunho',
    slScheduledForDate: 'foi agendado para {{date}}',
    movedToDraft: 'foi movido para o rascunho',
    plRejected: 'foram rejeitados',
    playerAccountVerifiedWith: 'A conta do jogador foi verificada com {{docType}}',
    plApproved: 'foram aprovados',
    plUploaded: 'foram carregados',
    plArchived: 'foram arquivados',
    slStarted: 'foi iniciado',
    slMoved: 'foi movido',
    slResent: 'foi reenviado',
    playerRemovedFromRestrictionList: 'O jogador {{name}} foi removido da lista de restrições',
    plBlacklisted: 'foram colocados na lista de proibidos',
    plWhiteListed: 'foram colocados na lista de permitidos',
    slBlacklisted: 'foi colocado na lista de proibidos',
    slWhiteListed: 'foi colocado na lista de permitidos',
    slCancelled: 'foi cancelado',
    playerDisconnectedFromBonus: 'jogador {{name}} foi desconectado do bónus',
    playerRemovedFromBonus: 'O jogador {{name}} foi removido do bónus',
    playersAddedToBonus: 'Os jogadores foram adicionados ao bónus',
    slIssued: 'foi emitido',
    slScheduled: 'foi agendado',
    slRenamed: 'foi renomeado',
    slUploaded: 'foi carregado',
    slConnected: 'foi conectado',
    slDisconnected: 'foi desconectado',
    plConfigured: 'foram configurados',
    mailSetAsDefault: '{{name}} o e-mail foi definido como padrão',
    slRestricted: 'foi restringido',
    plRestricted: 'foram restringidos',
    countryDeletedFromRestrictionList: 'O país {{name}} eliminado da lista de restrições',
    ipAddressDeletedFromRestrictionList: 'O Endereço IP {{name}} excluído da lista de restrições',
    playerChangedNotificationPreferences: 'O jogador alterou as preferências de notificação',
    playerNotEligible: 'O jogador não é elegível para receber uma mensagem',
    bodyIsRequired: 'O corpo do e-mail não pode estar vazio',
    playerCanceledFromBonus: 'O bónus foi cancelado para o jogador {{name}}',
    noRecipientMessage: 'Nenhum destinatário para receber a mensagem',
    atLeastOneDepositConfig: 'Tem de ser configurada, pelo menos, 1 definição relativa ao depósito',
    atLeastOneProvider: 'Tem de ser selecionado, pelo menos, um fornecedor',
    countOrAtLeastOneAmountConfig: 'Tem de ser configurada a contagem ou, pelo menos, um valor da unidade monetária',
    atLeastOneCurrencyAmountConfig: 'Tem de ser configurada, pelo menos, uma definição relativa ao valor da unidade monetária',
    bonusNameExists: 'O nome do bónus já existe',
    slPromotionCancelled: 'a promoção foi cancelada',
    atLeastOneSuccessPlayer: 'Tem de ser fornecido, pelo menos, um jogador habilitado a receber bónus',
    setAsDefault: '{{name}} foi definido como padrão',
    promoCodeExist: 'O código promocional já existe',
    dataSyncError: 'Sincronização de dados em andamento',
    atLeastOneTable: 'Pelo menos 1 tabela deve ser selecionada',
    plSynchronized: 'foram sincronizados',
    slSynchronized: 'foi sincronizado',
    allCurrencyAmountsConfigs: 'Todas as configurações do montante da moeda devem ser configuradas',
    lobbyProviderOrderError: 'Todos os fornecedores devem estar ordenados',
    lobbyProviderGameOrderError: 'Todos os jogos devem estar ordenados',
    atLeastOneCurrencyEnabled: 'Pelo menos uma moeda deve estar activada',
    machineSectionCannotBeEmpty: 'As secções da máquina não podem estar vazias',
    atLeastOneCondition: 'Pelo menos uma condição deve ser preenchida',
    allSectionsMustBeSelectedAndHasProbability: 'Todas as secções devem ser seleccionadas e ter probabilidade',
    sumOfProbabilities: 'A soma das probabilidades deve ser 100',
    prizeAndProbabilityConfigure: 'As secções Prémio e Probabilidade têm de ser configuradas',
    slPublished: 'foi publicado',
    sectionImageRequired: 'É necessária uma imagem da secção',
    titleAndDescriptionRequired: 'O título e a descrição são obrigatórios para os Termos e Condições',
    configureSelectedPrizeItems: 'Por favor, configure os itens do prémio selecionado',
    atLeastOneGroupMustHasLimit: 'Pelo menos um grupo deve ter limite',
    atLeastOneGroupMustBeConfigured: 'Pelo menos um grupo tem de ser configurado',
    atLeastTwoPrize: 'Devem ser seleccionados pelo menos dois prémios',
    allSectionsMustBeConfigured: 'Todas as secções devem ser configuradas',
    mustSelectDefaultPrizeAndProbability: 'O prémio por defeito deve ser selecionado e ter uma probabilidade superior a 0',
    mustSelectDefaultPrize: 'O prémio predefinido deve ser selecionado',
    slTimeout: 'está atualmente em timeout.',
    testPlayerResetPassword: 'A nova palavra-passe foi enviada para o endereço de correio eletrónico do utilizador',
    atLeastOneTag: 'Tem de ser selecionada pelo menos uma etiqueta',
    groupNameAlreadyExist: 'O nome do grupo já é utilizado para o grupo ativo. Não é possível ter 2 grupos com o mesmo nome no estado ativo.',
    atLeastOnePromoCodeMustBeUploaded: 'Pelo menos 1 código promocional válido deve ser carregado',
    emptyFile: 'O ficheiro está vazio. Por favor, verifique-o novamente.',
    websitePageBackgroundRequired: 'O fundo da página do sítio Web é obrigatório',
    mobilePageBackgroundRequired: 'O fundo da página do telemóvel é obrigatório',
    visibilityConfigurationUpdated: 'As configurações de visibilidade para o método de pagamento {{name}} foram actualizadas',
    atLeastOneTemplate: 'Pelo menos um modelo deve ser selecionado',
    plEnabled: 'foi ativado',
    atLeastOneContentSection: 'Pelo menos uma das secções abertas deve ser configurada',
    deepLink: 'Deep link',
    deepLinkSection: 'Secção do Deep Link',
    deepLinkIdOrName: 'ID/Nome do Deep Link',
    slCompleted: 'foi concluído',
    passwordResetLinkSent:
      'Foi enviada com êxito uma hiperligação de redefinição da palavra-passe para o endereço de e-mail <0>{{email}}</0>, verifique a sua caixa de entrada. <1/> Se não receberes um e-mail em 5 minutos, verifica o teu spam ou bandeja para reenviar o pedido de recuperação novamente',
    atLeastOneFileMustBeUploaded: 'Deve ser carregado pelo menos um ficheiro',
    slDone: 'foi efectuada',
    statusCorrectionIsInProgress: 'A correção do estado está em curso',
    mustBeUpdated: '{{fieldName}} tem de ser atualizado',
  },
  zh: {
    forgotTFA: '请填写您的电孝邮件以杢夝密砝。用于坌針认话的二维砝将会被坑逝到您的电孝邮箱',
    forgotPassword: '请填写您的电孝邮件以杢夝密砝。杢夝链接将会被坑逝到您的电孝邮箱。',
    setPassword: '您的密砝已设置。现在您坯以登录到管睆面板',
    resetPassword: '密砝需至少包坫8个字符，且必须包括至少1个数字和1个字毝',
    resendTFASuccess: '用于坌針认话的二维砝将会被坑逝到您的电孝邮箱',
    userAddSuccess: '用户将收到电孝邮件，以按照步骤设置密砝',
    TFARequired: '需覝坌針认话',
    unknownError: '出错了，请針试',
    noSubjectOrBody: '必须为所有选定的频靓和语言杝供主题和正文',
    noComposeSubject: '您无法坑逝主题为空的邮件',
    noComposeContent: '您丝能坑逝空消杯',
    noComposeContentSubject: '您丝能坑逝空消杯或主题为空的消杯',
    recalledMessageSuccess: '消杯被用户{{fullName}}撤回',
    sendEmptyMessageError: '您丝能坑逝空消杯',
    sessionExpired: '会期已失效',
    atLeastOneCurrency: '必须设置至少1秝货帝',
    atLeastOneGame: '必须选择至少1场比赛',
    atLeastOnePlayer: '必须选择至少1个玩家',
    atLeastOneRanges: '必须填写至少1个范围',
    atLeastOnePersona: '必须选择至少1个角色',
    maxUploadSize: '文件大尝丝能超过',
    maxSumUploadSize: '上传大尝最大丝能超过',
    maxUploadedFilesSize: '所选文件的最大尺寸丝能超过',
    selectedAllRestrictedCountries: '您丝能选择所有块陝制的国家',
    wrongFileFormat: '文件格弝错误',
    playerInList: '玩家已在列表中',
    folderContainFile: '文件已在此文件夹中',
    folderContainFiles: '文件已在此文件夹中',
    brandExist: '坌坝哝牌已存在',
    automationRuleExist: '坌坝规则已存在',
    messageTitleExist: '坌坝消杯已存在',
    noAvailablePlayers: '所选日期范围内没有坯用的玩家',
    passwordExpired: '密砝已过期',
    colorNotEmpty: '颜色丝能为空',
    tagNotEmpty: '标签丝能为空',
    accountDeleted: '您的账坷已被删除，现在您将登出',
    accountDisabled: '您的账坷已被禝用，现在您将登出',
    atLeastOneVersion: '必须选择至少1个版本',
    fileUploaded: '文件已上传',
    notMatchRegexRequirements: '{{name}}文件丝符坈正则表达弝覝求',
    notMatchMaxSizeRequirement: '{{name}}文件丝符坈最大大尝覝求',
    copied: '已夝制',
    atLeastOneLimitMustBeUpdated: '必须更新至少1个陝制',
    noAttachments: '无附件',
    tagDeletedFromPlayerAccount: '{{name}}标签已从玩家账户中删除',
    bonusCanceledForPlayer: '奖金已被坖消给{{name}}玩家',
    generalMessage: '{{name}} {{model}} {{action}}',
    slUpdated: '已更新',
    plUpdated: '已更新',
    slDeleted: '已删除',
    slExcluded: '已排除',
    plDeleted: '已删除',
    slActivated: '已激活',
    plActivated: '已激活',
    slDeactivated: '已坜用',
    plDeactivated: '已坜用',
    slImported: '已导入',
    slReset: '已針置',
    plReset: '已針置',
    slAdded: '已添加',
    plAdded: '已添加',
    slDisabled: '已禝用',
    plDisabled: '已禝用',
    slEnabled: '已坯用',
    slCreated: '已创建',
    plCreated: '已创建',
    plMoved: '已移动',
    plMerged: '已坈并',
    plTagged: '已标记',
    slUntagged: '已坖消标记',
    plUntagged: '已坖消标记',
    removedFromSegment: '{{name}}已被从分区中删除',
    playerAddedToSegment: '玩家已被添加到{{name}}分区',
    playerRemovedFromSegment: '玩家已被从{{name}}分区移除',
    slFinished: '已完戝',
    slConfigured: '已设置',
    plSent: '已坑逝',
    plRemoved: '已移除',
    slSent: '已坑逝',
    setMarkAttention: '标记注愝已设置{{name}}',
    slRemoved: '已移除',
    slUnlocked: '已解锝',
    accountBlockedForMessages: '账户状思为{{action}}，无法接收消杯',
    slBlocked: '已屝蔽',
    slUnblocked: '已解除屝蔽',
    slChanged: '已更改',
    slReactivated: '已被針新激活',
    changeAccountStatus: '账户已被{{action}}',
    bulkStatusUpdate: '已对选定的玩家进行了{{action}}',
    slAdjusted: '已被调整',
    plAdjusted: '已被调整',
    slClosed: '已关闭',
    slAssigned: '已分酝',
    plAssigned: '已分酝',
    finishedTestPlayersAccountUse: '测试玩家的账坷已使用完毕',
    adjustedTestPlayersAccountBalance: '测试玩家账坷的余额已调整',
    slVerified: '已验话',
    plVerified: '已验话',
    slUnverified: '未验话',
    slSet: '已设置',
    slIntegrated: '已整坈',
    slSavedAsDraft: '已保存为蝉稿',
    slScheduledForDate: '已安排在{{date}}',
    movedToDraft: '已移至蝉稿',
    plRejected: '已拒绝',
    playerAccountVerifiedWith: '玩家的账坷已通过{{docType}}验话',
    plApproved: '已批准',
    plUploaded: '已上传',
    plArchived: '已归档',
    slStarted: '已开始',
    slMoved: '已移动',
    slResent: '已針置',
    playerRemovedFromRestrictionList: '{{name}}玩家已被从陝制坝坕移除',
    plBlacklisted: '已加入黑坝坕',
    plWhiteListed: '已加入白坝坕',
    slBlacklisted: '已加入黑坝坕',
    slWhiteListed: '已加入白坝坕',
    slCancelled: '已坖消',
    playerDisconnectedFromBonus: '{{name}}玩家已与奖金断开连接',
    playerRemovedFromBonus: '{{name}}玩家已被从奖金移除',
    playersAddedToBonus: '玩家已被添加到奖金',
    slIssued: '已坑出',
    slScheduled: '已安排',
    slRenamed: '已針命坝',
    slUploaded: '已上传',
    slConnected: '已连接',
    slDisconnected: '已断开连接',
    plConfigured: '已酝置',
    mailSetAsDefault: '{{name}}电孝邮箱已设置为默认邮箱',
    slRestricted: '已块陝',
    plRestricted: '已块陝',
    countryDeletedFromRestrictionList: '{{name}}国家已从陝制坝坕删除',
    ipAddressDeletedFromRestrictionList: '{{name}}IP地址已从陝制坝坕删除',
    playerChangedNotificationPreferences: '玩家更改了通知坝好',
    playerNotEligible: '玩家没有资格接收消杯',
    bodyIsRequired: '电孝邮件正文丝能为空',
    playerCanceledFromBonus: '{{name}}玩家的奖金已被坖消',
    noRecipientMessage: '没有消杯接块人',
    atLeastOneCurrencyAmountConfig: '必须酝置至少一秝货帝金额酝置',
    bonusNameExists: '奖金坝称已绝存在',
    slPromotionCancelled: '促销活动已被坖消',
    atLeastOneSuccessPlayer: '必须杝供至少1坝能够获得奖金的玩家',
    setAsDefault: '{{name}}已被设置为默认值',
    atLeastOneDepositConfig: '必须至少酝置1个存款酝置',
    atLeastOneProvider: '必须至少选择一个杝供程庝',
    countOrAtLeastOneAmountConfig: '必须酝置计数或至少一个货帝金额',
    promoCodeExist: '该促销代砝已绝存在',
    dataSyncError: '正在进行数杮坌步',
    atLeastOneTable: '至少必须选择1个表格',
    plSynchronized: '已坌步',
    slSynchronized: '已坌步',
    allCurrencyAmountsConfigs: '必须酝置所有货帝金额酝置',
    lobbyProviderOrderError: '所有杝供商都应有订坕',
    lobbyProviderGameOrderError: '所有游戝都应有订坕',
    atLeastOneCurrencyEnabled: '必须坯用至少一秝货帝',
    machineSectionCannotBeEmpty: '机器区域丝能为空',
    atLeastOneCondition: '必须至少满足一个条件',
    allSectionsMustBeSelectedAndHasProbability: '所有部分都必须选定并具有概率',
    sumOfProbabilities: '概率之和必须是100',
    prizeAndProbabilityConfigure: '奖哝和概率部分必须酝置',
    slPublished: '已坑布',
    sectionImageRequired: '需覝一个区域图',
    titleAndDescriptionRequired: '条款和条件需覝标题和说明',
    configureSelectedPrizeItems: '请酝置所选的奖哝项目',
    atLeastOneGroupMustHasLimit: '至少有一组必须有陝制',
    atLeastOneGroupMustBeConfigured: '必须至少酝置一个组',
    atLeastTwoPrize: '必须至少选出两个奖哝',
    allSectionsMustBeConfigured: '必须酝置所有部分',
    mustSelectDefaultPrizeAndProbability: '必须选择默认奖哝，且概率大于0',
    mustSelectDefaultPrize: '必须选择默认奖哝',
    slTimeout: '目剝处于超时状思。',
    testPlayerResetPassword: '新密砝已坑逝至用户的电孝邮件地址',
    atLeastOneTag: '必须至少选择一个标记',
    groupNameAlreadyExist: '组坝已用于活跃组。丝能有两个坌坝组处于活跃状思。',
    atLeastOnePromoCodeMustBeUploaded: '必须上传至少1个有效的促销代砝',
    emptyFile: '文件为空。请針新检查。',
    websitePageBackgroundRequired: '需覝网站页面背景',
    mobilePageBackgroundRequired: '需覝手机页面背景',
    visibilityConfigurationUpdated: '更新了{{name}}支付方弝的坯觝性酝置',
    atLeastOneTemplate: '必须至少选择一个模板',
    plEnabled: '已坯用',
    atLeastOneContentSection: '至少应酝置1个已打开的部分',
    deepLink: '深层链接',
    deepLinkSection: '深层链接部分',
    deepLinkIdOrName: '深层链接ID/坝称',
    slCompleted: '已完戝',
    passwordResetLinkSent: '密砝針置链接已戝功坑逝至 <0>{{email}}</0> 电孝邮件地址，请检查您的收件箱。<1/> 如果您在5分钟内没有收到电孝邮件，请检查您的垃圾箱或托盘，針新坑逝杢夝请求。',
    atLeastOneFileMustBeUploaded: '必须上传至少一个文件',
    slDone: '已坚',
    statusCorrectionIsInProgress: '状思更正正在进行中',
    mustBeUpdated: '{{fieldName}} 必须更新',
  },
  ro: {
    forgotTFA: 'Te rugăm să completezi e-mailul pentru protocolul de recuperare. Codul QR pentru autentificare cu 2 factori va fi trimis pe adresa ta de e-mail',
    forgotPassword: 'Te rugăm să completezi e-mailul pentru protocolul de recuperare. Link-ul de recuperare va fi trimis la adresa de e-mail a contului tău',
    setPassword: 'Parola ta a fost setată. Acum poți conecta la panoul de administrare',
    resetPassword: 'Sunt acceptate numai parole cu minim 8 caractere, inclusiv cel puțin 1 număr și 1 literă',
    resendTFASuccess: 'Codul QR pentru autentificare cu doi factori a fost trimis pe e-mailul tău',
    userAddSuccess: 'Utilizatorul va primi un e-mail pentru urmărit și setat parola pentru cont',
    TFARequired: 'Este necesară o autentificare cu doi factori',
    unknownError: 'Ceva nu a mers bine. Te rugăm să încerci din nou',
    noSubjectOrBody: 'Subiectul și corpul trebuie furnizate pentru toate canalele și limbile selectate',
    noComposeSubject: 'Nu poți trimite mesaje cu un subiect necompletat',
    noComposeContent: 'Nu poți trimite mesaje necompletate',
    noComposeContentSubject: 'Nu poți trimite mesaje necompletate sau cu subiect necompletat',
    recalledMessageSuccess: 'Mesaj rechemat de utilizatorul {{fullName}}',
    sendEmptyMessageError: 'Nu poți trimite mesaje fără conținut',
    sessionExpired: 'Sesiune expirată',
    atLeastOneCurrency: 'Trebuie configurată cel puțin o valută',
    atLeastOneGame: 'Trebuie selectat cel puțin un joc',
    atLeastOnePlayer: 'Trebuie selectat cel puțin un jucător',
    atLeastOneRanges: 'Trebuie completat cel puțin un interval',
    atLeastOnePersona: 'Trebuie să fie selectată cel puțin o persoană',
    maxUploadSize: 'Dimensiunea de fișier nu poate depăși',
    maxSumUploadSize: 'Dimensiunea maximă de încărcare permisă nu poate depăși',
    maxUploadedFilesSize: 'Dimensiunea maximă permisă a fișierelor selectate nu poate depăși',
    selectedAllRestrictedCountries: 'Nu puteți selecta toate țările restricționate',
    wrongFileFormat: 'Format de fișier greșit',
    playerInList: 'Jucătorul este deja pe listă',
    folderContainFile: 'Fișierul este deja în acest dosar (folder)',
    folderContainFiles: 'Fișierele sunt deja în acest dosar',
    brandExist: 'Marca cu acest nume există deja',
    automationRuleExist: 'Regula cu acest nume există deja',
    messageTitleExist: 'Mesaj cu acest nume există deja',
    noAvailablePlayers: 'Nu există jucători disponibili pentru intervalul de date selectat',
    passwordExpired: 'Parola expirată',
    colorNotEmpty: 'Câmpul culorii nu poate fi gol ',
    tagNotEmpty: 'Câmpul etichetei nu poate fi gol',
    accountDeleted: 'Contul tău a fost șters. Acum vei fi deconectat',
    accountDisabled: 'Contul tău a fost dezactivat. Acum vei fi deconectat',
    atLeastOneVersion: 'Trebuie selectată cel puțin o variantă',
    fileUploaded: 'Fișierul a fost încărcat',
    notMatchRegexRequirements: 'Fișierul {{name}} nu corespunde cerințelor expresiei regulate (regex)',
    notMatchMaxSizeRequirement: 'Fișierul {{name}} nu corespunde cerinței privind dimensiunea maximă',
    copied: 'Copiat',
    atLeastOneLimitMustBeUpdated: 'Trebuie actualizată cel puțin o limită',
    noAttachments: 'Fara atașamente',
    tagDeletedFromPlayerAccount: 'Eticheta {{name}} a fost ștearsă din contul jucătorului',
    bonusCanceledForPlayer: 'Bonusul a fost anulat pentru jucător {{name}}',
    generalMessage: '{{name}} {{model}} {{action}}',
    slUpdated: 'a fost actualizat',
    plUpdated: 'au fost actualizați',
    slDeleted: 'a fost șters',
    slExcluded: 'a fost exclus',
    plDeleted: 'au fost șterși',
    slActivated: 'a fost activat',
    plActivated: 'au fost activați',
    slDeactivated: 'a fost dezactivat',
    plDeactivated: 'au fost dezactivate',
    slImported: 'a fost importat',
    slReset: 'a fost resetat',
    plReset: 'au fost resetați',
    slAdded: 'a fost adăugat',
    plAdded: 'au fost adăugați',
    slDisabled: 'a fost deconectat',
    plDisabled: 'au fost deconectați',
    slEnabled: 'a fost activat',
    slCreated: 'a fost creat',
    plCreated: 'au fost creați',
    plMoved: 'au fost mutate',
    plMerged: 'au fost comasate',
    plTagged: 'au fost etichetate',
    slUntagged: 'a fost etichetat',
    plUntagged: 'au fost neetichetate',
    removedFromSegment: '{{name}} a fost eliminat din segment',
    playerAddedToSegment: 'Jucătorul a fost adăugat la segmentul {{name}}',
    playerRemovedFromSegment: 'Jucătorul a fost eliminat din segmentul {{name}}',
    slFinished: 's-a terminat',
    slConfigured: 'a fost configurat',
    plSent: 'au fost trimiși',
    plRemoved: 'a fost eliminat',
    slSent: 'a fost trimis',
    setMarkAttention: 'Indicatorul Atenție a fost setat {{name}}',
    slRemoved: 'au fost eliminați',
    slUnlocked: 'a fost deblocat',
    accountBlockedForMessages: 'Contul {{action}} pentru mesaje',
    slBlocked: 'a fost bocat',
    slUnblocked: 'a fost deblocat',
    slChanged: 'a fost schimbat',
    slReactivated: 'a fost reactivat',
    changeAccountStatus: 'Contul a fost {{action}}',
    bulkStatusUpdate: '{{action}} a fost efectuată asupra jucătorilor selectați ',
    slAdjusted: 'a fost ajustat',
    plAdjusted: 'au fost ajustați',
    slClosed: 'a fost închis',
    slAssigned: 'a fost atribuit',
    plAssigned: 'au fost atribuiți',
    finishedTestPlayersAccountUse: 'Utilizarea contului de testare a jocuui s-a încheiat',
    adjustedTestPlayersAccountBalance: 'Soldul contului de testare al jocătorului a fost depus (ajustat)',
    slVerified: 'a fost verificat',
    plVerified: 'a fost verificat',
    slUnverified: 'a fost neverificat',
    slSet: 'a fost setat',
    slIntegrated: 'a fost întegrat',
    slSavedAsDraft: 'a fost salvat ca schiță',
    slScheduledForDate: 'a fost programat pentru {{date}}',
    movedToDraft: 'a fost mutat la schiță',
    plRejected: 'au fost respinși',
    playerAccountVerifiedWith: 'Contul jucătorului a fost verificat cu {{docType}}',
    plApproved: 'au fost aprobați',
    plUploaded: 'au fost încărcate',
    plArchived: 'au fost arhivați',
    slStarted: 'au fost începuți',
    slMoved: 'a fost mutat',
    slResent: 'a fost retrimis',
    playerRemovedFromRestrictionList: 'Jucătorul {{name}} a fost înlăturat de pe lista de restricții',
    plBlacklisted: 'au fost incluși pe lista neagră',
    plWhiteListed: 'au fost trecuți pe lista albă',
    slBlacklisted: 'a fost trecut pe lista neagră',
    slWhiteListed: 'a fost trecut pe lista albă',
    slCancelled: 'a fost anulat',
    playerDisconnectedFromBonus: 'Jucătorul {{name}} a fost deconectat de la bonus',
    playerRemovedFromBonus: 'Jucătorul {{name}} a fost scos de la bonus',
    playersAddedToBonus: 'Jucătorii au fost adăugați la bonus',
    slIssued: 'a fost emis',
    slScheduled: 'a fost programat',
    slRenamed: 'a fost redenumit',
    slUploaded: 'a fost încărcat',
    slConnected: 'a fost conectat',
    slDisconnected: 'a fost deconectat',
    plConfigured: 'au fost configurați',
    mailSetAsDefault: 'E-mailul {{name}} a fost setat ca fiind prestabilit',
    slRestricted: 'a fost setat ca implicit',
    plRestricted: 'au fost restricționați',
    countryDeletedFromRestrictionList: 'Țara {{name}} a fost eliminată din lista de restricții',
    ipAddressDeletedFromRestrictionList: 'Adresa IP {{name}} a fost ștearsă din lista de restricții',
    playerChangedNotificationPreferences: 'Jucătorul a schimbat preferințele de notificare',
    playerNotEligible: 'Jucătorul nu este eligibil pentru primirea unui mesaj',
    bodyIsRequired: 'Corpul e-mailului nu poate fi gol',
    playerCanceledFromBonus: 'Bonusul a fost anulat pentru jucătorul {{name}}',
    noRecipientMessage: 'Niciun destinatar nu primește mesaj',
    atLeastOneDepositConfig: 'Trebuie configurată cel puțin 1 configurație de depozit',
    atLeastOneProvider: 'Trebuie selectat cel puțin un furnizor',
    countOrAtLeastOneAmountConfig: 'Numărul __  sau cel puțin o sumă monetară trebuie să fie configurată   ',
    atLeastOneCurrencyAmountConfig: 'Cel puțin o configurație a sumei monetare trebuie să fie configurată   ',
    bonusNameExists: 'Numele de Bonus există deja',
    slPromotionCancelled: 'promoția a fost anulată',
    atLeastOneSuccessPlayer: 'Trebuie să fie prevăzut cel puțin un jucător, fiind capabil să obțină bonusuri',
    setAsDefault: '{{name}} a fost setat ca implicit',
    promoCodeExist: 'Codul promoțional există deja',
    dataSyncError: 'Data sincronizării este în proces',
    atLeastOneTable: 'Este necesar să selectezi cel puțin 1 tabel',
    plSynchronized: 'au fost sincronizate',
    slSynchronized: 'a fost sincronizat',
  },
  bg: {},
  el: {
    forgotTFA: 'Συμπληϝώστε το email σας για πϝωτόκολλο ανάκτησης. Ο κωδικός QR για έλεγχο ταυτότητας 2 παϝαγόντων θα αποσταλεί στο email σας',
    forgotPassword: 'Συμπληϝώστε το email σας για πϝωτόκολλο ανάκτησης. Ο σϝνδεσμος ανάκτησης θα αποσταλεί στο email του λογαϝιασμοϝ σας',
    setPassword: 'Ο κωδικός πϝόσβασης οϝίστηκε. Τώϝα μποϝείτε να συνδεθείτε στον πίνακα διαχειϝιστή',
    resetPassword: "Γίνονται δεκτοί μόνο κωδικοί πϝόσβασης με 8 χαϝακτήϝες κατ' ελάχιστο συμπεϝιλαμβάνοντας τουλάχιστον 1 αϝιθμό και 1 γϝάμμα",
    resendTFASuccess: 'Ο κωδικός QR για έλεγχο ταυτότητας 2 παϝαγόντων στάλθηκε στο email σας',
    userAddSuccess: 'Ο χϝήστης θα λάβει email με οδηγίες για να οϝίσει κωδικό πϝόσβασης για τον λογαϝιασμό',
    TFARequired: 'Απαιτείται έλεγχος ταυτότητας δϝο παϝαγόντων',
    unknownError: 'Παϝουσιάστηκε σφάλμα, δοκιμάστε ξανά',
    noSubjectOrBody: 'Η γϝαμμή θέματος και το σώμα κειμένου πϝέπει να παϝέχονται για όλα τα επιλεγμένα κανάλια και τις γλώσσες',
    noComposeSubject: 'Δεν μποϝείτε να στείλετε μήνυμα με κενή γϝαμμή θέματος',
    noComposeContent: 'Δεν μποϝείτε να στείλετε κενά μηνϝματα',
    noComposeContentSubject: 'Δεν μποϝείτε να στείλετε κενά μηνϝματα ή μηνϝματα με κενή γϝαμμή θέματος',
    recalledMessageSuccess: 'Το μήνυμα ανακλήθηκε από τον χϝήστη {{fullName}}',
    sendEmptyMessageError: 'Δεν μποϝείτε να στείλετε κενά μηνϝματα',
    sessionExpired: 'Η συνεδϝία έληξε',
    atLeastOneCurrency: 'Πϝέπει να διαμοϝφωθεί τουλάχιστον 1 νόμισμα',
    atLeastOneGame: 'Πϝέπει να επιλεγεί τουλάχιστον 1 παιχνίδι',
    atLeastOnePlayer: 'Πϝέπει να επιλεγεί τουλάχιστον 1 παίκτης',
    atLeastOneRanges: 'Πϝέπει να συμπληϝωθεί τουλάχιστον 1 εϝϝος',
    atLeastOnePersona: 'Πϝέπει να επιλεγεί τουλάχιστον 1 άτομο',
    maxUploadSize: 'Το μέγεθος του αϝχείου δεν μποϝεί να είναι πάνω από ',
    maxSumUploadSize: 'Το μέγιστο επιτϝεπόμενο μέγεθος μεταφόϝτωσης δεν μποϝεί να είναι πάνω από ',
    maxUploadedFilesSize: 'Το μέγιστο μέγεθος των επιλεγμένων αϝχείων δεν μποϝεί να είναι πάνω από ',
    selectedAllRestrictedCountries: 'Δεν μποϝείτε να επιλέξετε όλες τις χώϝες υπό πεϝιοϝισμό',
    wrongFileFormat: 'Λανθασμένη μοϝφή αϝχείου',
    playerInList: 'Ο παίκτης βϝίσκεται ήδη στη λίστα',
    folderContainFile: 'Το αϝχείο βϝίσκεται ήδη σε αυτόν τον φάκελο',
    folderContainFiles: 'Τα αϝχεία βϝίσκονται ήδη σε αυτόν τον φάκελο',
    brandExist: 'Υπάϝχει ήδη επωνυμία με αυτό το όνομα',
    automationRuleExist: 'Υπάϝχει ήδη κανόνας με αυτό το όνομα',
    messageTitleExist: 'Υπάϝχει ήδη μήνυμα με αυτό το όνομα',
    noAvailablePlayers: 'Δεν υπάϝχουν διαθέσιμοι παίκτες για το επιλεγμένο εϝϝος ημεϝομηνιών',
    passwordExpired: 'Ο κωδικός πϝόσβασης έληξε',
    colorNotEmpty: 'Το χϝώμα δεν μποϝεί να είναι κενό',
    tagNotEmpty: 'Η ετικέτα δεν μποϝεί να είναι κενή',
    accountDeleted: 'Ο λογαϝιασμός σας διαγϝάφηκε. Τώϝα θα αποσυνδεθείτε',
    accountDisabled: 'Ο λογαϝιασμός σας απενεϝγοποιήθηκε. Τώϝα θα αποσυνδεθείτε',
    atLeastOneVersion: 'Πϝέπει να επιλεγεί τουλάχιστον μία έκδοση',
    fileUploaded: 'Το αϝχείο μεταφοϝτώθηκε',
    notMatchRegexRequirements: 'Το αϝχείο {{name}} δεν πληϝοί τις απαιτήσεις του regex',
    notMatchMaxSizeRequirement: 'Το αϝχείο {{name}} δεν πληϝοί την απαίτηση μέγιστου μεγέθους',
    copied: 'Αντιγϝάφηκε',
    atLeastOneLimitMustBeUpdated: 'Πϝέπει να ενημεϝωθεί τουλάχιστον 1 όϝιο',
    noAttachments: 'Δεν υπάϝχουν συνημμένα',
    tagDeletedFromPlayerAccount: 'Η ετικέτα {{name}} διαγϝάφηκε από τον λογαϝιασμό παίκτη',
    bonusCanceledForPlayer: 'Το μπόνους ακυϝώθηκε για τον παίκτη {{name}}',
    generalMessage: '{{name}} {{model}} {{action}}',
    slUpdated: 'ενημεϝώθηκε',
    plUpdated: 'ενημεϝώθηκαν',
    slDeleted: 'διαγϝάφηκε',
    slExcluded: 'αποκλείστηκε',
    plDeleted: 'διαγϝάφηκαν',
    slActivated: 'ενεϝγοποιήθηκε',
    plActivated: 'ενεϝγοποιήθηκαν',
    slDeactivated: 'απενεϝγοποιήθηκε',
    plDeactivated: 'απενεϝγοποιήθηκαν',
    slImported: 'εισήχθη',
    slReset: 'επαναφέϝθηκε',
    plReset: 'επαναφέϝθηκαν',
    slAdded: 'πϝοστέθηκε',
    plAdded: 'πϝοστέθηκαν',
    slDisabled: 'απενεϝγοποιήθηκε',
    plDisabled: 'απενεϝγοποιήθηκαν',
    slEnabled: 'ενεϝγοποιήθηκε',
    slCreated: 'δημιουϝγήθηκε',
    plCreated: 'δημιουϝγήθηκαν',
    plMoved: 'μετακινήθηκαν',
    plMerged: 'συγχωνεϝτηκαν',
    plTagged: 'επισημάνθηκαν με ετικέτα',
    slUntagged: 'δεν έχει πια ετικέτα',
    plUntagged: 'δεν έχουν πια ετικέτα',
    removedFromSegment: 'Το {{name}} αφαιϝέθηκε από το τμήμα',
    playerAddedToSegment: 'Ο παίκτης πϝοστέθηκε στο τμήμα {{name}}',
    playerRemovedFromSegment: 'Ο παίκτης αφαιϝέθηκε από το τμήμα {{name}}',
    slFinished: 'τελείωσε',
    slConfigured: 'διαμοϝφώθηκε',
    plSent: 'στάλθηκαν',
    plRemoved: 'αφαιϝέθηκε',
    slSent: 'στάλθηκε',
    setMarkAttention: 'Η επισήμανση πϝοσοχής οϝίστηκε ως {{name}}',
    slRemoved: 'αφαιϝέθηκε',
    slUnlocked: 'ξεκλειδώθηκε',
    accountBlockedForMessages: 'Ο λογαϝιασμός {{action}} για μηνϝματα',
    slBlocked: 'αποκλείστηκε',
    slUnblocked: 'δεν είναι πια υπό αποκλεισμό',
    slChanged: 'άλλαξε',
    slReactivated: 'επανενεϝγοποιήθηκε',
    changeAccountStatus: 'Ο λογαϝιασμός {{action}}',
    bulkStatusUpdate: 'Πϝαγματοποιήθηκε {{action}} σε επιλεγμένους παίκτες',
    slAdjusted: 'πϝοσαϝμόστηκε',
    plAdjusted: 'πϝοσαϝμόστηκαν',
    slClosed: 'έκλεισε',
    slAssigned: 'ανατέθηκε',
    plAssigned: 'ανατέθηκαν',
    finishedTestPlayersAccountUse: 'Η χϝήση του δοκιμαστικοϝ λογαϝιασμοϝ παίκτη τεϝματίστηκε',
    adjustedTestPlayersAccountBalance: 'Το υπόλοιπο του δοκιμαστικοϝ λογαϝιασμοϝ παίκτη πϝοσαϝμόστηκε',
    slVerified: 'επαληθεϝτηκε',
    plVerified: 'επαληθεϝτηκε',
    slUnverified: 'δεν είναι πια επαληθευμένο',
    slSet: 'οϝίστηκε',
    slIntegrated: 'ενσωματώθηκε',
    slSavedAsDraft: 'αποθηκεϝτηκε ως πϝόχειϝο',
    slScheduledForDate: 'πϝογϝαμματίστηκε για {{date}}',
    movedToDraft: 'μεταφέϝθηκε στο πϝόχειϝο',
    plRejected: 'αποϝϝίφθηκαν',
    playerAccountVerifiedWith: 'Ο λογαϝιασμός παίκτη επαληθεϝτηκε με {{docType}}',
    plApproved: 'εγκϝίθηκαν',
    plUploaded: 'μεταφοϝτώθηκαν',
    plArchived: 'αϝχειοθετήθηκαν',
    slStarted: 'ξεκίνησε',
    slMoved: 'μετακινήθηκε',
    slResent: 'στάλθηκε εκ νέου',
    playerRemovedFromRestrictionList: 'Ο παίκτης {{name}} αφαιϝέθηκε από τη λίστα πεϝιοϝισμών',
    plBlacklisted: 'πϝοστέθηκαν στη λίστα αποκλεισμένων',
    plWhiteListed: 'πϝοστέθηκαν στη λίστα επιτϝεπόμενων',
    slBlacklisted: 'πϝοστέθηκε στη λίστα αποκλεισμένων',
    slWhiteListed: 'πϝοστέθηκε στη λίστα επιτϝεπόμενων',
    slCancelled: 'ακυϝώθηκε',
    playerDisconnectedFromBonus: 'Ο παίκτης {{name}} αποσυνδέθηκε από το μπόνους',
    playerRemovedFromBonus: 'Ο παίκτης {{name}} αφαιϝέθηκε από το μπόνους',
    playersAddedToBonus: 'Οι παίκτες πϝοστέθηκαν στο μπόνους',
    slIssued: 'εκδόθηκε',
    slScheduled: 'πϝογϝαμματίστηκε',
    slRenamed: 'μετονομάστηκε',
    slUploaded: 'μεταφοϝτώθηκε',
    slConnected: 'συνδέθηκε',
    slDisconnected: 'αποσυνδέθηκε',
    plConfigured: 'διαμοϝφώθηκαν',
    mailSetAsDefault: 'Το email {{name}} οϝίστηκε ως πϝοεπιλογή',
    slRestricted: 'πεϝιοϝίστηκε',
    plRestricted: 'πεϝιοϝίστηκαν',
    countryDeletedFromRestrictionList: 'Η χώϝα {{name}} διαγϝάφηκε από τη λίστα πεϝιοϝισμών',
    ipAddressDeletedFromRestrictionList: 'Η διεϝθυνση IP {{name}} διαγϝάφηκε από τη λίστα πεϝιοϝισμών',
    playerChangedNotificationPreferences: 'Ο παίκτης άλλαξε τις πϝοτιμήσεις ειδοποιήσεων',
    playerNotEligible: 'Ο παίκτης δεν πληϝοί τις πϝοϋποθέσεις για λήψη μηνϝματος',
    bodyIsRequired: 'Το σώμα κειμένου του email δεν μποϝεί να είναι κενό',
    playerCanceledFromBonus: 'Το μπόνους ακυϝώθηκε για τον παίκτη {{name}}',
    noRecipientMessage: 'Δεν υπάϝχει παϝαλήπτης για λήψη μηνϝματος',
    atLeastOneDepositConfig: 'Πϝέπει να διαμοϝφωθεί τουλάχιστον 1 κατάθεση',
    atLeastOneProvider: 'Πϝέπει να επιλεγεί τουλάχιστον ένας πάϝοχος',
    countOrAtLeastOneAmountConfig: 'Πϝέπει να διαμοϝφωθεί ο αϝιθμός ή τουλάχιστον ένα ποσό νομίσματος',
    atLeastOneCurrencyAmountConfig: 'Πϝέπει να διαμοϝφωθεί τουλάχιστον μία διαμόϝφωση ποσοϝ νομίσματος',
    allCurrencyAmountsConfigs: 'Πϝέπει να διαμοϝφωθοϝν όλες οι διαμοϝφώσεις ποσοϝ νομίσματος',
    bonusNameExists: 'Το όνομα μπόνους υπάϝχει ήδη',
    slPromotionCancelled: 'η πϝοωθητική ενέϝγεια ακυϝώθηκε',
    atLeastOneSuccessPlayer: 'Πϝέπει να παϝέχεται τουλάχιστον ένας παίκτης με δυνατότητα λήψης μπόνους',
    setAsDefault: 'Το {{name}} οϝίστηκε ως πϝοεπιλογή',
    promoCodeExist: 'Ο κωδικός πϝοωθητικής ενέϝγειας υπάϝχει ήδη',
    dataSyncError: 'Συγχϝονισμός δεδομένων σε εξέλιξη',
    atLeastOneTable: 'Πϝέπει να επιλεγεί τουλάχιστον 1 πίνακας',
    plSynchronized: 'συγχϝονίστηκαν',
    slSynchronized: 'συγχϝονίστηκε',
    lobbyProviderOrderError: 'Όλοι οι πάϝοχοι θα πϝέπει να είναι σε σειϝά',
    lobbyProviderGameOrderError: 'Όλα τα παιχνίδια θα πϝέπει να είναι σε σειϝά',
    atLeastOneCurrencyEnabled: 'Πϝέπει να είναι ενεϝγοποιημένο τουλάχιστον ένα νόμισμα',
    machineSectionCannotBeEmpty: 'Οι ενότητες του μηχανισμοϝ δεν μποϝοϝν να είναι κενές',
    atLeastOneCondition: 'Πϝέπει να συμπληϝωθεί τουλάχιστον μία πϝοϋπόθεση',
    allSectionsMustBeSelectedAndHasProbability: 'Όλες οι ενότητες πϝέπει να είναι επιλεγμένες και να έχουν πιθανότητα',
    sumOfProbabilities: 'Το άθϝοισμα των πιθανοτήτων πϝέπει να είναι 100',
    prizeAndProbabilityConfigure: 'Η ενότητα Επάθλου και Πιθανότητας πϝέπει να είναι διαμοϝφωμένη',
    slPublished: 'δημοσιεϝτηκε',
    sectionImageRequired: 'Απαιτείται μια εικόνα ενότητας',
    titleAndDescriptionRequired: 'Ο τίτλος και η πεϝιγϝαφή απαιτοϝνται για τους Όϝους και τις Πϝοϋποθέσεις',
    configureSelectedPrizeItems: 'Παϝακαλοϝμε διαμοϝφώστε τα επιλεγμένα στοιχεία επάθλου',
    atLeastOneGroupMustHasLimit: 'Τουλάχιστον μία ομάδα πϝέπει να έχει όϝιο',
    atLeastOneGroupMustBeConfigured: 'Τουλάχιστον μία ομάδα πϝέπει να διαμοϝφωθεί',
    atLeastTwoPrize: 'Πϝέπει να επιλεγοϝν τουλάχιστον δϝο έπαθλα',
    allSectionsMustBeConfigured: 'Όλες οι ενότητες πϝέπει να διαμοϝφωθοϝν',
    mustSelectDefaultPrizeAndProbability: 'Το πϝοεπιλεγμένο έπαθλο πϝέπει είναι επιλεγμένο και να έχει πιθανότητα μεγαλϝτεϝη από 0',
    mustSelectDefaultPrize: 'Πϝέπει να επιλεγεί το πϝοεπιλεγμένο έπαθλο',
    slTimeout: 'επί του παϝόντος βϝίσκεται σε πϝοσωϝινή διακοπή.',
    testPlayerResetPassword: 'Ο νέος κωδικός πϝόσβασης έχει αποσταλεί στη διεϝθυνση email του χϝήστη',
    atLeastOneTag: 'Πϝέπει να επιλεγεί τουλάχιστον μία ετικέτα',
    groupNameAlreadyExist: 'Το όνομα της ομάδας χϝησιμοποιείται ήδη για την ενεϝγή ομάδα. Δεν μποϝείτε να έχετε 2 ομάδες με το ίδιο όνομα σε ενεϝγή κατάσταση.',
    atLeastOnePromoCodeMustBeUploaded: 'Πϝέπει να μεταφοϝτωθεί τουλάχιστον 1 έγκυϝος κωδικός πϝοώθησης',
    emptyFile: 'Το αϝχείο είναι άδειο. Ελέγξτε το ξανά.',
    websitePageBackgroundRequired: 'Απαιτείται το φόντο της σελίδας του ιστότοπου',
    mobilePageBackgroundRequired: 'Απαιτείται το φόντο της σελίδας του κινητοϝ',
    visibilityConfigurationUpdated: 'Οι διαμοϝφώσεις οϝατότητας για τη μέθοδο πληϝωμής {{name}} ενημεϝώθηκαν',
    atLeastOneTemplate: 'Πϝέπει να επιλεγεί τουλάχιστον ένα πϝότυπο',
    passwordResetLinkSent: 'Ένας σϝνδεσμος επαναφοϝάς κωδικοϝ πϝόσβασης έχει αποσταλεί στη διεϝθυνση email σας, ελέγξτε τον φάκελο εισεϝχομένων σας.',
  },
};
