export default {
  en: {
    changes: 'Changes',
    changeDate: 'Change Date',
    changedBy: 'Changed By',
    gameRatio: 'Game Ratio',
    gameTypes: 'Game Types',
    totalCurrentAmount: 'Total Current Amount',
    operatorIncome: 'Operator Income',
    playerExclusion: 'Player Exclusion',
    exclusionType: 'Exclusion Type',
    appliedBy: 'Applied By',
    totalFunds: 'Total Funds',
    operatorsIncome: 'Operators Income',
    totalAmountReturned: 'Total Amount Returned',
    totalAddedAmount: 'Total Added Amount',
    totalEarnedAmount: 'Total Earned Amount',
    totalBetAmount: 'Total Bet Amount',
    balanceAdjustment: 'Balance Adjustment',
    playerFullName: 'Player First Name & Last Name',
    balanceAdjustmentDate: 'Balance Adjustment Date',
    fixedOddsTotalBetAmount: 'Fixed Odds Total Bet Amount',
    fixedOddsTotalWinAmount: 'Fixed Odds Total Win Amount',
    fixedOddsTotalLostAmount: 'Fixed Odds Total Lost Amount',
    fixedOddsBonusBetAmount: 'Fixed Odds Total Bonus Bet',
    casinoTotalBetAmount: 'Casino Games Total Bet Amount',
    casinoTotalWinAmount: 'Casino Games Total Win Amount',
    casinoTotalLostAmount: 'Casino Games Total Lost Amount',
    casinoBonusBetAmount: 'Casino Games Total Bonus Bet',
    sessionEndReason: 'Session End Reason',
    unsettledFundsAmount: 'Unsettled Funds Amount',
    safeServer: 'Safe Server',
    casinoGamesTotalBonusAmount: 'Casino Games Total Bonus Amount',
    fixedOddsTotalBonusAmount: 'Fixed Odds Total Bonus Amount',
    mirroringServer: 'Mirroring Server',
    logDate: 'Log Date',
    changeNotification: 'Change Notification',
    changeCategory: 'Change Category',
    changeDescription: 'Change Description',
    changeReason: 'Change Reason',
    previousVersion: 'Previous Version',
    currentVersion: 'Current Version',
    change: 'Change',
    inaccessibilityAreas: 'Inaccessibility Areas',
    inaccessibilityFrom: 'Inaccessibility From',
    inaccessibilityTo: 'Inaccessibility To',
    inaccessibilityReason: 'Inaccessibility Reason',
    reconnectionFailedAttempts: 'Reconnection Failed Attempts',
    serverName: 'Server Name',
    inaccessibilityDate: 'Inaccessibility Date',
    inaccessibilityServer: 'Inaccessibility Server',
    largeTransactionsInfo: 'The system is using the default conversion for this report, if you want to change the conversion you can do it from Brand Settings→Conversion Settings→Others',
    transactionType: 'Transaction Type',
    balanceAdjustmentCount: 'Balance Adjustment Count',
    selfExclusionCount: 'Self Exclusion Count',
    accountClosedCount: 'Account Closed Count',
    operatorInterventionCount: 'Operator Intervention Count',
    unusualEventsCount: 'Other Significant/Unusual Events Count',
    jackpotWinRatio: 'Jackpot Win Ratio',
    jackpotConfiguration: 'JackPot Configuration',
    jackpotName: 'Jackpot Name',
    gamePaylistId: 'Game Paylist ID',
    gameSessionId: 'Game Session ID',
    jackpotPrizeDate: 'Jackpot Prize Date',
    maximumJackpotLevel: 'Maximum Jackpot Level',
    jackpotAmount: 'Jackpot Amount',
    processingUserID: 'Processing User ID',
    processingUserName: 'Processing User Name',
    confirmingUserID: 'Confirming User ID',
    confirmingUserName: 'Confirming User Name',
    progressiveJackpotName: 'Progressive Jackpot Name',
    setupParameters: 'Setup Parameters',
    existingGamesPaylist: 'Existing Games Paylist',
    totalBetsAmount: 'Total Bets Amount',
    totalContributionsWinAmount: 'Total Contributions Win Amount',
    nonContributionFinance: 'Non Contribution Finance',
    jackpotPrizeCurrentAmount: 'Jackpot Prize Current Amount',
    jackpotContributionCurrentAmount: 'Jackpot Contribution Current Amount',
    terminationDate: 'Termination Date',
    maximumAwardedAmount: 'Maximum Awarded Amount',
    exceedingLimitAmount: 'Exceeding Limit Amount',
    promotionalItems: 'Promotional Items',
    promotionId: 'Promotion ID',
    promotionType: 'Promotion Type',
    totalBalanceAllocated: 'Total Balance Allocated',
    totalAmountPrizes: 'Total Amount - Prizes',
    totalAmountUsed: 'Total Amount Used',
    totalAdjustmentAmount: 'Total Adjustment Amount',
    finalBalance: 'Final Balance',
    softwareChanges: 'Software Changes',
    taxationDaily: 'Taxation Daily',
    taxationMonthly: 'Taxation Monthly',
    betWin: 'Bet Win',
    taxation: 'Taxation',
    quarterDetails: 'Quarter Details',
    statisticalReportQuarter: 'Statistical Report Q{{quarter}} {{year}}',
    bettingSelfProtectionReportInfo: "The provided report is based on the Brand's timezone only, any time zone changes in this page will not affect the report",
    totalAmountExpired: 'Total Amount Expired',
    report: 'Report',
    exclusions: 'Exclusions',
    selectCriteriaToGenerateOrExportReport: 'Select criteria to generate or export report',
    run: 'Run',
    exclusionId: 'Exclusion ID',
    exclusionDurationCategory: 'Exclusion Duration Category',
    exclusionChannel: 'Exclusion Channel',
    exclusionRequestDate: 'Exclusion Request Date',
    expectedExclusionDuration: 'Expected Exclusion Duration',
    exclusionStartDate: 'Exclusion Start Date',
    endExclusionRequestDate: 'End Exclusion Request Date',
    exclusionEndDate: 'Exclusion End Date',
    exclusionReason: 'Exclusion Reason',
    reportCreationDate: 'Report Creation Date',
    reportUpdateDate: 'Report Update Date',
    resetFilters: 'Reset Filters',
    year: 'Year',
    licenseeLegalName: 'Licensee Legal Name',
    licenseType: 'License Type',
    gameCategoryA: 'Game Category A',
    betCategoryB: 'Bet Category B',
    totalAmountWagered: 'Total Amount Wagered',
    totalAmountWageredSettled: 'Total Amount Wagered Settled',
    totalNumberPlayers: 'Total Number Players',
    totalNumberPlayersSettled: 'Total Number Players Settled',
    totalAmountWon: 'Total Amount Won',
    rake: 'Rake',
    totalAmountWonJackpots: 'Total Amount Won Jackpots',
    totalNumberPlayersWon: 'Total Number Players Won',
    totalAmountWithholdingTax: 'Total Amount Withholding Tax',
    contribution345: 'Contribution345',
    contributionHGC05: 'ContributionHGC05',
    monthlyEconomic: 'Monthly Economic',
    month: 'Month',
    jackpotWinnings: 'Jackpot Winnings',
    jackpotID: 'Jackpot ID',
    jackpotType: 'Jackpot Type',
    jackpotStatus: 'Jackpot Status',
    profitDistributionLevelID: 'Profit Distribution Level ID',
    jackpotWonDate: 'Jackpot Won Date',
    totalJackpotWonAmount: 'Total Jackpot Won Amount',
    totalJackpotRake: 'Total Jackpot Rake',
    currency: 'Currency',
    ptPGameSessionID: 'Pt PGame Session ID',
    pokerTournamentID: 'Poker Tournament ID',
    jackpotPlayerAmount: 'Jackpot Player Amount',
    monthlyEconomicBetting2: 'Monthly Economic Betting 2',
    gameCategoryB: 'Game Category B',
    winningsCategory: 'Winnings Category',
    totalNumberCoupons: 'Total Number Coupons',
    playSession: 'Play Session',
    loginSessionStartDate: 'Login Session Start Date',
    loginSessionEndDate: 'Login Session End Date',
    loginSessionEndReason: 'Login Session Reason',
    channelIndicator: 'Channel Indicator',
    roamingIndicator: 'Roaming Indicator',
    beginningBalance: 'Beginning Balance',
    endingBalance: 'Ending Balance',
    reportDateFrom: 'Report Date From',
    reportDateTo: 'Report Date To',
    monthlyEconomicPlayerTaxes: 'Monthly Economic Player Taxes',
    totalNumberSessions: 'Total Number Sessions',
    monthlyEconomicBetting1: 'Monthly Economic Betting 1',
    betEventCategoryA: 'Bet Event Category A',
    limitCategory: 'Limit Category',
    limitCreationDate: 'Limit Creation Date',
    limitChangeDate: 'Limit Change Date',
    limitStartDate: 'Limit Start Date',
    newLimitDuration: 'New Limit Duration (hours)',
    newLimitAmount: 'New Limit Amount',
    oldLimitDuration: 'Old Limit Duration (hours)',
    oldLimitAmount: 'Old Limit Amount',
    limitInitiator: 'Limit Initiator',
    logInSessionId: 'Log In Session ID',
    gameSessions: 'Game Sessions',
    manufacturerLN: 'ManufacturerLN',
    totalRedeemableBonusAmount: 'Total Amount of Redeemable Bonuses',
    totalNonRedeemableBonusAmountWon: 'Total Non Redeemable Bonus Amount Won',
    totalNetAmountWon: 'Total Net Amount Won',
    totalPlayerAmountWon: 'Total Player Amount Won',
    totalNonRedeemableBonusAmountWagered: 'Total Non Redeemable Bonus Amount Wagered',
    totalAmountLicenseeRake: 'Total Licensee Rake',
    totalAmountJackpotRake: 'Total Jackpot Rake',
    monthlyGGR: 'Monthly GGR',
    licenseID: 'License ID',
    licenseName: 'License Name',
    GGR: 'GGR',
    greekPublicParticipation: 'Greek Public Participation',
    resourceEEEP: 'Resource EEEP',
    hGCBetEventID: 'HGC Bet Event ID',
    betEventType: 'Bet Event Type',
    mainBetEventID: 'Main Bet Event ID',
    betEventCreationDate: 'Bet Event Creation Date',
    betEventResultDate: 'Bet Event Result Date',
    betEventCancellation: 'Bet Event Cancellation',
    betEventCancellationDate: 'Bet Event Cancellation Date',
    eventName: 'Event Name',
    eventType: 'Event Type',
    betEvents: 'Bet Events',
    betEventCategoryB: 'Bet Event Category B',
    betEventCategoryC: 'Bet Event Category C',
    betEventCategoryD: 'Bet Event Category D',
    bonusSchemas: 'Bonus Schemas',
    bonusStartDate: 'Bonus Start Date',
    bonusEndDate: 'Bonus End Date',
    givenNumberOfBonus: 'Given Number Of Bonus',
    givenBonusAmount: 'Given Bonus Amount',
    usedNumberOfBonus: 'Used Number Of Bonus',
    usedBonusAmount: 'Used Bonus Amount',
    bonusCategoryA: 'Bonus Category A',
    bonusCategoryB: 'Bonus Category B',
    wagerMultiplier: 'Wager Multiplier',
    amountWageredIndicator: 'Amount Wagered Indicator',
    otherWageringRequirements: 'Other Wagering Requirements',
    onlineAccounts: 'Online Accounts',
    accountType: 'Account Type',
    accountStatusDate: 'Account Status Date',
    accountStatusReason: 'Account Status Reason',
    surname: 'Surname',
    idNumber: 'Id Number',
    passportNumber: 'Passport Number',
    vatNumber: 'Vat Number',
    amkaNumber: 'Amka Number',
    addressCountry: 'Address Country',
    postCode: 'Post Code',
    emailAddress: 'Email Address',
    kycDate: 'Kyc Date',
    playersBetting: "Player's Betting",
    eventId: 'Event ID',
    betSettledPeriod: 'Bet Settled Period',
    betTypeB: 'Bet Type B',
    betStatus: 'Bet Status',
    betIp: 'Bet IP',
    playerRisk: 'Player Risk',
    betIdOdds: 'Bet ID Odds',
    betEventId: 'Bet Event ID',
    betEventName: 'Bet Event Name',
    betEventOdds: 'Bet Event Odds',
    betPlacedDate: 'Bet Placed Date',
    betSettlementDate: 'Bet Settlement Date',
    netAmountWon: 'Net Amount Won',
    withholdingTax2_5: 'Withholding Tax 2.5%',
    withholdingTax5: 'Withholding Tax 5%',
    withholdingTax7_5: 'Withholding Tax 7.5%',
    realMoneyWagers: 'Real Money Wagers',
    nonRedeemableBonuses: 'Non Redeemable Bonuses',
    realMoneyWinnings: 'Real Money Winnings',
    winningsFromNonRedeemableBonuses: 'Winnings From Non Redeemable Bonuses',
    bettingPerformance: 'Betting Performance',
    domainName: 'Domain Name',
    betType: 'Bet Type',
    totalNumberWagers: 'Total Number Wagers',
    totalNumberWagersSettled: 'Total Number Wagers Settled',
    totalNonRedeemableBonusAmountWageredSettled: 'Total Non Redeemable Bonus Amount Wagered Settled',
    recordType: 'RecordType',
    gamePerformanceDaily: 'Game Performance Daily',
    sharedLiquidityIndicator: 'Shared Liquidity Indicator',
    totalNumberGameSessions: 'Total Number Of Game Sessions',
    totalNumberPokerCashGamesHands: 'Total Number Poker Cash Games Hands',
    totalNumberTaxationSessions: 'Total Number Of Taxation Sessions',
    totalNumberJackpots: 'Total Number Jackpots',
    totalNumberIncompleteGameSessions: 'Total Number Of Incomplete Game Sessions',
    bettingAndSelfProtection: 'Betting and Self-Protection',
    jackpotSettings: 'Jackpot Settings',
    jackpotProviderID: 'Jackpot Provider ID',
    profitDistributionLevelCount: 'Profit Distribution Level Count',
    increaseRate: 'Increase Rate',
    startUpAmount: 'Start Up Amount',
    amountJackpotLimitFloor: 'Amount Jackpot Limit Floor',
    amountJackpotLimitCeiling: 'Amount Jackpot Limit Ceiling',
    timeJackpotLimitCeiling: 'Time Jackpot Limit Ceiling',
    totalJackpotAmount: 'Total Jackpot Amount',
    paytableIDCount: 'Paytable ID Count',
    paytableID: 'Paytable ID',
    jackpotEndDate: 'Jackpot End Date',
    jackpotTransferedAmount: 'Jackpot Transfered Amount',
    oldProfitDistributionLevelID: 'Old Profit Distribution Level ID',
    newJackpotID: 'New Jackpot ID',
    newProfitDistributionLevelID: 'New Profit Distribution Level ID',
    jackpotTransferredDate: 'Jackpot Transferred Date',
    jackpotStartDate: 'Jackpot Start Date',
    totalExclusionCount: 'Total Exclusion Count',
    taxAmountPercent: 'Tax Amount ({{percent}}%)',
    totalBalances: 'Total Balances',
    companyProfit: 'Company Profit',
    balanceAvailable: 'Balance Available',
    withdrawalAmount: 'Withdrawal Amount',
    nonRedeemableBonusAmountWagered: 'Non Redeemable Bonus Amount Wagered',
    totalWithholdingTax: 'Total Withholding Tax',
    nonRedeemableBonusAmountWon: 'Non Redeemable Bonus Amount Won',
    minimumWagerAmount: 'Minimum Wager Amount',
    withholdingTax15: 'Withholding Tax 15%',
    withholdingTax20: 'Withholding Tax 20%',
    addNotification: 'Add Notification',
    addServer: 'Add Server',
    serverInaccessibility: 'Server Inaccessibility',
    largeTransactions: 'Large Transactions',
    restrictions: 'Restrictions',
  },
  es: {
    changes: 'Cambios',
    changeDate: 'Fecha de modificación',
    changedBy: 'Modificado por',
    gameRatio: 'Ratio de juego',
    gameTypes: 'Tipos de juego',
    totalCurrentAmount: 'Importe total actual',
    operatorIncome: 'Ingresos de los operadores',
    playerExclusion: 'Exclusión de los jugadores',
    exclusionType: 'Tipo de exclusión',
    appliedBy: 'Aplicado por',
    totalFunds: 'Fondos totales',
    operatorsIncome: 'Ingresos de los operadores',
    totalAmountReturned: 'Importe total devuelto',
    totalAddedAmount: 'Importe total añadido',
    totalEarnedAmount: 'Importe total ganado',
    totalBetAmount: 'Importe total de la apuesta',
    balanceAdjustment: 'Ajuste del saldo',
    playerFullName: 'Nombre y Apellidos del Jugador',
    balanceAdjustmentDate: 'Balance Fecha de ajuste',
    fixedOddsTotalBetAmount: 'Cuotas fijas Importe total de la apuesta',
    fixedOddsTotalWinAmount: 'Cuotas fijas Importe total de la ganancia',
    fixedOddsTotalLostAmount: 'Cuotas fijas Importe total perdido',
    fixedOddsBonusBetAmount: 'Cuotas fijas Total Bonificación Apuesta',
    casinoTotalBetAmount: 'Juegos de casino Importe total de la apuesta',
    casinoTotalWinAmount: 'Juegos de casino Importe total de ganancias',
    casinoTotalLostAmount: 'Juegos de casino Importe total perdido',
    casinoBonusBetAmount: 'Juegos de Casino Total Bono Apuesta',
    sessionEndReason: 'Motivo de la finalización de la sesión',
    unsettledFundsAmount: 'Importe de los fondos no liquidados',
    safeServer: 'Servidor Seguro',
    casinoGamesTotalBonusAmount: 'Juegos de Casino Importe Total de la Bonificación',
    fixedOddsTotalBonusAmount: 'Cuotas Fijas Importe Total de la Bonificación',
    mirroringServer: 'Servidor Espejo',
    logDate: 'Fecha de Registro',
    changeNotification: 'Notificación de Cambio',
    changeCategory: 'Categoría de Cambio',
    changeDescription: 'Descripción del Cambio',
    changeReason: 'Razón del Cambio',
    previousVersion: 'Versión Anterior',
    currentVersion: 'Versión Actual',
    change: 'Cambio',
    inaccessibilityAreas: 'Ýreas de Inaccesibilidad',
    inaccessibilityFrom: 'Inaccesibilidad Desde',
    inaccessibilityTo: 'Inaccesibilidad Hasta',
    inaccessibilityReason: 'Razón de Inaccesibilidad',
    reconnectionFailedAttempts: 'Intentos Fallidos de Reconexión',
    serverName: 'Nombre del Servidor',
    inaccessibilityDate: 'Fecha de Inaccesibilidad',
    inaccessibilityServer: 'Servidor de Inaccesibilidad',
    largeTransactionsInfo:
      'El sistema está utilizando la conversión predeterminada para este informe; si deseas cambiar la conversión, puedes hacerlo desde Configuración de Marca → Configuración de Conversión → Otros',
    transactionType: 'Tipo de Transacción',
    balanceAdjustmentCount: 'Conteo de Ajuste de Balance',
    selfExclusionCount: 'Conteo de Autoexclusión',
    accountClosedCount: 'Conteo de Cuenta Cerrada',
    operatorInterventionCount: 'Conteo de Intervención del Operador',
    unusualEventsCount: 'Conteo de Otros Eventos Significativos/Inusuales',
    jackpotWinRatio: 'Cociente de Ganancias de Jackpot',
    jackpotConfiguration: 'Configuración de Jackpot',
    jackpotName: 'Nombre del Jackpot',
    gamePaylistId: 'ID de la Lista de Juegos',
    gameSessionId: 'ID de Sesión de Juego',
    jackpotPrizeDate: 'Fecha del Premio del Jackpot',
    maximumJackpotLevel: 'Nivel Máximo del Jackpot',
    jackpotAmount: 'Importe del Jackpot',
    processingUserID: 'ID de Usuario de Procesamiento',
    processingUserName: 'Nombre de Usuario de Procesamiento',
    confirmingUserID: 'ID de Usuario de Confirmación',
    confirmingUserName: 'Nombre de Usuario de Confirmación',
    progressiveJackpotName: 'Nombre del Jackpot Progresivo',
    setupParameters: 'Parámetros de Configuración',
    existingGamesPaylist: 'Lista de Juegos Existente',
    totalBetsAmount: 'Importe Total de Apuestas',
    totalContributionsWinAmount: 'Importe Total de Contribuciones Ganadoras',
    nonContributionFinance: 'Finanzas de No Contribución',
    jackpotPrizeCurrentAmount: 'Importe Actual del Premio del Jackpot',
    jackpotContributionCurrentAmount: 'Importe Actual de Contribución del Jackpot',
    terminationDate: 'Fecha de Terminación',
    maximumAwardedAmount: 'Importe Máximo Otorgado',
    exceedingLimitAmount: 'Importe que Excede el Límite',
    promotionalItems: 'Artículos Promocionales',
    promotionId: 'ID de Promoción',
    promotionType: 'Tipo de Promoción',
    totalBalanceAllocated: 'Balance Total Asignado',
    totalAmountPrizes: 'Importe Total - Premios',
    totalAmountUsed: 'Importe Total Utilizado',
    totalAdjustmentAmount: 'Importe Total de Ajuste',
    finalBalance: 'Balance Final',
    softwareChanges: 'Cambios de Software',
    taxationDaily: 'Impuestos Diarios',
    taxationMonthly: 'Impuestos Mensuales',
    betWin: 'Ganancia de Apuesta',
    taxation: 'Impuestos',
    quarterDetails: 'Detalles del Trimestre',
    statisticalReportQuarter: 'Informe Estadístico Q{{trimestre}} {{año}}',
    bettingSelfProtectionReportInfo: 'El informe proporcionado se basa únicamente en la zona horaria de la Marca; cualquier cambio en la zona horaria en esta página no afectará el informe',
    serverInaccessibility: 'Inaccesibilidad del Servidor',
    largeTransactions: 'Grandes transacciones',
    restrictions: 'Restricciones',
  },
  tr: {
    changes: 'Değişiklikler',
    changeDate: 'Değişim Tarihi',
    changedBy: 'Değiştiren',
    gameRatio: 'Oyun Oranı',
    gameTypes: 'Oyun Türleri',
    totalCurrentAmount: 'Toplam Mevcut Tutar',
    operatorIncome: 'Operatör Geliri',
    playerExclusion: 'Oyuncu Hariç Tutma',
    exclusionType: 'Hariç Tutma Türü',
    appliedBy: 'Uygulayan',
    totalFunds: 'Toplam Fon Miktarı',
    operatorsIncome: 'Operatörlerin Geliri',
    totalAmountReturned: 'İade Edilen Toplam Tutar',
    totalAddedAmount: 'Toplam Eklenen Tutar',
    totalEarnedAmount: 'Toplam Kazanılan Tutar',
    totalBetAmount: 'Toplam Bahis Tutarı',
    balanceAdjustment: 'Bakiye Düzenleme',
    playerFullName: 'Oyuncu Adı & Soyadı',
    balanceAdjustmentDate: 'Bakiye Düzenleme Tarihi',
    fixedOddsTotalBetAmount: 'Sabit Oranlı Toplam Bahis Tutarı',
    fixedOddsTotalWinAmount: 'Sabit Oranlı Toplam Kazanç Miktarı',
    fixedOddsTotalLostAmount: 'Sabit Oranlı Toplam Kayıp Tutarı',
    fixedOddsBonusBetAmount: 'Sabit Oranlı Toplam Bonus Bahsi',
    casinoTotalBetAmount: 'Casino Oyunları Toplam Bahis Tutarı',
    casinoTotalWinAmount: 'Casino Oyunları Toplam Kazanç Miktarı',
    casinoTotalLostAmount: 'Casino Oyunları Toplam Kayıp Tutarı',
    casinoBonusBetAmount: 'Casino Oyunları Toplam Bonus Bahsi',
    sessionEndReason: 'Oturum Bitiş Nedeni',
    unsettledFundsAmount: 'Ödenmemiş Fon Tutarı',
    safeServer: 'Güvenli Sunucu',
    casinoGamesTotalBonusAmount: 'Casino Oyunları Toplam Bonus Tutarı',
    fixedOddsTotalBonusAmount: 'Sabit Oran Toplam Bonus Tutarı',
    mirroringServer: 'Yansıtma Sunucusu',
    logDate: 'Kayıt Tarihi',
    changeNotification: 'Bildirimi Değiştir',
    changeCategory: 'Kategoriyi Değiştir',
    changeDescription: 'Açıklamayı Değiştir',
    changeReason: 'Nedeni Değiştir',
    previousVersion: 'Önceki Sürüm',
    currentVersion: 'Güncel Sürüm',
    change: 'Değiştir',
    inaccessibilityAreas: 'Erişilmezlik Alanları',
    inaccessibilityFrom: 'Erişilemezlik Tarihinden',
    inaccessibilityTo: 'Erişilemezlik Tarihine',
    inaccessibilityReason: 'Erişilmezlik Nedeni',
    reconnectionFailedAttempts: 'Yeniden Bağlantı Başarısız Girişimler',
    serverName: 'Sunucu Adı',
    inaccessibilityDate: 'Erişilmezlik Tarihi',
    inaccessibilityServer: 'Erişilmezlik Sunucusu',
    largeTransactionsInfo: 'Sistem bu rapor için varsayılan çevrimi kullanmaktadır, eğer çevrimi değiştirmek isterseniz bunu Marka Ayarları→Çevrim Ayarları→Diğer bölümünden yapabilirsiniz.',
    transactionType: 'İşlem Türü',
    balanceAdjustmentCount: 'Bakiye Ayarlama Sayısı',
    selfExclusionCount: 'Kendini Hariç Tutma Sayısı',
    accountClosedCount: 'Hesap Kapatma Sayısı',
    operatorInterventionCount: 'Operatör Müdahale Sayısı',
    unusualEventsCount: 'Diğer Önemli/ Sıradışı Etkinlikler Sayısı',
    jackpotWinRatio: 'Jackpot Kazanma Oranı',
    jackpotConfiguration: 'JackPot Yapılandırması',
    jackpotName: 'Jackpot Adı',
    gamePaylistId: 'Oyun Ödeme Listesi ID',
    gameSessionId: 'Oyun Oturum ID',
    jackpotPrizeDate: 'Jackpot Ödül Tarihi',
    maximumJackpotLevel: 'Maksimum Jackpot Seviyesi',
    jackpotAmount: 'Jackpot Tutarı',
    processingUserID: 'Kullanıcı ID işleme',
    processingUserName: 'Kullanıcı Adı İşleme',
    confirmingUserID: 'Kullanıcı ID Onaylama',
    confirmingUserName: 'Kullanıcı Adı Onaylama',
    progressiveJackpotName: 'Progressive Jackpot Adı',
    setupParameters: 'Parametreleri Ayarla',
    existingGamesPaylist: 'Mevcut Oyunlar Ödeme Listesi',
    totalBetsAmount: 'Toplam Bahis Tutarı',
    totalContributionsWinAmount: 'Toplam Katkı Kazanç Tutarı',
    nonContributionFinance: 'Katılım Sağlamayan Finans',
    jackpotPrizeCurrentAmount: 'Jackpot Ödülü Güncel Tutar',
    jackpotContributionCurrentAmount: 'Jackpot Katkısı Güncel Tutar',
    terminationDate: 'Sonlandırma Tarihi',
    maximumAwardedAmount: 'Maksimum Ödül Tutarı',
    exceedingLimitAmount: 'Limit Aşımı Tutarı',
    promotionalItems: 'Promosyon Öğeleri',
    promotionId: 'Promosyon ID',
    promotionType: 'Promosyon Türü',
    totalBalanceAllocated: 'Dağıtılan Toplam Bakiye',
    totalAmountPrizes: 'Toplam Tutar - Ödüller',
    totalAmountUsed: 'Toplam Kullanılan Tutar',
    totalAdjustmentAmount: 'Toplam Düzeltme Tutarı',
    finalBalance: 'Son Bakiye',
    softwareChanges: 'Yazılım Değişiklikleri',
    taxationDaily: 'Günlük Vergilendirme',
    taxationMonthly: 'Aylık Vergilendirme',
    betWin: 'Bahis Kazanç',
    taxation: 'Vergilendirme',
    quarterDetails: 'Çeyrek Detaylar',
    statisticalReportQuarter: 'İstatistiksel Rapor Q{{quarter}} {{year}}',
    bettingSelfProtectionReportInfo: 'Sağlanan rapor yalnızca Markanın saat dilimine göre verilmiştir, bu sayfadaki herhangi bir saat dilimi değişikliği raporu etkilemez',
    serverInaccessibility: 'Sunucu Erişilemezliği',
    largeTransactions: 'Büyük İşlemler',
    restrictions: 'Sınırlamalar',
  },
  ru: {
    changes: 'Изменениѝ',
    changeDate: 'Дата изменениѝ',
    changedBy: 'Изменено (кем)',
    gameRatio: 'Коѝффициент игры',
    gameTypes: 'Типы игр',
    totalCurrentAmount: 'Общаѝ текущаѝ ѝумма',
    operatorIncome: 'Доход оператора',
    playerExclusion: 'Иѝключение игрока',
    exclusionType: 'Тип иѝключениѝ',
    appliedBy: 'Применено (кем)',
    total: 'Вѝего',
    totalFunds: 'Вѝего ѝредѝтв',
    operatorsIncome: 'Доход операторов',
    totalAmountReturned: 'Общаѝ ѝумма возврата ѝредѝтв',
    totalAddedAmount: 'Вѝего добавленнаѝ ѝумма',
    totalEarnedAmount: 'Вѝего заработаннаѝ ѝумма',
    totalBetAmount: 'Вѝего ѝумма ѝтавок',
    balanceAdjustment: 'Пополнение баланѝа',
    playerFullName: 'Имѝ и фамилиѝ игрока',
    balanceAdjustmentDate: 'Дата пополнениѝ баланѝа',
    fixedOddsTotalBetAmount: 'Общаѝ ѝумма ѝтавок по фикѝированным коѝффициентам',
    fixedOddsTotalWinAmount: 'Общаѝ ѝумма выигрышей по фикѝированным коѝффициентам',
    fixedOddsTotalLostAmount: 'Общаѝ ѝумма проигрышей по фикѝированным коѝффициентам',
    fixedOddsBonusBetAmount: 'Вѝего бонуѝных ѝтавок по фикѝированным коѝффициентам',
    casinoTotalBetAmount: 'Общаѝ ѝумма ѝтавок в казино играх',
    casinoTotalWinAmount: 'Общаѝ ѝумма выигрышей в казино играх',
    casinoTotalLostAmount: 'Общаѝ ѝумма проигрышей в казино играх',
    casinoBonusBetAmount: 'Вѝего ѝумма бонуѝных ѝтавок в казино играх',
    sessionEndReason: 'Причина завершениѝ ѝеѝѝии',
    unsettledFundsAmount: 'Сумма нераѝпределенных ѝредѝтв',
    safeServer: 'Безопаѝный ѝервер',
    casinoGamesTotalBonusAmount: 'Общаѝ ѝумма бонуѝа в играх казино',
    fixedOddsTotalBonusAmount: 'Общаѝ ѝумма бонуѝа ѝ фикѝированным коѝффициентом',
    mirroringServer: 'Зеркальный ѝервер',
    logDate: ' Дата лога',
    changeNotification: 'Уведомление об изменениѝх',
    changeCategory: 'Категориѝ изменений',
    changeDescription: 'Опиѝание изменений',
    changeReason: 'Причина изменений',
    previousVersion: 'Предыдущаѝ верѝиѝ',
    currentVersion: 'Текущаѝ верѝиѝ',
    change: 'Изменить',
    inaccessibilityAreas: 'Недоѝтупные зоны',
    inaccessibilityFrom: 'Недоѝтупноѝть от',
    inaccessibilityTo: 'Недоѝтупноѝть',
    inaccessibilityReason: 'Причина недоѝтупноѝти',
    reconnectionFailedAttempts: 'Неудачные попытки повторного подключениѝ',
    serverName: 'Название ѝервера',
    inaccessibilityDate: 'Дата недоѝтупноѝти',
    inaccessibilityServer: 'Сервер недоѝтупноѝти',
    largeTransactionsInfo:
      'Сиѝтема иѝпользует конвертацию по умолчанию длѝ ѝтого отчета, еѝли вы хотите изменить конвертацию, вы можете ѝделать ѝто в разделе Наѝтройки бренда→Наѝтройки конвертации→Другие',
    transactionType: 'Тип транзакции',
    balanceAdjustmentCount: 'Подѝчет корректировки баланѝа',
    selfExclusionCount: 'Подѝчет ѝамоиѝключений',
    accountClosedCount: 'Подѝчет закрытых ѝчетов',
    operatorInterventionCount: 'Подѝчет вмешательѝтв оператора',
    unusualEventsCount: 'Подѝчет других важных/необычных ѝобытий ',
    jackpotWinRatio: 'Коѝффициент выигрыша джекпота',
    jackpotConfiguration: 'Конфигурациѝ джекпота',
    jackpotName: 'Название джекпота',
    gamePaylistId: 'ID игрового пейлиѝта',
    gameSessionId: 'ID игровой ѝеѝѝии',
    jackpotPrizeDate: 'Дата розыгрыша джекпота',
    maximumJackpotLevel: 'Макѝимальный уровень джекпота',
    jackpotAmount: 'Сумма джекпота',
    processingUserID: 'Обработка ID пользователѝ',
    processingUserName: 'Обработка имени пользователѝ',
    confirmingUserID: 'Подтверждение ID пользователѝ',
    confirmingUserName: 'Подтверждение имени пользователѝ',
    progressiveJackpotName: 'Название прогреѝѝивного джекпота',
    setupParameters: 'Параметры наѝтройки',
    existingGamesPaylist: 'Пейлиѝт ѝущеѝтвующих игр',
    totalBetsAmount: 'Общаѝ ѝумма ѝтавок',
    totalContributionsWinAmount: 'Сумма выигрыша общих взноѝов ',
    nonContributionFinance: 'Финанѝирование без взноѝов ',
    jackpotPrizeCurrentAmount: 'Текущаѝ ѝумма розыгрыша джекпота ',
    jackpotContributionCurrentAmount: 'Текущаѝ ѝумма взноѝа в джекпот',
    terminationDate: 'Дата окончаниѝ',
    maximumAwardedAmount: 'Макѝимальнаѝ ѝумма вознаграждениѝ',
    exceedingLimitAmount: 'Превышение ѝуммы лимита',
    promotionalItems: 'Промо-изделиѝ',
    promotionId: 'ID промоакции',
    promotionType: 'Тип промоакции',
    totalBalanceAllocated: 'Итого раѝпределенный оѝтаток',
    totalAmountPrizes: 'Итоговаѝ ѝумма — призы',
    totalAmountUsed: 'Вѝего иѝпользованнаѝ ѝумма',
    totalAdjustmentAmount: 'Вѝего ѝумма корректировки',
    finalBalance: 'Итоговый баланѝ',
    softwareChanges: 'Изменениѝ в программном обеѝпечении',
    taxationDaily: ' Ежедневное налогообложение',
    taxationMonthly: ' Ежемеѝѝччное налогообложение',
    betWin: 'Выигрыш по ѝтавке',
    taxation: 'Налогообложение',
    quarterDetails: 'Подробноѝти квартала',
    statisticalReportQuarter: 'Статиѝтичеѝкий отчет Q{{квартал}} {{год}}',
    bettingSelfProtectionReportInfo: 'Предоѝтавленный отчет оѝнован только на чаѝовом поѝѝе бренда, любые изменениѝ чаѝового поѝѝа на ѝтой ѝтранице не повлиѝют на отчет',
    serverInaccessibility: 'Недоступность сервера',
    largeTransactions: 'Крупные транзакции',
    restrictions: 'Ограничения',
  },
  pt: {
    changes: 'Alterações',
    changeDate: 'Data da Alteração',
    changedBy: 'Alterado por',
    gameRatio: 'Proporção do Jogo',
    gameTypes: 'Tipos de Jogos',
    totalCurrentAmount: 'Valor Total Atual',
    operatorIncome: 'Rendimento do Operador',
    playerExclusion: 'Exclusão do Jogador',
    exclusionType: 'Tipo de Exclusão',
    appliedBy: 'Aplicado por',
    totalFunds: 'Total de Fundos',
    operatorsIncome: 'Rendimento dos Operadores',
    totalAmountReturned: 'Valor Total Devolvido',
    totalAddedAmount: 'Valor Total Adicionado',
    totalEarnedAmount: 'Valor Total Ganho',
    totalBetAmount: 'Valor Total da Aposta',
    balanceAdjustment: 'Ajuste do Saldo',
    playerFullName: 'Primeiro e Último Nome do Jogador',
    balanceAdjustmentDate: 'Data do Ajuste do Saldo',
    fixedOddsTotalBetAmount: 'Odds Fixas Valor Total da Aposta',
    fixedOddsTotalWinAmount: 'Odds Fixas Valor Total Ganho',
    fixedOddsTotalLostAmount: 'Odds Fixas Valor Total Perdido',
    fixedOddsBonusBetAmount: 'Odds Fixas Aposta Total de Bónus',
    casinoTotalBetAmount: 'Jogos de Casino Valor Total da Aposta',
    casinoTotalWinAmount: 'Jogos de Casino Valor Total Ganho',
    casinoTotalLostAmount: 'Jogos de Casino Valor Total Perdido',
    casinoBonusBetAmount: 'Jogos de Casino Aposta Total de Bónus',
    sessionEndReason: 'Motivo do Fim da Sessão',
    unsettledFundsAmount: 'Valor Não Resolvido de Fundos',
    safeServer: 'Servidor Seguro',
    casinoGamesTotalBonusAmount: 'Valor Total do Bônus de Jogos de Casino',
    fixedOddsTotalBonusAmount: 'Valor Total do Bônus de Odds Fixas',
    mirroringServer: 'Servidor Espelho',
    logDate: 'Data de registro',
    changeNotification: 'Notificação de alteração',
    changeCategory: 'Categoria de alteração',
    changeDescription: 'Alterar Descrição',
    changeReason: 'Alterar Motivo',
    previousVersion: 'Versão anterior',
    currentVersion: 'Versão atual',
    change: 'Alteração',
    inaccessibilityAreas: 'Zonas de inacessibilidade',
    inaccessibilityFrom: 'Inacessibilidade de',
    inaccessibilityTo: 'Inacessibilidade para',
    inaccessibilityReason: 'Motivo da inacessibilidade',
    reconnectionFailedAttempts: 'Tentativas de reconexão falhadas',
    serverName: 'Nome do servidor',
    inaccessibilityDate: 'Data de inacessibilidade',
    inaccessibilityServer: 'Servidor de Inacessibilidade',
    largeTransactionsInfo:
      'O sistema está a utilizar a conversão predefinida para este relatório. Se pretender alterar a conversão, pode fazê-lo a partir de Definições da marca→Definições de conversão→Outros',
    transactionType: 'Tipo de transação',
    balanceAdjustmentCount: 'Contagem de ajustamento do saldo',
    selfExclusionCount: 'Contagem de auto-exclusão',
    accountClosedCount: 'Contagem de contas encerradas',
    operatorInterventionCount: 'Contagem de intervenções do operador',
    unusualEventsCount: 'Contagem de outros acontecimentos significativos/incomuns ',
    jackpotWinRatio: 'Rácio de ganhos do jackpot',
    jackpotConfiguration: 'Configuração do JackPot',
    jackpotName: 'Nome do Jackpot',
    gamePaylistId: 'ID da lista de pagamento do jogo',
    gameSessionId: 'ID da sessão de jogo',
    jackpotPrizeDate: 'Data do prémio do jackpot',
    maximumJackpotLevel: 'Nível máximo do jackpot',
    jackpotAmount: 'Montante do jackpot',
    processingUserID: 'Processamento do ID do utilizador',
    processingUserName: 'Processamento do nome do utilizador',
    confirmingUserID: 'Confirmação do ID do utilizador',
    confirmingUserName: 'Confirmação do nome do utilizador',
    progressiveJackpotName: 'Nome do jackpot progressivo',
    setupParameters: 'Parâmetros de configuração',
    existingGamesPaylist: 'Jogos Existentes Lista de pagamentos',
    totalBetsAmount: 'Montante total de apostas',
    totalContributionsWinAmount: '\nTotal das Contribuições Montante de Ganho',
    nonContributionFinance: 'Financiamento sem contribuição',
    jackpotPrizeCurrentAmount: 'Montante atual do prémio do jackpot',
    jackpotContributionCurrentAmount: 'Contribuição para o Jackpot Montante Atual',
    terminationDate: 'Data de término',
    maximumAwardedAmount: 'Montante máximo atribuído',
    exceedingLimitAmount: 'Montante que excede o limite',
    promotionalItems: 'Itens promocionais',
    promotionId: 'ID da promoção',
    promotionType: 'Tipo de promoção',
    totalBalanceAllocated: 'Saldo Total Atribuído',
    totalAmountPrizes: 'Montante total - Prémios',
    totalAmountUsed: 'Montante total utilizado',
    totalAdjustmentAmount: 'Montante total de ajuste',
    finalBalance: 'Saldo final',
    softwareChanges: 'Alterações de software',
    taxationDaily: 'Tributação diária',
    taxationMonthly: 'Tributação mensal',
    betWin: 'Ganho de aposta',
    taxation: 'Tributação',
    quarterDetails: 'Detalhes do trimestre',
    statisticalReportQuarter: 'Relatório estatístico Q{{quarter}} {{year}}',
    bettingSelfProtectionReportInfo: 'O relatório fornecido baseia-se apenas no fuso horário da Marca, quaisquer alterações de fuso horário nesta página não afectarão o relatório',
    serverInaccessibility: 'Inacessibilidade do servidor',
    largeTransactions: 'Grandes Transações',
    restrictions: 'Restrições',
  },
  zh: {
    changes: '坘化',
    changeDate: '改坘日期',
    changedBy: '更改者',
    gameRatio: '游戝比例',
    gameTypes: '游戝类型',
    totalCurrentAmount: '目剝总金额',
    operatorIncome: '绝蝥者收入',
    playerExclusion: '玩家排除',
    exclusionType: '排除类型',
    appliedBy: '应用方弝',
    total: '总的',
    totalFunds: '资金总额',
    operatorsIncome: '绝蝥者收入',
    totalAmountReturned: '返回的总金额',
    totalAddedAmount: '总增加金额',
    totalEarnedAmount: '总收入金额',
    totalBetAmount: '总赌注金额',
    balanceAdjustment: '余额调整',
    playerFullName: '玩家的坝字和姓氝',
    balanceAdjustmentDate: '余额调整日期',
    fixedOddsTotalBetAmount: '固定赔率总投注额',
    fixedOddsTotalLostAmount: '固定赔率总赢利金额',
    fixedOddsBonusBetAmount: '固定赔率总奖金投注',
    casinoTotalBetAmount: '娱九场游戝总投注额',
    casinoTotalWinAmount: '娱九场游戝总获胜金额',
    casinoTotalLostAmount: '娱九场游戝总束失金额',
    casinoBonusBetAmount: '娱九场游戝总奖金投注',
    sessionEndReason: '专场结束的原因',
    unsettledFundsAmount: '未结算资金金额',
    fixedOddsTotalWinAmount: '赢钱总额的固定赔率',
    safeServer: '安全的朝务器',
    casinoGamesTotalBonusAmount: '娱九场游戝的总奖金数额',
    fixedOddsTotalBonusAmount: '固定赔率总赢利金额',
    mirroringServer: '镜僝朝务器',
    logDate: '日志日期',
    changeNotification: '更改通知',
    changeCategory: '更改类别',
    changeDescription: '更改说明',
    changeReason: '更改原因',
    previousVersion: '先剝版本',
    currentVersion: '当剝版本',
    change: '更改',
    inaccessibilityAreas: '无法访问区域',
    inaccessibilityFrom: '无法访问从',
    inaccessibilityTo: '无法访问至',
    inaccessibilityReason: '无法访问原因',
    reconnectionFailedAttempts: '針新连接失败尝试',
    serverName: '朝务器坝称',
    inaccessibilityDate: '无法访问日期',
    inaccessibilityServer: '无法访问朝务器',
    largeTransactionsInfo: '系统对该报告使用默认转杢，如果您想更改转杢，坯以从哝牌设置→转杢设置→其他中进行更改。',
    transactionType: '交易类型',
    balanceAdjustmentCount: '余额调整计数',
    selfExclusionCount: '自我排除计数',
    accountClosedCount: '账户关闭计数',
    operatorInterventionCount: '擝作员介入次数',
    unusualEventsCount: '其他針大/异常事件计数',
    jackpotWinRatio: '大奖中奖率',
    jackpotConfiguration: '大奖酝置',
    jackpotName: '大奖坝称',
    gamePaylistId: '游戝付费表ID',
    gameSessionId: '游戝会期ID',
    jackpotPrizeDate: '大奖日期',
    maximumJackpotLevel: '最高大奖级别',
    jackpotAmount: '大奖金额',
    processingUserID: '处睆用户ID',
    processingUserName: '处睆用户坝',
    confirmingUserID: '确认用户ID',
    confirmingUserName: '确认用户坝',
    progressiveJackpotName: '累积大奖坝称',
    setupParameters: '设置坂数',
    existingGamesPaylist: '现有游戝付费表',
    totalBetsAmount: '投注总额',
    totalContributionsWinAmount: '总贡献获胜金额',
    nonContributionFinance: '非贡献财务',
    jackpotPrizeCurrentAmount: '大奖奖哝当剝金额',
    jackpotContributionCurrentAmount: '大奖贡献当剝金额',
    terminationDate: '终止日期',
    maximumAwardedAmount: '最高奖励金额',
    exceedingLimitAmount: '超出陝制金额',
    promotionalItems: '促销哝',
    promotionId: '促销ID',
    promotionType: '促销类型',
    totalBalanceAllocated: '分酝余额共计',
    totalAmountPrizes: '总金额 - 奖哝',
    totalAmountUsed: '已用总额',
    totalAdjustmentAmount: '调整总额',
    finalBalance: '最终余额',
    softwareChanges: '软件坘更',
    taxationDaily: '税务日报',
    taxationMonthly: '税务月报',
    betWin: '投注赢',
    taxation: '税务',
    quarterDetails: '季度详情',
    statisticalReportQuarter: '统计报告Q{{quarter}} {{year}}',
    bettingSelfProtectionReportInfo: '所杝供的报告仅基于哝牌的时区，此页面中的任何时区坘化都丝会影哝报告',
    serverInaccessibility: '服务器无法访问',
    largeTransactions: '大额交易',
    restrictions: '限制',
  },
  ro: {
    changeDate: 'Data modificării',
    changedBy: 'Modificat de ',
    changes: 'Modificări',
    gameRatio: 'Raport de joc',
    gameTypes: 'Tipul jocului',
    totalCurrentAmount: 'Valoarea totală curentă',
    operatorIncome: 'Veniturile operatorului',
    playerExclusion: 'Excluderea jucătorilor',
    exclusionType: 'Tipul de excludere',
    appliedBy: 'Aplicat de către',
    totalFunds: 'Fonduri totale',
    operatorsIncome: 'Veniturile operatorilor',
    totalAmountReturned: 'Suma totală returnată',
    totalAddedAmount: 'Suma totală adăugată',
    totalEarnedAmount: 'Suma totală câștigată',
    totalBetAmount: 'Suma totală de pariu',
    balanceAdjustment: 'Ajustarea soldului',
    playerFullName: 'Numele și prenumele jucătorului',
    balanceAdjustmentDate: 'Data ajustării soldului',
    fixedOddsTotalBetAmount: 'Suma totală de pariu în cote fixe',
    fixedOddsTotalWinAmount: 'Suma totală de câștig în cote fixe',
    fixedOddsTotalLostAmount: 'Suma totală de pierdere în cote fixe',
    fixedOddsBonusBetAmount: 'Suma totală de bonus în cote fixe',
    casinoTotalBetAmount: 'Suma totală a pariului la jocuri de Casino',
    casinoTotalWinAmount: 'Suma totală de câștig la jocurile de Casino',
    casinoTotalLostAmount: 'Suma totală pierdută la jocuri de casino',
    casinoBonusBetAmount: 'Pariul total de Bonus la jocurile de casino',
    sessionEndReason: 'Motivul încheierii sesiunii',
    unsettledFundsAmount: 'Suma fondurilor nedecontate ',
    safeServer: 'Server de siguranță',
    casinoGamesTotalBonusAmount: 'Suma totală a Bonusului la jocuri de casino',
    fixedOddsTotalBonusAmount: 'Suma totală a Bonusului în cotă fixă',
    mirroringServer: 'Serverul de oglindire',
    logDate: 'Data înregistrării',
    largeTransactions: 'Tranzacții mari',
  },
  bg: {},
  el: {
    changes: 'Αλλαγές',
    changeDate: 'Ημεϝομηνία αλλαγής',
    changedBy: 'Η αλλαγή έγινε από',
    gameRatio: 'Αναλογία διαστάσεων παιχνιδιοϝ',
    gameTypes: 'Τϝποι παιχνιδιοϝ',
    totalCurrentAmount: 'Συνολικό τϝέχον ποσό',
    operatorIncome: 'Έσοδα χειϝιστή',
    playerExclusion: 'Αποκλεισμός παίκτη',
    exclusionType: 'Τϝπος αποκλεισμοϝ',
    appliedBy: 'Εφαϝμόστηκε από',
    totalFunds: 'Συνολικά χϝήματα',
    operatorsIncome: 'Έσοδα χειϝιστών',
    totalAmountReturned: 'Συνολικό ποσό που επιστϝάφηκε',
    totalAddedAmount: 'Συνολικό ποσό που πϝοστέθηκε',
    totalEarnedAmount: 'Συνολικό ποσό που κεϝδήθηκε',
    totalBetAmount: 'Συνολικό ποσό στοιχήματος',
    balanceAdjustment: 'Πϝοσαϝμογή υπολοίπου',
    playerFullName: "'Ονομα και Επώνυμο παίκτη",
    balanceAdjustmentDate: 'Ημεϝομηνία πϝοσαϝμογής υπολοίπου',
    fixedOddsTotalBetAmount: 'Συνολικό ποσό στοιχήματος με σταθεϝές αποδόσεις',
    fixedOddsTotalWinAmount: 'Συνολικό ποσό κέϝδους με σταθεϝές αποδόσεις',
    fixedOddsTotalLostAmount: 'Συνολικό ποσό απώλειας με σταθεϝές αποδόσεις',
    fixedOddsBonusBetAmount: 'Συνολικό μπόνους στοίχημα με σταθεϝές αποδόσεις',
    casinoTotalBetAmount: 'Συνολικό ποσό στοιχήματος σε παιχνίδια καζίνο',
    casinoTotalWinAmount: 'Συνολικό ποσό κέϝδους σε παιχνίδια καζίνο',
    casinoTotalLostAmount: 'Συνολικό ποσό απώλειας σε παιχνίδια καζίνο',
    casinoBonusBetAmount: 'Συνολικό στοίχημα μπόνους σε παιχνίδια καζίνο',
    sessionEndReason: 'Αιτία λήξης συνεδϝίας',
    unsettledFundsAmount: 'Ποσό μη διευθετημένων χϝημάτων',
    safeServer: 'Ασφαλής διακομιστής',
    casinoGamesTotalBonusAmount: 'Συνολικό ποσό μπόνους σε παιχνίδια καζίνο',
    fixedOddsTotalBonusAmount: 'Συνολικό ποσό μπόνους με σταθεϝές αποδόσεις',
    mirroringServer: 'Διακομιστής-καθϝέπτης',
    logDate: 'Ημεϝομηνία αϝχείου καταγϝαφής',
    changeNotification: 'Ειδοποίηση αλλαγής',
    changeCategory: 'Κατηγοϝία αλλαγής',
    changeDescription: 'Πεϝιγϝαφή αλλαγής',
    changeReason: 'Αιτία αλλαγής',
    previousVersion: 'Πϝοηγοϝμενη έκδοση',
    currentVersion: 'Τϝέχουσα έκδοση',
    change: 'Αλλαγή',
    inaccessibilityAreas: 'Πεϝιοχές έλλειψης πϝοσβασιμότητας',
    inaccessibilityFrom: 'Έλλειψη πϝοσβασιμότητας από',
    inaccessibilityTo: 'Έλλειψη πϝοσβασιμότητας έως',
    inaccessibilityReason: 'Αιτία έλλειψης πϝοσβασιμότητας',
    reconnectionFailedAttempts: 'Αποτυχημένες πϝοσπάθειες επανασϝνδεσης',
    serverName: 'Όνομα διακομιστή',
    inaccessibilityDate: 'Ημεϝομηνία έλλειψης πϝοσβασιμότητας',
    inaccessibilityServer: 'Διακομιστής έλλειψης πϝοσβασιμότητας',
    largeTransactionsInfo:
      'Το σϝστημα χϝησιμοποιεί την πϝοεπιλεγμένη μετατϝοπή για αυτή την αναφοϝά. Αν θέλετε να αλλάξετε τη μετατϝοπή, μποϝείτε να το κάνετε από Ρυθμίσεις επωνυμίας -> Ρυθμίσεις μετατϝοπών -> Άλλα',
    transactionType: 'Τϝπος συναλλαγής',
    balanceAdjustmentCount: 'Αϝιθμός πϝοσαϝμογών υπολοίπου',
    selfExclusionCount: 'Αϝιθμός αυτοαποκλεισμών',
    accountClosedCount: 'Αϝιθμός κλειστών λογαϝιασμών',
    operatorInterventionCount: 'Αϝιθμός παϝεμβάσεων χειϝιστή',
    unusualEventsCount: 'Αϝιθμός άλλων σημαντικών/ασυνήθιστων γεγονότων',
    jackpotWinRatio: 'Αναλογία κέϝδους τζάκποτ',
    jackpotConfiguration: 'Διαμόϝφωση τζάκποτ',
    jackpotName: 'Όνομα τζάκποτ',
    gamePaylistId: 'Αναγνωϝιστικό λίστας πληϝωμών παιχνιδιοϝ',
    gameSessionId: 'Αναγνωϝιστικό συνεδϝίας παιχνιδιοϝ',
    jackpotPrizeDate: 'Ημεϝομηνία επάθλου τζάκποτ',
    maximumJackpotLevel: 'Ανώτατο επίπεδο τζάκποτ',
    jackpotAmount: 'Ποσό τζάκποτ',
    processingUserID: 'Γίνεται επεξεϝγασία του αναγνωϝιστικοϝ χϝήστη',
    processingUserName: 'Γίνεται επεξεϝγασία του ονόματος χϝήστη',
    confirmingUserID: 'Γίνεται επιβεβαίωση του αναγνωϝιστικοϝ χϝήστη',
    confirmingUserName: 'Γίνεται επιβεβαίωση του ονόματος χϝήστη',
    progressiveJackpotName: 'Όνομα Πϝοοδευτικοϝ τζάκποτ',
    setupParameters: 'Παϝάμετϝοι ϝϝθμισης',
    existingGamesPaylist: 'Υφιστάμενη λίστα πληϝωμών παιχνιδιών',
    totalBetsAmount: 'Συνολικό ποσό στοιχημάτων',
    totalContributionsWinAmount: 'Συνολικό ποσό κέϝδους από συνεισφοϝές',
    nonContributionFinance: 'Οικονομικά χωϝίς συνεισφοϝές',
    jackpotPrizeCurrentAmount: 'Τϝέχον ποσό επάθλου τζάκποτ',
    jackpotContributionCurrentAmount: 'Τϝέχον ποσό συνεισφοϝάς τζάκποτ',
    terminationDate: 'Ημεϝομηνία τεϝματισμοϝ',
    maximumAwardedAmount: 'Μέγιστο ποσό που δόθηκε',
    exceedingLimitAmount: 'Υπεϝβαίνον ποσό οϝίου',
    promotionalItems: 'Στοιχεία πϝοωθητικών ενεϝγειών',
    promotionId: 'Αναγνωϝιστικό πϝοωθητικής ενέϝγειας',
    promotionType: 'Τϝπος πϝοωθητικής ενέϝγειας',
    totalBalanceAllocated: 'Συνολικό υπόλοιπο που δόθηκε',
    totalAmountPrizes: 'Συνολικό ποσό - Έπαθλα',
    totalAmountUsed: 'Συνολικό ποσό που χϝησιμοποιήθηκε',
    totalAdjustmentAmount: 'Συνολικό ποσό πϝοσαϝμογής',
    finalBalance: 'Τελικό υπόλοιπο',
    softwareChanges: 'Αλλαγές λογισμικοϝ',
    serverInaccessibility: 'Έλλειψη προσβασιμότητας διακομιστή',
    largeTransactions: 'Μεγάλες συναλλαγές',
    restrictions: 'Περιορισμοί',
  },
};
