export default {
  en: {
    requiredField: '{{fieldName}} cannot be empty!',
    domain: 'Invalid Domain Name!',
    invalidNumber: 'Invalid number!',
    invalidIntegerNumber: 'Invalid integer number!',
    minMax: '{{fieldName}} must be between {{min}} - {{max}}',
    lessThan: '{{fieldName}} cannot be less than {{min}}',
    greaterThan: '{{fieldName}} cannot be greater than {{max}}',
    mustBeLowerThan: '{{fieldName}} must be lower than {{max}}',
    mustBeGreaterThan: '{{fieldName}} must be greater than {{min}}',
    utoMax: '{{fieldName}} can be up to {{max}} characters long, including at least 1 letter',
    scriptName: 'Script Name should be 2-55 characters long. Can contain latin letters, numbers, and the following special characters: ), (, _, -',
    alLeastOneScript: 'At least one script should be filled!',
    limited1to50: '{{fieldName}} should be 1-50 characters long',
    rateMax: 'Rate can be up to {{max}} characters long, can contain numbers and a dot only. Maximum allowed count of digits after dot is 8',
    dateLaterThanNow: '{{fieldName}} must be later than now',
    dateLaterThanDate: '{{fieldName}} must be later than the date to notify',
    dateGreaterThanNow: '{{fieldName}} must be greater than now',
    toNotifyLessThanEffective: 'Date to Notify must be earlier than effective date',
    ahead1Hour: 'End time must be at least 1 hour ahead of start time',
    ahead1Day: 'End date must be at least 1 day ahead of start date',
    ahead7Day: 'End date must be at least 7 days ahead of start date',
    ahead1Moth: 'End date must be at least 1 month ahead of start date',
    u1to50: '{{fieldName}} should be 1-50 characters long, can contain unicode letters, numbers, spaces, underscores only, cannot start with spaces and underscores',
    limited2to50: '{{fieldName}} can be 2-50 characters long, can contain latin letters, numbers, and underscores only, including at least 1 letter',
    decimalNumber1toMaxWithPrecision: '{{fieldName}} can be up to {{max}} characters long, can contain numbers and a dot only. Maximum allowed count of digits after dot is {{precision}}',
    url: '{{fieldName}} is not a valid URL, can be up to 300 characters long → max length exceeded',
    uLS1to50: '{{fieldName}} can be 1-50 characters long. Can contain unicode letters and spaces only!',
    u1to100: '{{fieldName}} can be up to 100 characters long, including at least 1 letter.',
    limit1Day: '24h limit must be less than 7d and 30d current(pending) limits',
    limit7Days: '7d limit must be between 24h and 30d current(pending) limits',
    limit30Days: '30d limit must be greater than 24h and 7d current(pending) limits',
    digit: '{{fieldName}} should be number.',
    limitedMinToMax: '{{fieldName}} can be {{min}}-{{max}} characters long, can contain latin letters and numbers only!',
    address: '{{fieldName}} can be 2-100 characters long, can contain unicode letters, numbers, spaces and the following special characters - . , | /',
    lN3to50: '{{fieldName}} should be 3-50 characters long. Can contain latin letters, numbers and underscores only, including at least 1 letter.',
    uFL1to50: '{{fieldName}} can be up to 50 characters long, can contain unicode letters only, starting with uppercase letter.',
    tag: '{{fieldName}} can be up to 50 characters long, including unicode letters, numbers, underscores, dashes and spaces only, cannot start with spaces, underscores and dashes.',
    uL1to50: '{{fieldName}} can be up to 50 characters long. Can contain unicode letters, numbers, underscores and spaces only, including at least 1 letter.',
    fileName: '{{fieldName}} can be up to 200 characters long. Cannot contain " < > ? | : \\\\ / * symbols.',
    correspondToPeriod: '{{fieldName}}  must correspond to period configuration.',
    correspondToOperationDate: '{{fieldName}}  must correspond to operation date.',
    limited1to50FirstLetter: '{{fieldName}} can be up to 50 characters long, can contain unicode letters, numbers, underscores and spaces only.',
    uMinToMaxFNotS: '{{fieldName}} can be {{min}}-{{max}} characters long. Can contain unicode letters, numbers, underscores and spaces only and cannot start with space',
    notOnlySpaces1toMax: '{{fieldName}} should be up to {{max}} characters long, and cannot be only spaces',
    maxLimit: '{{fieldName}} must be up to {{max}} characters long',
    invalidUrl: 'Invalid URL!',
    uLSpace: '{{fieldName}} can be {{min}}-{{max}} characters long. Can contain letters and spaces only and cannot start with space.',
    lNSpecialCharacters: '{{fieldName}} can contain 2-10 characters including Latin letters, numbers, spaces and special characters -/',
    canNotBeValue: '{{fieldName}} cannot be {{value}}',
    mustBeLowerOrEqual: '{{fieldMin}} must be lower than or equal to {{fieldMax}}',
    mustBeGreaterOrEqual: '{{fieldMax}} must be greater than or equal to {{fieldMin}}',
    generalMobileNumber: '{{fieldName}} must start with + or with 00 and then contain {{min}}-{{max}} digits',
    alreadyExist: '{{fieldName}} with this name already exists',
    generalPercent: '{{fieldName}} must greater than {{min}} and lower than {{max}}',
    exportMaxCount: '{{fieldName}} export can be maximum {{count}}',
    minMaxStep: '{{fieldName}} must be between {{min}} - {{max}}, and the step is {{step}}',
    minMaxStepSuggestions: '{{fieldName}} must be between {{min}} - {{max}}, and the step is {{step}}, as a suggestion choose provided amounts: {{amount1}}, {{amount2}}',
    uLSMinToMaxFNotS:
      '{{fieldName}} can be {{min}}-{{max}} characters long. Can contain Unicode letters, numbers, underscores and spaces only and cannot start with space and should contain at least 1 letter',
    brandName: 'Name should be 3-50 characters long. Can contain latin letters, numbers and spaces only.',
    notOnlySpaces1toMaxSeparateByComma: '{{fieldName}} should be up to {{max}} characters long, not include only spaces, and should be separated by a comma',
    groupNameAlreadyExist: 'The group name is already used for the active group. You can not have 2 groups with the same name in active status.',
    phoneNumber:
      'The phone number provided does not match the required pattern. Please ensure the number starts with an optional "+" symbol, followed by a non-zero digit (1-9), and has 7 to 14 digits (0-9) after it.',
    lLNLimitMinToMax: '{{fieldName}} can be {{min}}-{{max}} characters long. Can contain Latin lowercase letters, numbers, underscores, hyphens and cannot start with space',
    passwordDontMatch: 'Passwords do not match!',
    password8to40NUL: 'Password can be 8-40 characters long, including at least a number and uppercase letter.',
    nameAlreadyExist: '{{fieldName}} with this name already exists',
    limit1Year: '1y limit must be greater than 24h, 7d and 30d current(pending) limits',
    isNotValid: 'The input is not a valid {{fieldName}}!',
    fixed32: '{{fieldName}} is not valid, should be 32 characters long.',
    alLeastTwoMachines: 'At least two machines should be selected',
    invalidDateMatching: 'Date matching is not allowed, please change it',
    mustUsedOnlyOnce: 'The selected dates can be used only once',
    uniqStartEndNumbersInRange: 'The start and end numbers of the range cannot be identical',
    lN3to100: '{{fieldName}} should be 3-100 characters long. Can contain latin letters, numbers and underscores only, including at least 1 letter.',
    notOnlySpaces3toMax: '{{fieldName}} should be up to {{max}} characters long, and cannot be only spaces',
    uMinToMaxFNotSENotS: '{{fieldName}} can be {{min}}-{{max}} characters long. Can contain unicode letters, numbers, dashes and spaces only and cannot start and end with space',
    maxSelectionsCount: 'Max count of selections are {{count}}',
    splitAmount: 'Amount cannot be split, please reselect',
    powerMustBeLower: '{{field1}} ^ {{field2}} must be lower than or equal to {{field3}}',
    operatorLimit1Day: '24 hours limit must be less than 7 days, 1 month and 1 year limits',
    operatorLimit7Days: '7 days limit must be between 24 hours, 1 month and 1 year limits',
    operatorLimit30Days: '1 month limit must be greater than 24 hours and 7 days but less than 1 year limits',
    operatorLimitAnnual: '1 year limit must be greater than 24 hours, 7 days and 1 month limits',
    uLNScMinToMaxFNotSMin1L:
      '{{fieldName}} can be {{min}}-{{max}} characters long. Can contain unicode letters, numbers, spaces, hyphens, underscores, and special characters only and cannot start with space and should contain at least 1 letter',
  },
  es: {
    requiredField: ' ¡{{fieldName}} no puede estar vacío!',
    domain: '¡Nombre de dominio no válido!',
    invalidNumber: '¡Número no válido!',
    invalidIntegerNumber: '¡Número entero no válido!',
    minMax: '{{fieldName}} debe tener entre {{min}} y {{max}}',
    lessThan: '{{fieldName}} no puede ser menor de {{min}}',
    mustBeLowerThan: '{{fieldName}} debe ser inferior a {{max}}',
    mustBeGreaterThan: '{{fieldName}} debe ser mayor que {{min}}',
    utoMax: '{{fieldName}} puede tener hasta {{max}} caracteres, incluyendo al menos una letra',
    scriptName: 'El nombre del script puede tener entre 2 y 55 caracteres. Puede contener letras latinas, números y los siguientes caracteres especiales: ), (, _, -',
    alLeastOneScript: '¡Se debe rellenar al menos un script!',
    limited1to50: '{{fieldName}} debe tener entre 1 y 50 caracteres',
    rateMax: 'La cuota puede tener hasta {{max}} caracteres y contener tan solo números y un punto. El número máximo permitido de dígitos después del punto es 8',
    dateLaterThanNow: '{{fieldName}} debe ser posterior al momento presente',
    dateLaterThanDate: '{{fieldName}} debe ser posterior a la fecha de notificación',
    dateGreaterThanNow: '{{fieldName}} debe ser mayor que el momento presente',
    toNotifyLessThanEffective: 'La fecha de notificación debe ser inferior a la fecha de entrada en vigor',
    minBetAmount: 'El importe de apuesta mín. debe ser inferior o igual al importe del bono',
    ahead1Hour: 'La hora de finalización debe ser al menos 1 hora antes de la hora de inicio',
    ahead1Day: 'La hora de finalización debe ser al menos 1 hora antes de la fecha de inicio',
    ahead7Day: 'La hora de finalización debe ser al menos 7 días antes de la fecha de inicio',
    ahead1Moth: 'La hora de finalización debe ser al menos 1 mes antes de la fecha de inicio',
    u1to50: '{{fieldName}} debe tener entre 1 y 50 caracteres, contener tan solo letras unicode, números, espacios y barras bajas y no puede empezar con espacios y barras bajas',
    limited2to50: '{{fieldName}} puede tener entre 2 y 50 caracteres y solo puede contener letras latinas y números',
    decimalNumber1toMaxWithPrecision:
      '{{fieldName}} puede tener hasta {{max}} caracteres y puede contener tan solo números y un punto. El número máximo permitido de dígitos después del punto es {{precision}}',
    url: '{{fieldName}} no es una URL válida y puede tener hasta 300 caracteres → longitud máxima superada',
    uLS1to50: '{{fieldName}} puede tener entre 1 y 50 caracteres. ¡Solo puede contener letras unicode y espacios!',
    u1to100: '{{fieldName}} puede tener hasta 100 caracteres, incluyendo al menos una letra',
    limit1Day: 'El límite de 24 horas debe ser inferior a los límites actuales (pendientes) de 7 y 30 días',
    limit7Days: 'El límite de 7 días debe entre los límites actuales (pendientes) de 24 horas y 30 días',
    limit30Days: 'El límite de 30 días debe ser mayor que los límites actuales (pendientes) de 24 horas y 7 días',
    digit: '{{fieldName}} debe ser un número.',
    limitedMinToMax: '¡{{fieldName}} puede tener entre {{min}} y {{max}} caracteres y tan solo contener letras latinas y números!',
    address: '¡{{fieldName}} puede tener entre 2 y 100 caracteres y contener letras unicode, números, espacios y los siguientes caracteres especiales - . , | /',
    lN3to50: '{{fieldName}} debe tener entre 3 y 50 caracteres. Puede contener tan solo letras latinas, números y barras bajas, incluyendo al menos 1 letra',
    uFL1to50: '{{fieldName}} puede tener hasta 50 caracteres y puede contener tan solo letras unicode, empezando por letra mayúscula.',
    uL1to50: '{{fieldName}} puede tener hasta 50 caracteres. Puede contener tan solo letras unicode, números, barras bajas y espacios, incluyendo al menos 1 letra.',
    fileName: '{{fieldName}} puede tener hasta 200 caracteres. No puede contener los símbolos " < > ? | : \\ / *.',
    correspondToPeriod: '{{fieldName}} debe corresponder a la configuración del periodo.',
    correspondToOperationDate: '{{fieldName}} debe corresponder a la fecha de actividad.',
    limited1to50FirstLetter: '{{fieldName}} puede tener hasta 50 caracteres y contener tan solo letras unicode, números, barras bajas y espacios.',
    uMinToMaxFNotS: '{{fieldName}} puede tener entre {{min}} y {{max}} caracteres. Solo puede contener letras unicode, números, barras bajas y espacios y no puede comenzar con espacio',
    notOnlySpaces1toMax: '{{fieldName}} debe tener un máximo de {{max}} caracteres, y no pueden ser solo espacios',
    maxLimit: '{{fieldName}} debe tener hasta {{max}} caracteres',
    invalidUrl: '¡URL no válida!',
    lNSpecialCharacters: '{{fieldName}} puede tener de 2 a 10 caracteres entre letras latinas, números, espacios y caracteres especiales -/',
    canNotBeValue: ' {{fieldName}} no puede estar {{value}}',
    uLSpace: '{{fieldName}} puede tener {{min}}-{{max}} caracteres. Sólo puede contener letras y espacios y no puede comenzar con un espacio.',
    mustBeLowerOrEqual: '{{fieldMin}} debe ser inferior o igual a {{fieldMax}}',
    mustBeGreaterOrEqual: '{{fieldMax}} debe ser mayor o igual que {{fieldMin}}',
    generalMobileNumber: '{{fieldName}} debe comenzar con + o con 00 y luego contener los dígitos {{min}}-{{max}}',
    alreadyExist: '{{fieldName}} con este nombre ya existe',
    generalPercent: '{{fieldName}} debe ser mayor que {{min}} y menor que {{max}}',
    exportMaxCount: 'La exportación de {{fieldName}} puede ser como máximo {{count}}',
    greaterThan: '{{fieldName}} no puede ser mayor que {{max}}',
    tag: '{{fieldName}} puede tener hasta 50 caracteres, incluyendo letras unicode, números, guiones bajos, guiones y espacios solamente, no puede empezar con espacios, guiones bajos y guiones.',
    minMaxStep: '{{fieldName}} debe estar entre {{min}} - {{max}}, y el paso es {{step}}.',
    minMaxStepSuggestions: '{{fieldName}} debe estar entre {{min}} - {{max}}, y el paso es {{step}}, como sugerencia elija las cantidades proporcionadas: {{amount1}}, {{amount2}}',
    uLSMinToMaxFNotS:
      '{{fieldName}} puede tener {{min}}-{{max}} caracteres. Sólo puede contener letras Unicode, números, guiones bajos y espacios, no puede empezar por espacio y debe contener al menos 1 letra.',
  },
  tr: {
    requiredField: '{{fieldName}} boş olamaz!',
    domain: 'Geçersiz Domain Adı!',
    invalidNumber: 'Geçersiz sayı!',
    invalidIntegerNumber: 'Geçersiz tam sayı!',
    minMax: '{{fieldName}}, {{min}} - {{max}} aralığında olmalıdır',
    lessThan: '{{fieldName}}, {{min}} değerinden küçük olamaz',
    mustBeLowerThan: '{{fieldName}}, {{max}} değerinden küçük olmalıdır',
    mustBeGreaterThan: '{{fieldName}}, {{min}} değerinden büyük olmalıdır',
    utoMax: '{{fieldName}} en az 1 harf dahil olacak şekilde en fazla {{max}} karakter uzunluğunda olabilir',
    scriptName: 'Komut Dosyası Adı 2-55 karakter uzunluğunda olmalıdır. Latin harfleri, sayılar ve şu özel karakterleri içerebilir: ), (, _, -',
    alLeastOneScript: 'En az bir komut dosyası doldurulmalıdır!',
    limited1to50: '{{fieldName}} 1-50 karakter uzunluğunda olmalıdır',
    rateMax: 'Oran, {{max}} karakter uzunluğunda olabilir ve yalnızca sayılar ve nokta içerebilir. Noktadan sonra izin verilen maksimum hane sayısı 8’dir',
    dateLaterThanNow: '{{fieldName}} şu andan daha sonra olmalıdır',
    dateLaterThanDate: '{{fieldName}} bildirim tarihinden daha sonra olmalıdır',
    dateGreaterThanNow: '{{fieldName}} şu andan daha büyük olmalıdır',
    toNotifyLessThanEffective: 'Bildirim tarihi geçerlilik tarihinden önce olmalıdır',
    minBetAmount: 'Minimum Bahis Miktarı, bonus miktarına denk ya da daha düşük olmalıdır',
    ahead1Hour: 'Bitiş saati, başlangıç saatinden en az 1 saat sonra olmalıdır',
    ahead1Day: 'Bitiş tarihi, başlangıç tarihinden en az 1 gün sonra olmalıdır',
    ahead7Day: 'Bitiş tarihi, başlangıç tarihinden en az 7 gün sonra olmalıdır',
    ahead1Moth: 'Bitiş tarihi, başlangıç tarihinden en az 1 ay sonra olmalıdır',
    u1to50: '{{fieldName}} 1-50 karakter uzunluğunda olmalıdır, sadece unicode harfler, sayılar, boşluklar, alt çizgiler içerebilir, boşluk ve alt çizgi ile başlayamaz',
    limited2to50: '{{fieldName}} 2-50 karakter uzunluğunda olabilir, en az 1 harf olmak üzere yalnızca latin harfleri, sayılar ve alt çizgiler içerebilir',
    decimalNumber1toMaxWithPrecision:
      '{{fieldName}} en fazla {{max}} karakter uzunluğunda olabilir, yalnızca sayılar ve nokta içerebilir. Noktadan sonra izin verilen maksimum hane sayısı {{precision}}’dir',
    url: '{{fieldName}} geçerli bir URL değildir, 300 karakter uzunluğunda olabilir → maksimum uzunluk geçildi',
    uLS1to50: '{{fieldName}} 1-50 karakter uzunluğunda olabilir. Yalnızca unicode harfleri ve boşlukları içerebilir!',
    u1to100: '{{fieldName}} en az 1 harf dahil olacak şekilde en fazla 100 karakter uzunluğunda olabilir.',
    limit1Day: '24 saatlik limit, 7 günlük ve 30 günlük mevcut (bekleyen) limitlerden az olmalıdır',
    limit7Days: '7 günlük limit, 24 saatlik ve 30 günlük mevcut (bekleyen) limitleri arasında olmalıdır',
    limit30Days: '30 günlük limit, 24 saatlik ve 7 günlük mevcut (bekleyen) limitlerden büyük olmalıdır',
    digit: '{{fieldName}} sayı olmalıdır.',
    limitedMinToMax: '{{fieldName}},{{min}}-{{max}} karakter uzunluğunda olabilir, yalnızca latin harfler ve sayılar içerebilir!',
    address: '{{fieldName}} 2-100 karakter uzunluğunda olabilir. Yalnızca unicode harfler, sayılar, boşluk ve şu karakterleri içerebilir: - . , | /',
    lN3to50: '{{fieldName}} 3-50 karakter uzunluğunda olmalıdır. En az 1 harf dahil olmak üzere yalnızca latin harfleri, sayılar ve alt çizgiler içerebilir',
    uFL1to50: '{{fieldName}} en fazla 50 karakter uzunluğunda olabilir, yalnızca unicode harfler içerebilir, büyük harfle başlar.',
    uL1to50: '{{fieldName}} en fazla 50 karakter uzunluğunda olabilir. En az 1 harf dahil olmak üzere yalnızca unicode harfler, sayılar, alt çizgiler ve boşluk içerebilir.',
    fileName: '{{fieldName}} en fazla 200 karakter uzunluğunda olabilir. " < > ? | : \\\\ / * sembollerini içeremez.',
    correspondToPeriod: '{{fieldName}} periyot yapılandırmasına karşılık gelmelidir.',
    correspondToOperationDate: '{{fieldName}} operasyon tarihine karşılık gelmelidir.',
    limited1to50FirstLetter: '{{fieldName}} en fazla 50 karakter uzunluğunda olabilir, yalnızca unicode harfleri, sayılar, alt çizgiler ve boşluk içerebilir.',
    uMinToMaxFNotS: '{{fieldName}},{{min}}-{{max}} karakter uzunluğunda olabilir. Yalnızca unicode harfleri, sayılar, alt çizgiler ve boşluk içerebilir ve boşlukla başlayamaz',
    notOnlySpaces1toMax: '{{fieldName}} en fazla {{max}} karakter uzunluğunda olabilir ve sadece boşluktan oluşamaz',
    maxLimit: '{{fieldName}} en fazla {{max}} karakter uzunluğunda olmalıdır',
    invalidUrl: 'Geçersiz URL!',
    lNSpecialCharacters: '{{fieldName}} Latin harfleri, sayılar, boşluk ve -/ özel karakterler dahil olmak üzere 2-10 karakter içerebilir',
    canNotBeValue: '{{fieldName}}, {{value}} olamaz',
    uLSpace: '{{fieldName}} , {{min}}-{{max}} karakter uzunluğunda olabilir. Yalnızca harfler ve boşluk içerebilir ve boşlukla başlayamaz.',
    mustBeLowerOrEqual: '{{fieldMin}}, {{fieldMax}} değerinden küçük veya eşit olmalıdır.',
    mustBeGreaterOrEqual: '{{fieldMax}}, {{fieldMin}} değerinden büyük veya eşit olmalıdır.',
    generalMobileNumber: '{{fieldName}} + veya 00 ile başlamalı ve ardından {{min}}-{{max}} rakamlarını içermelidir',
    alreadyExist: 'Bu isimde {{fieldName}} zaten mevcut',
    generalPercent: '{{fieldName}}, {{min}} değerinden büyük ve {{max}} değerinden küçük olmalıdır.',
    exportMaxCount: '{{fieldName}} dışa aktarım  maksimum {{count}} olabilir',
    greaterThan: '{{fieldName}}, {{max}} değerinden büyük olamaz',
    tag: '{{fieldName}} en fazla 50 karakter uzunluğunda olabilir ve yalnızca unicode harfler, sayılar, alt çizgiler, tire ve boşluklar içerebilir; boşluk, alt çizgi ve tire ile başlayamaz.',
    minMaxStep: "{{fieldName}}, {{min}} - {{max}} arasında olmalıdır ve kademe {{step}}'tir.",
    minMaxStepSuggestions: "{{fieldName}}, {{min}} - {{max}} arasında olmalıdır ve kademe {{step}}'tir, öneri olarak sunulan miktarları seçin: {{amount1}}, {{amount2}}",
    uLSMinToMaxFNotS:
      '{{fieldName}} {{min}}-{{max}} karakter uzunluğunda olabilir. Yalnızca Unicode harfler, sayılar, alt çizgiler ve boşluklar içerebilir ve boşlukla başlayamaz ve en az 1 harf içermelidir',
  },
  ru: {
    requiredField: '‎{{fieldName}} не может быть пустым!',
    domain: 'Недопустимое имя домена!',
    invalidNumber: 'Недопустимый номер!',
    invalidIntegerNumber: 'Недопустимое целое число!',
    minMax: '‎{{fieldName}} должно быть в диапазоне {{min}} - {{max}}',
    lessThan: '‎{{fieldName}} не может быть меньше {{min}}',
    mustBeLowerThan: '‎{{fieldName}} должно быть ниже {{max}}',
    mustBeGreaterThan: '‎{{fieldName}} должно быть больше {{min}}',
    utoMax: '‎{{fieldName}} может иметь длину до {{max}} символов, включая минимум 1 букву',
    scriptName: 'Имя скрипта должно иметь длину в 2–55 символов, может содержать только латинские буквы, цифры и следующие специальные знаки: ), (, _, - ',
    alLeastOneScript: 'По меньшей мере один скрипт должен быть заполнен!',
    limited1to50: '‎{{fieldName}} должен содержать 1-50 символов',
    rateMax: 'Ставка может иметь длину до {{max}} символов, может содержать только цифры и точку. После точки может быть не больше 8 символов',
    dateLaterThanNow: '‎{{fieldName}} должно быть позже текущего времени',
    dateLaterThanDate: '‎{{fieldName}} должно быть позднее даты уведомления',
    dateGreaterThanNow: '‎{{fieldName}} должно быть позже текущего времени',
    toNotifyLessThanEffective: 'Дата уведомления должна быть раньше даты вступления в силу',
    minBetAmount: 'Минимальный размер ставки должен быть меньше или равен размеру бонуса',
    ahead1Hour: 'Время завершения должно по меньшей мере на 1 час опережать время начала',
    ahead1Day: 'Время завершения должно по меньшей мере на 1 день опережать время начала',
    ahead7Day: 'Время завершения должно по меньшей мере на 7 дней опережать время начала',
    ahead1Moth: 'Время завершения должно по меньшей мере на 1 месяц опережать время начала',
    u1to50: '‎{{fieldName}} должно иметь длину в 1-50 символов, может содержать только буквы Юникода, цифры, пробелы и знаки подчеркивания, не может начинаться с пробелов и знаков подчеркивания',
    limited2to50: '‎{{fieldName}} может иметь длину в 2-50 символов, может содержать только латинские буквы, цифры и знаки подчеркивания',
    decimalNumber1toMaxWithPrecision: '‎{{fieldName}} может иметь длину до {{max}} символов, может содержать только цифры и точку. После точки допустимо не более {{precision}} символов',
    url: '‎{{fieldName}} не является допустимым URL, может иметь длину до 300 символов → максимально допустимая длина превышена',
    uLS1to50: '‎{{fieldName}} может иметь длину в 1-50 символов. Может содержать только буквы Юникода и пробелы!',
    u1to100: '‎{{fieldName}} может иметь длину до 100 символов, включая минимум 1 букву.',
    limit1Day: '‎24-часовой лимит должен быть меньше текущих (отложенных) лимитов на 7 дней и 30 дней',
    limit7Days: '‎7-дневный лимит должен быть в диапазоне между текущими (отложенными) лимитами на 24 часа и 30 дней',
    limit30Days: '‎30-дневный лимит должен быть больше текущих (отложенных) лимитов на 24 часа и 7 дней',
    digit: '‎{{fieldName}} должно быть цифрой.',
    limitedMinToMax: '‎{{fieldName}} может иметь длину в {{min}}-{{max}} символов, может содержать только буквы латинского алфивита и цифры.',
    address: '‎{{fieldName}} может иметь длину в 2-100 символов, может содержать только буквы Юникода, цифры, пробелы и следующие специальные знаки - . , | /',
    lN3to50: '‎{{fieldName}} должен иметь длину в 3-50 символов. Может содержать только буквы, цифры и знаки подчеркивания, включя минимум 1 букву.',
    uFL1to50: '‎{{fieldName}} может иметь длину до 50 символов, может содержать только буквы Юникода и начинаться со строчной буквы.',
    uL1to50: '‎{{fieldName}} может иметь длину до 50 символов, может содержать только буквы Юникода, цифры, знаки подчеркивания и пробелы, включая минимум 1 букву.',
    fileName: '‎{{fieldName}} может иметь длину до 200 символов. Не может содержать символы " < > ? | : \\\\ / *.',
    correspondToPeriod: '‎{{fieldName}} должно соответствовать настройке периода.',
    correspondToOperationDate: '‎{{fieldName}} должно соответствовать дате операции.',
    limited1to50FirstLetter: '‎{{fieldName}} может иметь длину до 50 символов, может содержать только буквы Юникода, цифры, знаки подчеркивания и пробелы.',
    uMinToMaxFNotS: '‎{{fieldName}} может иметь длину в {{min}}-{{max}} символов. Может содержать только буквы Юникода, цифры, знаки подчеркивания и пробелы и не может начинаться с пробела',
    notOnlySpaces1toMax: '‎{{fieldName}} должен иметь длину до {{max}} символов, не может содержать только пробелы',
    maxLimit: '‎{{fieldName}} должен иметь длину до {{max}} символов',
    invalidUrl: 'Недопустимый URL!',
    lNSpecialCharacters: '‎{{fieldName}} может содержать от 2 до 10 символов, включая буквы латинского алфавита, цифры, пробелы и специальные знаки -/',
    canNotBeValue: '‎{{fieldName}} не может составлять {{value}} ',
    uLSpace: '‎{{fieldName}} имя может содержать {{min}}-{{max}} символов, включая только буквы и пробелы.',
    mustBeLowerOrEqual: '{{fieldMin}} должно быть меньше или равно {{fieldMax}}',
    mustBeGreaterOrEqual: '{{fieldMax}} должно быть больше или равно {{fieldMin}}',
    generalMobileNumber: '{{fieldName}} должно начинаться с + или с 00, а затем содержать цифры {{min}}-{{max}}',
    alreadyExist: '{{fieldName}} с таким названием уже существует',
    generalPercent: '{{fieldName}} должно быть больше {{min}} и меньше {{max}}',
    exportMaxCount: 'Экспорт {{fieldName}} может быть максимум {{count}}',
    tag: '‎{{fieldName}} может иметь длину до 50 символов, включая только буквы Юникода, цифры, знаки подчеркивания и тире.',
    greaterThan: '{{fieldName}} не может быть больше {{max}} ',
    minMaxStep: '{{fieldName}} должно быть в диапазоне от {{min}} до {{max}}, а шаг равен {{step}}',
    minMaxStepSuggestions: '{{fieldName}} должно быть в диапазоне {{min}} - {{max}}, а шаг - {{step}}; в качестве предложения выберите предоставленные суммы: {{amount1}}, {{amount2}}',
    uLSMinToMaxFNotS:
      '{{fieldName}} может иметь длину от {{min}} до {{max}} символов. Может содержать только буквы Юникода, цифры, знаки подчеркивания и пробелы, не может начинаться с пробела и должно содержать как минимум 1 букву',
  },
  pt: {
    requiredField: '{{fieldName}} não pode estar vazio!',
    domain: 'Nome de domínio inválido!',
    invalidNumber: 'Número inválido!',
    invalidIntegerNumber: 'Número inteiro inválido!',
    minMax: '{{fieldName}} tem de ter entre {{min}} - {{max}}',
    lessThan: '{{fieldName}} não pode ser inferior a {{min}}',
    mustBeLowerThan: '{{fieldName}} tem de ser inferior a {{max}}',
    mustBeGreaterThan: '{{fieldName}} tem de ser maior que {{min}}',
    utoMax: '{{fieldName}} pode ter até {{max}} caracteres, incluindo pelo menos 1 letra',
    scriptName: 'O Nome do Script precisa ter de 2 a 55 caracteres. Pode conter letras do alfabeto latino, números e os seguintes caracteres especiais: ), (, _, -',
    alLeastOneScript: 'Pelo menos um script precisa de ser preenchido!',
    limited1to50: '{{fieldName}} precisa ter entre 1 e 50 caracteres',
    rateMax: 'A taxa pode ter até {{max}} caracteres de comprimento, pode conter apenas números e um ponto. A contagem máxima permitida de dígitos após o ponto é de 8',
    dateLaterThanNow: '{{fieldName}} tem de ser posterior à hora atual',
    dateLaterThanDate: '{{fieldName}} tem de ser posterior à data da notificação',
    dateGreaterThanNow: '{{fieldName}} tem de ser superior à hora atual',
    toNotifyLessThanEffective: 'A data da notificação tem de ser anterior à data de vigência',
    ahead1Hour: 'A hora de término tem de ser, pelo menos, 1 hora após a data de início',
    ahead1Day: 'A data de término tem de ser de pelo menos 1 dia antes da data de início',
    ahead7Day: 'A data de término tem de ser de pelo menos 7 dias antes da data de início',
    ahead1Moth: 'A data de término tem de ser de pelo menos 1 mês antes da data de início',
    u1to50: '{{fieldName}} tem de ter de 1 a 50 caracteres, pode conter letras unicode, números, espaços, underscores, mas não pode começar com espaços e underscores',
    limited2to50: '{{fieldName}} pode ter de 2 a 50 caracteres, pode conter apenas letras do alfabeto latino, números e underscores, incluindo pelo menos 1 letra',
    decimalNumber1toMaxWithPrecision: '{{fieldName}} pode ter até {{max}} caracteres, pode conter números e apenas um ponto. A contagem máxima permitida de dígitos após o ponto é {{precision}}',
    url: '{{fieldName}} não é uma URL válida, pode ter até 300 caracteres de comprimento → comprimento máximo excedido',
    uLS1to50: '{{fieldName}} pode ter de 1 a 50 caracteres. Pode conter apenas letras unicode e espaços!',
    u1to100: '{{fieldName}} pode ter até 100 caracteres, incluindo pelo menos 1 letra.',
    limit1Day: 'O limite de 24h tem de ser inferior a 7d e 30d do limite atual (pendente)',
    limit7Days: 'O limite 7d tem de estar entre 24h e 30d dos limites atuais (pendente)',
    limit30Days: 'O limite 30d tem de ser superior dos atuais 24h e 7d limites (pendente)',
    digit: '{{fieldName}} tem de ser um número.',
    limitedMinToMax: '{{fieldName}} pode ter de {{min}} a {{max}} caracteres, pode conter apenas letras do alfabeto latino e números!',
    address: '{{fieldName}} pode ter de 2 a 100 caracteres, pode conter letras unicode, números, espaços e os seguintes caracteres especiais - . , | /',
    lN3to50: '{{fieldName}} deve ter de 3 a 50 caracteres. Pode conter apenas letras do alfabeto latino, números e underscores, incluindo pelo menos 1 letra.',
    uFL1to50: '{{fieldName}} pode ter até 50 caracteres, pode conter apenas letras unicode, começando com letra maiúscula.',
    uL1to50: '{{fieldName}} pode ter até 50 caracteres. Pode conter letras unicode, números, underscorese espaços apenas, incluindo pelo menos 1 letra.',
    fileName: '{{fieldName}} pode ter até 200 caracteres. Não pode conter os símbolos " < > ? | : \\\\ / *.',
    correspondToPeriod: '{{fieldName}} tem de corresponder à configuração do período.',
    correspondToOperationDate: '{{fieldName}} tem de corresponder à data de operação.',
    limited1to50FirstLetter: '{{fieldName}} pode ter até 50 caracteres, pode conter letras unicode, números, underscorese espaços apenas.',
    uMinToMaxFNotS: '{{fieldName}} pode ter de {{min}} a {{max}} caracteres. Pode conter letras unicode, números, underscorese espaços apenas e não pode começar com espaço',
    notOnlySpaces1toMax: '{{fieldName}} tem de ter até {{max}} caracteres de comprimento e não podem ser apenas espaços',
    maxLimit: '{{fieldName}} tem de ser ter até {{max}} caracteres de comprimento',
    invalidUrl: 'URL inválido!',
    lNSpecialCharacters: '{{fieldName}} pode conter de 2 a 10 caracteres, incluindo letras do alfabeto latino, números, espaços e caracteres especiais -/',
    canNotBeValue: '{{fieldName}} não pode ser {{value}}',
    mustBeLowerOrEqual: '{{fieldMin}} tem de ser inferior ou igual a {{fieldMax}}',
    mustBeGreaterOrEqual: '{{fieldMax}} tem de ser maior ou igual a {{fieldMin}}',
    uLSpace: '{{fieldName}} pode ter {{min}}-{{max}} caracteres. Só pode conter letras e espaços e não pode iniciar com um espaço.',
    generalMobileNumber: '{{fieldName}} tem de iniciar com + ou com 00 e depois conter {{min}}-{{max}} dígitos',
    alreadyExist: '{{fieldName}} com este nome já existe',
    generalPercent: '{{fieldName}} tem de ser superior a {{min}} e inferior a {{max}}',
    exportMaxCount: 'A exportação de {{fieldName}} pode atingir no máximo {{count}}',
    greaterThan: '{{fieldName}} não pode ser maior que {{max}}',
    tag: '{{fieldName}} pode ter até 50 caracteres, incluindo letras unicode, números, sublinhados, hífens e espaços apenas, não pode começar com espaços, sublinhados e hífens.',
    minMaxStep: '{{fieldName}} deve estar entre {{min}} - {{max}}, e a etapa é {{step}}',
    minMaxStepSuggestions: '{{fieldName}} deve estar entre {{min}} - {{max}}, e o passo é {{step}}, como sugestão, escolha os valores fornecidos: {{amount1}}, {{amount2}}',
    uLSMinToMaxFNotS:
      '{{fieldName}} pode ter {{min}}-{{max}} caracteres. Pode conter apenas letras Unicode, números, sublinhados e espaços e não pode começar com espaço e deve conter pelo menos 1 letra',
  },
  zh: {
    requiredField: '{{fieldName}} 不能为空！',
    domain: '无效域名名称！',
    invalidNumber: '无效号码！',
    invalidIntegerNumber: '无效整数！',
    minMax: '{{fieldName}} 必须介于 {{min}} - {{max}} 之间',
    lessThan: '{{fieldName}} 不得低于 {{min}}',
    mustBeLowerThan: '{{fieldName}} 必须低于 {{max}}',
    mustBeGreaterThan: '{{fieldName}} 必须大于 {{min}}',
    utoMax: '{{fieldName}} 长度最多包含{{max}}个字符，其中包含至少1个字母。',
    scriptName: '脚本名称长度应介于2-55个字符。可以包含拉丁字母、数字和特殊字符: ), (, _, -',
    alLeastOneScript: '至少应该填写一个脚本！',
    limited1to50: '{{fieldName}} 长度应介于1-50个字符。',
    rateMax: '比率长度最高可含 {{max}} 个字符，只能包含数字和一个小数点。小数点后最多允许有8位数',
    dateLaterThanNow: '{{fieldName}} 必须晚于现在',
    dateLaterThanDate: '{{fieldName}} 必须晚于通知日期',
    dateGreaterThanNow: '{{fieldName}} 必须大于现在',
    toNotifyLessThanEffective: '通知日期必须早于生效日期',
    ahead1Hour: '结束时间必须至少早于开始时间1个小时',
    ahead1Day: '结束日期必须至少早于开始日期1天。',
    ahead7Day: '结束日期必须至少早于开始日期7天。',
    ahead1Moth: '结束日期必须至少早于开始日期1个月',
    u1to50: '{{fieldName}} 长度应介于1-50个字符，只能包含unicode字母、数字、空格、下划线，不得以空格和下划线开头。',
    limited2to50: '{{fieldName}} 长度介于2-50个字符，只能包含拉丁字母、数字和下划线，其中至少包含1个字母。',
    url: '{{fieldName}} 不是一个有效的URL，长度最多包含300个字符→超过了最大长度',
    uLS1to50: '{{fieldName}} 长度介于1-50个字符，只能包含unicode字母和空格！',
    u1to100: '{{fieldName}} 长度最多包含100个字符，其中至少包含1个字母。',
    limit1Day: '‎24小时的限制必须小于7天和30天的当前（待定）限制',
    limit7Days: '‎7天的限制必须小于24小时和30天的当前（待定）限制',
    limit30Days: '‎30天的限制必须大于24小时和7天的当前（待定）限制',
    digit: '{{fieldName}} 应由数字组成。',
    limitedMinToMax: '{{fieldName}} 长度介于{{min}}-{{max}}个字符，只能包含拉丁字母和数字！',
    address: '{{fieldName}} 长度介于2-100个字符，可以包含unicode字母、数字、空格和特殊字符- . , | /。',
    lN3to50: '{{fieldName}} 长度应介于3-50个字符。只能包含拉丁字母、数字和下划线，其中至少包含1个字母。',
    uFL1to50: '{{fieldName}} 长度最多包含50个字符，只能包含unicode字母，首字母需大写。',
    uL1to50: '{{fieldName}} 长度最多包含50个字符，只能包含unicode字母、数字、下划线和空格，其中必须包含至少1个字母。',
    fileName: '{{fieldName}} 长度最多包含200个字符，不可包含以下符号：" < > ? | : \\\\ / *。',
    correspondToPeriod: '{{fieldName}} 必须与周期配置一致。',
    correspondToOperationDate: '{{fieldName}} 必须与操作日期一致。',
    limited1to50FirstLetter: '{{fieldName}} 长度最多包含50个字符，只能包含unicode字母、数字、下划线和空格。',
    uMinToMaxFNotS: '{{fieldName}} 长度介于{{min}}-{{max}}个字符，只能包含unicode字母、数字、下划线和空格，且文件名不能以空格开头。',
    notOnlySpaces1toMax: '{{fieldName}} 长度最长应不超过 {{max}} 个字符，并且不得只由空格组成。',
    maxLimit: '{{fieldName}} 长度最多包含 {{max}} 个字符。',
    invalidUrl: '无效URL！',
    lNSpecialCharacters: '{{fieldName}} 长度介于2-10个字符，其中包含拉丁字母、数字、空格和特殊符号“-”和“/”。',
    canNotBeValue: '{{fieldName}} 不能是 {{value}}',
    mustBeLowerOrEqual: '{{fieldMin}} 必须小于或等于 {{fieldMax}}',
    mustBeGreaterOrEqual: '{{fieldMax}} 必须大于或等于 {{fieldMin}}',
    uLSpace: '{{fieldName}}的长度可以是{{min}}-{{max}}字符。只能包含字母和空格，不能以空格开头。',
    tag: '{{fieldName}} 长度最多包含50个字符，其中只能包含unicode字母、数字、下划线和破折号。',
    generalMobileNumber: '{{fieldName}}必须以+或00开头，然后包含{{min}}-{{max}}的数字。',
    alreadyExist: '有此名称的{{fieldName}}已经存在',
    generalPercent: '{{fieldName}}必须大于{{min}}和小于{{max}}。',
    exportMaxCount: '{{fieldName}}出口最大可以是{{count}}。',
    greaterThan: '{{fieldName}}不能大于{{max}}',
    minMaxStep: '{{fieldName}}必须在{{min}}之间。- {{max}}，且招儿为{{step}}。',
    minMaxStepSuggestions: '{{fieldName}}必须在{{min}}之间。- {{max}}，且招儿为{{step}}，作为建议，选择提供的金额： {{amount1}}, {{amount2}}。',
    uLSMinToMaxFNotS: '{{fieldName}}的长度可以是{{min}}-{{max}}字符。只能包含Unicode字母、数字、下划线和空格，不能以空格开头，并应至少包含一个字母。',
  },
  ro: {
    requiredField: '{{fieldName}} nu poate fi gol!',
    domain: 'Numele de domeniu nevalid!',
    invalidNumber: 'Număr invalid!',
    invalidIntegerNumber: 'Număr întreg invalid!',
    minMax: '{{fieldName}} trebuie să fie între {{min}} - {{max}}',
    lessThan: '{{fieldName}} nu poate fi mai mic decât {{min}}',
    mustBeLowerThan: '{{fieldName}} trebuie să fie mai jos {{max}}',
    mustBeGreaterThan: '{{fieldName}} trebuie să fie mai mare decât {{min}}',
    utoMax: '{{fieldName}} poate avea până la {{max}} de caractere, inclusiv cel puțin o literă',
    scriptName: 'Numele scriptului trebuie să aibă 2-55 de caractere. Poate conține litere latine, numere și următoarele caractere speciale: ), (, _, -',
    alLeastOneScript: 'Trebuie să fie completat cel puțin un script!',
    limited1to50: '{{fieldName}} trebuie să aibă între 1-50 de caractere',
    rateMax: 'Rata poate avea până la {{max}} caractere, poate să conțină doar numere și un punct. Numărul maxim permis de digiți după punct este 8',
    dateLaterThanNow: '{{fieldName}} trebuie să fie mai târziu decât ora curentă ',
    dateLaterThanDate: '{{fieldName}} trebuie să fie mai târziu decât data notificării',
    dateGreaterThanNow: '{{fieldName}} trebuie să fie mai mare decât momentul actual',
    toNotifyLessThanEffective: 'Data notificării trebuie să fie mai devreme datei efective\n',
    ahead1Hour: 'Ora de încheiere trebuie să fie cu cel puțin 1 oră înainte de ora de începere',
    ahead1Day: 'Data de încheiere trebuie să fie cu cel puțin o zi înainte de data de începere',
    ahead7Day: 'Data de încheiere trebuie să fie cu cel puțin 7 zile înainte de data de începere',
    ahead1Moth: 'Data de încheiere trebuie să fie cu cel puțin o lună înainte de data de începere',
    u1to50: '{{fieldName}} trebuie să aibă 1-50 de caractere, poate conține numai caractere Unicode, numere, spații, liniuţe de subliniere, nu poate începe cu spații și linii de subliniere',
    limited2to50: '{{fieldName}} poate avea între 2-50 de caractere, poate conține doar litere latine, cifre și liniuțe de jos, inclusiv cel puțin 1 literă',
    decimalNumber1toMaxWithPrecision: '{{fieldName}} poate avea până la {{max}} caractere, poate să conțină doar numere și un punct. Numărul maxim permis de digiţi după punct este {{precision}}',
    url: '{{fieldName}} nu este o adresă URL validă, poate avea până la 300 de caractere → lungimea maximă depășită',
    uLS1to50: '{{fieldName}} poate avea 1-50 de caractere. Poate să conțină doar litere și spații Unicode!',
    u1to100: '{{fieldName}} poate avea până la 100 de caractere, inclusiv cel puțin o literă.',
    limit1Day: 'Limita de 24 de ore trebuie să fie mai mică decât limitele actuale (în așteptare) de 7 zile și 30 zile',
    limit7Days: 'Limita de 7 zile trebuie să fie între limitele curente (în așteptare) de 24 de ore și 30 de zile',
    limit30Days: 'Limita 30 de zile trebuie să fie mai mare decât limitele curente (în așteptare) de 24 ore și 7 zile',
    digit: '{{fieldName}} trebuie să fie un număr.',
    address: '{{fieldName}} poate avea între 2-100 de caractere, poate conține litere Unicode, numere, spații și următoarele caractere speciale - . , | /!',
    lN3to50: '{{fieldName}} trebuie să aibă 3-50 de caractere. Poate să conțină doar litere latine, cifre și liniuțe de subliniere, inclusiv cel puțin 1 literă.',
    uFL1to50: '{{fieldName}} poate avea până la 50 de caractere, poate conține doar litere Unicode, începând cu literele majuscule.',
    uL1to50: '{{fieldName}} poate avea până la 50 de caractere. Poate să conțină doar litere Unicode, cifre, linii de subliniere și spații, inclusiv cel puțin 1 literă.',
    fileName: '{{fieldName}} poate avea până la 200 de caractere. Nu poate conține simboluri ca „ < > ? | : \\\\ / *.',
    correspondToPeriod: '{{fieldName}} trebuie să corespundă configurației perioadei.',
    correspondToOperationDate: '{{fieldName}} trebuie să corespundă datei operațiunii.',
    limited1to50FirstLetter: '{{fieldName}} poate avea până la 50 de caractere, poate să conțină doar litere Unicode, numere, liniuțe de subliniere și spații.',
    notOnlySpaces1toMax: '{{fieldName}} trebuie să cuprindă până la {{max}} caractere și nu poate avea doar spații',
    maxLimit: '{{fieldName}} trebuie să aibă până la {{max}} caractere',
    invalidUrl: 'Adresă URL nevalidă',
    uLSpace: 'Numele {{fieldName}} poate conține {{min}}-{{max}} caractere, inclusiv numai litere cu spații și spații.',
    lNSpecialCharacters: '{{fieldName}} poate conține între 2-10 caractere, inclusiv doar litere latine, cifre, spații și caractere speciale',
    canNotBeValue: '{{fieldName}} nu poate fi {{value}}',
    mustBeLowerOrEqual: '{{fieldMin}} trebuie să fie mai mic decât sau egal cu {{fieldMax}}',
    mustBeGreaterOrEqual: '{{fieldMin}} trebuie să fie mai mare sau egal cu {{fieldMax}}',
    greaterThan: '{{fieldName}} nu poate fi mai mare decât {{max}}',
    limitedMinToMax: '{{fieldName}} poate avea o lungime de {{min}}-{{{max}} caractere, poate conține doar litere latine și numere!',
    tag: '{{fieldName}} poate avea o lungime de până la 50 de caractere, inclusiv litere Unicode, numere, caractere de subliniere, liniuțe și spații, și nu poate începe cu spații, caractere de subliniere și liniuțe.',
    uMinToMaxFNotS:
      '{{fieldName}} poate avea o lungime de {{min}}-{{{max}} caractere. Poate cuprinde doar caractere Unicode, numere, caractere de subliniere și spații și nu poate începe cu un spațiu.',
    generalMobileNumber: '{{fieldName}} trebuie să înceapă cu + sau 00, apoi să conțină cifre între {{min}}-{{max}}',
    alreadyExist: '{{fieldName}} cu acest nume există deja ',
    generalPercent: '{{fieldName}} trebuie să fie mai mare decât {{min}} și mai mică decât {{max}}',
    exportMaxCount: 'Exportul {{fieldName}} poate fi maximum de {{count}}',
    minMaxStep: '{{fieldName}} trebuie să fie între {{min}} - {{max}}, iar pasul este de {{step}}',
    minMaxStepSuggestions: '{{fieldName}} trebuie să fie între {{min} - {{max}}, iar pasul este {{step}}; ca o sugestie, alege din sumele furnizate aici: {{suma1}}, {{suma2}}',
    uLSMinToMaxFNotS:
      '{{fieldName}} poate avea o lungime de {{min}}-{{{max}} caractere. Poate conține doar caractere Unicode, numere, caractere de subliniere și spații, nu poate începe cu un spațiu și trebuie să conțină cel puțin o literă',
  },
  bg: {},
  el: {
    requiredField: 'Το {{fieldName}} δεν μπορεί να είναι κενό!',
    domain: 'Μη έγκυρο όνομα τομέα!',
    invalidNumber: 'Μη έγκυρος αριθμός!',
    invalidIntegerNumber: 'Μη έγκυρος ακέραιος αριθμός!',
    minMax: 'Το {{fieldName}} πρέπει να είναι μεταξύ {{min}} - {{max}}',
    lessThan: 'Το {{fieldName}} δεν μπορεί να είναι μικρότερο από {{min}}',
    greaterThan: 'Το {{fieldName}} δεν μπορεί να είναι μεγαλύτερο από {{max}}',
    mustBeLowerThan: 'Το {{fieldName}} πρέπει να είναι μικρότερο από {{max}}',
    mustBeGreaterThan: 'Το {{fieldName}} πρέπει να είναι μεγαλύτερο από {{min}}',
    utoMax: 'Το {{fieldName}} μπορεί να έχει μήκος έως {{max}} χαρακτήρες, συμπεριλαμβάνοντας τουλάχιστον 1 γράμμα',
    scriptName: 'Το όνομα σεναρίου πρέπει να έχει μήκος 2-55 χαρακτήρες. Μπορεί να περιέχει λατινικά γράμματα, αριθμούς και τους παρακάτω ειδικούς χαρακτήρες: ), (, _, -',
    alLeastOneScript: 'Πρέπει να συμπληρωθεί τουλάχιστον ένα σενάριο!',
    limited1to50: 'Το {{fieldName}} πρέπει να έχει μήκος 1-50 χαρακτήρες',
    rateMax: 'Η τιμή μπορεί να έχει μήκος έως {{max}} χαρακτήρες, μπορεί να περιέχει μόνο αριθμούς και μια τελεία. Το μέγιστο επιτρεπόμενο πλήθος ψηφίων μετά την τελεία είναι 8',
    dateLaterThanNow: 'Το {{fieldName}} πρέπει να είναι μεταγενέστερο από το τώρα',
    dateLaterThanDate: 'Το {{fieldName}} πρέπει να είναι μεταγενέστερο από την ημερομηνία ειδοποίησης',
    dateGreaterThanNow: 'Το {{fieldName}} πρέπει να είναι μεγαλύτερο από το τώρα',
    toNotifyLessThanEffective: 'Η ημερομηνία ειδοποίησης πρέπει να είναι προγενέστερη από την ημερομηνία έναρξης ισχύος',
    ahead1Hour: 'Ο χρόνος λήξης πρέπει να είναι τουλάχιστον 1 ώρα μετά τον χρόνο έναρξης',
    ahead1Day: 'Η ημερομηνία λήξης πρέπει να είναι τουλάχιστον 1 ημέρα μετά την ημερομηνία έναρξης',
    ahead7Day: 'Η ημερομηνία λήξης πρέπει να είναι τουλάχιστον 7 ημέρες μετά την ημερομηνία έναρξης',
    ahead1Moth: 'Η ημερομηνία λήξης πρέπει να είναι τουλάχιστον 1 μήνα μετά την ημερομηνία έναρξης',
    u1to50:
      'Το {{fieldName}} πρέπει να έχει μήκος 1-50 χαρακτήρες, μπορεί να περιέχει μόνο γράμματα unicode, αριθμούς, κενά διαστήματα, κάτω παύλες, δεν μπορεί να αρχίζει με κενά διαστήματα και κάτω παύλες',
    limited2to50: 'Το {{fieldName}} μπορεί να έχει μήκος 2-50 χαρακτήρες, μπορεί να περιέχει μόνο λατινικά γράμματα, αριθμούς και κάτω παύλες, συμπεριλαμβάνοντας τουλάχιστον 1 γράμμα',
    decimalNumber1toMaxWithPrecision:
      'Το {{fieldName}} μπορεί να έχει έως {{max}} χαρακτήρες, μπορεί να περιέχει μόνο αριθμούς και μια τελεία. Το μέγιστο επιτρεπόμενο πλήθος ψηφίων μετά την τελεία είναι {{precision}}',
    url: 'Το {{fieldName}} δεν είναι έγκυρη διεύθυνση URL, μπορεί να έχει μήκος έως και 300 χαρακτήρες, υπάρχει υπέρβαση μέγιστου μήκους',
    uLS1to50: 'Το {{fieldName}} μπορεί να έχει μήκος 1-50 χαρακτήρες. Μπορεί να περιέχει μόνο γράμματα unicode και κενά διαστήματα!',
    u1to100: 'Το {{fieldName}} μπορεί να έχει μήκος έως 100 χαρακτήρες, συμπεριλαμβάνοντας τουλάχιστον 1 γράμμα.',
    limit1Day: 'Το όριο των 24 ωρών πρέπει να είναι μικρότερο από τα τρέχοντα (σε εκκρεμότητα) όρια των 7 και 30 ημερών',
    limit7Days: 'Το όριο των 7 ημερών πρέπει να είναι μεταξύ των τρεχόντων (σε εκκρεμότητα) ορίων των 24 ωρών και των 30 ημερών',
    limit30Days: 'Το όριο των 30 ημερών πρέπει να είναι μεγαλύτερο από τα τρέχοντα (σε εκκρεμότητα) όρια των 24 ωρών και των 7 ημερών',
    digit: 'Το {{fieldName}} πρέπει να είναι αριθμός.',
    limitedMinToMax: 'Το {{fieldName}} μπορεί να έχει μήκος {{min}}-{{max}} χαρακτήρες, μπορεί να περιέχει μόνο λατινικά γράμματα και αριθμούς!',
    address: 'Το {{fieldName}} πρέπει να έχει μήκος 2-100 χαρακτήρες, μπορεί να περιέχει μόνο γράμματα unicode, αριθμούς, κενά διαστήματα και τους παρακάτω ειδικούς χαρακτήρες - . , | /',
    lN3to50: 'Το {{fieldName}} πρέπει να έχει μήκος 3-50 χαρακτήρες. Μπορεί να περιέχει μόνο λατινικά γράμματα, αριθμούς και κάτω παύλες, συμπεριλαμβάνοντας τουλάχιστον 1 γράμμα.',
    uFL1to50: 'Το {{fieldName}} μπορεί να έχει μήκος έως 50 χαρακτήρες, μπορεί να περιέχει μόνο γράμματα unicode, ξεκινώντας με κεφαλαίο γράμμα.',
    tag: 'Το {{fieldName}} μπορεί να έχει μήκος έως 50 χαρακτήρες, συμπεριλαμβάνοντας μόνο γράμματα unicode, αριθμούς, κάτω παύλες, παύλες και κενά διαστήματα, δεν μπορεί να ξεκινά με κενά διαστήματα, κάτω παύλες και παύλες.',
    uL1to50: 'Το {{fieldName}} μπορεί να έχει μήκος έως 50 χαρακτήρες. Μπορεί να περιέχει μόνο γράμματα unicode, αριθμούς, κάτω παύλες και κενά διαστήματα, συμπεριλαμβάνοντας τουλάχιστον 1 γράμμα.',
    fileName: 'Το {{fieldName}} μπορεί να έχει μήκος έως 200 χαρακτήρες. Δεν μπορεί να περιέχει τα σύμβολα " < > ? | : \\\\ / *.',
    correspondToPeriod: 'Το {{fieldName}} πρέπει να αντιστοιχεί στη διαμόρφωση περιόδου.',
    correspondToOperationDate: 'Το {{fieldName}} πρέπει να αντιστοιχεί στην ημερομηνία λειτουργίας.',
    limited1to50FirstLetter: 'Το {{fieldName}} μπορεί να έχει μήκος έως 50 χαρακτήρες, μπορεί να περιέχει μόνο γράμματα unicode, αριθμούς, κάτω παύλες και κενά διαστήματα.',
    uMinToMaxFNotS:
      'Το {{fieldName}} μπορεί να έχει μήκος {{min}}-{{max}} χαρακτήρες. Μπορεί να περιέχει μόνο γράμματα unicode, αριθμούς, κάτω παύλες και κενά διαστήματα και δεν μπορεί να αρχίζει με κενό διάστημα',
    notOnlySpaces1toMax: 'Το {{fieldName}} πρέπει να έχει μήκος έως {{max}} χαρακτήρες και δεν μπορεί να αποτελείται μόνο από κενά διαστήματα',
    maxLimit: 'Το {{fieldName}} πρέπει να έχει μήκος έως {{max}} χαρακτήρες',
    invalidUrl: 'Μη έγκυρη διεύθυνση URL!',
    uLSpace: 'Το {{fieldName}} μπορεί να έχει μήκος {{min}}-{{max}} χαρακτήρες. Μπορεί να περιέχει μόνο γράμματα και κενά διαστήματα και δεν μπορεί να αρχίζει με κενό διάστημα.',
    lNSpecialCharacters: 'Το {{fieldName}} μπορεί να περιέχει 2-10 χαρακτήρες συμπεριλαμβάνοντας λατινικά γράμματα, αριθμούς, κενά διαστήματα και τους ειδικούς χαρακτήρες -/',
    canNotBeValue: 'Το {{fieldName}} δεν μπορεί να είναι {{value}}',
    mustBeLowerOrEqual: 'Το {{fieldName}} πρέπει να είναι μικρότερο ή ίσο με {{fieldMax}}',
    mustBeGreaterOrEqual: 'Το {{fieldMin}} πρέπει να είναι μεγαλύτερο ή ίσο με {{fieldMax}}',
    generalMobileNumber: 'Το {{fieldName}} πρέπει να ξεκινά με + ή με 00 και στη συνέχεια να περιέχει {{min}}-{{max}} ψηφία',
    alreadyExist: 'Υπάρχει ήδη {{fieldName}} με αυτό το όνομα',
    generalPercent: 'Το {{fieldName}} πρέπει να είναι μεγαλύτερο από {{min}} και μικρότερο από {{max}}',
    exportMaxCount: 'Η εξαγωγή {{fieldName}} μπορεί να είναι κατά το μέγιστο {{count}}',
    minMaxStep: 'Το {{fieldName}} πρέπει να είναι μεταξύ {{min}} - {{max}} και το βήμα είναι {{step}}',
    minMaxStepSuggestions: 'Το {{fieldName}} πρέπει να είναι μεταξύ {{min}} - {{max}} και το βήμα είναι {{step}}, ως υπόδειξη επιλέξτε τα παρεχόμενα ποσά: {{amount1 }}, {{amount2}}',
    uLSMinToMaxFNotS:
      'Το {{fieldName}} μπορεί να έχει μήκος {{min}}-{{max}} χαρακτήρες. Μπορεί να περιέχει μόνο γράμματα unicode, αριθμούς, κάτω παύλες και κενά διαστήματα και δεν μπορεί να αρχίζει με κενό διάστημα και πρέπει να περιέχει τουλάχιστον 1 γράμμα',
    brandName: 'Το όνομα πρέπει να έχει μήκος 3-50 χαρακτήρες. Μπορεί να περιέχει μόνο λατινικά γράμματα, αριθμούς και κενά διαστήματα.',
    notOnlySpaces1toMaxSeparateByComma: 'Το {{fieldName}} πρέπει να έχει μήκος έως {{max}} χαρακτήρες, να μην περιλαμβάνει μόνο κενά διαστήματα και πρέπει να χωρίζονται με κόμμα',
    groupNameAlreadyExist: 'Το όνομα της ομάδας χρησιμοποιείται ήδη για την ενεργή ομάδα. Δεν μπορείτε να έχετε 2 ομάδες με το ίδιο όνομα σε ενεργή κατάσταση.',
    phoneNumber:
      'Ο παρεχόμενος αριθμός τηλεφώνου δεν ταιριάζει με το απαιτούμενο μοτίβο. Βεβαιωθείτε ότι ο αριθμός αρχίζει με ένα προαιρετικό σύμβολο "+", ακολουθούμενο από ένα μη μηδενικό ψηφίο (1-9) και έχει 7 έως 14 ψηφία (0-9) μετά από αυτό.',
    lLNLimitMinToMax:
      'Το {{fieldName}} μπορεί να έχει μήκος {{min}}-{{max}} χαρακτήρες. Μπορεί να περιέχει μόνο λατινικά πεζά γράμματα, αριθμούς, κάτω παύλες, ενωτικά και δεν μπορεί να αρχίζει με κενό διάστημα',
    passwordDontMatch: 'Οι κωδικοί πρόσβασης δεν ταιριάζουν!',
    password8to40NUL: 'Ο κωδικός πρόσβασης μπορεί να έχει μήκος 8-40 χαρακτήρες, συμπεριλαμβάνοντας τουλάχιστον έναν αριθμό και κεφαλαίο γράμμα.',
  },
};
