import { Popover } from 'antd';
import { IconSvg } from 'components/common';
import { AdminSelect } from 'components/common/form';
import { useEffect, useMemo, useState } from 'react';
import {
  StyledHeaderSelectClose, StyledHeaderSelectToggle, StyledHeaderSelectWrapper, StyledListItemViewMode,
} from 'components/layout/Layout.styled';
import { withTheme } from 'styled-components';
import { LocalStorageService } from 'services';
import PropTypes from 'prop-types';
import _ from 'lodash';

const Brands = (props) => {
  const {
    brandSettings,
    brand,
    brands,
    theme: { colors },
    brandId,
    setCurrentBrand,
  } = props;

  const [open, setOpen] = useState(false);

  const isVisible = useMemo(() => brands?.length && (brandSettings?.editMode || brandSettings?.viewMode), [brandSettings?.editMode, brandSettings?.viewMode, brands?.length]);

  const brandsList = useMemo(() => {
    if (_.isNil(brandSettings?.isNewVersion)) {
      return brands;
    }
    return brands.filter((el) => el.isNewVersion === brandSettings?.isNewVersion);
  }, [brands, brandSettings?.isNewVersion]);

  useEffect(() => {
    if (brandsList?.length) {
      const currentData = LocalStorageService.get('current') || {};
      const id = currentData?.brandId || brandsList[0]?.id;
      if (id) {
        setCurrentBrand(id);

        LocalStorageService.set('current', {
          ...currentData,
          brandId: id,
        });
      }
    }
  }, [setCurrentBrand, brandsList]);

  if (!isVisible) {
    return;
  }

  if (brandSettings?.viewMode) {
    const { name } = brand;
    return (
      <Popover placement="bottomLeft" content={name} title="" overlayClassName="description-popover">
        <StyledListItemViewMode isBold>{name}</StyledListItemViewMode>
      </Popover>
    );
  }

  const openDropDown = () => {
    setOpen(true);
  };

  const closeDropDown = () => {
    setOpen(false);
  };

  const brandChange = (id) => {
    const currentData = LocalStorageService.get('current') || {};
    LocalStorageService.set('current', {
      ...currentData,
      brandId: id,
    });

    setCurrentBrand(id);
    closeDropDown();
  };

  return (
    !!brandsList.length && (
      <div>
        <StyledHeaderSelectToggle onClick={openDropDown}>
          <IconSvg icon="PartnersIcon" width="1.4rem" height="1.4rem" color={colors.white} />
        </StyledHeaderSelectToggle>
        <StyledHeaderSelectWrapper isOpen={open ? 1 : 0}>
          <AdminSelect onChange={brandChange} value={brandId} allowClear={false} data={brandsList} isToString={false} virtual={false} />
          <StyledHeaderSelectClose onClick={closeDropDown}>
            <IconSvg icon="CloseModalIcon" width="1.14rem" height="1.14rem" color={colors.white} />
          </StyledHeaderSelectClose>
        </StyledHeaderSelectWrapper>
      </div>
    )
  );
};

Brands.propTypes = {
  theme: PropTypes.object.isRequired,
  setCurrentBrand: PropTypes.func.isRequired,
  brandId: PropTypes.number,
  brandSettings: PropTypes.object,
  brands: PropTypes.array,
  brand: PropTypes.object,
};

Brands.defaultProps = {
  brandId: null,
  brandSettings: null,
  brands: [],
  brand: {},
};

export default withTheme(Brands);
