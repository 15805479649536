import PaymentActionType from './paymentActionType';

export const getDeposits = (params) => ({
  type: PaymentActionType.GET_DEPOSITS,
  payload: { params },
});

export const saveDeposits = (data) => ({
  type: PaymentActionType.SAVE_DEPOSITS,
  payload: { data },
});

export const resetDeposits = () => ({
  type: PaymentActionType.RESET_DEPOSITS,
});

export const getWithdrawals = (params) => ({
  type: PaymentActionType.GET_WITHDRAWALS,
  payload: { params },
});

export const saveWithdrawals = (data) => ({
  type: PaymentActionType.SAVE_WITHDRAWALS,
  payload: { data },
});

export const resetWithdrawals = () => ({
  type: PaymentActionType.RESET_WITHDRAWALS,
});

export const addDepositNote = (data, params) => ({
  type: PaymentActionType.ADD_DEPOSIT_NOTE,
  payload: { data, params },
});

export const addWithdrawalNote = (data, params) => ({
  type: PaymentActionType.ADD_WITHDRAWAL_NOTE,
  payload: { data, params },
});

export const getDepositNotes = (id, params, isPlayer) => ({
  type: PaymentActionType.GET_DEPOSIT_NOTES,
  payload: { id, params, isPlayer },
});

export const getWithdrawalNotes = (id, params, isPlayer) => ({
  type: PaymentActionType.GET_WITHDRAWAL_NOTES,
  payload: { id, params, isPlayer },
});

export const getDepositDetails = (id, params, isPlayer) => ({
  type: PaymentActionType.GET_DEPOSIT_DETAILS,
  payload: { id, params, isPlayer },
});

export const getWithdrawalDetails = (id, params, isPlayer) => ({
  type: PaymentActionType.GET_WITHDRAWAL_DETAILS,
  payload: { id, params, isPlayer },
});

export const getDepositLogs = (id, params, isPlayer) => ({
  type: PaymentActionType.GET_DEPOSIT_LOGS,
  payload: { id, params, isPlayer },
});

export const getWithdrawalLogs = (id, params, isPlayer) => ({
  type: PaymentActionType.GET_WITHDRAWAL_LOGS,
  payload: { id, params, isPlayer },
});

export const changeWithdrawalStatusToInProcess = (data, params, isPlayer) => ({
  type: PaymentActionType.CHANGE_WITHDRAWAL_STATUS_TO_IN_PROCESS,
  payload: { data, params, isPlayer },
});

export const changeWithdrawalStatusToApproved = (data, params, isPlayer) => ({
  type: PaymentActionType.CHANGE_WITHDRAWAL_STATUS_TO_APPROVED,
  payload: { data, params, isPlayer },
});

export const changeWithdrawalStatusToFrozen = (data, params, isPlayer) => ({
  type: PaymentActionType.CHANGE_WITHDRAWAL_STATUS_TO_FROZEN,
  payload: { data, params, isPlayer },
});

export const changeWithdrawalStatusToCancelled = (data, params, isPlayer) => ({
  type: PaymentActionType.CHANGE_WITHDRAWAL_STATUS_TO_CANCELLED,
  payload: { data, params, isPlayer },
});

export const generateDepositsReport = (data) => ({
  type: PaymentActionType.GENERATE_DEPOSITS_REPORT,
  payload: { data },
});

export const generateWithdrawalsReport = (data) => ({
  type: PaymentActionType.GENERATE_WITHDRAWALS_REPORT,
  payload: { data },
});

export const changeOfflineWithdrawalStatusToInProcess = (data, params, isPlayer) => ({
  type: PaymentActionType.CHANGE_OFFLINE_WITHDRAWAL_STATUS_TO_IN_PROCESS,
  payload: { data, params, isPlayer },
});

export const changeOfflineWithdrawalStatusToApproved = (data, params, isPlayer) => ({
  type: PaymentActionType.CHANGE_OFFLINE_WITHDRAWAL_STATUS_TO_APPROVED,
  payload: { data, params, isPlayer },
});

export const changeOfflineWithdrawalStatusToDeclined = (data, params, isPlayer) => ({
  type: PaymentActionType.CHANGE_OFFLINE_WITHDRAWAL_STATUS_TO_DECLINED,
  payload: { data, params, isPlayer },
});

export const changeWithdrawalStatusToDeclined = (data, params, isPlayer) => ({
  type: PaymentActionType.CHANGE_WITHDRAWAL_STATUS_TO_DECLINED,
  payload: { data, params, isPlayer },
});

export const getTransactionPlayerInfo = (params, isDeposit, isPlayer) => ({
  type: PaymentActionType.GET_TRANSACTION_PLAYER_INFO,
  payload: { params, isDeposit, isPlayer },
});

export const changeOfflineDepositStatus = (data, params, isPlayer) => ({
  type: PaymentActionType.CHANGE_OFFLINE_DEPOSIT_STATUS,
  payload: { data, params, isPlayer },
});

export const getPlayerDeposits = (id, params) => ({
  type: PaymentActionType.GET_PLAYER_DEPOSITS,
  payload: { id, params },
});

export const savePlayerDeposits = (data) => ({
  type: PaymentActionType.SAVE_PLAYER_DEPOSITS,
  payload: { data },
});

export const resetPlayerDeposits = () => ({
  type: PaymentActionType.RESET_PLAYER_DEPOSITS,
});

export const exportPlayerDeposits = (id, params) => ({
  type: PaymentActionType.EXPORT_PLAYER_DEPOSITS,
  payload: { id, params },
});

export const getPlayerWithdrawals = (id, params) => ({
  type: PaymentActionType.GET_PLAYER_WITHDRAWALS,
  payload: { id, params },
});

export const savePlayerWithdrawals = (data) => ({
  type: PaymentActionType.SAVE_PLAYER_WITHDRAWALS,
  payload: { data },
});

export const resetPlayerWithdrawals = () => ({
  type: PaymentActionType.RESET_PLAYER_WITHDRAWALS,
});

export const exportPlayerWithdrawals = (id, params) => ({
  type: PaymentActionType.EXPORT_PLAYER_WITHDRAWALS,
  payload: { id, params },
});

export const getDepositTags = (id) => ({
  type: PaymentActionType.GET_DEPOSIT_TAGS,
  payload: { id },
});

export const saveDepositTags = (data) => ({
  type: PaymentActionType.SAVE_DEPOSIT_TAGS,
  payload: { data },
});

export const resetDepositTags = () => ({
  type: PaymentActionType.RESET_DEPOSIT_TAGS,
});

export const assignDepositTags = (id, data) => ({
  type: PaymentActionType.ASSIGN_DEPOSIT_TAGS,
  payload: { id, data },
});

export const exportDepositTags = (id, columns) => ({
  type: PaymentActionType.EXPORT_DEPOSIT_TAGS,
  payload: { id, columns },
});

export const getWithdrawalTags = (id) => ({
  type: PaymentActionType.GET_WITHDRAWAL_TAGS,
  payload: { id },
});

export const saveWithdrawalTags = (data) => ({
  type: PaymentActionType.SAVE_WITHDRAWAL_TAGS,
  payload: { data },
});

export const resetWithdrawalTags = () => ({
  type: PaymentActionType.RESET_WITHDRAWAL_TAGS,
});

export const assignWithdrawalTags = (id, data) => ({
  type: PaymentActionType.ASSIGN_WITHDRAWAL_TAGS,
  payload: { id, data },
});

export const exportWithdrawalTags = (id, columns) => ({
  type: PaymentActionType.EXPORT_WITHDRAWAL_TAGS,
  payload: { id, columns },
});

export const getAssignedTagCategories = (params, isLoading = true) => ({
  type: PaymentActionType.GET_ASSIGNED_TAG_CATEGORIES,
  payload: { params, isLoading },
});

export const saveAssignedTagCategories = (data) => ({
  type: PaymentActionType.SAVE_ASSIGNED_TAG_CATEGORIES,
  payload: { data },
});

export const resetAssignedTagCategories = () => ({
  type: PaymentActionType.RESET_ASSIGNED_TAG_CATEGORIES,
});

export const getAssignedTags = (params, isLoading = true) => ({
  type: PaymentActionType.GET_ASSIGNED_TAGS,
  payload: { params, isLoading },
});

export const saveAssignedTags = (data) => ({
  type: PaymentActionType.SAVE_ASSIGNED_TAGS,
  payload: { data },
});

export const resetAssignedTags = () => ({
  type: PaymentActionType.RESET_ASSIGNED_TAGS,
});

export const savePaymentNotes = (id, data) => ({
  type: PaymentActionType.SAVE_PAYMENT_NOTES,
  payload: { id, data },
});

export const resetPaymentNotes = () => ({
  type: PaymentActionType.RESET_PAYMENT_NOTES,
});

export const savePaymentLogs = (id, data) => ({
  type: PaymentActionType.SAVE_PAYMENT_LOGS,
  payload: { id, data },
});

export const resetPaymentLogs = () => ({
  type: PaymentActionType.RESET_PAYMENT_LOGS,
});

export const savePaymentDetails = (id, data) => ({
  type: PaymentActionType.SAVE_PAYMENT_DETAILS,
  payload: { id, data },
});

export const resetPaymentDetails = () => ({
  type: PaymentActionType.RESET_PAYMENT_DETAILS,
});

export const saveTransactionPlayerInfo = (id, data) => ({
  type: PaymentActionType.SAVE_TRANSACTION_PLAYER_INFO,
  payload: { id, data },
});

export const resetTransactionPlayerInfo = () => ({
  type: PaymentActionType.RESET_TRANSACTION_PLAYER_INFO,
});

export const changeBulkWithdrawalStatus = (data) => ({
  type: PaymentActionType.CHANGE_BULK_WITHDRAWAL_STATUS,
  payload: { data },
});
