import PropTypes from 'prop-types';
import { StyledSelectWrapper } from 'components/styledComponents/select/Select.styled';

const AdminSingleSelect = (props) => {
  const { data, renderOptions, ...rest } = props;

  const filterOption = (input, option) => option.children?.toString().toLowerCase().startsWith(input.toLowerCase());

  return (
    <StyledSelectWrapper filterOption={filterOption} {...rest}>
      {renderOptions(data)}
    </StyledSelectWrapper>
  );
};

export default AdminSingleSelect;

AdminSingleSelect.propTypes = {
  data: PropTypes.array,
  renderOptions: PropTypes.func.isRequired,
};

AdminSingleSelect.defaultProps = {
  data: [],
};
