import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Pages from 'components/layout/Pages';
import { selectBreadCrumbParams, selectCurrentBrandVersion, selectUserPartnerAccessIds } from 'selectors';
import { setActiveMenu, resetBreadCrumbParams } from 'actions/setting/settingActionCreator';

const mapStateToProps = ({ settings, auth, brands }) => ({
  breadcrumbParams: selectBreadCrumbParams(settings),
  isNewVersion: selectCurrentBrandVersion({ auth, brands }),
  partnerAccessIds: selectUserPartnerAccessIds(auth),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setActiveMenu,
    resetBreadCrumbParams,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Pages);
