import _ from 'lodash';
import i18n from 'services/i18n';
import { AppConstants } from 'constants/index';

/**
 * Convert an array to a tree-structured array.
 * @param   {array}     array     The Array need to Converted.
 * @param   {string}    id        The alias of the unique ID of the object in the array.
 * @param   {string}    parentId       The alias of the parent ID of the object in the array.
 * @param   {string}    children  The alias of children of the object in the array.
 * @return  {array}    Return a tree-structured array.
 */
export function arrayToTree(array, id = 'id', parentId = 'pid', children = 'children') {
  const result = [];
  const hash = {};

  array.forEach((item, index) => {
    hash[array[index][id]] = array[index];
  });

  array.forEach((item) => {
    const hashParent = hash[item[parentId]];
    if (hashParent) {
      if (!hashParent[children]) {
        hashParent[children] = [];
      }
      hashParent[children].push(item);
    } else {
      result.push(item);
    }
  });
  return result;
}

/**
 * Convert an input value to number.
 * @param {Object} e The event of input.
 * @param {Number} precision symbols count after the point
 * @param {Boolean} isNegative The type of input
 * @returns {null|String} Return a converted value.
 */
export const onlyNumbersFormatter = (e, precision = 0, isNegative = false) => {
  const { value } = e.target;

  if (!value) {
    return '';
  }

  let onlyDigits = value.replace(AppConstants.regex.notNDotUnderscore, '');

  if (precision) {
    onlyDigits = onlyDigits.match(isNegative ? AppConstants.regex.negativeNumber : AppConstants.regex.doublePositiveNumber);
    const dotPosition = value.indexOf('.');

    // if value with dot this will return by precision
    if (dotPosition !== -1) {
      return onlyDigits[0].slice(0, dotPosition + precision + 1);
    }
  } else {
    // for integer case
    onlyDigits = onlyDigits.match(isNegative ? AppConstants.regex.negIntNumber : AppConstants.regex.intNumber);
  }

  return onlyDigits?.[0] || '';
};

/**
 * Download file.
 * @param   {content}    Stream or list    String | Array.
 * @param   {filename}   Generated filename   String.
 * @return  {File}       Download generated file.
 */
export const downloadFile = (content, fileName, extension = 'csv') => {
  const link = document.createElement('a');
  if (link.download !== undefined) {
    let stream = content;
    if (extension === 'csv' && Array.isArray(stream)) {
      stream = '';
      content.forEach((row) => {
        stream += row.join(',');
        stream += '\n';
      });
    }

    // feature detection
    // Browsers that support HTML5 download attribute
    const blob = new Blob([stream], { type: 'octet/stream' });
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${fileName}.${extension}`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

/**
 * Get file name and extension
 * @param {string} filename with extension.
 * @param {boolean} isExtensionLowerCased.
 * @returns {[string, string]} Return file name, file extension.
 */
export const getFileNameAndExtension = (filename, isExtensionLowerCased = true) => {
  const index = filename.lastIndexOf('.');
  return [filename.substring(0, index), isExtensionLowerCased ? filename.substring(index + 1).toLowerCase() : filename.substring(index + 1)];
};

/**
 *Copy code to clipBoard
 * @param {value} Value for copy String
 */
export const copyCodeToClipBoard = (value) => {
  const dummy = document.createElement('input');
  document.body.appendChild(dummy);
  dummy.value = value;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};

/**
 * Get file size
 * @param   {bytes} Size Number.
 * @param   {decimals} Decimal points Number.
 */
export const getFileSize = (bytes, decimals = 2) => {
  if (bytes === 0) return `0 ${i18n.t('memorySize.bytes')}`;
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [i18n.t('memorySize.bytes'), i18n.t('memorySize.kb'), i18n.t('memorySize.mb'), i18n.t('memorySize.gb')];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

/**
 * @param String string
 * @returns {string} string without HTML Tags, Spaces, New Lines
 */
export const isEmptyHtml = (str) => str.replace(AppConstants.regex.emptyHtml, '$1').trim();

/**
 * @param String string
 * @returns {string} remove HTML tags
 */
export const stripTags = (str) => str.replace(AppConstants.regex.htmlTag, '');

/**
 *
 * @param val
 * @param decimals fixed number
 * @returns {Number} formatted string
 * e.g 1 0000 000 , 5000.00
 * * @returns {Number} string
 */
// todo please write with one regexp
export const toFormatNumber = (val, decimal, isFormat = true) => {
  if (_.isNaN(+val) || (!val && val !== 0)) {
    return '';
  }
  let value = val.toString();
  if (decimal) {
    value = value.match(new RegExp(`^-?\\d+(?:\\.\\d{0,${decimal}})?`))?.[0];
  }

  const parts = value?.split('.');
  if (isFormat) {
    parts[0] = parts?.[0].replace(AppConstants.regex.splitNumber, '$1 ');
  }
  return parts.join('.');
};

/**
 *
 * @param {*} String value
 * @returns {true|false } Boolean
 */
export const isTrue = (value) => value === 'true';

/**
 * To check if is JSON
 * @param {string} text.
 * @returns {boolean}
 */
export const isJSON = (text) => {
  if (!_.isString(text)) {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Generate Map from object
 * @param order
 * @param obj
 * @returns {*}
 */
export const generateMapByOrder = (order, obj = {}) => order.reduce((result, current) => {
  result.set(current, obj[current]);
  return result;
}, new Map());

/**
 * @returns {number} random number
 */
export const getRandomNumber = () => {
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(1);
  return crypto.getRandomValues(array)[0]; // Compliant for security-sensitive use cases
};

/**
 * Converts exponential notation to a human's readable string
 * @param {number|string|Array} num - number or array of its parts
 * @return {string}
 */
export const fromExponential = (num) => {
  const getExponentialParts = (numb) => (Array.isArray(numb) ? numb : String(numb).split(/[eE]/));

  const isExponential = (numb) => {
    const eParts = getExponentialParts(numb);
    return !Number.isNaN(Number(eParts[1]));
  };

  const eParts = getExponentialParts(num);
  if (!isExponential(eParts)) {
    return eParts[0];
  }

  const sign = eParts[0][0] === '-' ? '-' : '';
  const digits = eParts[0].replace(/^-/, '');
  const digitsParts = digits.split('.');
  const wholeDigits = digitsParts[0];
  const fractionDigits = digitsParts[1] || '';
  let e = Number(eParts[1]);

  if (e === 0) {
    return `${sign + wholeDigits}.${fractionDigits}`;
  }
  if (e < 0) {
    // move dot to the left
    const countWholeAfterTransform = wholeDigits.length + e;
    if (countWholeAfterTransform > 0) {
      // transform whole to fraction
      const wholeDigitsAfterTransform = wholeDigits.substr(0, countWholeAfterTransform);
      const wholeDigitsTransformedToFraction = wholeDigits.substr(countWholeAfterTransform);
      return `${sign + wholeDigitsAfterTransform}.${wholeDigitsTransformedToFraction}${fractionDigits}`;
    }
    // not enough whole digits: prepend with fractional zeros

    // first e goes to dotted zero
    let zeros = '0.';
    e = countWholeAfterTransform;
    while (e) {
      zeros += '0';
      e += 1;
    }
    return sign + zeros + wholeDigits + fractionDigits;
  }
  // move dot to the right
  const countFractionAfterTransform = fractionDigits.length - e;
  if (countFractionAfterTransform > 0) {
    // transform fraction to whole
    // countTransformedFractionToWhole = e
    const fractionDigitsAfterTransform = fractionDigits.substr(e);
    const fractionDigitsTransformedToWhole = fractionDigits.substr(0, e);
    return `${sign + wholeDigits + fractionDigitsTransformedToWhole}.${fractionDigitsAfterTransform}`;
  }
  // not enough fractions: append whole zeros
  let zerosCount = -countFractionAfterTransform;
  let zeros = '';
  while (zerosCount) {
    zeros += '0';
    zerosCount -= 1;
  }
  return sign + wholeDigits + fractionDigits + zeros;
};

/**
 * @param html
 * @returns {string}
 */

export const decodeHtml = (html) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

/**
 * Hour representation
 * @param hour
 * @returns {string}
 */

export const getHour = (hour) => (hour < 10 ? `0${hour}` : `${hour}`);
