const BetActionType = {
  GET_CASINO_BETS: 'GET_CASINO_BETS',
  SAVE_CASINO_BETS: 'SAVE_CASINO_BETS',
  RESET_CASINO_BETS: 'RESET_CASINO_BETS',

  GET_PLAYER_CASINO_BETS: 'GET_PLAYER_CASINO_BETS',
  SAVE_PLAYER_CASINO_BETS: 'SAVE_PLAYER_CASINO_BETS',
  RESET_PLAYER_CASINO_BETS: 'RESET_PLAYER_CASINO_BETS',

  GET_CASINO_BET_DETAILS: 'GET_CASINO_BET_DETAILS',
  SAVE_CASINO_BET_DETAILS: 'SAVE_CASINO_BET_DETAILS',
  RESET_CASINO_BET_DETAILS: 'RESET_CASINO_BET_DETAILS',

  GET_CASINO_BET_V2_DETAILS: 'GET_CASINO_BET_V2_DETAILS',

  GET_SPORT_BET_DETAILS: 'GET_SPORT_BET_DETAILS',
  SAVE_SPORT_BET_DETAILS: 'SAVE_SPORT_BET_DETAILS',
  RESET_SPORT_BET_DETAILS: 'RESET_SPORT_BET_DETAILS',

  GET_SPORT_BET_V2_DETAILS: 'GET_SPORT_BET_V2_DETAILS',

  GENERATE_CASINO_BETS_REPORT: 'GENERATE_CASINO_BETS_REPORT',
  EXPORT_PLAYER_CASINO_BETS: 'EXPORT_PLAYER_CASINO_BETS',

  GET_SPORT_BETS: 'GET_SPORT_BETS',
  SAVE_SPORT_BETS: 'SAVE_SPORT_BETS',
  RESET_SPORT_BETS: 'RESET_SPORT_BETS',

  GENERATE_SPORT_BETS_REPORT: 'GENERATE_SPORT_BETS_REPORT',
  EXPORT_PLAYER_SPORT_BETS: 'EXPORT_PLAYER_SPORT_BETS',

  GET_PLAYER_SPORT_BETS: 'GET_PLAYER_SPORT_BETS',
  SAVE_PLAYER_SPORT_BETS: 'SAVE_PLAYER_SPORT_BETS',
  RESET_PLAYER_SPORT_BETS: 'RESET_PLAYER_SPORT_BETS',

  GET_SPORT_BET: 'GET_SPORT_BET',
  SAVE_SPORT_BET: 'SAVE_SPORT_BET',
  RESET_SPORT_BET: 'RESET_SPORT_BET',

  GET_CASINO_BET: 'GET_CASINO_BET',
  SAVE_CASINO_BET: 'SAVE_CASINO_BET',
  RESET_CASINO_BET: 'RESET_CASINO_BET',
};

export default BetActionType;
