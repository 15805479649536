import LobbyManagementActionType from './lobbyManagementActionType';

export const getLobbies = (params) => ({
  type: LobbyManagementActionType.GET_LOBBIES,
  payload: { params },
});

export const saveLobbies = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBIES,
  payload: { data },
});

export const resetLobbies = () => ({
  type: LobbyManagementActionType.RESET_LOBBIES,
});

export const getLobby = (id, params) => ({
  type: LobbyManagementActionType.GET_LOBBY,
  payload: { id, params },
});

export const saveLobby = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY,
  payload: { data },
});

export const resetLobby = () => ({
  type: LobbyManagementActionType.RESET_LOBBY,
});

export const editLobby = (id, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY,
  payload: { id, data, name },
});

export const changeLobbyStatus = (id, params, name) => ({
  type: LobbyManagementActionType.CHANGE_LOBBY_STATUS,
  payload: { id, params, name },
});

export const getLobbyProviders = (id, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_PROVIDERS,
  payload: { id, params },
});

export const saveLobbyProviders = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_PROVIDERS,
  payload: { data },
});

export const resetLobbyProviders = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_PROVIDERS,
});

export const changeLobbyProviderStatus = (lobbyId, providerId, params, name) => ({
  type: LobbyManagementActionType.CHANGE_LOBBY_PROVIDER_STATUS,
  payload: {
    lobbyId,
    providerId,
    params,
    name,
  },
});

export const editLobbyProvider = (lobbyId, providerId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_PROVIDER,
  payload: {
    lobbyId,
    providerId,
    data,
    name,
  },
});

export const getLobbyProvider = (lobbyId, providerId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_PROVIDER,
  payload: { lobbyId, providerId, params },
});

export const saveLobbyProvider = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_PROVIDER,
  payload: { data },
});

export const resetLobbyProvider = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_PROVIDER,
});

export const getLobbyProvidersOrders = (lobbyId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_PROVIDERS_ORDERS,
  payload: { lobbyId, params },
});

export const saveLobbyProvidersOrders = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_PROVIDERS_ORDERS,
  payload: { data },
});

export const resetLobbyProvidersOrders = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_PROVIDERS_ORDERS,
});

export const editLobbyProvidersOrders = (lobbyId, params, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_PROVIDERS_ORDERS,
  payload: {
    lobbyId,
    params,
    data,
    name,
  },
});

export const getCsvLobbyProvidersOrders = (lobbyId, data) => ({
  type: LobbyManagementActionType.GET_CSV_LOBBY_PROVIDERS_ORDERS,
  payload: { lobbyId, data },
});

export const saveCsvLobbyProvidersOrders = (data) => ({
  type: LobbyManagementActionType.SAVE_CSV_LOBBY_PROVIDERS_ORDERS,
  payload: { data },
});

export const resetCsvLobbyProvidersOrders = () => ({
  type: LobbyManagementActionType.RESET_CSV_LOBBY_PROVIDERS_ORDERS,
});

export const exportLobbyProviders = (lobbyId, params) => ({
  type: LobbyManagementActionType.EXPORT_LOBBY_PROVIDERS,
  payload: { lobbyId, params },
});

export const getLobbyProviderGamesOrders = (lobbyId, providerId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_PROVIDER_GAMES_ORDERS,
  payload: { lobbyId, providerId, params },
});

export const saveLobbyProviderGamesOrders = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_PROVIDER_GAMES_ORDERS,
  payload: { data },
});

export const resetLobbyProviderGamesOrders = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_PROVIDER_GAMES_ORDERS,
});

export const editLobbyProviderGamesOrders = (lobbyId, providerId, params, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_PROVIDER_GAMES_ORDERS,
  payload: {
    lobbyId,
    params,
    data,
    name,
    providerId,
  },
});

export const getCsvLobbyProviderGamesOrders = (lobbyId, providerId, data) => ({
  type: LobbyManagementActionType.GET_CSV_LOBBY_PROVIDER_GAMES_ORDERS,
  payload: { lobbyId, providerId, data },
});

export const saveCsvLobbyProviderGamesOrders = (data) => ({
  type: LobbyManagementActionType.SAVE_CSV_LOBBY_PROVIDER_GAMES_ORDERS,
  payload: { data },
});

export const resetCsvLobbyProviderGamesOrders = () => ({
  type: LobbyManagementActionType.RESET_CSV_LOBBY_PROVIDER_GAMES_ORDERS,
});

export const getLobbyLogs = (lobbyId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_LOGS,
  payload: { lobbyId, params },
});

export const saveLobbyLogs = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_LOGS,
  payload: { data },
});

export const resetLobbyLogs = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_LOGS,
});

export const getLobbyGameGroups = (lobbyId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_GAME_GROUPS,
  payload: { lobbyId, params },
});

export const saveLobbyGameGroups = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_GAME_GROUPS,
  payload: { data },
});

export const resetLobbyGameGroups = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_GAME_GROUPS,
});

export const reorderLobbyGameGroup = (lobbyId, groupId, data) => ({
  type: LobbyManagementActionType.REORDER_LOBBY_GAME_GROUP,
  payload: { lobbyId, groupId, data },
});

export const changeLobbyGameGroupStatus = (lobbyId, groupId, data, name) => ({
  type: LobbyManagementActionType.CHANGE_LOBBY_GAME_GROUP_STATUS,
  payload: {
    lobbyId,
    groupId,
    data,
    name,
  },
});

export const getLobbyGameGroup = (lobbyId, groupId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_GAME_GROUP,
  payload: { lobbyId, params, groupId },
});

export const saveLobbyGameGroup = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_GAME_GROUP,
  payload: { data },
});

export const resetLobbyGameGroup = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_GAME_GROUP,
});

export const editLobbyGameGroup = (lobbyId, groupId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_GAME_GROUP,
  payload: {
    lobbyId,
    groupId,
    data,
    name,
  },
});

export const getLobbyGeneralWidgets = (lobbyId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_GENERAL_WIDGETS,
  payload: { lobbyId, params },
});

export const saveLobbyGeneralWidgets = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_GENERAL_WIDGETS,
  payload: { data },
});

export const resetLobbyGeneralWidgets = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_GENERAL_WIDGETS,
});

export const getLobbyJackpotWidgets = (lobbyId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_JACKPOT_WIDGETS,
  payload: { lobbyId, params },
});

export const saveLobbyJackpotWidgets = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_JACKPOT_WIDGETS,
  payload: { data },
});

export const resetLobbyJackpotWidgets = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_JACKPOT_WIDGETS,
});

export const getLobbyGeneralWidget = (lobbyId, widgetId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_GENERAL_WIDGET,
  payload: { lobbyId, widgetId, params },
});

export const saveLobbyGeneralWidget = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_GENERAL_WIDGET,
  payload: { data },
});

export const resetLobbyGeneralWidget = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_GENERAL_WIDGET,
});

export const getLobbyJackpotWidget = (lobbyId, widgetId, params) => ({
  type: LobbyManagementActionType.GET_LOBBY_JACKPOT_WIDGET,
  payload: { lobbyId, widgetId, params },
});

export const saveLobbyJackpotWidget = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_JACKPOT_WIDGET,
  payload: { data },
});

export const resetLobbyJackpotWidget = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_JACKPOT_WIDGET,
});

export const changeLobbyGeneralWidgetStatus = (lobbyId, widgetId, data, name) => ({
  type: LobbyManagementActionType.CHANGE_LOBBY_GENERAL_WIDGET_STATUS,
  payload: {
    lobbyId,
    widgetId,
    data,
    name,
  },
});

export const changeLobbyJackpotWidgetStatus = (lobbyId, widgetId, data, name) => ({
  type: LobbyManagementActionType.CHANGE_LOBBY_JACKPOT_WIDGET_STATUS,
  payload: {
    lobbyId,
    widgetId,
    data,
    name,
  },
});

export const manageLobbyGeneralWidget = (lobbyId, widgetId, data, name) => ({
  type: LobbyManagementActionType.MANAGE_LOBBY_GENERAL_WIDGET,
  payload: {
    lobbyId,
    widgetId,
    data,
    name,
  },
});

export const manageLobbyJackpotWidget = (lobbyId, widgetId, data, name) => ({
  type: LobbyManagementActionType.MANAGE_LOBBY_JACKPOT_WIDGET,
  payload: {
    lobbyId,
    widgetId,
    data,
    name,
  },
});

export const getLobbyWidgets = (lobbyId, params, sectionType) => ({
  type: LobbyManagementActionType.GET_LOBBY_WIDGETS,
  payload: { lobbyId, params, sectionType },
});

export const saveLobbyWidgets = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_WIDGETS,
  payload: { data },
});

export const resetLobbyWidgets = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_WIDGETS,
});

export const reorderLobbyWidgets = (lobbyId, widgetId, data) => ({
  type: LobbyManagementActionType.REORDER_LOBBY_WIDGETS,
  payload: { lobbyId, widgetId, data },
});

export const getLobbyHomeGameWidget = (widgetId) => ({
  type: LobbyManagementActionType.GET_LOBBY_HOME_GAME_WIDGET,
  payload: {
    widgetId,
  },
});

export const saveLobbyWidget = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_WIDGET,
  payload: { data },
});

export const resetLobbyWidget = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_WIDGET,
});

export const createLobbyHomeGameWidget = (lobbyId, data, name) => ({
  type: LobbyManagementActionType.CREATE_LOBBY_HOME_GAME_WIDGET,
  payload: { lobbyId, data, name },
});

export const editLobbyHomeGameWidget = (widgetId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_HOME_GAME_WIDGET,
  payload: { widgetId, data, name },
});

export const deleteLobbyHomeGameWidget = (id, lobbyId, name) => ({
  type: LobbyManagementActionType.DELETE_LOBBY_HOME_GAME_WIDGET,
  payload: {
    id,
    lobbyId,
    name,
  },
});

export const changeLobbyWidgetStatus = (widgetId, data, name) => ({
  type: LobbyManagementActionType.CHANGE_LOBBY_WIDGET_STATUS,
  payload: {
    widgetId,
    data,
    name,
  },
});

export const getLobbyHomeTopWinWidget = (widgetId) => ({
  type: LobbyManagementActionType.GET_LOBBY_HOME_TOP_WIN_WIDGET,
  payload: {
    widgetId,
  },
});

export const createLobbyHomeTopWinWidget = (lobbyId, data, name) => ({
  type: LobbyManagementActionType.CREATE_LOBBY_HOME_TOP_WIN_WIDGET,
  payload: { lobbyId, data, name },
});

export const editLobbyHomeTopWinWidget = (widgetId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_HOME_TOP_WIN_WIDGET,
  payload: { widgetId, data, name },
});

export const deleteLobbyHomeTopWinWidget = (id, lobbyId, name) => ({
  type: LobbyManagementActionType.DELETE_LOBBY_HOME_TOP_WIN_WIDGET,
  payload: { id, lobbyId, name },
});

export const getLobbyActionLinkWidget = (widgetId) => ({
  type: LobbyManagementActionType.GET_LOBBY_ACTION_LINK_WIDGET,
  payload: { widgetId },
});

export const createLobbyActionLinkWidget = (lobbyId, data, name, sectionType) => ({
  type: LobbyManagementActionType.CREATE_LOBBY_ACTION_LINK_WIDGET,
  payload: {
    lobbyId,
    data,
    name,
    sectionType,
  },
});

export const editLobbyActionLinkWidget = (widgetId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_ACTION_LINK_WIDGET,
  payload: { widgetId, data, name },
});

export const deleteLobbyActionLinkWidget = (id, lobbyId, name) => ({
  type: LobbyManagementActionType.DELETE_LOBBY_ACTION_LINK_WIDGET,
  payload: { id, lobbyId, name },
});

export const getLobbyHomeTopXWidget = (widgetId) => ({
  type: LobbyManagementActionType.GET_LOBBY_HOME_TOP_X_WIDGET,
  payload: {
    widgetId,
  },
});

export const createLobbyHomeTopXWidget = (lobbyId, data, name) => ({
  type: LobbyManagementActionType.CREATE_LOBBY_HOME_TOP_X_WIDGET,
  payload: { lobbyId, data, name },
});

export const editLobbyHomeTopXWidget = (widgetId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_HOME_TOP_X_WIDGET,
  payload: { widgetId, data, name },
});

export const deleteLobbyHomeTopXWidget = (id, lobbyId, name) => ({
  type: LobbyManagementActionType.DELETE_LOBBY_HOME_TOP_X_WIDGET,
  payload: {
    id,
    lobbyId,
    name,
  },
});

export const getLobbyHomeProvidersAndGamesWidget = (widgetId) => ({
  type: LobbyManagementActionType.GET_LOBBY_HOME_PROVIDERS_AND_GAMES_WIDGET,
  payload: {
    widgetId,
  },
});

export const createLobbyHomeProvidersAndGamesWidget = (lobbyId, data, name) => ({
  type: LobbyManagementActionType.CREATE_LOBBY_HOME_PROVIDERS_AND_GAMES_WIDGET,
  payload: { lobbyId, data, name },
});

export const editLobbyHomeProvidersAndGamesWidget = (widgetId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_HOME_PROVIDERS_AND_GAMES_WIDGET,
  payload: { widgetId, data, name },
});

export const deleteLobbyHomeProvidersAndGamesWidget = (id, lobbyId, name) => ({
  type: LobbyManagementActionType.DELETE_LOBBY_HOME_PROVIDERS_AND_GAMES_WIDGET,
  payload: {
    id,
    lobbyId,
    name,
  },
});

export const getLobbyGroupWidget = (id) => ({
  type: LobbyManagementActionType.GET_LOBBY_GROUP_WIDGET,
  payload: { id },
});

export const createLobbyGroupWidget = (lobbyId, data, name, sectionType) => ({
  type: LobbyManagementActionType.CREATE_LOBBY_GROUP_WIDGET,
  payload: {
    lobbyId,
    data,
    name,
    sectionType,
  },
});

export const editLobbyGroupWidget = (widgetId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_GROUP_WIDGET,
  payload: { widgetId, data, name },
});

export const deleteLobbyGroupWidget = (id, lobbyId, name) => ({
  type: LobbyManagementActionType.DELETE_LOBBY_GROUP_WIDGET,
  payload: { id, lobbyId, name },
});

export const getLobbyHomeSingleGameWidget = (widgetId) => ({
  type: LobbyManagementActionType.GET_LOBBY_HOME_SINGLE_GAME_WIDGET,
  payload: { widgetId },
});

export const createLobbyHomeSingleGameWidget = (lobbyId, data, name) => ({
  type: LobbyManagementActionType.CREATE_LOBBY_HOME_SINGLE_GAME_WIDGET,
  payload: { lobbyId, data, name },
});

export const editLobbyHomeSingleGameWidget = (widgetId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_HOME_SINGLE_GAME_WIDGET,
  payload: { widgetId, data, name },
});

export const deleteLobbyHomeSingleGameWidget = (id, lobbyId, name) => ({
  type: LobbyManagementActionType.DELETE_LOBBY_HOME_SINGLE_GAME_WIDGET,
  payload: { id, lobbyId, name },
});

export const getLobbyProviderWidget = (widgetId, sectionType) => ({
  type: LobbyManagementActionType.GET_LOBBY_PROVIDER_WIDGET,
  payload: { widgetId, sectionType },
});

export const createLobbyProviderWidget = (lobbyId, data, name, sectionType) => ({
  type: LobbyManagementActionType.CREATE_LOBBY_PROVIDER_WIDGET,
  payload: {
    lobbyId,
    data,
    name,
    sectionType,
  },
});

export const editLobbyProviderWidget = (widgetId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_PROVIDER_WIDGET,
  payload: { widgetId, data, name },
});

export const deleteLobbyProviderWidget = (id, lobbyId, name) => ({
  type: LobbyManagementActionType.DELETE_LOBBY_PROVIDER_WIDGET,
  payload: { id, lobbyId, name },
});

export const getLobbyHomeCsvProviders = (lobbyId, data) => ({
  type: LobbyManagementActionType.GET_LOBBY_HOME_CSV_PROVIDERS,
  payload: { lobbyId, data },
});

export const saveLobbyHomeCsvProviders = (data) => ({
  type: LobbyManagementActionType.SAVE_LOBBY_HOME_CSV_PROVIDERS,
  payload: { data },
});

export const resetLobbyHomeCsvProviders = () => ({
  type: LobbyManagementActionType.RESET_LOBBY_HOME_CSV_PROVIDERS,
});

export const getLobbyHomeRandomGameWidget = (widgetId) => ({
  type: LobbyManagementActionType.GET_LOBBY_HOME_RANDOM_GAME_WIDGET,
  payload: { widgetId },
});

export const createLobbyHomeRandomGameWidget = (lobbyId, data, name) => ({
  type: LobbyManagementActionType.CREATE_LOBBY_HOME_RANDOM_GAME_WIDGET,
  payload: { lobbyId, data, name },
});

export const editLobbyHomeRandomGameWidget = (widgetId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_HOME_RANDOM_GAME_WIDGET,
  payload: { widgetId, data, name },
});

export const deleteLobbyHomeRandomGameWidget = (id, lobbyId, name) => ({
  type: LobbyManagementActionType.DELETE_LOBBY_HOME_RANDOM_GAME_WIDGET,
  payload: { id, lobbyId, name },
});

export const getLobbyHomeJackpotWidget = (id) => ({
  type: LobbyManagementActionType.GET_LOBBY_HOME_JACKPOT_WIDGET,
  payload: { id },
});

export const createLobbyHomeJackpotWidget = (lobbyId, data, name) => ({
  type: LobbyManagementActionType.CREATE_LOBBY_HOME_JACKPOT_WIDGET,
  payload: { lobbyId, data, name },
});

export const editLobbyHomeJackpotWidget = (widgetId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_HOME_JACKPOT_WIDGET,
  payload: { widgetId, data, name },
});

export const deleteLobbyHomeJackpotWidget = (id, lobbyId, name) => ({
  type: LobbyManagementActionType.DELETE_LOBBY_HOME_JACKPOT_WIDGET,
  payload: { id, lobbyId, name },
});

export const getLobbyHomeCollectionWidget = (id) => ({
  type: LobbyManagementActionType.GET_LOBBY_HOME_COLLECTION_WIDGET,
  payload: { id },
});

export const createLobbyHomeCollectionWidget = (lobbyId, data, name) => ({
  type: LobbyManagementActionType.CREATE_LOBBY_HOME_COLLECTION_WIDGET,
  payload: { lobbyId, data, name },
});

export const editLobbyHomeCollectionWidget = (widgetId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_HOME_COLLECTION_WIDGET,
  payload: { widgetId, data, name },
});

export const deleteLobbyHomeCollectionWidget = (id, lobbyId, name) => ({
  type: LobbyManagementActionType.DELETE_LOBBY_HOME_COLLECTION_WIDGET,
  payload: { id, lobbyId, name },
});

export const getLobbyHomeImageWidget = (id) => ({
  type: LobbyManagementActionType.GET_LOBBY_HOME_IMAGE_WIDGET,
  payload: { id },
});

export const createLobbyHomeImageWidget = (lobbyId, data, name) => ({
  type: LobbyManagementActionType.CREATE_LOBBY_HOME_IMAGE_WIDGET,
  payload: { lobbyId, data, name },
});

export const editLobbyHomeImageWidget = (widgetId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_HOME_IMAGE_WIDGET,
  payload: { widgetId, data, name },
});

export const deleteLobbyHomeImageWidget = (id, lobbyId, name) => ({
  type: LobbyManagementActionType.DELETE_LOBBY_HOME_IMAGE_WIDGET,
  payload: { id, lobbyId, name },
});

export const getLobbyHomeBannerWidget = (id) => ({
  type: LobbyManagementActionType.GET_LOBBY_HOME_BANNER_WIDGET,
  payload: { id },
});

export const createLobbyHomeBannerWidget = (lobbyId, data, name) => ({
  type: LobbyManagementActionType.CREATE_LOBBY_HOME_BANNER_WIDGET,
  payload: { lobbyId, data, name },
});

export const editLobbyHomeBannerWidget = (widgetId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_HOME_BANNER_WIDGET,
  payload: { widgetId, data, name },
});

export const deleteLobbyHomeBannerWidget = (id, lobbyId, name) => ({
  type: LobbyManagementActionType.DELETE_LOBBY_HOME_BANNER_WIDGET,
  payload: { id, lobbyId, name },
});

export const getLobbyHomeStoryWidget = (id) => ({
  type: LobbyManagementActionType.GET_LOBBY_HOME_STORY_WIDGET,
  payload: { id },
});

export const createLobbyHomeStoryWidget = (lobbyId, data, name) => ({
  type: LobbyManagementActionType.CREATE_LOBBY_HOME_STORY_WIDGET,
  payload: { lobbyId, data, name },
});

export const editLobbyHomeStoryWidget = (widgetId, data, name) => ({
  type: LobbyManagementActionType.EDIT_LOBBY_HOME_STORY_WIDGET,
  payload: { widgetId, data, name },
});

export const deleteLobbyHomeStoryWidget = (id, lobbyId, name) => ({
  type: LobbyManagementActionType.DELETE_LOBBY_HOME_STORY_WIDGET,
  payload: { id, lobbyId, name },
});
