import styled, { css } from 'styled-components';
import { Input, InputNumber } from 'antd';
import { truncate } from 'utils/styledHelpers';

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  input {
    height: 2.57rem;
  }
`;
const FormInputWrapper = styled.div`
  .ant-select-disabled  .ant-select-selection-item {
    color: ${(props) => props.theme.colors.filterLabelColor};
    width: 100%;
  }
  .ant-form-item-label > label {
    width: 100%;
  }
  .ant-input-affix-wrapper {
    border-radius: ${(props) => (props.isInput ? `0 ${props.theme.customBorderRadius.borderRadiusSmall} ${props.theme.customBorderRadius.borderRadiusSmall} 0` : props.theme.customBorderRadius.borderRadiusSmall)};
  }
  .ant-form-item-label > label.ant-form-item-required::before,
  .ant-form-item-label > label::after {
    display: ${(props) => (props.isHintLabel ? 'none !important' : '')};
  }

  .ant-picker-cell.ant-picker-cell-in-view,
  .ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view {
    vertical-align: middle;
  }
  .ant-form-item-required {
    .hint-label p:not(:empty):after {
      content: '*';
      margin-left: 2px;
    }
  }
  
  .ant-input-number-input {
    padding: 0 1.14rem;
    font-size: 1rem;
  }
  .ant-input {
    background: transparent;
  }
  .ant-input-number {
    background-color: transparent !important;
  }
  .ant-input-number {
   border-radius: 8px;
    &-handler {
      border-color: ${(props) => props.theme.colors.primaryBorder};
    &:active {
      background: ${(props) => props.theme.colors.filterLabelColor};
     }
    }
    &-wrap {
     border-left: 1px solid ${(props) => props.theme.colors.secondaryBorder};
    }
    &-down {
    border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
    }
   }
  }

  .ant-form-item-label > label:not(.ant-form-item-required)::after {
    display: none;
  }

  .ant-form-item-control-input-content,
  .ant-form-item-control-input-content .ant-input-affix-wrapper {
    height: ${(props) => (props.midSize ? '2.29rem' : `${props.smallSize ? '2em' : '2.57rem'}`)};
    
    .ant-input-disabled {
      opacity: 0.5;
      &:hover {
        border-color: #c2c6cb;
        border-right-width: 1px;
      }
    }
    
    & input.ant-input,
    span.ant-input-password,
    .ant-input-search {
      height: 100%;
      font-size: ${(props) => props.theme.typography.fontSizeDefault};
      margin-right: 1.14rem;

      .ant-input-group-addon {
        background: ${(props) => props.theme.colors.secondaryBg};
        border: 1px solid ${(props) => props.theme.colors.primaryBorder};
        border-left: none;
        .ant-input-search-button {
          background: transparent;
          border-color: transparent;
        }
        .ant-btn:hover,
        .ant-btn:focus {
          background: transparent;
          border-color: transparent;
        }
      }
    }
  }
  .ant-input-affix-wrapper {
    height: 2.29rem;
    background: transparent;
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    border-color: transparent;
  }
  .ant-input-number-input-wrap {
    height: ${(props) => (props.midSize ? '2.14rem' : `${props.smallSize ? '2em' : '2.57rem'}`)};
    display: initial;
    .ant-input-number-input {
      height: 100%;
      background-color: transparent;
      border-color: ${(props) => props.theme.colors.primaryBorder};
      color: ${(props) => props.theme.colors.primaryText};
      &::placeholder {
        color: ${(props) => props.theme.colors.filterLabelColor};
      }
    }
  }
  .ant-picker.ant-picker-range {
    width: 100%;
  }
  .ant-input-number {
    width: 100%;
    height: 100%;
  }
  .ant-input-search-icon {
    svg {
      width: 1.29rem;
      height: 1.29rem;
      fill: ${(props) => props.theme.colors.primaryBorder};
    }
    &::before {
      display: none;
    }
  }

  .ant-input-group .ant-input-affix-wrapper {
    .ant-input {
      background: transparent;
    }
  }
  .ant-input-clear-icon {
    color: ${(props) => props.theme.colors.secondaryColor};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.8rem;
    z-index: 1;
    svg {
      width: 1.14rem;
      height: 1.14rem;
    }
  }
  &.status {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .ant-form-item {
      flex-grow: 1;
      max-width: 100%;
      min-width: 0;
    }
    .ant-btn {
       margin:  0 0 0 0.57rem;
    }
    .ant-input {
      ${truncate()};
    }
    
  }
`;
const NotFormItemInputWrapper = styled.div`
  position: relative;
  margin-bottom: ${(props) => (props.hasMarginBottom ? '1.14rem' : 0)};
  .ant-input-search-button {
    position: relative;
    > .anticon.anticon-search {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;
    }
  }
  .ant-input-search-icon {
    font-size: 1.14rem;
  }
  .ant-input-group-addon {
    background-color: transparent;
  }
  .ant-input-clear-icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 3rem;
    font-size: 1.1rem;
    background: ${(props) => props.theme.colors.secondaryBg};
    z-index: 22;
    > svg {
      fill: ${(props) => props.theme.colors.secondaryColor};
    }
  }
  .ant-btn-icon-only {
    height: 2.5rem;
    width: 2.8rem;
    vertical-align: middle;
  }
  .ant-btn-icon-only > * {
    font-size: 1.14rem;
  }
  .ant-input {
    margin-right: 2rem;
  }
  .ant-input-group > .ant-input-group-addon {
    position: absolute;
    z-index: 11;
    top: 50%;
    transform: translateY(-50%);
    right: 2.87rem;
    display: block;
    > button {
      background: transparent;
    }
    .ant-btn,
    .ant-btn:hover {
      border: none;
    }
  }
  .ant-input-search {
    height: 2.57rem;
    &-icon::before {
      content: none;
    }
  }
`;
const StyledDisabledInputField = styled.div`
  ${(props) => props.isDisabled
    && css`
      .ant-select-selection-item {
        color: ${props.theme.colors.filterLabelColor}!important;
      }
      .ant-select-multiple.ant-select-allow-clear .ant-select-selector,
      .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        cursor: not-allowed;
      }
    `};
`;

const StyledInputAddGroup = styled.div`
  font-size: 1rem;

  > div:first-child {
    margin-bottom: 0.57rem;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.primaryText};
  }
  svg {
    margin-left: 0.57rem;
  }
  .ant-btn {
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    font-size: 1rem;
    height: 2.57rem;
    padding: 0 1.14rem;
  }
`;

const StyledClearIcon = styled.div`
  .ant-input-affix-wrapper > input.ant-input {
    padding-right: 1.3rem;
  }
  .ant-input-clear-icon {
    color: ${(props) => props.theme.colors.secondaryColor};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.8rem;
    z-index: 1;
    svg {
      width: 1.14rem;
      height: 1.14rem;
    }
  }
`;
const StyledInputAddWrapper = styled(StyledClearIcon)`
  display: flex;
  align-items: center;
  .ant-btn {
    min-width: ${(props) => (props.tags ? '4.5rem' : 'auto')};
  }
  > span {
    height: 100%;
    margin-right: ${(props) => (props.tags ? '0.57rem' : '0')};
  }
  height: ${(props) => (props.isSmall ? '2.28rem' : '2.58rem;')};
  margin-right: 0.57rem;
`;
const StyledEditablePermissions = styled.td`
  overflow: visible !important;
  .ant-form-item {
    position: relative;
    left: 0;
  }
  ${({ permissionsStyled }) => permissionsStyled
    && `
    &:first-of-type {
      padding: 0 2.3rem 0 1.1rem !important;
    }
  `}
  .ant-form-item-control-input {
    width: 100%;
    max-width: 20rem;
    height: ${(props) => (props.isTextArea ? 'auto' : '2.2rem')};
    .ant-input {
      background-color: ${(props) => props.theme.colors.selectBg};
    }
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) {
      .ant-select-selector {
        background-color: ${(props) => props.theme.colors.selectBg};
        border-color: ${(props) => props.theme.colors.primaryBorder};
      }
    }
  }
  .ant-form-item-explain {
    &-error {
      max-width: 20rem;
    }
  }
`;

const StyledSavePermissions = styled.td`
  position: sticky !important;
  z-index: 2;
  text-align: right;
  right: 0;
  padding: ${(props) => (props.permissionsStyled ? '1.14rem .5rem' : '')} !important;
  a {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    font-size: ${(props) => (props.permissionsStyled ? props.theme.typography.fontSizeDefault : props.theme.typography.fontSizeMiddle)};
  }
  a:first-child {
    margin-right: 1.14rem;
    color: ${(props) => props.theme.colors.secondaryColor};
  }
  a:last-child {
    color: ${(props) => props.theme.colors.blue};
  }
  > div {
    display: inline-flex;
    gap: 1.14rem;
    margin-top: 0.4rem;
  }
`;

// separate Input
const StyledAmountWrapper = styled.div`
  .ant-space-compact {
    display: flex;
    text-align: center;
    height: ${(props) => (props.filter ? '2.29rem' : '2.58rem')};
    margin-right: 0 !important;
    border-right: 0;

    div {
      height: 100%;
    }

    .ant-form-item-explain {
      position: absolute;
      z-index: 555;
      top: 100%;
      &-error {
        height: auto;
      }
    }
  }
  .ant-space-compact > *:not(:last-child) {
    margin-left: -1px;
  }
  .ant-input-number,
  .ant-input-affix-wrapper {
    background: transparent;
  }
  .ant-input,
  .ant-input-affix-wrapper {
    border-left: none;
    border-right: none;
  }
  .ant-input-number-input {
    ::placeholder {
      color: ${(props) => props.theme.colors.filterLabelColor};
    }
    color: ${(props) => props.theme.colors.primaryText};
  }

  .ant-input-number-handler {
    border-left: 1px solid ${(props) => props.theme.colors.secondaryBorder};
    &:active {
      background: ${(props) => props.theme.colors.secondaryBorder};
    }
  }
  .ant-input-number-handler-down {
    border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  }
`;
const StyledLabelText = styled.label`
  display: block;
  color: ${(props) => props.theme.colors.filterLabelColor} !important;
  font-size: 1rem !important;
  padding-bottom: 8px;
`;
const StyledSeparateWrapper = styled(Input)`
  width: 3.42rem !important;
  max-height: 2.58rem;
  height: auto !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background: ${(props) => props.theme.colors.secondaryBg}!important;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  pointer-events: none;
  background: transparent !important;
  border-color: ${(props) => props.theme.colors.primaryBorder}!important;
`;
const StyledNumericInputLeft = styled(Input)`
  &.ant-input {
    width: 100%;
    height: 100%;
    transition: none;
    position: relative;
    z-index: 45;
    font-size: 1rem;
    text-align: left;
    border: 1px solid ${(props) => props.theme.colors.primaryBorder};
    border-radius: ${(props) => `${props.theme.customBorderRadius.borderRadiusSmall} 0 0 ${props.theme.customBorderRadius.borderRadiusSmall}`} !important;
    border-right-color: transparent !important;

    &.ant-input-status-error:focus,
    .ant-input-status-error:hover {
      border-color: ${(props) => props.theme.colors.danger};
    }

    &:focus,
    &:hover {
      border-color: ${(props) => props.theme.colors.blue};
    }
  }
`;
const StyledNumericInputRight = styled(Input)`
  &.ant-input {
    width: 100%;
    border-left: none;
    font-size: 1rem;
    text-align: left;
    transition: none;
    height: 100%;
    border: 1px solid ${(props) => props.theme.colors.primaryBorder};
    border-radius: 0 ${(props) => `${props.theme.customBorderRadius.borderRadiusSmall} ${props.theme.customBorderRadius.borderRadiusSmall} 0`} !important;
    border-left-color: transparent !important;

    &:focus,
    &:hover {
      border: 1px solid ${(props) => props.theme.colors.blue};
    }
  }
`;
const StyledInfoLabel = styled.div`
  .ant-form-item-label > label::after {
    left: -2rem;
  }
`;
const StyledCheckboxWrapper = styled.div`
  .ant-checkbox-group {
    display: flex;
  }
  label {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
`;

const StyledShowOneError = styled.div`
  position: relative;
  .ant-col {
    position: initial;
  }
  .ant-form-item-has-error {
    + .ant-input.ant-input-disabled {
      border-color: ${(props) => props.theme.colors.danger}!important;
    }
    ~ .ant-form-item .ant-input {
      border-top: 1px solid ${(props) => props.theme.colors.danger};
      border-bottom: 1px solid ${(props) => props.theme.colors.danger};
      border-right: 1px solid ${(props) => props.theme.colors.danger};
      &:focus {
        box-shadow: 0 0 2px ${(props) => props.theme.colors.danger};
        border-left: 1px solid ${(props) => props.theme.colors.danger};
      }
      &:hover {
        border: 1px solid ${(props) => props.theme.colors.danger};
        box-shadow: 0 0 2px ${(props) => props.theme.colors.danger};
      }
    }
  }
  .ant-space-compact {
    position: relative;

    .ant-form-item-control-input {
      height: 100%;
    }
    .ant-form-item-control-input + div {
      height: auto;
    }
  }
`;

const StyledInputInTable = styled.div`
  .ant-form-item {
    max-width: 15.7rem;

    .ant-form-item-control {
      .ant-input-affix-wrapper {
        background-color: ${(props) => props.theme.colors.secondaryBg};
      }
    }
  }
`;

const StyledCheckboxWrapperInTable = styled.div`
  display: inline-block;
`;

export {
  FormInputWrapper,
  NotFormItemInputWrapper,
  StyledDisabledInputField,
  StyledInputNumber,
  StyledInputAddGroup,
  StyledInputAddWrapper,
  StyledClearIcon,
  StyledEditablePermissions,
  StyledSavePermissions,
  StyledAmountWrapper,
  StyledLabelText,
  StyledNumericInputLeft,
  StyledNumericInputRight,
  StyledSeparateWrapper,
  StyledInfoLabel,
  StyledShowOneError,
  StyledCheckboxWrapper,
  StyledInputInTable,
  StyledCheckboxWrapperInTable,
};
