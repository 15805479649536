import CampaignV2ActionType from 'actions/campaignV2/campaignV2ActionType';

const initialState = {
  campaigns: {
    data: [],
    total: 0,
  },
  campaign: {},
  createdCampaignId: null,
  generalConfigEndedStatus: false,
  participationGroup: {},
  participationGroups: [],
  participationGroupEndedStatus: false,
  freeBetBonus: {},
  freeSpinBonus: {},
  combinedBonus: {},
  specialBonus: {},
  bonusEndedStatus: false,
  bonuses: [],
  triggerConditions: [],
  triggerCondition: {},
  triggerConditionEndedStatus: false,
  bonusContents: [],
  bonusContent: {},
  bonusContentEndedStatus: false,
  campaignContents: [],
  campaignContent: {},
  campaignContentEndedStatus: false,
  offersContents: [],
  offersContent: {},
  offersContentEndedStatus: false,
  units: [],
  unit: {},
  unitEndedStatus: false,
  publishEndedStatus: false,
  campaignV2Statistics: {
    data: [],
    total: 0,
  },
  campaignsV2Units: {
    data: [],
    total: 0,
  },
  campaignV2UnitPlayers: {
    data: [],
    total: 0,
  },
  freeBetBonuses: {
    data: [],
    total: 0,
  },
  spinBonuses: {
    data: [],
    total: 0,
  },
  wagerBonuses: {
    data: [],
    total: 0,
  },
  specialBonuses: {
    data: [],
    total: 0,
  },
  crossPlatformCampaignsV2: {},
  crossPlatformCampaignV2: {},
  crossPlatformCampaignV2FreeBetBonus: {},
  crossPlatformCampaignV2FreeSpinBonus: {},
  crossPlatformCampaignV2CombinedBonus: {},
  crossPlatformCampaignV2SpecialBonus: {},
  crossPlatformCampaignV2Bonuses: [],
  crossPlatformCampaignV2BonusContents: [],
  crossPlatformCampaignV2BonusContent: {},
  crossPlatformCampaignV2Units: [],
  crossPlatformCampaignV2Unit: {},
  crossPlatformCampaignV2Statistics: {},
  playerFreeBetBonusesV2: {
    data: [],
    total: 0,
  },
  playerSpinBonusesV2: {
    data: [],
    total: 0,
  },
  playerCombinedBonusesV2: {
    data: [],
    total: 0,
  },
  playerSpecialBonusesV2: {
    data: [],
    total: 0,
  },
  promoCodesV2Packs: {
    data: [],
    total: 0,
  },
  promoCodesV2: [],
  usedPromoCodesV2: {
    data: [],
    total: 0,
  },
  promoCodeV2: {},
};

const campaignV2Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case CampaignV2ActionType.SAVE_CAMPAIGNS_V2:
    return {
      ...state,
      campaigns: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGNS_V2:
    return {
      ...state,
      campaigns: {
        data: [],
        total: 0,
      },
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2:
    return {
      ...state,
      campaign: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2:
    return {
      ...state,
      campaign: {},
    };
  case CampaignV2ActionType.SAVE_CREATED_CAMPAIGN_V2_ID:
    return {
      ...state,
      createdCampaignId: payload.id,
    };
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_GENERAL_CONFIG_ENDED_STATUS:
    return {
      ...state,
      generalConfigEndedStatus: payload.status,
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_PARTICIPATION_GROUP:
    return {
      ...state,
      participationGroup: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_PARTICIPATION_GROUP:
    return {
      ...state,
      participationGroup: {},
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_PARTICIPATION_GROUPS:
    return {
      ...state,
      participationGroups: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_PARTICIPATION_GROUPS:
    return {
      ...state,
      participationGroups: [],
    };
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_PARTICIPATION_GROUP_ENDED_STATUS:
    return {
      ...state,
      participationGroupEndedStatus: payload.status,
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_FREE_BET_BONUS:
    return {
      ...state,
      freeBetBonus: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_FREE_BET_BONUS:
    return {
      ...state,
      freeBetBonus: {},
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_FREE_SPIN_BONUS:
    return {
      ...state,
      freeSpinBonus: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_FREE_SPIN_BONUS:
    return {
      ...state,
      freeSpinBonus: {},
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_COMBINED_BONUS:
    return {
      ...state,
      combinedBonus: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_COMBINED_BONUS:
    return {
      ...state,
      combinedBonus: {},
    };
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_BONUS_ENDED_STATUS:
    return {
      ...state,
      bonusEndedStatus: payload.status,
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_SPECIAL_BONUS:
    return {
      ...state,
      specialBonus: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_SPECIAL_BONUS:
    return {
      ...state,
      specialBonus: {},
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_BONUSES:
    return {
      ...state,
      bonuses: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_BONUSES:
    return {
      ...state,
      bonuses: [],
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_TRIGGER_CONDITIONS:
    return {
      ...state,
      triggerConditions: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_TRIGGER_CONDITIONS:
    return {
      ...state,
      triggerConditions: [],
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_TRIGGER_CONDITION:
    return {
      ...state,
      triggerCondition: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_TRIGGER_CONDITION:
    return {
      ...state,
      triggerCondition: {},
    };
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_TRIGGER_CONDITION_ENDED_STATUS: {
    return {
      ...state,
      triggerConditionEndedStatus: payload.status,
    };
  }
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_BONUS_CONTENTS:
    return {
      ...state,
      bonusContents: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_BONUS_CONTENTS:
    return {
      ...state,
      bonusContents: [],
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_BONUS_CONTENT:
    return {
      ...state,
      bonusContent: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_BONUS_CONTENT:
    return {
      ...state,
      bonusContent: {},
    };
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_BONUS_CONTENT_ENDED_STATUS: {
    return {
      ...state,
      bonusContentEndedStatus: payload.status,
    };
  }
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_CAMPAIGN_CONTENTS:
    return {
      ...state,
      campaignContents: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_CAMPAIGN_CONTENTS:
    return {
      ...state,
      campaignContents: [],
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_CAMPAIGN_CONTENT:
    return {
      ...state,
      campaignContent: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_CAMPAIGN_CONTENT:
    return {
      ...state,
      campaignContent: {},
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_OFFERS_CONTENTS:
    return {
      ...state,
      offersContents: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_OFFERS_CONTENTS:
    return {
      ...state,
      offersContents: [],
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_OFFERS_CONTENT:
    return {
      ...state,
      offersContent: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_OFFERS_CONTENT:
    return {
      ...state,
      offersContent: {},
    };
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_OFFERS_CONTENT_ENDED_STATUS: {
    return {
      ...state,
      offersContentEndedStatus: payload.status,
    };
  }
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_CAMPAIGN_CONTENT_ENDED_STATUS: {
    return {
      ...state,
      campaignContentEndedStatus: payload.status,
    };
  }
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_UNITS:
    return {
      ...state,
      units: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_UNITS:
    return {
      ...state,
      units: [],
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_UNIT:
    return {
      ...state,
      unit: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_UNIT:
    return {
      ...state,
      unit: {},
    };
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_UNIT_ENDED_STATUS:
    return {
      ...state,
      unitEndedStatus: payload.status,
    };
  case CampaignV2ActionType.CHANGE_CAMPAIGN_V2_PUBLISH_ENDED_STATUS: {
    return {
      ...state,
      publishEndedStatus: payload.status,
    };
  }
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_STATISTICS: {
    return {
      ...state,
      campaignV2Statistics: payload.data,
    };
  }
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_STATISTICS: {
    return {
      ...state,
      campaignV2Statistics: {
        data: [],
        total: 0,
      },
    };
  }
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_UNIT_PLAYERS: {
    return {
      ...state,
      campaignV2UnitPlayers: payload.data,
    };
  }
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_UNIT_PLAYERS: {
    return {
      ...state,
      campaignV2UnitPlayers: {
        data: [],
        total: 0,
      },
    };
  }
  case CampaignV2ActionType.SAVE_CAMPAIGNS_V2_UNITS:
    return {
      ...state,
      campaignsV2Units: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGNS_V2_UNITS:
    return {
      ...state,
      campaignsV2Units: {
        data: [],
        total: 0,
      },
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_FREE_BET_BONUSES:
    return {
      ...state,
      freeBetBonuses: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_FREE_BET_BONUSES:
    return {
      ...state,
      freeBetBonuses: {
        data: [],
        total: 0,
      },
    };

  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_SPIN_BONUSES:
    return {
      ...state,
      spinBonuses: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_SPIN_BONUSES:
    return {
      ...state,
      spinBonuses: {
        data: [],
        total: 0,
      },
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_WAGER_BONUSES:
    return {
      ...state,
      wagerBonuses: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_WAGER_BONUSES:
    return {
      ...state,
      wagerBonuses: {
        data: [],
        total: 0,
      },
    };
  case CampaignV2ActionType.SAVE_CAMPAIGN_V2_SPECIAL_BONUSES:
    return {
      ...state,
      specialBonuses: payload.data,
    };
  case CampaignV2ActionType.RESET_CAMPAIGN_V2_SPECIAL_BONUSES:
    return {
      ...state,
      specialBonuses: {
        data: [],
        total: 0,
      },
    };
  case CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGNS_V2:
    return {
      ...state,
      crossPlatformCampaignsV2: payload.data,
    };
  case CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGNS_V2:
    return {
      ...state,
      crossPlatformCampaignsV2: {},
    };
  case CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2:
    return {
      ...state,
      crossPlatformCampaignV2: payload.data,
    };
  case CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2:
    return {
      ...state,
      crossPlatformCampaignV2: {},
    };
  case CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS:
    return {
      ...state,
      crossPlatformCampaignV2FreeBetBonus: payload.data,
    };
  case CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS:
    return {
      ...state,
      crossPlatformCampaignV2FreeBetBonus: {},
    };
  case CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS:
    return {
      ...state,
      crossPlatformCampaignV2FreeSpinBonus: payload.data,
    };
  case CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS:
    return {
      ...state,
      crossPlatformCampaignV2FreeSpinBonus: {},
    };
  case CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS:
    return {
      ...state,
      crossPlatformCampaignV2CombinedBonus: payload.data,
    };
  case CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS:
    return {
      ...state,
      crossPlatformCampaignV2CombinedBonus: {},
    };
  case CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS:
    return {
      ...state,
      crossPlatformCampaignV2SpecialBonus: payload.data,
    };
  case CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS:
    return {
      ...state,
      crossPlatformCampaignV2SpecialBonus: {},
    };
  case CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_BONUSES:
    return {
      ...state,
      crossPlatformCampaignV2Bonuses: payload.data,
    };
  case CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_BONUSES:
    return {
      ...state,
      crossPlatformCampaignV2Bonuses: [],
    };
  case CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENTS:
    return {
      ...state,
      crossPlatformCampaignV2BonusContents: payload.data,
    };
  case CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENTS:
    return {
      ...state,
      crossPlatformCampaignV2BonusContents: [],
    };
  case CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT:
    return {
      ...state,
      crossPlatformCampaignV2BonusContent: payload.data,
    };
  case CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT:
    return {
      ...state,
      crossPlatformCampaignV2BonusContent: {},
    };
  case CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_UNITS:
    return {
      ...state,
      crossPlatformCampaignV2Units: payload.data,
    };
  case CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_UNITS:
    return {
      ...state,
      crossPlatformCampaignV2Units: [],
    };
  case CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_UNIT:
    return {
      ...state,
      crossPlatformCampaignV2Unit: payload.data,
    };
  case CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_UNIT:
    return {
      ...state,
      crossPlatformCampaignV2Unit: {},
    };
  case CampaignV2ActionType.SAVE_CROSS_PLATFORM_CAMPAIGN_V2_STATISTICS:
    return {
      ...state,
      crossPlatformCampaignV2Statistics: payload.data,
    };
  case CampaignV2ActionType.RESET_CROSS_PLATFORM_CAMPAIGN_V2_STATISTICS:
    return {
      ...state,
      crossPlatformCampaignV2Statistics: {},
    };
  case CampaignV2ActionType.SAVE_PLAYER_FREE_BET_BONUSES_V2:
    return {
      ...state,
      playerFreeBetBonusesV2: payload.data,
    };
  case CampaignV2ActionType.RESET_PLAYER_FREE_BET_BONUSES_V2:
    return {
      ...state,
      playerFreeBetBonusesV2: {
        data: [],
        total: 0,
      },
    };
  case CampaignV2ActionType.SAVE_PLAYER_SPIN_BONUSES_V2:
    return {
      ...state,
      playerSpinBonusesV2: payload.data,
    };
  case CampaignV2ActionType.RESET_PLAYER_SPIN_BONUSES_V2:
    return {
      ...state,
      playerSpinBonusesV2: {
        data: [],
        total: 0,
      },
    };
  case CampaignV2ActionType.SAVE_PLAYER_COMBINED_BONUSES_V2:
    return {
      ...state,
      playerCombinedBonusesV2: payload.data,
    };
  case CampaignV2ActionType.RESET_PLAYER_COMBINED_BONUSES_V2:
    return {
      ...state,
      playerCombinedBonusesV2: {
        data: [],
        total: 0,
      },
    };
  case CampaignV2ActionType.SAVE_PLAYER_SPECIAL_BONUSES_V2:
    return {
      ...state,
      playerSpecialBonusesV2: payload.data,
    };
  case CampaignV2ActionType.RESET_PLAYER_SPECIAL_BONUSES_V2:
    return {
      ...state,
      playerSpecialBonusesV2: {
        data: [],
        total: 0,
      },
    };
  case CampaignV2ActionType.SAVE_PROMO_CODES_V2_PACKS:
    return {
      ...state,
      promoCodesV2Packs: payload.data,
    };
  case CampaignV2ActionType.RESET_PROMO_CODES_V2_PACKS:
    return {
      ...state,
      promoCodesV2Packs: {
        data: [],
        total: 0,
      },
    };
  case CampaignV2ActionType.SAVE_PROMO_CODES_V2:
    return {
      ...state,
      promoCodesV2: payload.data,
    };
  case CampaignV2ActionType.RESET_PROMO_CODES_V2: {
    return {
      ...state,
      promoCodesV2: [],
    };
  }
  case CampaignV2ActionType.SAVE_USED_PROMO_CODES_V2:
    return {
      ...state,
      usedPromoCodesV2: payload.data,
    };
  case CampaignV2ActionType.RESET_USED_PROMO_CODES_V2: {
    return {
      ...state,
      usedPromoCodesV2: {
        data: [],
        total: 0,
      },
    };
  }
  case CampaignV2ActionType.SAVE_PROMO_CODE_V2:
    return {
      ...state,
      promoCodeV2: payload.data,
    };
  case CampaignV2ActionType.RESET_PROMO_CODE_V2:
    return {
      ...state,
      promoCodeV2: {},
    };
  default:
    return state;
  }
};

export default campaignV2Reducer;
