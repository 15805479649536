import { all } from 'redux-saga/effects';
import playerSaga from './playerSaga';
import authSaga from './authSaga';
import brandSaga from './brandSaga';
import brandSettingSaga from './brandSettingSaga';
import partnerSaga from './partnerSaga';
import userSaga from './userSaga';
import permissionSaga from './permissionSaga';
import permissionGroupSaga from './permissionGroupSaga';
import staticCollectionSaga from './staticCollectionSaga';
import notificationSaga from './notificationSaga';
import translationSaga from './translationSaga';
import templateSaga from './templateSaga';
import fileManagerSaga from './fileManagerSaga';
import gameSaga from './gameSaga';
import promoCodeSaga from './promoCodeSaga';
import bonusSaga from './bonusSaga';
import thirdPartyServicesSaga from './thirdPartyServicesSaga';
import cashBackSaga from './cashBackSaga';
import quickSearchSaga from './quickSearchSaga';
import referralSaga from './referralSaga';
import blacklistSaga from './blacklistSaga';
import tagSaga from './tagSaga';
import auditLogSaga from './auditLogSaga';
import paymentSaga from './paymentSaga';
import brandPaymentSaga from './brandPaymentSaga';
import reportingSaga from './reportingSaga';
import betSaga from './betSaga';
import operatorLimitSaga from './operatorLimitSaga';
import ipWhitelistSaga from './ipWhitelistSaga';
import legalAgreementSaga from './legalAgreementSaga';
import brandAutomationSaga from './brandAutomationSaga';
import accountUpdatesSaga from './accountUpdatesSaga';
import kycSaga from './kycSaga';
import cashierSaga from './cashierSaga';
import gameSegmentSaga from './gameSegmentSaga';
import playerSegmentSaga from './playerSegmentSaga';
import playerPerformanceSaga from './playerPerformanceSaga';
import dashboardSaga from './dashboardSaga';
import localeSaga from './localeSaga';
import seoSettingSaga from './seoSettingSaga';
import systemConversionSaga from './systemConversionSaga';
import gameManagementSaga from './gameManagementSaga';
import similarAccountSaga from './similarAccountSaga';
import blockedProviderSaga from './blockedProviderSaga';
import paymentMethodSaga from './paymentMethodSaga';
import overviewSaga from './overviewSaga';
import resourceSaga from './resourceSaga';
import previousAccount from './previousAccountSaga';
import reasonsSaga from './reasonsSaga';
import campaignSaga from './campaignSaga';
import taxesSaga from './taxesSaga';
import agentSystemSaga from './agentSystemSaga';
import devToolSaga from './devToolSaga';
import lobbyManagementSaga from './lobbyManagementSaga';
import promotionSaga from './promotionSaga';
import brandMarketSaga from './brandMarketSaga';
import exportCenterSaga from './exportCenterSaga';
import pushNotificationSaga from './pushNotificationSaga';
import playerWalletSaga from './playerWalletSaga';
import regulatorySaga from './regulatorySaga';
import betStatusCorrectionSaga from './betStatusCorrectionSaga';
import releaseNote from './releaseNoteSaga';
import playerSession from './playerSessionSaga';
import campaignV2 from './campaignV2Saga';
import playerComplaint from './playerComplaintSaga';
import selfAssessments from './selfAssessmentSaga';
import cashBackV2 from './cashBackV2Saga';

export default function* rootSaga() {
  yield all([
    playerSaga(),
    authSaga(),
    brandSaga(),
    brandSettingSaga(),
    brandMarketSaga(),
    partnerSaga(),
    permissionSaga(),
    permissionGroupSaga(),
    userSaga(),
    staticCollectionSaga(),
    notificationSaga(),
    translationSaga(),
    templateSaga(),
    fileManagerSaga(),
    gameSaga(),
    promoCodeSaga(),
    bonusSaga(),
    thirdPartyServicesSaga(),
    cashBackSaga(),
    quickSearchSaga(),
    referralSaga(),
    tagSaga(),
    blacklistSaga(),
    auditLogSaga(),
    paymentSaga(),
    brandPaymentSaga(),
    reportingSaga(),
    betSaga(),
    operatorLimitSaga(),
    ipWhitelistSaga(),
    legalAgreementSaga(),
    brandAutomationSaga(),
    accountUpdatesSaga(),
    kycSaga(),
    cashierSaga(),
    gameSegmentSaga(),
    playerSegmentSaga(),
    playerPerformanceSaga(),
    dashboardSaga(),
    localeSaga(),
    seoSettingSaga(),
    systemConversionSaga(),
    gameManagementSaga(),
    similarAccountSaga(),
    blockedProviderSaga(),
    paymentMethodSaga(),
    overviewSaga(),
    resourceSaga(),
    previousAccount(),
    reasonsSaga(),
    campaignSaga(),
    taxesSaga(),
    agentSystemSaga(),
    devToolSaga(),
    lobbyManagementSaga(),
    promotionSaga(),
    exportCenterSaga(),
    pushNotificationSaga(),
    playerWalletSaga(),
    regulatorySaga(),
    betStatusCorrectionSaga(),
    releaseNote(),
    playerSession(),
    campaignV2(),
    playerComplaint(),
    selfAssessments(),
    cashBackV2(),
  ]);
}
