import RegulatoryActionType from 'actions/regulatory/regulatoryActionType';

export const getSoftwareChanges = (params) => ({
  type: RegulatoryActionType.GET_SOFTWARE_CHANGES,
  payload: { params },
});

export const exportSoftwareChanges = (params) => ({
  type: RegulatoryActionType.EXPORT_SOFTWARE_CHANGES,
  payload: { params },
});

export const getGameRatio = (params) => ({
  type: RegulatoryActionType.GET_GAME_RATIO,
  payload: { params },
});

export const exportGameRatio = (params) => ({
  type: RegulatoryActionType.EXPORT_GAME_RATIO,
  payload: { params },
});

export const getPlayerExclusion = (params) => ({
  type: RegulatoryActionType.GET_PLAYER_EXCLUSION,
  payload: { params },
});

export const exportPlayerExclusion = (params) => ({
  type: RegulatoryActionType.EXPORT_PLAYER_EXCLUSION,
  payload: { params },
});

export const getGameTypes = (params) => ({
  type: RegulatoryActionType.GET_GAME_TYPES_REPORT,
  payload: { params },
});

export const exportGameTypes = (params) => ({
  type: RegulatoryActionType.EXPORT_GAME_TYPES_REPORT,
  payload: { params },
});

export const getBalanceAdjustment = (params) => ({
  type: RegulatoryActionType.GET_BALANCE_ADJUSTMENT,
  payload: { params },
});

export const exportBalanceAdjustment = (params) => ({
  type: RegulatoryActionType.EXPORT_BALANCE_ADJUSTMENT,
  payload: { params },
});

export const getSessions = (params) => ({
  type: RegulatoryActionType.GET_SESSIONS,
  payload: { params },
});

export const exportSessions = (params) => ({
  type: RegulatoryActionType.EXPORT_SESSIONS,
  payload: { params },
});

export const getMirroringServer = (params) => ({
  type: RegulatoryActionType.GET_MIRRORING_SERVER,
  payload: { params },
});

export const exportMirroringServer = (params) => ({
  type: RegulatoryActionType.EXPORT_MIRRORING_SERVER,
  payload: { params },
});

export const getSafeServer = (params) => ({
  type: RegulatoryActionType.GET_SAFE_SERVER,
  payload: { params },
});

export const exportSafeServer = (params) => ({
  type: RegulatoryActionType.EXPORT_SAFE_SERVER,
  payload: { params },
});

export const getChangeNotification = (params) => ({
  type: RegulatoryActionType.GET_CHANGE_NOTIFICATION,
  payload: { params },
});

export const exportChangeNotification = (params) => ({
  type: RegulatoryActionType.EXPORT_CHANGE_NOTIFICATION,
  payload: { params },
});

export const addChangeNotification = (data) => ({
  type: RegulatoryActionType.ADD_CHANGE_NOTIFICATION,
  payload: { data },
});

export const getInaccessibilityServer = (params) => ({
  type: RegulatoryActionType.GET_INACCESSIBILITY_SERVER,
  payload: { params },
});

export const exportInaccessibilityServer = (params) => ({
  type: RegulatoryActionType.EXPORT_INACCESSIBILITY_SERVER,
  payload: { params },
});

export const addInaccessibilityServer = (data) => ({
  type: RegulatoryActionType.ADD_INACCESSIBILITY_SERVER,
  payload: { data },
});

export const deleteInaccessibilityServer = (id) => ({
  type: RegulatoryActionType.DELETE_INACCESSIBILITY_SERVER,
  payload: { id },
});

export const getSignificantEventsLargeTransactions = (params) => ({
  type: RegulatoryActionType.GET_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS,
  payload: { params },
});

export const exportSignificantEventsLargeTransactions = (params) => ({
  type: RegulatoryActionType.EXPORT_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS,
  payload: { params },
});

export const getSignificantEventsRestrictions = (params) => ({
  type: RegulatoryActionType.GET_SIGNIFICANT_EVENTS_RESTRICTIONS,
  payload: { params },
});

export const exportSignificantEventsRestrictions = (params) => ({
  type: RegulatoryActionType.EXPORT_SIGNIFICANT_EVENTS_RESTRICTIONS,
  payload: { params },
});

export const getJackpotWinRatio = (params) => ({
  type: RegulatoryActionType.GET_JACKPOT_WIN_RATIO,
  payload: { params },
});

export const exportJackpotWinRatio = (params) => ({
  type: RegulatoryActionType.EXPORT_JACKPOT_WIN_RATIO,
  payload: { params },
});

export const getJackpotConfiguration = (params) => ({
  type: RegulatoryActionType.GET_JACKPOT_CONFIGURATION,
  payload: { params },
});

export const exportJackpotConfiguration = (params) => ({
  type: RegulatoryActionType.EXPORT_JACKPOT_CONFIGURATION,
  payload: { params },
});

export const getPromotionalItems = (params) => ({
  type: RegulatoryActionType.GET_PROMOTIONAL_ITEMS,
  payload: { params },
});

export const exportPromotionalItems = (params) => ({
  type: RegulatoryActionType.EXPORT_PROMOTIONAL_ITEMS,
  payload: { params },
});

export const getTaxationDaily = (params) => ({
  type: RegulatoryActionType.GET_TAXATION_DAILY,
  payload: { params },
});

export const exportTaxationDaily = (params) => ({
  type: RegulatoryActionType.EXPORT_TAXATION_DAILY,
  payload: { params },
});

export const getTaxationMonthly = (params) => ({
  type: RegulatoryActionType.GET_TAXATION_MONTHLY,
  payload: { params },
});

export const exportTaxationMonthly = (params) => ({
  type: RegulatoryActionType.EXPORT_TAXATION_MONTHLY,
  payload: { params },
});

export const exportBettingSelfProtectionReport = (params, name) => ({
  type: RegulatoryActionType.EXPORT_BETTING_SELF_PROTECTION_REPORT,
  payload: { params, name },
});

export const getExclusions = (params) => ({
  type: RegulatoryActionType.GET_EXCLUSIONS,
  payload: { params },
});

export const exportExclusions = (params) => ({
  type: RegulatoryActionType.EXPORT_EXCLUSIONS,
  payload: { params },
});

export const saveRegulatoryReport = (data) => ({
  type: RegulatoryActionType.SAVE_REGULATORY_REPORT,
  payload: { data },
});

export const resetRegulatoryReport = () => ({
  type: RegulatoryActionType.RESET_REGULATORY_REPORT,
});

export const getMonthlyEconomicReport = (params) => ({
  type: RegulatoryActionType.GET_MONTHLY_ECONOMIC_REPORT,
  payload: { params },
});

export const exportMonthlyEconomicReport = (params) => ({
  type: RegulatoryActionType.EXPORT_MONTHLY_ECONOMIC_REPORT,
  payload: { params },
});

export const getJackpotWinnings = (params) => ({
  type: RegulatoryActionType.GET_JACKPOT_WINNINGS,
  payload: { params },
});

export const exportJackpotWinnings = (params) => ({
  type: RegulatoryActionType.EXPORT_JACKPOT_WINNINGS,
  payload: { params },
});

export const getMonthlyEconomicBetting2Report = (params) => ({
  type: RegulatoryActionType.GET_MONTHLY_ECONOMIC_BETTING_2_REPORT,
  payload: { params },
});

export const exportMonthlyEconomicBetting2Report = (params) => ({
  type: RegulatoryActionType.EXPORT_MONTHLY_ECONOMIC_BETTING_2_REPORT,
  payload: { params },
});

export const getPlaySessions = (params) => ({
  type: RegulatoryActionType.GET_PLAY_SESSIONS,
  payload: { params },
});

export const exportPlaySessions = (params) => ({
  type: RegulatoryActionType.EXPORT_PLAY_SESSIONS,
  payload: { params },
});

export const getMonthlyEconomicPlayerTaxes = (params) => ({
  type: RegulatoryActionType.GET_MONTHLY_ECONOMIC_PLAYER_TAXES,
  payload: { params },
});

export const exportMonthlyEconomicPlayerTaxes = (params) => ({
  type: RegulatoryActionType.EXPORT_MONTHLY_ECONOMIC_PLAYER_TAXES,
  payload: { params },
});

export const getMonthlyEconomicBetting1Report = (params) => ({
  type: RegulatoryActionType.GET_MONTHLY_ECONOMIC_BETTING_1_REPORT,
  payload: { params },
});

export const exportMonthlyEconomicBetting1Report = (params) => ({
  type: RegulatoryActionType.EXPORT_MONTHLY_ECONOMIC_BETTING_1_REPORT,
  payload: { params },
});

export const getPlayersLimits = (params) => ({
  type: RegulatoryActionType.GET_PLAYERS_LIMITS,
  payload: { params },
});

export const exportPlayersLimits = (params) => ({
  type: RegulatoryActionType.EXPORT_PLAYERS_LIMITS,
  payload: { params },
});

export const getGameSessions = (params) => ({
  type: RegulatoryActionType.GET_GAME_SESSIONS,
  payload: { params },
});

export const exportGameSessions = (params) => ({
  type: RegulatoryActionType.EXPORT_GAME_SESSIONS,
  payload: { params },
});

export const getGGRMonthlyReport = (params) => ({
  type: RegulatoryActionType.GET_GGR_MONTHLY_REPORT,
  payload: { params },
});

export const exportGGRMonthlyReport = (params) => ({
  type: RegulatoryActionType.EXPORT_GGR_MONTHLY_REPORT,
  payload: { params },
});

export const getBetEvents = (params) => ({
  type: RegulatoryActionType.GET_BET_EVENTS,
  payload: { params },
});

export const exportBetEvents = (params) => ({
  type: RegulatoryActionType.EXPORT_BET_EVENTS,
  payload: { params },
});

export const getBonusSchemas = (params) => ({
  type: RegulatoryActionType.GET_BONUS_SCHEMAS,
  payload: { params },
});

export const exportBonusSchemas = (params) => ({
  type: RegulatoryActionType.EXPORT_BONUS_SCHEMAS,
  payload: { params },
});

export const getOnlineAccounts = (params) => ({
  type: RegulatoryActionType.GET_ONLINE_ACCOUNTS,
  payload: { params },
});

export const exportOnlineAccounts = (params) => ({
  type: RegulatoryActionType.EXPORT_ONLINE_ACCOUNTS,
  payload: { params },
});

export const getPlayersBetting = (params) => ({
  type: RegulatoryActionType.GET_PLAYERS_BETTING,
  payload: { params },
});

export const exportPlayersBetting = (params) => ({
  type: RegulatoryActionType.EXPORT_PLAYERS_BETTING,
  payload: { params },
});

export const getBettingPerformance = (params) => ({
  type: RegulatoryActionType.GET_BETTING_PERFORMANCE,
  payload: { params },
});

export const exportBettingPerformance = (params) => ({
  type: RegulatoryActionType.EXPORT_BETTING_PERFORMANCE,
  payload: { params },
});

export const getGamePerformanceDaily = (params) => ({
  type: RegulatoryActionType.GET_GAME_PERFORMANCE_DAILY,
  payload: { params },
});

export const exportGamePerformanceDaily = (params) => ({
  type: RegulatoryActionType.EXPORT_GAME_PERFORMANCE_DAILY,
  payload: { params },
});

export const getJackpotSettings = (params) => ({
  type: RegulatoryActionType.GET_JACKPOT_SETTINGS,
  payload: { params },
});

export const exportJackpotSettings = (params) => ({
  type: RegulatoryActionType.EXPORT_JACKPOT_SETTINGS,
  payload: { params },
});

export const getTotalBalances = (params) => ({
  type: RegulatoryActionType.GET_TOTAL_BALANCES,
  payload: { params },
});

export const exportTotalBalances = (params) => ({
  type: RegulatoryActionType.EXPORT_TOTAL_BALANCES,
  payload: { params },
});
