import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { withTheme } from 'styled-components';
import ChangePasswordForm from 'components/auth/ChangePasswordForm';
import { StyledButtonLogin, StyledButtonBlueUnbordered, StyledButtonGroupRight } from 'components/styledComponents/button/Button.styled';
import { StyledPasswordExpired, StyledPasswordWrapper, StyledExpiredForm } from 'components/auth/Login.styled';
import { Info } from 'components/common/info';

const ExpirePassword = (props) => {
  const {
    changeExpiredPassword,
    changePasswordExpiredState,
    changeEndedStatus,
    endedStatus,
    handleSubmit,
    data,
    setData,
    loginForm,
    theme: { colors },
  } = props;

  const [form] = Form.useForm();
  const { getFieldsValue } = form;

  const handleChange = (e) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const { oldPassword, newPassword } = values;
      changeExpiredPassword({
        oldPassword,
        newPassword,
        email: data.userName,
      });
    });
  };

  useEffect(() => {
    if (endedStatus) {
      const { newPassword: password } = getFieldsValue();
      handleSubmit(null, { password });
      changeEndedStatus(false);
    }
  }, [changeEndedStatus, endedStatus, form, getFieldsValue, handleSubmit]);

  const handleBack = () => {
    loginForm.setFieldsValue({ password: '', userName: '' });
    setData({});
    changePasswordExpiredState(false);
  };

  return (
    <StyledPasswordWrapper>
      <StyledPasswordExpired>
        <Info color={colors.danger} content="Your password has expired. Please, change your password." />
      </StyledPasswordExpired>
      <StyledExpiredForm>
        <ChangePasswordForm form={form} translatable={false} />
      </StyledExpiredForm>
      <StyledButtonGroupRight>
        <StyledButtonBlueUnbordered onClick={handleBack}>Back</StyledButtonBlueUnbordered>
        <StyledButtonLogin isMarginLeft onClick={handleChange}>
          Change and Log In
        </StyledButtonLogin>
      </StyledButtonGroupRight>
    </StyledPasswordWrapper>
  );
};

ExpirePassword.propTypes = {
  changeExpiredPassword: PropTypes.func.isRequired,
  changePasswordExpiredState: PropTypes.func.isRequired,
  changeEndedStatus: PropTypes.func.isRequired,
  endedStatus: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  loginForm: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

ExpirePassword.defaultProps = {
  endedStatus: false,
};

export default withTheme(ExpirePassword);
