import qs from 'qs';
import {
  put, all, call, takeLatest, select,
} from 'redux-saga/effects';
import mime from 'mimetypes';
import {
  changeEndedStatus, changeGenerateReportEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import ReportingActionType from 'actions/reporting/reportingActionType';
import {
  saveBalanceAdjustmentsReport,
  saveBiBetByPlayer,
  saveClosedAccounts,
  saveLargeTransactions,
  saveLargeTransactionsByBrand,
  saveLargeTransactionsByPlayer,
  saveNegativeBalance,
  saveBiBetByCurrency,
  saveBiFinancialByPlayer,
  saveBiFinancialByCurrency,
  savePlayersWalletsInfo,
  saveFinancialByProvider,
  saveProviderTopPositiveGgrs,
  saveProviderTopNegativeGgrs,
  savePlayersFirstDeposits,
  saveBonusTotalsByPlayer,
  saveBonusTotalsByType,
  saveBonusTotalsByStatus,
  saveInactivePlayers,
  saveTopPlayersByGgr,
  saveTopPlayerByGgrDetails,
  saveTopPlayersByTransactionType,
  saveTopPlayerByTransactionTypeDetails,
  saveBonusTotalsByProvider,
  saveLargeTransactionRecipients,
  savePlayersWalletsInfoV2,
} from 'actions/reporting/reportingActionCreator';
import { downloadFile } from 'utils/utils';
import { NetworkService, i18n } from 'services';
import { AppConstants } from 'constants/index';
import { selectProvidersTopNegativeGgrs, selectProvidersTopPositiveGgrs } from 'selectors';
import { getError, getMessage } from 'utils/helpers';

function* getClosedAccounts({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, Player, ClosedAccounts } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, ClosedAccounts], options);
    yield put(saveClosedAccounts(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportClosedAccounts({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    const {
      ReportingAdmin, Player, ClosedAccounts, Export,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, ClosedAccounts, Export], options);
    downloadFile(data, i18n.t('reporting:closedAccounts'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLargeTransactions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, LargeTransaction } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, LargeTransaction], options);
    yield put(saveLargeTransactions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLargeTransactionRecipients({ payload }) {
  try {
    const { txnId } = payload;
    const { NotificationAdmin, LargeTransactions, Recipients } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [NotificationAdmin, LargeTransactions, txnId, Recipients]);
    yield put(saveLargeTransactionRecipients(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateLargeTransactions({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, LargeTransaction, Generate } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, LargeTransaction, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLargeTransactionsByBrand({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, LargeTransaction, ByBrand } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, LargeTransaction, ByBrand], options);
    yield put(saveLargeTransactionsByBrand(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportLargeTransactionsByBrand({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, LargeTransaction, ByBrand, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, LargeTransaction, ByBrand, Export], options);
    downloadFile(data, i18n.t('reporting:largeTransactionsByBrand'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLargeTransactionsByPlayer({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, LargeTransaction, ByPlayer } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, LargeTransaction, ByPlayer], options);
    yield put(saveLargeTransactionsByPlayer(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportLargeTransactionsByPlayer({ payload }) {
  try {
    const options = {
      params: payload.params,
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, LargeTransaction, ByPlayer, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, LargeTransaction, ByPlayer, Export], options);
    downloadFile(data, i18n.t('reporting:largeTransactionsByPlayer'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getNegativeBalance({ payload }) {
  try {
    const { params, brandId } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, Wallet, NegativeBalance } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Wallet, NegativeBalance, brandId], options);
    yield put(saveNegativeBalance(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportNegativeBalance({ payload }) {
  try {
    const { params, brandId } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Wallet, NegativeBalance, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Wallet, NegativeBalance, brandId, Export], options);
    downloadFile(data, i18n.t('reporting:negativeBalance'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBalanceAdjustments({ payload }) {
  try {
    const { params, brandId } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, Wallet, BalanceAdjustment } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Wallet, BalanceAdjustment, brandId], options);
    yield put(saveBalanceAdjustmentsReport(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBalanceAdjustments({ payload }) {
  try {
    const { params, brandId } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Wallet, BalanceAdjustment, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Wallet, BalanceAdjustment, brandId, Export], options);
    downloadFile(data, i18n.t('balanceAdjustments'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBiBetByPlayer({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    const { ReportingAdmin, BiBetReport, ByPlayer } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, BiBetReport, ByPlayer], options);
    yield put(saveBiBetByPlayer(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateBiBetByPlayer({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, BiBetReport, ByPlayer, Generate,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, BiBetReport, ByPlayer, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBiBetByCurrency({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    const { ReportingAdmin, BiBetReport, ByCurrency } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, BiBetReport, ByCurrency], options);
    yield put(saveBiBetByCurrency(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBiBetByCurrency({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, BiBetReport, ByCurrency, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, BiBetReport, ByCurrency, Export], options);
    downloadFile(data, i18n.t('reporting:betReportByCurrency'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBiFinancialByPlayer({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    const { ReportingAdmin, BiPaymentReport, ByPlayer } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, BiPaymentReport, ByPlayer], options);
    yield put(saveBiFinancialByPlayer(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBiFinancialByPlayer({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, BiPaymentReport, ByPlayer, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, BiPaymentReport, ByPlayer, Export], options);
    downloadFile(data, i18n.t('reporting:financialReportByPlayer'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBiFinancialByCurrency({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    const { ReportingAdmin, BiPaymentReport, ByCurrency } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, BiPaymentReport, ByCurrency], options);
    yield put(saveBiFinancialByCurrency(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBiFinancialByCurrency({ payload }) {
  try {
    const options = {
      data: payload.data,
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, BiPaymentReport, ByCurrency, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, BiPaymentReport, ByCurrency, Export], options);
    downloadFile(data, i18n.t('reporting:financialReportByCurrency'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayersWalletsInfo({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const { ReportingAdmin, Player, Wallets } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, Wallets], options);
    yield put(changeLoadingState(false));
    yield put(savePlayersWalletsInfo(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generatePlayersWalletsInfo({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Player, Wallets, Generate,
    } = AppConstants.api;

    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, Wallets, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayersWalletsInfoV2({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Player, V2, Wallets,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, V2, Wallets], options);
    yield put(changeLoadingState(false));
    yield put(savePlayersWalletsInfoV2(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generatePlayersWalletsInfoV2({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Player, V2, Wallets, Generate,
    } = AppConstants.api;

    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, V2, Wallets, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getFinancialByProvider({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, BetReport, Providers } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, Providers], options);
    yield put(saveFinancialByProvider(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getProviderTopGgrs({ payload }) {
  try {
    const { providerId, params } = payload;
    const options = {
      params,
    };
    const {
      ReportingAdmin, BetReport, Providers, Top,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, Providers, providerId, Top], options);
    const { reporting } = yield select();
    if (params.isPositive) {
      const ggrs = selectProvidersTopPositiveGgrs(reporting);
      ggrs[providerId] = data;
      yield put(saveProviderTopPositiveGgrs({ ...ggrs }));
    } else {
      const ggrs = selectProvidersTopNegativeGgrs(reporting);
      ggrs[providerId] = data;
      yield put(saveProviderTopNegativeGgrs({ ...ggrs }));
    }
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportFinancialByProvider({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, BetReport, Providers, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, Providers, Export], options);
    downloadFile(data, i18n.t('reporting:financialReportByProvider'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayersFirstDeposits({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, PaymentReport, PlayersFirstDeposit } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, PaymentReport, PlayersFirstDeposit], options);
    yield put(savePlayersFirstDeposits(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportPlayersFirstDeposits({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, PaymentReport, PlayersFirstDeposit, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, PaymentReport, PlayersFirstDeposit, Export], options);
    downloadFile(data, i18n.t('title:firstDeposits'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getInactivePlayers({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, Player, InactiveAccounts } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, InactiveAccounts], options);
    yield put(saveInactivePlayers(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateInactivePlayers({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Player, InactiveAccounts, Generate,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Player, InactiveAccounts, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBonusTotalsByPlayer({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const {
      ReportingAdmin, Bonus, Totals, ByPlayer,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Bonus, Totals, ByPlayer], options);
    yield put(saveBonusTotalsByPlayer(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* generateBonusTotalsByPlayerReport({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Bonus, Totals, ByPlayer, Generate,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Bonus, Totals, ByPlayer, Generate], options);
    yield put(changeGenerateReportEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBonusTotalsByStatus({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, Bonus, Totals, ByStatus,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Bonus, Totals, ByStatus], options);
    yield put(saveBonusTotalsByStatus(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBonusTotalsByStatus({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Bonus, Totals, ByStatus, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Bonus, Totals, ByStatus, Export], options);
    downloadFile(data, i18n.t('reporting:bonusTotalsByStatus'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBonusTotalsByType({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, Bonus, Totals, ByType,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Bonus, Totals, ByType], options);
    yield put(saveBonusTotalsByType(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBonusTotalsByType({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Bonus, Totals, ByType, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Bonus, Totals, ByType, Export], options);
    downloadFile(data, i18n.t('reporting:bonusTotalsByType'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBonusTotalsByProvider({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      ReportingAdmin, Bonus, Totals, ByProvider,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Bonus, Totals, ByProvider], options);
    yield put(saveBonusTotalsByProvider(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportBonusTotalsByProvider({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      ReportingAdmin, Bonus, Totals, ByProvider, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Bonus, Totals, ByProvider, Export], options);
    downloadFile(data, i18n.t('reporting:bonusTotalsByProvider'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* transferInactivePlayersMoney({ payload }) {
  try {
    const { data } = payload;
    const options = {
      data,
    };
    const { Transaction, Player, TransferInactivePlayersMoney } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Player, TransferInactivePlayersMoney], options);
    yield put(setSuccessMessage(getMessage(i18n.t('action'), i18n.t('notification:slDone'))));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTopPlayersByGgr({ payload }) {
  try {
    const { params } = payload;
    const options = { params };
    const { ReportingAdmin, BetReport, TopPlayersGgr } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, TopPlayersGgr], options);
    yield put(saveTopPlayersByGgr(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportTopPlayersByGgr({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    const {
      ReportingAdmin, BetReport, TopPlayersGgr, Export,
    } = AppConstants.api;

    yield put(changeLoadingState(true));
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, TopPlayersGgr, Export], options);
    downloadFile(data, i18n.t('reporting:topPlayersByGgr'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTopPlayerByGgrDetails({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, BetReport, TopPlayersGgr } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, TopPlayersGgr, playerId], options);

    yield put(saveTopPlayerByGgrDetails(playerId, data));

    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTopPlayersByTransactionType({ payload }) {
  try {
    const { params } = payload;
    const options = { params };
    const { ReportingAdmin, BetReport, TopPlayers } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, TopPlayers], options);
    yield put(saveTopPlayersByTransactionType(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportTopPlayersByTransactionType({ payload }) {
  try {
    const { params } = payload;
    const { isDeposit } = params;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };

    const {
      ReportingAdmin, BetReport, TopPlayers, Export,
    } = AppConstants.api;

    yield put(changeLoadingState(true));
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, TopPlayers, Export], options);
    downloadFile(data, i18n.t(isDeposit ? 'reporting:topPlayersByDeposits' : 'reporting:topPlayersByWithdrawals'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getTopPlayerByTransactionTypeDetails({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, BetReport, TopPlayers } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, BetReport, TopPlayers, playerId], options);

    yield put(saveTopPlayerByTransactionTypeDetails(playerId, data));

    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* reportingSaga() {
  yield all([
    takeLatest(ReportingActionType.GET_CLOSED_ACCOUNTS, getClosedAccounts),
    takeLatest(ReportingActionType.EXPORT_CLOSED_ACCOUNTS, exportClosedAccounts),
    takeLatest(ReportingActionType.GET_LARGE_TRANSACTIONS, getLargeTransactions),
    takeLatest(ReportingActionType.GET_LARGE_TRANSACTION_RECIPIENTS, getLargeTransactionRecipients),
    takeLatest(ReportingActionType.GET_LARGE_TRANSACTIONS_BY_BRAND, getLargeTransactionsByBrand),
    takeLatest(ReportingActionType.GET_LARGE_TRANSACTIONS_BY_PLAYER, getLargeTransactionsByPlayer),
    takeLatest(ReportingActionType.GENERATE_LARGE_TRANSACTIONS, generateLargeTransactions),
    takeLatest(ReportingActionType.EXPORT_LARGE_TRANSACTIONS_BY_BRAND, exportLargeTransactionsByBrand),
    takeLatest(ReportingActionType.EXPORT_LARGE_TRANSACTIONS_BY_PLAYER, exportLargeTransactionsByPlayer),
    takeLatest(ReportingActionType.GET_NEGATIVE_BALANCE, getNegativeBalance),
    takeLatest(ReportingActionType.EXPORT_NEGATIVE_BALANCE, exportNegativeBalance),
    takeLatest(ReportingActionType.GET_BALANCE_ADJUSTMENTS_REPORT, getBalanceAdjustments),
    takeLatest(ReportingActionType.EXPORT_BALANCE_ADJUSTMENTS_REPORT, exportBalanceAdjustments),
    takeLatest(ReportingActionType.GET_BI_BET_BY_PLAYER, getBiBetByPlayer),
    takeLatest(ReportingActionType.GENERATE_BI_BET_BY_PLAYER, generateBiBetByPlayer),
    takeLatest(ReportingActionType.GET_BI_BET_BY_CURRENCY, getBiBetByCurrency),
    takeLatest(ReportingActionType.EXPORT_BI_BET_BY_CURRENCY, exportBiBetByCurrency),
    takeLatest(ReportingActionType.GET_BI_FINANCIAL_BY_PLAYER, getBiFinancialByPlayer),
    takeLatest(ReportingActionType.EXPORT_BI_FINANCIAL_BY_PLAYER, exportBiFinancialByPlayer),
    takeLatest(ReportingActionType.GET_BI_FINANCIAL_BY_CURRENCY, getBiFinancialByCurrency),
    takeLatest(ReportingActionType.EXPORT_BI_FINANCIAL_BY_CURRENCY, exportBiFinancialByCurrency),
    takeLatest(ReportingActionType.GET_PLAYERS_WALLETS_INFO, getPlayersWalletsInfo),
    takeLatest(ReportingActionType.GENERATE_PLAYERS_WALLETS_INFO, generatePlayersWalletsInfo),
    takeLatest(ReportingActionType.GET_PLAYERS_WALLETS_INFO_V2, getPlayersWalletsInfoV2),
    takeLatest(ReportingActionType.GENERATE_PLAYERS_WALLETS_INFO_V2, generatePlayersWalletsInfoV2),
    takeLatest(ReportingActionType.GET_FINANCIAL_BY_PROVIDER, getFinancialByProvider),
    takeLatest(ReportingActionType.EXPORT_FINANCIAL_BY_PROVIDER, exportFinancialByProvider),
    takeLatest(ReportingActionType.GET_PROVIDER_TOP_GGRS, getProviderTopGgrs),
    takeLatest(ReportingActionType.GET_PLAYERS_FIRST_DEPOSITS, getPlayersFirstDeposits),
    takeLatest(ReportingActionType.EXPORT_PLAYERS_FIRST_DEPOSITS, exportPlayersFirstDeposits),
    takeLatest(ReportingActionType.GET_BONUS_TOTALS_BY_PLAYER, getBonusTotalsByPlayer),
    takeLatest(ReportingActionType.GENERATE_BONUS_TOTALS_BY_PLAYER_REPORT, generateBonusTotalsByPlayerReport),
    takeLatest(ReportingActionType.GET_BONUS_TOTALS_BY_TYPE, getBonusTotalsByType),
    takeLatest(ReportingActionType.EXPORT_BONUS_TOTALS_BY_TYPE, exportBonusTotalsByType),
    takeLatest(ReportingActionType.GET_BONUS_TOTALS_BY_STATUS, getBonusTotalsByStatus),
    takeLatest(ReportingActionType.EXPORT_BONUS_TOTALS_BY_STATUS, exportBonusTotalsByStatus),
    takeLatest(ReportingActionType.GET_BONUS_TOTALS_BY_PROVIDER, getBonusTotalsByProvider),
    takeLatest(ReportingActionType.EXPORT_BONUS_TOTALS_BY_PROVIDER, exportBonusTotalsByProvider),
    takeLatest(ReportingActionType.GET_INACTIVE_PLAYERS, getInactivePlayers),
    takeLatest(ReportingActionType.GENERATE_INACTIVE_PLAYERS, generateInactivePlayers),
    takeLatest(ReportingActionType.TRANSFER_INACTIVE_PLAYERS_MONEY, transferInactivePlayersMoney),
    takeLatest(ReportingActionType.GET_TOP_PLAYERS_BY_GGR, getTopPlayersByGgr),
    takeLatest(ReportingActionType.EXPORT_TOP_PLAYERS_BY_GGR, exportTopPlayersByGgr),
    takeLatest(ReportingActionType.GET_TOP_PLAYER_BY_GGR_DETAILS, getTopPlayerByGgrDetails),
    takeLatest(ReportingActionType.GET_TOP_PLAYERS_BY_TRANSACTION_TYPE, getTopPlayersByTransactionType),
    takeLatest(ReportingActionType.EXPORT_TOP_PLAYERS_BY_TRANSACTION_TYPE, exportTopPlayersByTransactionType),
    takeLatest(ReportingActionType.GET_TOP_PLAYER_BY_TRANSACTION_TYPE_DETAILS, getTopPlayerByTransactionTypeDetails),
  ]);
}
