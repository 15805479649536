export default {
  en: {
    verification: 'Verification',
    country: 'Countries',
    currency: 'Currencies',
    language: 'Languages',
    domain: 'Domains',
    captcha: 'Captcha',
    player: 'Players',
    seo: 'Seo',
    socialNetwork: 'Social Networks',
    schemaMarkup: 'Schema Markup',
    openGraph: 'Open Graph',
    twitter: 'Twitter',
    sms: 'SMS',
    smtp: 'SMTP',
    email: 'Emails',
    affiliate: 'Affiliate',
    gamification: 'Gamification',
    analytics: 'Analytics',
    crm: 'CRM',
    liveChatSolution: 'Live Chat Solution',
    customScript: 'Custom Script',
    agSolution: 'AG Solution',
    retailSolution: 'Retail Solution',
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
    other: 'Others',
    blockedCountries: 'Blocked Countries',
    blockedIPs: 'BLOCKED IPs',
    playerLimits: 'Player Limits',
    failedPayments: 'Failed Payments',
    methods: 'Methods',
    settings: 'Settings',
    fees: 'Fees',
    limits: 'Limits',
    sent: 'Sent',
    scheduled: 'Scheduled',
    draft: 'Draft',
    mainContent: 'Main Content',
    errorMessages: 'Error Codes',
    accountDetails: 'Account Details',
    financials: 'Financials',
    bets: 'Bets',
    adjustments: 'Adjustments',
    bonuses: 'Bonuses',
    wagers: 'Wagers',
    monitoring: 'Monitoring',
    tags: 'Tags',
    comms: 'Comms',
    segments: 'Segments',
    referees: 'Referees',
    notes: 'Notes',
    kpis: 'KPIs',
    attachments: 'Attachments',
    history: 'History',
    vertical: 'Vertical',
    horizontal: 'Horizontal',
    overview: 'Overview',
    details: 'Details',
    payments: 'Payments',
    preferences: 'Preferences',
    sport: 'Sport',
    casino: 'Casino',
    deposits: 'Deposits',
    withdrawals: 'Withdrawals',
    responsibleGaming: 'Responsible Gaming',
    logs: 'Logs',
    cashbackBonus: 'Cashback',
    referralBonus: 'Referral',
    massMessages: 'Mass Messages',
    inboxMessages: 'Inbox Messages',
    systemNotifications: 'System Notifications',
    userLogs: 'User Logs',
    playerLogs: 'Player Logs',
    previousAccounts: 'Previous Accounts',
    similarAccounts: 'Similar Accounts',
    legalAgreements: 'Legal Agreements',
    accountUpdates: 'Account Updates',
    systemTemplates: 'System Templates',
    customTemplates: 'Custom Templates',
    special: 'Special',
    statusUpdates: 'Status Updates',
    balanceAdjustments: 'Balance Adjustments',
    accumulatedCashBack: 'Accumulated',
    transferredCashBack: 'Transferred',
    failedCashBack: 'Failed',
    accumulated: 'Accumulated',
    transferred: 'Transferred',
    failed: 'Failed',
    userManagementLogs: 'User Management Logs',
    closedAccounts: 'Closed Accounts',
    playersTotals: 'Players Totals',
    negativeBalance: 'Negative Balance',
    byTransaction: 'By Transaction',
    byPlayer: 'By Player',
    byBrand: 'By Brand',
    betAggregations: 'Bet Aggregations',
    financialAggregations: 'Payment Aggregations',
    byCurrency: 'Currency',
    myGroups: 'Custom Groups',
    defaultGroups: 'Default Groups',
    inventory: 'Inventory',
    withdraw: 'Withdraw',
    txnDetails: 'TXN Details',
    statusLogs: 'Status logs',
    payeeData: 'Payee Data',
    templates: 'Templates',
    rules: 'Rules',
    games: 'Games',
    groups: 'Groups',
    playerVerification: 'Player Verification Settings',
    verificationConf: 'Verification Configuration',
    documentType: 'Document Type',
    blockedProviders: 'Blocked Providers',
    blockedPayments: 'Blocked Payments',
    reasons: 'Reasons',
    resources: 'Resources',
    changeNotification: 'Change Notification',
    gameRatio: 'Game Ratio',
    playerExclusion: 'Player Exclusion',
    gameTypes: 'Game Types',
    balanceAdjustment: 'Balance Adjustment',
    sessions: 'Sessions',
    layouts: 'Layouts',
    playersWallets: 'Players Wallets',
    systemCaches: 'System Caches',
    dbSynchronization: 'DB Synchronization',
    jobs: 'Jobs',
    userConfig: 'User Config',
    significantEvents: 'Significant Events',
    serverInaccessibility: 'Server Inaccessibility',
    playersWithNegativeGGR: '{{count}} Players with Negative GGR',
    playersWithPositiveGGR: '{{count}} Players with Positive GGR',
    promotionalItems: 'Promotional Items',
    walletsSettings: 'Wallets Settings',
    promotionTool: 'Gamification',
    spins: 'Spins',
    transactions: 'Transactions',
    providersAndGames: 'Providers and Games',
    financialConfigurations: 'Financial Configurations',
    paymentVisibility: 'Payment Visibility',
    pushNotifications: 'Push Notifications',
    currencyConfigs: 'Currency Configs',
    currencySettings: 'Currency Settings',
    bonusNotifications: 'Bonus Notifications',
    firstDeposits: 'First Deposits',
    promoSpinsAdjustments: 'Gamification Spin Adjustments',
    byType: 'By Type',
    byStatus: 'By Status',
    singleBets: 'Single Bets',
    bulkBets: 'Bulk Bets',
    campaigns: 'Campaigns',
    crossPlatformCampaigns: 'Cross-Platform Campaigns',
    inactivity: 'Inactivity',
    inactivePlayers: 'Inactive Players',
    machines: 'Machines',
    prizes: 'Prizes',
    players: 'Players',
    received: 'Received',
    sportStatusIcons: 'Sport Status Icons',
    unverifiedPlayersExclusions: 'Unverified Players Exclusions',
    topPlayers: 'Top Players',
    byGgr: 'By GGR',
    byDeposits: 'By Deposits',
    byWithdrawals: 'By Withdrawals',
    byProvider: 'By Provider',
    applicationSessions: 'Application Sessions',
    webSessions: 'Web Sessions',
    kyc: 'Know Your Customer',
    prizeAndProbabilitySection: 'Prize Section and Probability',
    advancedProbability: 'Advanced Probability',
    promotionPlayers: 'Promotion Players',
    blockAccess: 'Block Access',
    providers: 'Providers',
    paymentMethods: 'Payment Methods',
    robots: 'Robots',
    mainDomains: 'Main Domains',
    sportDomains: 'Sport Domains',
    jackpots: 'Jackpots',
    lobbyHome: 'Lobby Home',
    playerComplaints: 'Player Complaints',
    reasonsContent: 'Reasons Content',
    actions: 'Actions',
    authentication: 'Authentication',
    freeBet: 'Free bet',
    bonusesV2: 'Bonuses',
    spin: 'Spin',
    combined: 'Combined',
    taxSessions: 'Tax Sessions',
    verificationRules: 'Verification Rules',
  },
  es: {
    verification: 'Confirmación',
    country: 'Países',
    currency: 'Monedas',
    language: 'Idiomas',
    domain: 'Dominios',
    captcha: 'Captcha',
    player: 'Jugadores',
    seo: 'Seo',
    socialNetwork: 'Redes sociales',
    schemaMarkup: 'Marcado de esquemas',
    openGraph: 'Gráfico abierto',
    twitter: 'Twitter',
    sms: 'SMS',
    smtp: 'SMTP',
    email: 'Correos electrónicos',
    customScript: 'Script personalizado',
    deposit: 'Depósito',
    withdrawal: 'Retiro',
    other: 'Otros',
    blockedCountries: 'Países bloqueados',
    blockedIPs: 'IP BLOQUEADAS',
    playerLimits: 'Límites del jugador',
    failedPayments: 'Pagos fallidos',
    methods: 'Métodos',
    settings: 'Ajustes',
    fees: 'Cuotas',
    limits: 'Límites',
    sent: 'Enviado',
    scheduled: 'Programado',
    draft: 'Borrador',
    mainContent: 'Contenido principal',
    errorMessages: 'Códigos de error',
    accountDetails: 'Datos de la cuenta',
    financials: 'Finanzas',
    bets: 'Apuestas',
    adjustments: 'Ajustes',
    bonuses: 'Bonos',
    wagers: 'Apuestas',
    monitoring: 'Seguimiento',
    tags: 'Etiquetas',
    comms: 'Comunicaciones',
    segments: 'Segmentos',
    referees: 'Referencias',
    notes: 'Notas',
    kpis: 'KPI',
    attachments: 'Anexos',
    history: 'Historial',
    details: 'Detalles',
    payments: 'Pagos',
    preferences: 'Preferencias',
    sport: 'Deporte',
    casino: 'Casino',
    deposits: 'Depósitos',
    withdrawals: 'Retiros',
    responsibleGaming: 'Juego responsable',
    logs: 'Registros',
    cashbackBonus: 'Devolución',
    referralBonus: 'Bono por recomendación',
    massMessages: 'Mensajes masivos',
    inboxMessages: 'Mensajes de la bandeja de entrada',
    systemNotifications: 'Notificaciones del sistema',
    userLogs: 'Registros de usuarios',
    playerLogs: 'Registros de jugadores',
    previousAccounts: 'Cuentas previas',
    similarAccounts: 'Cuentas similares',
    legalAgreements: 'Contratos legales',
    accountUpdates: 'Actualizaciones de la cuenta',
    systemTemplates: 'Plantillas de sistema',
    customTemplates: 'Plantillas personalizadas',
    special: 'Especial',
    statusUpdates: 'Actualizaciones de estado',
    balanceAdjustments: 'Ajustes de saldo',
    accumulatedCashBack: 'Acumulado',
    transferredCashBack: 'Transferido',
    failedCashBack: 'Fallido',
    accumulated: 'Acumulado',
    transferred: 'Transferido',
    failed: 'Fallido',
    userManagementLogs: 'Registro de gestión de usuarios',
    closedAccounts: 'Cuentas cerradas',
    playersTotals: 'Jugadores totales',
    negativeBalance: 'Saldo negativo',
    byTransaction: 'Por transacción',
    byPlayer: 'Por jugador',
    byBrand: 'Por marca',
    betAggregations: 'Acumulación de apuestas',
    financialAggregations: 'Acumulaciones de pagos',
    byCurrency: 'Moneda',
    myGroups: 'Grupos personalizado',
    defaultGroups: 'Grupos por defecto',
    inventory: 'Inventario',
    withdraw: 'Retiro',
    txnDetails: 'Detalles de transacción',
    statusLogs: 'Registros de estado',
    payeeData: 'Datos del beneficiario',
    templates: 'Plantillas',
    rules: 'Reglas',
    games: 'Juegos',
    groups: 'Grupos',
    playerVerification: 'Configuración de verificación de jugadores',
    verificationConf: 'Configuración de verificación',
    documentType: 'Tipo de documento',
    blockedProviders: 'Proveedores bloqueados',
    vertical: 'Vertical',
    horizontal: 'Horizontal',
    overview: 'Resumen',
    reasons: 'Resumen',
    resources: 'Recursos',
    changeNotification: 'Aviso de cambio',
    gameRatio: 'Relación del juego',
    playerExclusion: 'Exclusión de jugadores',
    gameTypes: 'Tipos de juegos',
    balanceAdjustment: 'Ajuste del balance',
    sessions: 'Sesiones',
    layouts: 'Diseños',
    affiliate: 'Afiliado',
    gamification: 'Gamificación',
    analytics: 'Analítica',
    crm: 'CRM',
    liveChatSolution: 'Solución de Chat en Vivo',
    agSolution: 'Solución AG',
    retailSolution: 'Solución Retail',
    playersWallets: 'Cartera de los Jugadores',
    systemCaches: 'Cachés del Sistema',
    dbSynchronization: 'Sincronización de BD',
    jobs: 'Empleo',
    campaigns: 'Campañas',
    spins: 'Giros',
    userConfig: 'Configuración de usuario',
    significantEvents: 'Hechos Relevantes',
    serverInaccessibility: 'Inaccesibilidad del Servidor',
    playersWithNegativeGGR: '{{count}} Jugadores con GGR Negativo',
    playersWithPositiveGGR: '{{count}} Jugadores con GGR Positivo',
    promotionalItems: 'Artículos Promocionales',
    walletsSettings: 'Billeteras Configuración',
    promotionTool: 'Gamificación',
    transactions: 'Transacciones',
    providersAndGames: 'Proveedores y juegos',
    financialConfigurations: 'Configuraciones financieras',
    paymentVisibility: 'Visibilidad de los Pagos',
    pushNotifications: 'Notificaciones Push',
    currencyConfigs: 'Configuración de divisas',
    currencySettings: 'Ajustes de Moneda',
    bonusNotifications: 'Notificaciones de Bonificación',
    firstDeposits: 'Primeros Depósitos',
    promoSpinsAdjustments: 'Ajustes del Giro de Gamificación',
    byType: 'Por tipo',
    byStatus: 'Por Estado',
    singleBets: 'Apuestas Simples',
    bulkBets: 'Apuestas Masivas',
    crossPlatformCampaigns: 'Campañas Multiplataforma',
    inactivity: 'Inactividad',
    inactivePlayers: 'Jugadores Inactivos',
    machines: 'Máquinas',
    prizes: 'Premios',
    players: 'Jugadores',
    received: 'Recibido',
    sportStatusIcons: 'Iconos de Estado Deportivo',
    unverifiedPlayersExclusions: 'Jugadores no verificados Exclusiones',
    topPlayers: 'Mejores Jugadores',
    byGgr: 'Por GGR',
    byDeposits: 'Por Depósitos',
    byWithdrawals: 'Por Retiradas',
    actions: 'Acciones',
  },
  tr: {
    verification: 'Doğrulama',
    country: 'Ülkeler',
    currency: 'Para Birimleri',
    language: 'Diller',
    domain: 'Alan Adları',
    captcha: 'Captcha',
    player: 'Oyuncular',
    seo: 'Seo',
    socialNetwork: 'Sosyal Ağlar',
    schemaMarkup: 'Şema Biçimlendirmesi',
    openGraph: 'Açık Grafik',
    twitter: 'Twitter',
    sms: 'SMS',
    smtp: 'SMTP',
    email: 'E-postalar',
    customScript: 'Özel Komut Dizisi',
    deposit: 'Para Yatırma',
    withdrawal: 'Para Çekme',
    other: 'Diğer',
    blockedCountries: 'Engelli Ülkeler',
    blockedIPs: 'ENGELLİ IP’ler',
    playerLimits: 'Oyuncu Limitleri',
    failedPayments: 'Başarısız Ödemeler',
    methods: 'Metotlar',
    settings: 'Ayarlar',
    fees: 'Ücretler',
    limits: 'Limitler',
    sent: 'Gönderildi',
    scheduled: 'Planlandı',
    draft: 'Taslak',
    mainContent: 'Ana İçerik',
    errorMessages: 'Hata Kodları',
    accountDetails: 'Hesap Bilgileri',
    financials: 'Finans Raporları',
    bets: 'Bahisler',
    adjustments: 'Düzenlemeler',
    bonuses: 'Bonuslar',
    wagers: 'Bahis Olarak Konan Para',
    monitoring: 'İzleme',
    tags: 'Etiketler',
    comms: 'İletişim',
    segments: 'Bölümler',
    referees: 'Referans Verenler',
    notes: 'Notlar',
    kpis: 'Temel Performans Göstergeleri',
    attachments: 'Ekler',
    history: 'Geçmiş',
    details: 'Detaylar',
    payments: 'Ödemeler',
    preferences: 'Tercihler',
    sport: 'Spor',
    casino: 'Casino',
    deposits: 'Para Yatırma İşlemleri',
    withdrawals: 'Para Çekme İşlemleri',
    responsibleGaming: 'Sorumlu Bahis Oynama',
    logs: 'Günlükler',
    cashbackBonus: 'Kayıp bonusu',
    referralBonus: 'Referans Bonusu',
    massMessages: 'Toplu Mesajlar',
    inboxMessages: 'Gelen Kutusu Mesajları',
    systemNotifications: 'Sistem Bildirimleri',
    userLogs: 'Kullanıcı Günlükleri',
    playerLogs: 'Oyuncu Günlükleri',
    previousAccounts: 'Önceki Hesaplar',
    similarAccounts: 'Benzer Hesaplar',
    legalAgreements: 'Yasal Anlaşmalar',
    accountUpdates: 'Hesap Güncellemeleri',
    systemTemplates: 'Sistem Şablonları',
    customTemplates: 'Özel Şablonlar',
    special: 'Özel',
    statusUpdates: 'Durum Güncellemeleri',
    balanceAdjustments: 'Bakiye Düzenlemeleri',
    accumulatedCashBack: 'Biriken',
    transferredCashBack: 'Transfer Edilen',
    failedCashBack: 'Başarısız',
    accumulated: 'Biriken',
    transferred: 'Transfer Edilen',
    failed: 'Başarısız',
    userManagementLogs: 'Kullanıcı Yönetim Günlükleri',
    closedAccounts: 'Kapatılan Hesaplar',
    playersTotals: 'Oyuncu Toplamları',
    negativeBalance: 'Negatif Bakiye',
    byTransaction: 'İşleme göre',
    byPlayer: 'Oyuncuya göre',
    byBrand: 'Markaya göre',
    betAggregations: 'Bahis Toplamları',
    financialAggregations: 'Ödeme Toplamları',
    byCurrency: 'Para Birimi',
    myGroups: 'Özel Gruplar',
    defaultGroups: 'Varsayılan Gruplar',
    inventory: 'Envanter',
    withdraw: 'Para Çekme',
    txnDetails: 'TXN Detayları',
    statusLogs: 'Durum günlükleri',
    payeeData: 'Alacaklı Verileri',
    templates: 'Şablonlar',
    rules: 'Kurallar',
    games: 'Oyunlar',
    groups: 'Gruplar',
    playerVerification: 'Oyuncu Doğrulama Ayarları',
    verificationConf: 'Doğrulama Yapılandırması',
    documentType: 'Belge Türü',
    blockedProviders: 'Engellenen Sağlayıcılar',
    vertical: 'Dikey',
    horizontal: 'Yatay',
    overview: 'Genel bakış',
    reasons: 'Nedenler',
    resources: 'Kaynaklar',
    changeNotification: 'Değişim Bildirimi',
    gameRatio: 'Oyun Oranı',
    playerExclusion: 'Oyuncu Hariç Tutma',
    gameTypes: 'Oyun Türleri',
    balanceAdjustment: 'Bakiye Ayarlaması',
    sessions: 'Oturumlar',
    layouts: 'Düzenler',
    affiliate: 'Affiliate',
    gamification: 'Oyunlaştırma',
    analytics: 'Analitik',
    crm: 'CRM',
    liveChatSolution: 'Canlı Sohbet Çözümü',
    agSolution: 'AG Solution',
    retailSolution: 'Perakende Çözümü',
    playersWallets: 'Oyuncu Cüzdanları',
    systemCaches: 'Sistem Önbellekleri',
    dbSynchronization: 'DB Senkronizasyonu',
    jobs: 'Görev',
    campaigns: 'Kampanyalar',
    spins: 'Spinler',
    userConfig: 'Kullanıcı Yapılandırması',
    significantEvents: 'Önemli Etkinlikler',
    serverInaccessibility: 'Sunucu Erişilemezliği',
    playersWithNegativeGGR: "{{count}} Negatif GGR'a Sahip Oyuncular",
    playersWithPositiveGGR: "{{count}} Pozitif GGR'a Sahip Oyuncular",
    promotionalItems: 'Promosyon Ürünleri',
    walletsSettings: 'Cüzdan Ayarları',
    promotionTool: 'Oyunlaştırma',
    transactions: 'İşlemler',
    providersAndGames: 'Sağlayıcılar ve Oyunlar',
    financialConfigurations: 'Finansal Yapılandırmalar',
    paymentVisibility: 'Ödeme Görünürlüğü',
    pushNotifications: 'Anlık Bildirimler',
    currencyConfigs: 'Para Birimi Yapılandırmaları',
    currencySettings: 'Para Birimi Ayarları',
    bonusNotifications: 'Bonus Bildirimleri',
    firstDeposits: 'İlk Yatırımlar',
    promoSpinsAdjustments: 'Oyunlaştırma Spin Ayarları',
    byType: 'Türe Göre',
    byStatus: 'Duruma Göre',
    singleBets: 'Tekli Bahisler',
    bulkBets: 'Toplu Bahisler',
    crossPlatformCampaigns: 'Çapraz Platform Kampanyaları',
    inactivity: 'Etkin değil',
    inactivePlayers: 'Etkin Olmayan Oyuncular',
    machines: 'Makineler',
    prizes: 'Ödüller',
    players: 'Oyuncular',
    received: 'Alındı',
    sportStatusIcons: 'Spor Durumu Simgeleri',
    unverifiedPlayersExclusions: 'Doğrulanmamış Oyuncular Hariç Tutulanlar',
    topPlayers: 'En İyi Oyuncular',
    byGgr: "GGR'a göre",
    byDeposits: 'Yatırımlara Göre',
    byWithdrawals: 'Çekimlere Göre',
    actions: 'Eylemler',
  },
  ru: {
    verification: 'Верификация',
    country: 'Страны',
    currency: 'Валюты',
    language: 'Языки',
    domain: 'Домены',
    captcha: 'Капча',
    player: 'Игроки',
    seo: 'SEO',
    socialNetwork: 'Социальные сети',
    schemaMarkup: 'Схема',
    openGraph: 'Open Graph',
    twitter: 'Twitter',
    sms: 'SMS',
    smtp: 'SMTP',
    email: 'Электронные письма',
    customScript: 'Настраиваемый скрипт',
    deposit: 'Депозит',
    withdrawal: 'Вывод средств',
    other: 'Другое',
    blockedCountries: 'Заблокированные страны',
    blockedIPs: 'ЗАБЛОКИРОВАННЫЕ IP-АДРЕСА',
    playerLimits: 'Лимиты игроков',
    failedPayments: 'Отклоненные платежи',
    methods: 'Методы',
    settings: 'Настройки',
    fees: 'Комиссии',
    limits: 'Лимиты',
    sent: 'Отправлено',
    scheduled: 'Запланировано',
    draft: 'Черновик',
    mainContent: 'Основной контент',
    errorMessages: 'Коды ошибок',
    accountDetails: 'Детали учетной записи',
    financials: 'Финансовые показатели',
    bets: 'Ставки',
    adjustments: 'Корректировки',
    bonuses: 'Бонусы',
    wagers: 'Вейджеры',
    monitoring: 'Мониторинг',
    tags: 'Теги',
    comms: 'Связь',
    segments: 'Сегменты',
    referees: 'Рефералы',
    notes: 'Примечания',
    kpis: 'KPI',
    attachments: 'Вложения',
    history: 'История',
    details: 'Детали',
    payments: 'Платежи',
    preferences: 'Предпочтения',
    sport: 'Спорт',
    casino: 'Казино',
    deposits: 'Депозиты',
    withdrawals: 'Вывод средств',
    responsibleGaming: 'Ответственный гейминг',
    logs: 'Журналы',
    cashbackBonus: 'Кешбэк',
    referralBonus: 'Реферальный бонус',
    massMessages: 'Массовые сообщения',
    inboxMessages: 'Входящие сообщения',
    systemNotifications: 'Системные уведомления',
    userLogs: 'Журналы пользователей',
    playerLogs: 'Журналы игроков',
    previousAccounts: 'Предыдущие учетные записи',
    similarAccounts: 'Похожие учетные записи',
    legalAgreements: 'Юридические соглашения',
    accountUpdates: 'Обновления учетной записи',
    systemTemplates: 'Системные шаблоны',
    customTemplates: 'Настраиваемые шаблоны',
    special: 'Специальный',
    statusUpdates: 'Обновления статуса',
    balanceAdjustments: 'Корректировки баланса',
    accumulatedCashBack: 'Аккумулировано',
    transferredCashBack: 'Переведено',
    failedCashBack: 'Неудавшиеся',
    accumulated: 'Аккумулировано',
    transferred: 'Переведено',
    failed: 'Неудавшиеся',
    userManagementLogs: 'Журналы управления пользователями',
    closedAccounts: 'Закрытые учетные записи',
    playersTotals: 'Всего игроков',
    negativeBalance: 'Отрицательный баланс',
    byTransaction: 'По транзакциям',
    byPlayer: 'По игрокам',
    byBrand: 'По брендам',
    betAggregations: 'Агрегированные ставки',
    financialAggregations: 'Агрегированные платежи',
    byCurrency: 'Валюта',
    myGroups: 'Настраиваемые группы',
    defaultGroups: 'Группы по умолчанию',
    inventory: 'Учет',
    withdraw: 'Вывод средств',
    txnDetails: 'Детали транзакции',
    statusLogs: 'Журналы статуса',
    payeeData: 'Данные получателя платежа',
    templates: 'Шаблоны',
    rules: 'Правила',
    games: 'Игры',
    groups: 'Группы',
    playerVerification: 'Настройки проверки игрока',
    verificationConf: 'Структура проверки',
    documentType: 'Тип документа',
    blockedProviders: 'Заблокированные провайдеры',
    vertical: 'Вертикальный',
    horizontal: 'Горизонтальный',
    overview: 'Общие сведения',
    reasons: 'Причины',
    resources: 'Ресурсы',
    changeNotification: 'Изменить уведомление',
    gameRatio: 'Соотношение игр',
    playerExclusion: 'Исключение игрока',
    gameTypes: 'Типы игр',
    balanceAdjustment: 'Пополнение баланса',
    sessions: 'Сессии',
    layouts: 'Лейауты',
    affiliate: 'Партнер',
    gamification: 'Геймификация',
    analytics: 'Аналитика',
    crm: 'CRM',
    liveChatSolution: 'Live Chat решение',
    agSolution: 'AG решение',
    retailSolution: 'Ритейл решение',
    playersWallets: 'Кошельки игроков',
    systemCaches: 'Системные кэши',
    dbSynchronization: 'Синхронизация баз данных ',
    jobs: 'Задачи',
    campaigns: 'Кампании',
    spins: 'Спины',
    userConfig: 'Конфигурация пользователя',
    restrictions: 'Ограничения',
    significantEvents: 'Важные события',
    serverInaccessibility: 'Недоступность сервера',
    playersWithNegativeGGR: '{{count}} Игроки с отрицательным GGR',
    playersWithPositiveGGR: '{{count}} Игроки с положительным GGR',
    promotionalItems: 'Рекламные материалы',
    walletsSettings: 'Настройки кошельков',
    promotionTool: 'Геймификация',
    transactions: 'Транзакции',
    providersAndGames: 'Провайдеры и игры',
    financialConfigurations: 'Финансовые конфигурации',
    paymentVisibility: 'Видимость платежей',
    pushNotifications: 'Push-уведомления',
    currencyConfigs: 'Конфигурации валют',
    currencySettings: 'Настройки валют',
    bonusNotifications: 'Уведомления о бонусах',
    firstDeposits: 'Первые депозиты',
    promoSpinsAdjustments: 'Настройки игровых спинов',
    byType: 'По типу',
    byStatus: 'По статусу',
    singleBets: 'Одиночные ставки',
    bulkBets: 'Крупные ставки',
    crossPlatformCampaigns: 'Кросс-платформенные кампании',
    inactivity: 'Бездействие',
    inactivePlayers: 'Неактивные игроки',
    machines: 'Автоматы',
    prizes: 'Призы',
    players: 'Игроки',
    received: 'Получено',
    sportStatusIcons: 'Значки спортивного статуса',
    unverifiedPlayersExclusions: 'Исключения неверфицированных игроков',
    topPlayers: 'Топ-игроки',
    byGgr: 'По GGR',
    byDeposits: 'По депозитам',
    byWithdrawals: 'По выводам средств',
    actions: 'Действия',
  },
  pt: {
    verification: 'Verificação',
    country: 'Países',
    currency: 'Moedas',
    language: 'Idiomas',
    domain: 'Domínios',
    captcha: 'Captcha',
    player: 'Jogadores',
    seo: 'SEO',
    socialNetwork: 'Redes Sociais',
    schemaMarkup: 'Marcação de esquema',
    openGraph: 'Gráfico aberto',
    twitter: 'Twitter',
    sms: 'SMS',
    smtp: 'SMTP',
    email: 'E-mails',
    customScript: 'Script Personalizado',
    deposit: 'Depósito',
    withdrawal: 'Levantamento',
    other: 'Outros',
    blockedCountries: 'Países Bloqueados',
    blockedIPs: 'IPs BLOQUEADOS',
    playerLimits: 'Limites do Jogador',
    failedPayments: 'Pagamentos Sem Sucesso',
    methods: 'Métodos',
    settings: 'Configurações',
    fees: 'Taxas',
    limits: 'Limites',
    sent: 'Enviado',
    scheduled: 'Agendado',
    draft: 'Rascunho',
    mainContent: 'Conteúdo Principal',
    errorMessages: 'Códigos de Erro',
    accountDetails: 'Detalhes de Conta',
    financials: 'Finanças',
    bets: 'Apostas',
    adjustments: 'Ajustes',
    bonuses: 'Bónus',
    wagers: 'Apostas',
    monitoring: 'Monitorazação',
    tags: 'Tags',
    comms: 'Comunicações',
    segments: 'Segmentos',
    referees: 'Indicados',
    notes: 'Notas',
    kpis: 'KPIs',
    attachments: 'Anexos',
    history: 'Histórico',
    details: 'Detalhes',
    payments: 'Pagamentos',
    preferences: 'Preferências',
    sport: 'Desporto',
    casino: 'Casino',
    deposits: 'Depósitos',
    withdrawals: 'Levantamentos',
    responsibleGaming: 'Jogo Responsável',
    logs: 'Registos',
    cashbackBonus: 'Cashback',
    referralBonus: 'Bónus de Indicação',
    massMessages: 'Mensagens em Massa',
    inboxMessages: 'Mensagens da Caixa de entrada',
    systemNotifications: 'Notificações do Sistema',
    userLogs: 'Registos dos Utilizadores',
    playerLogs: 'Registos do Jogador',
    previousAccounts: 'Contas Anteriores',
    similarAccounts: 'Contas Similares',
    legalAgreements: 'Acordos Legais',
    accountUpdates: 'Atualizações de Contas',
    systemTemplates: 'Modelos de Sistemas',
    customTemplates: 'Modelos Personalizados',
    special: 'Especial',
    statusUpdates: 'Atualizações de Estado',
    balanceAdjustments: 'Ajustes de Saldo',
    accumulatedCashBack: 'Acumulado',
    transferredCashBack: 'Transferido',
    failedCashBack: 'Falhou',
    accumulated: 'Acumulado',
    transferred: 'Transferido',
    failed: 'Falhou',
    userManagementLogs: 'Registos de Gestão de Utilizadores',
    closedAccounts: 'Contas Encerradas',
    playersTotals: 'Totais dos Jogadores',
    negativeBalance: 'Saldo Negativo',
    byTransaction: 'Por Transação',
    byPlayer: 'Por Jogador',
    byBrand: 'Por Marca',
    betAggregations: 'Agregações de apostas',
    financialAggregations: 'Agregações de pagamentos',
    byCurrency: 'Moeda',
    myGroups: 'Grupos Personalizados',
    defaultGroups: 'Grupos predefinidos',
    inventory: 'Inventário',
    withdraw: 'Levantamento',
    txnDetails: 'Detalhes de TXN',
    statusLogs: 'Registos de Estado',
    payeeData: 'Dados do Beneficiário',
    templates: 'Modelos',
    rules: 'Regras',
    games: 'Jogos',
    groups: 'Grupos',
    blockedProviders: 'Fornecedores Bloqueados',
    vertical: 'Vertical',
    horizontal: 'Horizontal',
    overview: 'Visão geral',
    playerVerification: 'Configurações de Verificação do Jogador',
    verificationConf: 'Configuração da Verificação',
    documentType: 'Tipo de Documento',
    reasons: 'Motivos',
    resources: 'Recursos',
    changeNotification: 'Notificação de Alteração',
    gameRatio: 'Proporção do Jogo',
    playerExclusion: 'Exclusão do Jogador',
    gameTypes: 'Tipos de Jogos',
    balanceAdjustment: 'Ajuste do Saldo',
    sessions: 'Sessões',
    layouts: 'Esquemas',
    affiliate: 'Afiliado',
    gamification: 'Gamificação',
    analytics: 'Analítica',
    crm: 'CRM',
    liveChatSolution: 'Solução de chat em direto',
    agSolution: 'Solução AG',
    retailSolution: 'Solução de Varejo',
    playersWallets: 'Carteiras de Jogadores',
    systemCaches: 'Caches do sistema',
    dbSynchronization: 'Sincronização do banco de dados',
    jobs: 'Empregos',
    campaigns: 'Campanhas',
    spins: 'Giros',
    userConfig: 'Configuração do utilizador',
    significantEvents: 'Eventos significativos',
    serverInaccessibility: 'Inacessibilidade do servidor',
    playersWithNegativeGGR: '{{contagem}} Jogadores com GGR negativo',
    playersWithPositiveGGR: '{{contagem}} Jogadores com GGR positivo',
    promotionalItems: 'Itens promocionais',
    walletsSettings: 'Definições de carteiras',
    promotionTool: 'Gamificação',
    transactions: 'Transacções',
    providersAndGames: 'Fornecedores e jogos',
    financialConfigurations: 'Configurações financeiras',
    paymentVisibility: 'Visibilidade do pagamento',
    pushNotifications: 'Notificações Push',
    currencyConfigs: 'Configurações de moeda',
    currencySettings: 'Definições de moeda',
    bonusNotifications: 'Notificações de Bónus',
    firstDeposits: 'Primeiros Depósitos',
    promoSpinsAdjustments: 'Ajustes de giros de gamificação',
    byType: 'Por Tipo',
    byStatus: 'Por Estado',
    singleBets: 'Apostas Simples',
    bulkBets: 'Apostas em massa',
    crossPlatformCampaigns: 'Campanhas multiplataforma',
    inactivity: 'Inatividade',
    inactivePlayers: 'Jogadores inactivos',
    machines: 'Máquinas',
    prizes: 'Prémios',
    players: 'Jogadores',
    received: 'Recebido',
    sportStatusIcons: 'Ícones de estado do desporto',
    unverifiedPlayersExclusions: 'Exclusões de jogadores não verificados',
    topPlayers: 'Principais jogadores',
    byGgr: 'Por GGR',
    byDeposits: 'Por Depósitos',
    byWithdrawals: 'Por Retiradas',
    actions: 'Ações',
  },
  zh: {
    verification: '验证',
    country: '国家/地区',
    currency: '货币',
    language: '语言',
    domain: '域名',
    captcha: '验证码',
    player: '玩家',
    seo: 'SEO',
    socialNetwork: '社交网络',
    schemaMarkup: '模式标记',
    openGraph: '开放图谱',
    twitter: 'Twitter',
    sms: '短信',
    smtp: 'SMTP',
    email: '电子邮件',
    customScript: '自定义脚本',
    deposit: '存款',
    withdrawal: '取款',
    other: '其他',
    blockedCountries: '被封禁的国家/地区',
    blockedIPs: '被封禁的IP',
    playerLimits: '玩家限制',
    failedPayments: '付款失败',
    methods: '方式',
    settings: '设置',
    fees: '费用',
    limits: '限制',
    sent: '已发送',
    scheduled: '定时',
    draft: '草稿',
    mainContent: '主要内容',
    errorMessages: '错误代码',
    accountDetails: '账户详情',
    financials: '财务',
    bets: '投注',
    adjustments: '调整',
    bonuses: '奖金',
    wagers: '投注',
    monitoring: '监控',
    tags: '标签',
    comms: '通信',
    segments: '分区',
    referees: '推荐人',
    notes: '备注',
    kpis: 'KPI',
    attachments: '附件',
    history: '历史',
    details: '详情',
    payments: '付款',
    preferences: '首选项',
    sport: '体育',
    casino: '娱乐场',
    deposits: '存款',
    withdrawals: '取款',
    responsibleGaming: '负责任博彩',
    logs: '日志',
    cashbackBonus: '现金返还',
    referralBonus: '介绍人奖金',
    massMessages: '大量信息',
    inboxMessages: '收件箱信息',
    systemNotifications: '系统通知',
    userLogs: '用户日志',
    playerLogs: '玩家日志',
    previousAccounts: '之前的账户',
    similarAccounts: '相似账户',
    legalAgreements: '法律协议',
    accountUpdates: '账户更新',
    systemTemplates: '系统模板',
    customTemplates: '自定义模板',
    special: '特别',
    statusUpdates: '状态更新',
    balanceAdjustments: '余额调整',
    accumulatedCashBack: '累计',
    transferredCashBack: '已转账',
    failedCashBack: '失败',
    accumulated: '已累计',
    transferred: '已转账',
    failed: '失败',
    userManagementLogs: '用户管理日志',
    closedAccounts: '已关闭账户',
    playersTotals: '玩家总计',
    negativeBalance: '负余额',
    byTransaction: '按交易',
    byPlayer: '按玩家',
    byBrand: '按品牌',
    betAggregations: '投注累计',
    financialAggregations: '付款累计',
    byCurrency: '货币',
    myGroups: '自定义组',
    defaultGroups: '默认组',
    inventory: '清单',
    withdraw: '取款',
    txnDetails: '交易详情',
    statusLogs: '状态日志',
    payeeData: '付款人数据',
    templates: '模板',
    rules: '规则',
    games: '游戏',
    groups: '组',
    blockedProviders: '被封禁的提供商',
    vertical: '垂直',
    horizontal: '水平',
    overview: '概述',
    playerVerification: '玩家验证设置',
    verificationConf: '验证配置',
    documentType: '文件类型',
    reasons: '原因',
    resources: '资源',
    changeNotification: '更改通知方式',
    gameRatio: '游戏比率',
    playerExclusion: '玩家排除',
    gameTypes: '游戏类型',
    balanceAdjustment: '余额调整',
    sessions: '会期',
    layouts: '布局',
    affiliate: '伙伴关系',
    gamification: '游戏化',
    analytics: '分析',
    crm: 'CRM',
    liveChatSolution: '即时聊天解决方案',
    agSolution: 'AG解决方案',
    retailSolution: '零售解决方案',
    playersWallets: '玩家钱包',
    systemCaches: '系统缓存',
    dbSynchronization: 'DB同步化',
    jobs: '职务',
    campaigns: '活动',
    spins: '旋转',
    userConfig: '用户配置',
    significantEvents: '重要活动',
    serverInaccessibility: '服务器无法访问',
    playersWithNegativeGGR: '{{count}}负GGR的玩家',
    playersWithPositiveGGR: '{{count}}正GGR的玩家',
    promotionalItems: '促销品',
    walletsSettings: '钱包设置',
    promotionTool: '游戏化',
    transactions: '交易',
    providersAndGames: '提供商和游戏',
    financialConfigurations: '财务配置',
    paymentVisibility: '支付可见性',
    pushNotifications: '推送通知',
    currencyConfigs: '货币配置',
    currencySettings: '货币设置',
    bonusNotifications: '奖金通知',
    firstDeposits: '首次存款',
    promoSpinsAdjustments: '游戏化旋转调整',
    byType: '按类型',
    byStatus: '按状态',
    singleBets: '单注',
    bulkBets: '批量投注',
    crossPlatformCampaigns: '跨平台活动',
    inactivity: '不活跃',
    inactivePlayers: '不活跃玩家',
    machines: '机器',
    prizes: '奖品',
    players: '玩家',
    received: '已收到',
    sportStatusIcons: '体育状态图标',
    unverifiedPlayersExclusions: '未经证实的玩家不包括在内',
    topPlayers: '顶级玩家',
    byGgr: '按GGR',
    byDeposits: '按存款',
    byWithdrawals: '按取款',
    actions: '操作',
  },
  ro: {
    blockedProviders: 'Furnizori blocați',
    tags: 'Etichete',
    failed: 'Eșuat',
    failedCashBack: 'Eșuat',
    verification: 'Verificare',
    country: 'Țară',
    currency: 'Valută',
    language: 'Limbă',
    domain: 'Domenii',
    captcha: 'Captcha (cod de verificare)',
    player: 'Jucători',
    seo: 'SEO',
    socialNetwork: 'Rețele sociale',
    schemaMarkup: 'Schemă de marcare',
    openGraph: 'Grafic deschis',
    twitter: 'Twitter',
    sms: 'SMS',
    smtp: 'SMTP',
    email: 'E-mailuri',
    customScript: 'Script personalizat',
    deposit: 'Depunere',
    withdrawal: 'Retragere',
    other: 'Alte',
    blockedCountries: 'Țări blocate',
    blockedIPs: 'IP-uri blocate',
    playerLimits: 'Limite pentru jucători',
    failedPayments: 'Plăți eșuate',
    methods: 'Metode',
    settings: 'Setări',
    fees: 'Taxe',
    limits: 'Limite',
    sent: 'Transmis',
    scheduled: 'Programat',
    draft: 'Proiect',
    mainContent: 'Conținut principal',
    errorMessages: 'Coduri de eroare',
    accountDetails: 'Detalii de cont',
    financials: 'Finanțe',
    bets: 'Pariuri',
    adjustments: 'Reîncărcări',
    bonuses: 'Bonusuri',
    wagers: 'Pariuri',
    monitoring: 'Monitorizare',
    comms: 'Comuncații',
    segments: 'Segmente',
    referees: 'Recomandații',
    notes: 'Note',
    kpis: 'KPI-uri',
    attachments: 'Atașamente',
    history: 'Istoric',
    overview: 'Prezentare generală',
    details: 'Detalii',
    payments: 'Plăți',
    preferences: 'Preferințe',
    sport: 'Sport',
    casino: 'Casino',
    deposits: 'Depuneri',
    withdrawals: 'Retrageri',
    responsibleGaming: 'Joc responsabil',
    logs: 'Logări',
    cashbackBonus: 'Cashback',
    referralBonus: 'Bonus pentru recomandări',
    massMessages: 'Mesaje în masă',
    inboxMessages: 'File de mesaje primite',
    systemNotifications: 'Notificări de sistem',
    userLogs: 'Conectările utilizatorului',
    playerLogs: 'Log-urile jucătorului',
    previousAccounts: 'Conturi anterioare',
    similarAccounts: 'Conturi asemănătoare',
    legalAgreements: 'Acorduri juridice',
    accountUpdates: 'Actualizări de cont',
    systemTemplates: 'Șabloane de sistem',
    customTemplates: 'Șabloane personalizate',
    special: 'Special',
    statusUpdates: 'Actualizări de stare',
    balanceAdjustments: 'Reîncărcări de soldului',
    accumulatedCashBack: 'Acumulat',
    transferredCashBack: 'Transferat',
    accumulated: 'Acumulat',
    transferred: 'Transferat',
    userManagementLogs: 'Logări de gestionare a utilizatorului',
    closedAccounts: 'Conturi închise',
    playersTotals: 'Total de jucători',
    negativeBalance: 'Sold negativ',
    byTransaction: 'După tranzacție',
    byPlayer: 'În funcție de jucător',
    byBrand: 'În funcție de marcă (metodă de plată)',
    betAggregations: 'Agregări de pariuri',
    financialAggregations: 'Agregări de plată',
    byCurrency: 'Valută',
    myGroups: 'Grupuri personalizate',
    defaultGroups: 'Grupuri implicite',
    inventory: 'Inventar',
    withdraw: 'Retragere',
    txnDetails: 'Detalii de tranzacție',
    statusLogs: 'Jurnale de stare',
    payeeData: 'Datele beneficiarului plății ',
    templates: 'Șabloane',
    rules: 'Reguli',
    games: 'Jucători',
    groups: 'Grupuri',
    playerVerification: 'Setări de verificare a jucătorului',
    verificationConf: 'Configurație de verificare',
    documentType: 'Tip de document',
    reasons: 'Motive',
    resources: 'Resurse',
    vertical: 'Vericală',
    horizontal: 'Orizontală',
    changeNotification: 'Notificare de schimbare',
    gameRatio: 'Raport de joc',
    playerExclusion: 'Excluderea jucătorilor',
    gameTypes: 'Tipuri de joc',
    balanceAdjustment: 'Ajustarea soldului',
    sessions: 'Sesiuni',
    affiliate: 'Afiliere',
    gamification: 'Gamificare',
    analytics: 'Analitică',
    crm: 'CRM',
    liveChatSolution: 'Soluția Live Chat',
    agSolution: 'Soluția AG',
    retailSolution: 'Soluție retail',
    layouts: 'Machete (layouturi)',
    playersWallets: 'Portofele electronice ale jucătorilor',
    systemCaches: 'Analize de sistem',
    dbSynchronization: 'Sincronizarea bazelor de date',
    jobs: 'Sarcini',
    campaigns: 'Campanii',
    spins: 'Rotiri',
    inactivity: 'Inactivitate',
    actions: 'Acțiuni',
    promotionalItems: 'Articole promoționale',
  },
  bg: {},
  el: {
    verification: 'Επαλήθευση',
    country: 'Χώρες',
    currency: 'Νομίσματα',
    language: 'Γλώσσες',
    domain: 'Τομείς',
    captcha: 'Captcha',
    player: 'Παίκτες',
    seo: 'Seo',
    socialNetwork: 'Μέσα κοινωνικής δικτύωσης',
    schemaMarkup: 'Σήμανση δομημένων δεδομένων',
    openGraph: 'Άνοιγμα γραφήματος',
    twitter: 'Twitter',
    sms: 'SMS',
    smtp: 'SMTP',
    email: 'Email',
    affiliate: 'Συνεργάτης',
    gamification: 'Gamification',
    analytics: 'Αναλυτικά στοιχεία',
    crm: 'CRM',
    liveChatSolution: 'Λύση live συνομιλίας',
    customScript: 'Προσαρμοσμένο σενάριο',
    agSolution: 'Λύση AG',
    retailSolution: 'Retail Solution',
    deposit: 'Κατάθεση',
    withdrawal: 'Ανάληψη',
    other: 'Άλλα',
    blockedCountries: 'Αποκλεισμένες χώρες',
    blockedIPs: 'ΑΠΟΚΛΕΙΣΜΕΝΕΣ IP',
    playerLimits: 'Όρια παίκτη',
    failedPayments: 'Αποτυχημένες πληρωμές',
    methods: 'Μέθοδοι',
    settings: 'Ρυθμίσεις',
    fees: 'Χρεώσεις',
    limits: 'Όρια',
    sent: 'Απεστάλη',
    scheduled: 'Προγραμματισμένο',
    draft: 'Πρόχειρο',
    mainContent: 'Κύριο περιεχόμενο',
    errorMessages: 'Κωδικοί σφαλμάτων',
    accountDetails: 'Στοιχεία λογαριασμού',
    financials: 'Οικονομικά',
    bets: 'Στοιχήματα',
    adjustments: 'Προσαρμογές',
    bonuses: 'Μπόνους',
    wagers: 'Στοιχήματα',
    monitoring: 'Παρακολούθηση',
    tags: 'Ετικέτες',
    comms: 'Επικοινωνίες',
    segments: 'Τμήματα',
    referees: 'Παραπεμπόμενοι',
    notes: 'Σημειώσεις',
    kpis: 'KPI',
    attachments: 'Συνημμένα',
    history: 'Ιστορικό',
    vertical: 'Κάθετη',
    horizontal: 'Οριζόντια',
    overview: 'Επισκόπηση',
    details: 'Στοιχεία',
    payments: 'Πληρωμές',
    preferences: 'Προτιμήσεις',
    sport: 'Άθλημα',
    casino: 'Καζίνο',
    deposits: 'Καταθέσεις',
    withdrawals: 'Αναλήψεις',
    responsibleGaming: 'Υπεύθυνο παιχνίδι',
    logs: 'Αρχεία καταγραφής',
    cashbackBonus: 'Επιστροφή μετρητών',
    referralBonus: 'Μπόνους παραπομπής',
    massMessages: 'Μαζικά μηνύματα',
    inboxMessages: 'Μηνύματα εισερχομένων',
    systemNotifications: 'Ειδοποιήσεις συστήματος',
    userLogs: 'Αρχεία καταγραφής χρήστη',
    playerLogs: 'Αρχεία καταγραφής παίκτη',
    previousAccounts: 'Προηγούμενοι λογαριασμοί',
    similarAccounts: 'Παρόμοιοι λογαριασμοί',
    legalAgreements: 'Νομικές συμβάσεις',
    accountUpdates: 'Ενημερώσεις λογαριασμού',
    systemTemplates: 'Πρότυπα συστήματος',
    customTemplates: 'Προσαρμοσμένα πρότυπα',
    special: 'Ειδικό',
    statusUpdates: 'Ενημερώσεις κατάστασης',
    balanceAdjustments: 'Προσαρμογές υπολοίπου',
    accumulatedCashBack: 'Συγκεντρωτικά',
    transferredCashBack: 'Μεταφέρθηκε',
    failedCashBack: 'Αποτυχία',
    accumulated: 'Συγκεντρωτικά',
    transferred: 'Μεταφέρθηκε',
    failed: 'Αποτυχία',
    userManagementLogs: 'Αρχεία καταγραφής διαχείρισης χρηστών',
    closedAccounts: 'Κλειστοί λογαριασμοί',
    playersTotals: 'Συνολικά παικτών',
    negativeBalance: 'Αρνητικό υπόλοιπο',
    byTransaction: 'Ανά συναλλαγή',
    byPlayer: 'Ανά παίκτη',
    byBrand: 'Ανά επωνυμία',
    betAggregations: 'Συγκεντρωτικά στοιχημάτων',
    financialAggregations: 'Συγκεντρωτικά πληρωμών',
    byCurrency: 'Νόμισμα',
    myGroups: 'Προσαρμοσμένες ομάδες',
    defaultGroups: 'Προεπιλεγμένες ομάδες',
    inventory: 'Απόθεμα',
    withdraw: 'Ανάληψη',
    txnDetails: 'Στοιχεία συναλ.',
    statusLogs: 'Αρχεία καταγραφής κατάστασης',
    payeeData: 'Δεδομένα δικαιούχου πληρωμής',
    templates: 'Πρότυπα',
    rules: 'Κανόνες',
    games: 'Παιχνίδια',
    groups: 'Ομάδες',
    playerVerification: 'Ρυθμίσεις επαλήθευσης παίκτη',
    verificationConf: 'Διαμόρφωση επαλήθευσης',
    documentType: 'Τύπος εγγράφου',
    blockedProviders: 'Αποκλεισμένοι πάροχοι',
    reasons: 'Αιτίες',
    resources: 'Πόροι',
    changeNotification: 'Ειδοποίηση αλλαγής',
    gameRatio: 'Αναλογία διαστάσεων παιχνιδιού',
    playerExclusion: 'Αποκλεισμός παίκτη',
    gameTypes: 'Τύποι παιχνιδιού',
    balanceAdjustment: 'Προσαρμογή υπολοίπου',
    sessions: 'Συνεδρίες',
    layouts: 'Διατάξεις',
    playersWallets: 'Πορτοφόλια παικτών',
    systemCaches: 'Cache συστήματος',
    dbSynchronization: 'Συγχρονισμός DB',
    jobs: 'Εργασίες',
    significantEvents: 'Σημαντικά γεγονότα',
    serverInaccessibility: 'Έλλειψη προσβασιμότητας διακομιστή',
    playersWithNegativeGGR: '{{count}} παίκτες με αρνητικό GGR',
    playersWithPositiveGGR: '{{count}} παίκτες με θετικό GGR',
    walletsSettings: 'Ρυθμίσεις πορτοφολιών',
    spins: 'Περιστροφές',
    transactions: 'Συναλλαγές',
    providersAndGames: 'Πάροχοι και παιχνίδια',
    financialConfigurations: 'Οικονομικές διαμορφώσεις',
    paymentVisibility: 'Ορατότητα πληρωμής',
    pushNotifications: 'Ειδοποιήσεις push',
    campaigns: 'Καμπάνιες',
    inactivity: 'Αδράνεια',
    actions: 'Ενέργειες',
    promotionalItems: 'Προωθητικά Είδη',
  },
};
