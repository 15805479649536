import styled from 'styled-components';
import { Drawer, Layout } from 'antd';
import {
  flexContainer, media, stickyContainer, truncate,
} from 'utils/styledHelpers';

const StyledHeader = styled.div`
  .rc-virtual-list-holder-inner {
    height: auto;
    max-height: 20rem;
  }
  .ant-select-item-option-content {
    height: auto;
  }
  box-shadow: ${(props) => props.theme.shadows.headerShadow};
  position: relative;
  z-index: 2001; // Must be bigger than Loading component z-index
  width: 100%;
  .ant-layout-header {
    position: relative;
    ${flexContainer({
    align: 'center',
    justify: 'space-between',
    direction: 'initial',
  })};
    gap: 1rem;
    background: ${(props) => props.theme.colors.tertiaryBg};
    height: 3.43rem;
    line-height: 2.86rem;
    padding: 0;
  }
  .ant-select {
    width: 12.14rem;
  }

  ${media.sm`
    position: sticky;
    top: 0;
  `}
`;
const StyledAccountWrapper = styled.div`
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: transparent;
  }

  > .ant-dropdown-menu {
    padding: 0.7rem 0 0;
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusMiddle};
    box-shadow: none;

    .ant-dropdown-menu-item {
      white-space: nowrap;
      height: 40px;
      line-height: 40px;
      margin: 0;
      padding: 0 2rem;
      font-size: 1rem;

      .ant-menu-item:focus-visible,
      .ant-menu-submenu-title:focus-visible {
        box-shadow: none;
      }
      &:hover {
        background: transparent;
        svg path,
        svg ellipse {
          fill: ${(props) => props.theme.colors.blue};
        }
      }
      &:first-child {
        font-size: 1.14rem;
        font-weight: ${(props) => props.theme.typography.fontWeightMedium};
        margin-bottom: 0;
        padding: 0 2rem;
        color: ${(props) => props.theme.colors.primaryText};
        line-height: 1.4rem;
        height: 2.5rem;
        pointer-events: none;
      }
      &:last-child {
        border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
        height: 3.43rem;
        line-height: 3.43rem;
        margin-top: 0.86rem;
        border-radius: ${(props) => `0 0 ${props.theme.customBorderRadius.borderRadiusMiddle} ${props.theme.customBorderRadius.borderRadiusMiddle}`};
        &:hover {
          background: ${(props) => props.theme.colors.primaryBg};
          color: ${(props) => props.theme.colors.primaryText};
          svg path,
          svg ellipse {
            fill: ${(props) => props.theme.colors.secondaryColor};
          }
        }
        div:hover {
          color: ${(props) => props.theme.colors.primaryText};
        }
      }
    }
    svg {
      margin-right: 1.14rem;
    }

    .ant-dropdown-menu-item div {
      display: flex;
      color: ${(props) => props.theme.colors.primaryText};
    }
    .ant-dropdown-menu-item:not(:last-child) {
      &:hover {
        div {
          color: ${(props) => props.theme.colors.blue};
        }
      }
    }
  }
`;

const StyledLogoWrapper = styled.div`
  font-size: ${(props) => props.theme.typography.fontSizeSmall};
  max-width: ${(props) => props.menuWidth};
  border-right: 1px solid #4f5763;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.42rem;
  div {
    width: ${(props) => (!props.menuClose ? '9.6rem' : '1.71rem')};
  }
  ${media.sm`
      max-width: 4.6rem;
      opacity: 0;
  `}
`;
const StyledSidebar = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  background: ${(props) => props.theme.colors.secondaryBg};
  .ant-menu {
    color: ${(props) => props.theme.colors.blue};
  }
  .ant-menu.ant-menu-root {
    background: initial;
    transition: initial;
    width: 100%;
    overflow-y: overlay;
    svg g path,
    svg g,
    svg path,
    svg g g rect {
      fill: ${(props) => props.theme.colors.secondaryColor};
    }
    .ant-menu-submenu {
      overflow: hidden;
      border-radius: 0;
      .ant-menu {
        background: transparent;
      }
      &.ant-menu-submenu-active .ant-menu-title-content > span {
        color: ${(props) => props.theme.colors.blue};
      }
      .ant-menu-submenu-title:active {
        background: transparent;
      }
      .ant-menu-submenu-title {
        .ant-menu-title-content {
          display: flex;
          align-items: center;
        }
        height: 3.5rem;
        line-height: 3.4;
        margin: 0 !important;
        padding: 0 1.43rem 0 1.6rem !important;
        display: flex;
        color: ${(props) => props.theme.colors.primaryText};
        width: calc(100% + 1px);
        border-radius: 0;

        &:hover {
          .ant-menu-submenu-arrow:before,
          .ant-menu-submenu-arrow::after {
            background: ${(props) => props.theme.colors.blue};
          }
        }
        span {
          font-size: ${(props) => props.theme.typography.fontSizeDefault};
          color: ${(props) => props.theme.colors.primaryText};
          @media (max-width: 1280px) {
            font-size: 1.1rem;
          }
        }
        .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-arrow::after {
          background-color: ${(props) => props.theme.colors.secondaryColor};
          background-image: none;
        }
      }
      &.ant-menu-submenu-selected .ant-menu-submenu-title {
        color: ${(props) => props.theme.colors.primaryText};
        background-color: ${(props) => props.theme.colors.hoverBg};
        .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-arrow::after {
          background-color: ${(props) => props.theme.colors.blue};
        }
      }
      div {
        margin-right: 26px;
      }
      .ant-menu-sub {
        margin-left: 48px;
        width: calc(100% - 48px);
        padding: 18px 0;
        li {
          border-left: 1px solid ${(props) => props.theme.colors.primaryBorder};
          height: 2.28rem;
          padding-left: 21px !important;
          margin: 0 !important;
          border-radius: 0;
          &.ant-menu-item-selected,
          &:hover {
            border-left: 1px solid ${(props) => props.theme.colors.blue};
            background-color: transparent;
            span {
              color: ${(props) => props.theme.colors.blue};
            }
          }
        }
      }
    }
    .ant-menu-submenu {
      &.ant-menu-submenu-active {
        div.ant-menu-submenu-title > span {
          color: ${(props) => props.theme.colors.blue};
        }
        svg g path,
        svg g,
        svg g rect,
        svg ellipse,
        svg path {
          fill: ${(props) => props.theme.colors.blue};
        }
      }
    }
    .ant-menu-submenu-selected {
      > div {
        background-color: ${(props) => props.theme.colors.hoverBg};
        span {
          color: ${(props) => props.theme.colors.blue}!important;
        }
        svg g path,
        svg g,
        svg ellipse,
        svg g rect,
        svg path {
          fill: ${(props) => props.theme.colors.blue};
        }
      }
    }

    .ant-menu-item {
      padding: 0 1.43rem 0 1.6rem !important;
      transition: initial;
      width: 100%;
      height: 3.42rem;
      margin: 0;
      line-height: 3.4;
      display: flex;
      &:focus-visible {
        box-shadow: none;
      }
      &:hover {
        span {
          color: ${(props) => props.theme.colors.blue};
        }
        svg g path,
        svg g,
        svg path,
        svg g g rect {
          fill: ${(props) => props.theme.colors.blue};
        }
      }

      &:after {
        border-right: initial;
        left: 0;
      }

      &.ant-menu-item-selected {
        background-color: ${(props) => props.theme.colors.hoverBg};

        span {
          color: ${(props) => props.theme.colors.blue};
        }
        &:first-child {
          svg g rect {
            fill: ${(props) => props.theme.colors.blue};
          }
        }
        svg g path,
        svg g,
        svg path {
          fill: ${(props) => props.theme.colors.blue};
        }
      }
    }
  }
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: ${(props) => props.theme.colors.hoverBg};
  }
`;

const StyledSiderWrapper = styled(Layout.Sider)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 560;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.29);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.29);
  overflow-y: auto;
  transition: 0.3s transform;

  ${media.sm`
    position: fixed;
    top: 3.43rem;
    height: calc(100% - 3.43rem);
    transform: translateX(${(props) => (props.collapsed ? '-100%' : '0')});
  `}

  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    transform: rotate(270deg);
    top: 45%;
    transition: all 0ms ease-in-out;
    right: 1.71rem;
  }
  .ant-menu-submenu-inline.ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    transform: rotate(180deg);
    top: 50%;
    right: 1.71rem;
    transition: all 0ms ease-in-out;
  }
  .ant-layout-sider-children {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`;

const StyledMenuItem = styled.div`
 height: 100%;
 ${flexContainer()};
 justify-content: flex-start;
 text-align: left;
 line-height: normal;
 color: ${(props) => props.theme.colors.secondaryColor};
 padding-left: 0 !important;
 margin: 0 !important;
  > span {
  color: ${(props) => props.theme.colors.primaryText};
  font-size: ${(props) => props.theme.typography.fontSizeDefault};
    @media (max-width:1280px){
    font-size:1.1rem
    };
  }
}
> div {
 margin-right: 26px;
}
`;
const StyledList = styled.ul`
  .ant-select-item {
    height: 2.57rem;
    color: ${(props) => props.theme.colors.primaryText};
    align-items: center;
  }
  height: 100%;
  ${flexContainer()};
  list-style-type: none;
  padding: 0;
  margin: 0;
  .ant-select-selection-search {
    color: ${(props) => props.theme.colors.white};
  }
  li:hover {
    > svg {
      &:hover {
        g path,
        path {
          fill: ${(props) => props.theme.colors.hover};
        }
      }
    }
  }
`;
const StyledListItem = styled.li`
  height: 100%;
  width: ${(props) => (props.flexContainer ? '354px' : 'auto')};
  position: relative;
  ${flexContainer()};
  background-color: ${(props) => (props.flexContainer ? props.theme.colors.primaryBg : 'transparent')};
  padding: ${(props) => (props.flexContainer ? '0 16px' : '0 20px')};
  justify-content: center;
  div {
    height: 100%;
    ${flexContainer()};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    font-size: ${(props) => props.theme.typography.fontSizeDefault};
    color: ${(props) => props.theme.colors.primaryText};
    cursor: pointer;
  }
  h3 {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    font-size: ${(props) => props.theme.typography.fontSizeSmall};
    color: ${(props) => props.theme.colors.secondaryBorder};
    padding-right: 16px;
    margin: 0;
    display: flex;
    align-items: center;
  }
`;

const StyledListItemHeader = styled(StyledListItem)`
  padding: ${(props) => (props.smallPadding ? '0 0.57rem' : '0 1.71rem')};
  svg path {
    fill: ${(props) => props.theme.colors.white};
  }
  &:not(:first-child) {
    width: 3.8rem;

    ${media.xs`
      padding: 0 0.57rem;
      width: 3rem;
    `}
  }
  > div {
    height: 2.14rem;
    padding: 0 0.57rem;
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    &:hover {
      background: ${(props) => props.theme.colors.darkColor};
    }
  }
`;

const StyledListFirstItemHeader = styled.li`
  .ant-select.ant-select-focused.ant-select-show-search .ant-select-selector {
    box-shadow: none;
  }
  &:first-child {
    padding: 0;
    display: flex;
    align-items: center;
    .ant-select {
      width: 17.1rem;
      height: 2.28rem;
      margin-right: 1.14rem;
    }
    .ant-select-arrow {
      right: 1.14rem;
    }
    .ant-select-focused.ant-select-single.ant-select-open:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
      color: ${(props) => props.theme.colors.filterLabelColor};
    }
    .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
      background: #4f5763;
      padding-left: 1.14rem;
      border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    }
    .ant-select .ant-select-selection-item {
      line-height: 2.3;
      color: ${(props) => props.theme.colors.white};
    }
    div {
      font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    }
  }
`;

const StyledListItemViewMode = styled.span`
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.typography.fontSizeSmall};
  line-height: 1.14rem;
  font-weight: ${(props) => (props.isBold ? props.theme.typography.fontWeightBold : props.theme.typography.fontWeightRegular)};
  margin-right: 1.71rem;
  ${truncate()}

  ${media.xs`
    margin-right: 1rem;
    max-width: 4rem;
  `}

  &:not(:last-child) {
    padding-right: 1.71rem;
    border-right: 1px solid ${(props) => props.theme.colors.white};

    ${media.xs`
      padding-right: 1rem;
    `}
  }
`;

const StyledHeaderSelectWrapper = styled.div`
  ${media.sm`
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.colors.tertiaryBg};
    position: absolute;
    z-index: 9;
    left: ${(props) => (props.isOpen ? '22.7rem' : '-100%')};
    width: calc(100% - 22.7rem);
    top: 50%;
    transform: translateY(-50%);
    transition: .3s all;
    
    & > .ant-select.ant-select-single {
      width: 100%;
      margin: 0;
    }
  `}

  ${media.xs`
    width: calc(100% - 5.8rem);
    left: ${(props) => (props.isOpen ? '5.8rem' : '-100%')};
  `}
`;
const StyledHeaderSelectToggle = styled.div`
  cursor: pointer;
  display: none;
  padding: 1rem;

  ${media.sm`
    display: block;
  `}
`;
const StyledHeaderSelectClose = styled.div`
  cursor: pointer;
  display: none;
  padding: 1rem 1.4rem;

  ${media.sm`
    display: block;
  `}
`;

const StyledAnimationModeSun = styled.div`
  svg {
    transition: all 1s;
    &:hover {
      transform: rotate(360deg);
      path {
        fill: ${(props) => props.theme.colors.yellow}; !important;
      }
    }
    path {
      transition: all 1s;
    }
  }
`;
const StyledDrawerWrapper = styled(Drawer)`
  // FastSearch
  .ant-select-single .ant-select-selector .ant-select-selection-search-input {
    height: 2.57rem;
  }
  .ant-drawer-title {
    line-height: 20px;
  }
  .ant-select {
    cursor: initial;
  }
  .ant-select-auto-complete .ant-select-clear {
    right: 3.2rem;
  }
  .search-result &.no-result {
    pointer-events: none;
    cursor: default;
  }
  .ant-drawer-header {
    padding: 1rem 0 1rem 2.85rem;
    margin-bottom: 3.9rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
    position: relative;
  }
  .ant-drawer-title {
    color: ${(props) => props.theme.colors.primaryText};
    font-size: 1.14rem;
  }
  &.ant-drawer-content,
  .ant-drawer-header {
    background: ${(props) => props.theme.colors.secondaryBg};
  }
  .ant-select {
    margin-bottom: 0;
  }
  .ant-drawer-body {
    padding: 0 2.85rem;
    font-size: 1rem;
    > div {
      color: ${(props) => props.theme.colors.primaryText};
    }
    .ant-select.ant-select-auto-complete.ant-select-single {
      width: 100%;
      height: auto;
      margin-bottom: 1.42rem !important;
      margin-top: 0.57rem !important;
    }
  }
  .ant-select-selector {
    padding-right: 0;
  }
  .ant-drawer-close {
    margin-right: 1.14rem;
    font-size: 1.28rem;
    position: absolute;
    right: 0;
  }
`;
const StyledSearchWrapper = styled.div`
  padding: 0 0.28rem;
`;
const StyledSearchDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.colors.primaryText};
  margin-bottom: ${(props) => (!props.isMarginBottom ? '0.58rem' : 0)};
  font-size: 0.85rem;
`;
const StyledProviderDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const StyledPlayerDetails = styled.div`
  color: ${(props) => props.theme.colors.primaryText};
`;
const StyledProviderType = styled.div`
  color: ${(props) => props.theme.colors.secondaryColor};
  font-size: 0.85rem;
`;
const StyledProviderDot = styled.div`
  color: ${(props) => props.theme.colors.primaryText};
  font-size: 0.85rem;
  margin: 0 0.57rem;
  width: 4px;
  height: 4px;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusCircle};
  background: ${(props) => props.theme.colors.secondaryColor};
`;

const StyledProviderName = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.colors.primaryText};
  font-size: 0.85rem;
`;
const StyledBrandName = styled.div`
  background: ${(props) => props.theme.colors.primaryBg};
  padding: 0 0.57rem;
  border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
`;
const StyledStickyWrapper = styled.div`
  background: ${(props) => props.theme.colors.selectBg};
  ${stickyContainer};
`;
const StyledStickyNote = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > div {
    margin-right: 0.57rem;
  }
  p {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.primaryText};
    line-height: 20px;
    & > span {
      margin: 0 4px;
    }
  }

  ${media.sm`
    display: none;
  `}
`;
const StyledAccountMarginTop = styled.div`
  margin-top: 2.85rem;
  height: 100%;
  > div {
    height: 100%;
  }
`;
const StyleFlagsWrapper = styled.span`
  .ant-menu-vertical .ant-menu-item {
    margin: 0;
  }
  .ant-menu {
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
    padding: 4px 0;
    border-radius: ${(props) => props.theme.customBorderRadius.borderRadiusSmall};
    .ant-menu-item.ant-menu-item-active {
      background: ${(props) => props.theme.colors.hoverBg};
      span {
        color: ${(props) => props.theme.colors.primaryText};
      }
    }
  }
`;

const StyledSystemConversion = styled.div`
  ${media.sm`
    display: flex;
    align-items: center;
    width: 100%;
  `}
  .ant-select.ant-select-show-arrow {
    width: 6rem;

    ${media.sm`
      width: 100%;
      margin-right: 0;
    `}
  }
`;

export {
  StyledHeader,
  StyledSidebar,
  StyledSiderWrapper,
  StyledList,
  StyledListItem,
  StyledListItemHeader,
  StyledLogoWrapper,
  StyledAnimationModeSun,
  StyledListFirstItemHeader,
  StyledListItemViewMode,
  StyledHeaderSelectWrapper,
  StyledHeaderSelectToggle,
  StyledHeaderSelectClose,
  StyledAccountWrapper,
  StyledDrawerWrapper,
  StyledMenuItem,
  StyledStickyWrapper,
  StyledStickyNote,
  StyledAccountMarginTop,
  StyleFlagsWrapper,
  StyledBrandName,
  StyledSearchDetails,
  StyledProviderName,
  StyledSearchWrapper,
  StyledProviderDetails,
  StyledProviderType,
  StyledProviderDot,
  StyledPlayerDetails,
  StyledSystemConversion,
};
