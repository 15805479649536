import { createSelector } from 'reselect';
import { selectUserBrands } from './authSelector';

export const selectBrand = (state) => state.brand;
export const selectBrands = (state) => state.brands;
export const selectBrandLoading = (state) => state.loading;
export const selectCurrentBrandId = (state) => state.currentBrandId;
export const selectBrandName = (state) => selectBrand(state).name;
export const selectBrandMarkets = (state) => state.markets;
export const selectIsBrandExist = (state) => state.isBrandExist;
export const selectBrandTemplates = (state) => state.templates;
export const selectBrandCallEndedStatus = (state) => state.callEndedStatus;
export const selectBrandSportsBookState = (state) => selectBrand(state).isSport;

export const selectCurrentBrand = (state) => createSelector(
  () => selectUserBrands(state.auth),
  () => selectCurrentBrandId(state.brands),
  (userBrands, id) => userBrands?.find((brand) => brand.id === id),
)();

export const selectCurrentBrandVersion = (state) => createSelector(
  () => selectUserBrands(state.auth),
  () => selectCurrentBrandId(state.brands),
  (userBrands, id) => {
    const currentBrand = userBrands?.find((brand) => brand.id === id);
    return currentBrand?.isNewVersion;
  },
)();
